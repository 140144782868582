/** Live Session Fixture
 *
 *  Step level test data for live session
 */
import type { ILiveSessionData } from '@unionco/alaris-app-types'

import { liveSessionIntroFixture } from './liveSessionIntro'

import { liveSessionSummaryFixture } from '@fixtures/phase'

export const liveSessionFixture = {
  ...liveSessionSummaryFixture,
  segments: [liveSessionIntroFixture]
} as ILiveSessionData
