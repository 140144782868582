/** Collapisble Items Card
 *  A Card with expand all and collapse all buttons inside of header
 *  and a add item button at the bottom
 *
 *  Utilized for team members forms
 */
import CollapsibleItemsCardBody, {
  TCollapsibleItemsCardBodyComponent
} from './CollapsibleItemsCardBody'
import CollapsibleItemsCardHeader, {
  TCollapsibleItemsCardHeaderComponent
} from './CollapsibleItemsCardHeader'

interface ICollapsibleItemsCard {
  Body: TCollapsibleItemsCardBodyComponent
  Header: TCollapsibleItemsCardHeaderComponent
}

export const CollapsibleItemsCard: ICollapsibleItemsCard = {
  Body: CollapsibleItemsCardBody,
  Header: CollapsibleItemsCardHeader
}
