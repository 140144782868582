/** Copy Block Fixture
 *
 */
import type { ICopyBlockData } from '@unionco/alaris-app-types'

import { titleAndCopyTestData } from './titleAndCopy'

export const copyBlockFixture = (): ICopyBlockData => {
  return {
    ...titleAndCopyTestData('', ''),
    buttonText: '',
    moduleType: 'copyBlock'
  }
}
