import { IconSVG } from '@unionco/svg-icons'

import { IIconBaseProps, TSVGProps } from '@unionco/alaris-app-types'

const AddIconSolid: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <IconSVG viewBoxWidth={48} {...props} ref={undefined}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 0C10.752 0 0 10.752 0 24C0 37.248 10.752 48 24 48C37.248 48 48 37.248 48 24C48 10.752 37.248 0 24 0ZM34.2857 25.7143H25.7143V34.2857C25.7143 35.2286 24.9429 36 24 36C23.0571 36 22.2857 35.2286 22.2857 34.2857V25.7143H13.7143C12.7714 25.7143 12 24.9429 12 24C12 23.0571 12.7714 22.2857 13.7143 22.2857H22.2857V13.7143C22.2857 12.7714 23.0571 12 24 12C24.9429 12 25.7143 12.7714 25.7143 13.7143V22.2857H34.2857C35.2286 22.2857 36 23.0571 36 24C36 24.9429 35.2286 25.7143 34.2857 25.7143ZM2.4 24C2.4 35.907 12.093 45.6 24 45.6C35.907 45.6 45.6 35.907 45.6 24C45.6 12.093 35.907 2.4 24 2.4C12.093 2.4 2.4 12.093 2.4 24Z'
        fill='currentColor'
      />
    </IconSVG>
  )
}

const AddIconOutline: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <IconSVG viewBoxWidth={48} {...props} ref={undefined}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 24C0 10.752 10.752 0 24 0C37.248 0 48 10.752 48 24C48 37.248 37.248 48 24 48C10.752 48 0 37.248 0 24ZM25.7143 25.7143H34.2857C35.2286 25.7143 36 24.9429 36 24C36 23.0571 35.2286 22.2857 34.2857 22.2857H25.7143V13.7143C25.7143 12.7714 24.9429 12 24 12C23.0571 12 22.2857 12.7714 22.2857 13.7143V22.2857H13.7143C12.7714 22.2857 12 23.0571 12 24C12 24.9429 12.7714 25.7143 13.7143 25.7143H22.2857V34.2857C22.2857 35.2286 23.0571 36 24 36C24.9429 36 25.7143 35.2286 25.7143 34.2857V25.7143Z'
        fill='currentColor'
      />
    </IconSVG>
  )
}

export const AddIcon: React.FC<IIconBaseProps> = ({
  type = 'solid',
  ...props
}) => {
  switch (type) {
    case 'solid':
      return <AddIconSolid {...props} />
    case 'outline':
      return <AddIconOutline {...props} />
    default:
      return <></>
  }
}

export default AddIcon
