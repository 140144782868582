import {
  ILocationsIndexesData,
  IQuestionSegmentData,
  IUserProgressionKeysData,
  TPhaseStepSummaryData
} from '@unionco/alaris-app-types'

import { getIndexFromKey } from '../array'

function getProgressionIndexes(
  stepSummaries: TPhaseStepSummaryData[],
  locationKeys: IUserProgressionKeysData
) {
  const { question, segment, step } = locationKeys
  const sectionIndex = getIndexFromKey(stepSummaries, step)
  const stepData = stepSummaries[sectionIndex]
  // Segment

  // If stepData or stepData.segments is undefined, return 0 for all indexes
  if (!stepData || !stepData.segments) {
    return {
      section: 0,
      segment: 0,
      question: 0
    } as ILocationsIndexesData
  }

  const segments = stepData.segments
  const segmentIndex = getIndexFromKey(segments, segment)
  const currentSegment = segments[segmentIndex]
  // Question
  const isQuestionSegment = currentSegment.hasOwnProperty('questions')
  const questionIndex =
    isQuestionSegment && question && question !== ''
      ? getIndexFromKey(
        (currentSegment as IQuestionSegmentData).questions,
        question
      )
      : null

  return {
    section: sectionIndex,
    segment: segmentIndex,
    question: questionIndex
  } as ILocationsIndexesData
}

export { getProgressionIndexes }
