/** Team Member Header
 *  Used in both founder card and employee cards
 */

// Partial of ITeamMemberData
interface ITeamMemberHeaderProps {
  firstName: string
  lastName: string
  title: string
  age?: number
}

export const TeamMemberHeader: React.FC<ITeamMemberHeaderProps> = ({
  age,
  firstName,
  lastName,
  title
}) => {
  const name = `${firstName} ${lastName}`
  return (
    <div className='print:u-break-inside-avoid-page'>
      <h5 className='print:u-break-before-avoid-page'>
        {name}
        {age && <span>, {age}</span>}
      </h5>
      <p className='o-body-2'>{title}</p>
    </div>
  )
}

export default TeamMemberHeader
