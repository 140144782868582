/** Swiper component
 *  React component wrapper for the swiper web component
 *  @see https://swiperjs.com/blog/using-swiper-element-in-react
 */
import { useEffect, useRef } from 'react'
import { SwiperContainer, register } from 'swiper/element/bundle'
import { SwiperProps } from 'swiper/react'

// register()

interface ISwiperProps extends SwiperProps {
  children: React.ReactNode
}

export const SwiperUI: React.FC<ISwiperProps> = ({ children, ...rest }) => {
  const swiperRef = useRef<SwiperContainer>(null)

  useEffect(() => {
    if (!swiperRef || !swiperRef.current) return
    const { current: swiperEl } = swiperRef
    // Register Swiper web component
    register()

    // Add event listener
    swiperRef.current.addEventListener('slidechange', (e) => {
      console.log(e.detail)
    })

    // pass component props to parameters
    const params = {
      ...rest
    }

    // Assign it to swiper element
    Object.assign(swiperRef.current, params)

    // initialize swiper
    swiperEl.initialize()
  }, [rest, swiperRef])

  return (
    <swiper-container init={false} ref={swiperRef} style={{ maxWidth: '100%' }}>
      {children}
    </swiper-container>
  )
}
