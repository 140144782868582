/** Questionnaire Component
 * A middle component for questionnaire intro, question, or review as main content of quesitonnaire template
 */
import axios from 'axios'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'

import type {
  IPhaseSummaryData,
  IQuestionIntroSegmentData,
  IQuestionnaireData
} from '@unionco/alaris-app-types'

import { API_BASE, DashboardPageSlug } from '@appConstants'

import { getSidebarRoute } from '../SidebarTemplate/util'
import { isAdminTracking } from 'utils/tracking'
import { questionnaireCompleteTracking } from 'utils/tracking/questionnaire'

import { IAMContext, TClientContext, TIAMContext, UserContext } from 'context'

import { ErrorUI, SectionIntro, SidebarContext } from 'components'

import { QuestionnaireContext } from './context'
import Question from './question'
import QuestionnaireReview from './review'

interface IQuestionnaireProps {
  questionnaireData: IQuestionnaireData
}

export const Questionnaire: React.FC<IQuestionnaireProps> = ({
  questionnaireData
}) => {
  const router = useRouter()
  // User Data:
  const { jwt, userType } = useContext(UserContext) as TClientContext
  const {
    IAMData,
    setIAM,
    setReload: updateUserContext,
    setShowResumeInitially
  } = useContext(IAMContext) as TIAMContext
  const { id: userId, progression } = IAMData
  // Template Data:
  const { pageLocations, pageRoute, sidebarData } = useContext(SidebarContext)
  const {
    question: questionIndex,
    section: sectionIndex,
    segment: segmentIndex
  } = pageLocations
  const [previousPage, setPreviousPage] = useState<string>('')
  const phaseSummary = sidebarData as IPhaseSummaryData
  const activeQuestionnaire: IQuestionnaireData = questionnaireData
  const currentSegment = activeQuestionnaire.segments[segmentIndex]

  const { phase: phaseKey } = progression

  const submitQuestionnaire = async () => {
    const numOfSteps = (sidebarData as IPhaseSummaryData).steps.length
    const isLastStep = sectionIndex === numOfSteps - 1
    const steps = (sidebarData as IPhaseSummaryData).steps
    const currentStep = steps[sectionIndex]
    const stepKey = currentStep.key
    const segments = currentStep.segments
    const segmentKey = segments[segmentIndex].key

    const body = {
      phase: { key: phaseKey },
      segment: { index: segmentIndex, key: segmentKey },
      step: { index: sectionIndex, key: stepKey },
      userId,
      phaseFlag: isLastStep
    }

    const result = await axios.post(`${API_BASE}/api/steps/complete`, body, {
      headers: { Authorization: `Bearer ${jwt}` }
    })

    if (result.status === 200) {
      const isAdmin = isAdminTracking(userType)
      setIAM({ ...IAMData, lastLocationIndexes: undefined })
      setShowResumeInitially(false)
      updateUserContext(true)
      router.push(DashboardPageSlug)
      questionnaireCompleteTracking({
        is_admin_key: isAdmin,
        questionanire_title: questionnaireData.title
      })
    }
  }

  /** Reset show resume initially
   *
   */
  useEffect(() => {
    setShowResumeInitially(false)
  }, [setShowResumeInitially])

  /** Update user last location effect
   *  When page is changed updates user last location in DB
   *  so that when user comes back to site it has a flag to check
   *  if users progression is equal to last location, displaying
   *  a continue UI so they can pick up where they previously were.
   */
  useEffect(() => {
    if (previousPage !== window.location.href) {
      const updateUserLocation = async () => {
        /**
         * Get Keys of current location
         */
        const phaseSteps = phaseSummary.steps
        const step = phaseSteps[sectionIndex]
        const segment = step.segments[segmentIndex]
        const question =
          segment.type === 'questionSegment'
            ? segment.questions[questionIndex as number].key
            : null

        const updatedUserLocation = {
          userId,
          phase: phaseKey,
          step: step.key,
          segment: segment.key,
          question
        }

        /**
         *  Send keys to progression endpoint with location flag
         *  Keys are kept in DB for a better reference point for
         *  eventual updating of questionnaires
         */
        const result = await axios.post(
          `${API_BASE}/api/user-progression?isLocation=true`,
          updatedUserLocation,
          {
            headers: {
              Authorization: `Bearer ${jwt}`
            }
          }
        )

        /**
         *  Update the application state
         *  which uses indexes for speed, since collections are arrays,
         *  and thus don't need to be searched through each time
         */
        if (result.status === 200) {
          setPreviousPage(window.location.href)
          setIAM({ ...IAMData, lastLocationIndexes: pageLocations })
        }
      }

      updateUserLocation()
    }
  }, [
    userId,
    jwt,
    IAMData,
    phaseKey,
    phaseSummary.steps,
    pageLocations,
    previousPage,
    setIAM,
    questionIndex,
    segmentIndex,
    sectionIndex
  ])

  const getQuestionnaireContent = () => {
    switch (currentSegment.type) {
      case 'liveSessionIntro':
      case 'questionIntro': {
        // 0 = intro
        if (!currentSegment)
          return (
            <div className='u-flex'>
              <ErrorUI text='Error: Unable to load section intro' />
            </div>
          )
        const {
          icon,
          status: { percent, statusText },
          title,
          type
        } = activeQuestionnaire
        const {
          estimatedTime,
          introCopy,
          introImage,
          title: segmentTitle
        } = currentSegment as IQuestionIntroSegmentData
        return (
          <SectionIntro
            copy={introCopy}
            estimatedTime={estimatedTime}
            eyebrow={statusText}
            header={title}
            link={getSidebarRoute(pageRoute, activeQuestionnaire.key, 1, 0)}
            backgroundImage={introImage}
            icon={icon}
            percent={percent}
            sectionType={type}
            inQuestionnaire
            segmentTitle={segmentTitle}
          />
        )
      }
      case 'questionReview':
        // Last = review
        return <QuestionnaireReview segmentTitle={currentSegment.title} />
      case 'rangeSegment':
      default: {
        // 0 < x = Question
        return (
          <Question
            data={currentSegment}
            questionnaireTitle={activeQuestionnaire.title}
            segmentTitle={currentSegment.title}
          />
        )
      }
    }
  }

  if (!previousPage) return <></>

  return (
    <QuestionnaireContext.Provider value={{ submitQuestionnaire }}>
      {getQuestionnaireContent()}
    </QuestionnaireContext.Provider>
  )
}

export default Questionnaire
