/** Img Component
 *  Wrapper of next Image component for easier prop spreading
 *  With out this component, prop "...rest" spreading is more difficult
 *  @see https://nextjs.org/docs/api-reference/next/image#loader
 */
import Image from 'next/image'

import { TImageProps } from '@unionco/alaris-app-types'

export const Img: React.FC<TImageProps> = ({ alt, src, ...props }) => {
  return <Image src={src} alt={alt || ''} {...props} />
}

export default Img
