import { StyledSelect } from '@unionco/components'

import { fieldIsValid } from '../utils'
import { cx } from '@unionco/utils'

import LabelAndInput from '../labelAndInput'

interface IYesNoProps {
  defaultValue: string
  label: string
  name: string
  validation: null | true | string[]
}

export const YesNoSelect: React.FC<IYesNoProps> = ({
  defaultValue,
  label,
  name,
  validation
}) => {
  return (
    <LabelAndInput label={label} required className='u-basis-[calc(66%-.5rem)]'>
      <StyledSelect
        className={cx('u-text-secondary-800', fieldIsValid(name, validation))}
        classNames={{ select: 'u-bg-primary-300 u-text-secondary-800' }}
        name={name}
        defaultValue={defaultValue}
        required
      >
        <option value={'unanswered'}>Select an option</option>
        <option value={'yes'}>Yes</option>
        <option value={'no'}>No</option>
      </StyledSelect>
    </LabelAndInput>
  )
}

export default YesNoSelect
