import { useContext } from 'react'
import tw from 'twin.macro'

import { IMatchData } from '@unionco/alaris-app-types'

import { isAdminTracking } from 'utils/tracking'
import { exploreBuyerMatchTracking } from 'utils/tracking/exploreMatch'

import { TUserContext, UserContext } from 'context'

import { Img, StyledButton } from 'components/generic'
import { ModuleCard } from 'components/layout'

import HeatMapGrid from '../HeatMapGrid'

interface ITopMatchCardProps {
  data: IMatchData
  index: number
  isTopThree?: boolean
}

export const TopMatchCard: React.FC<ITopMatchCardProps> = ({
  data: {
    alignments,
    companyId,
    companyLogo,
    companyName,
    description,
    matchConfidence,
    matchConfidenceLabel,
    websiteLink,
    websiteName,
  },
  index,
  isTopThree
}) => {
  const { userType } = useContext(UserContext) as TUserContext

  function ordinalSuffix(index: number): string {
    switch (index + 1) {
      case 1:
        return '1st';
      case 2:
        return '2nd';
      case 3:
        return '3rd';
      default:
        return (index + 1) + 'th';
    }
  }
  /**
   * Track buyer view click
   */
  function trackingOnClick() {
    exploreBuyerMatchTracking({
      company_name: companyName,
      aligned_most: alignments[0],
      aligned_least: alignments[1],
      top_matches_position: `${index + 1}`,
      company_type: 'buyer',
      is_admin_key: isAdminTracking(userType),
      confidence_match_score: `${matchConfidence}%`
    })
  }

  return (
    <ModuleCard.Body
      className='u-items-center u-overflow-hidden u-rounded'
      bgColorClass={tw`u-bg-primary-300`}
      padding={null}
      flowspace='u-flow-space-inner--500'
    >
      <div className='u-w-full u-bg-primary-500 u-p-5 u-text-center'>
        <h4 className='u-text-white'>{`${ordinalSuffix(index)} Match: ${matchConfidenceLabel}`}</h4>
      </div>
      <div className='c-flow u-mt-300 u-items-center u-px-600 u-py-400'>
        <div className='c-flow u-flow-space-inner--100 u-items-center'>
          <div className='u-max-h-[2.5rem]'>
            <Img
              className='u-max-h-full u-w-auto'
              src={companyLogo}
              alt={''}
              width={150}
              height={150}
            />
          </div>
          <h4 className='u-pt-200'>{companyName}</h4>
          <a href={websiteLink}>{websiteName}</a>
        </div>
        <p>{description}</p>
        <HeatMapGrid title='General Points of Alignment' alignment={alignments[0]} isTopThree={isTopThree} onElementClick={() => { }} />
        <StyledButton
          type='a'
          href={`/buyer-at-a-glance?companyId=${companyId}`}
          theme='primary'
          themeStyle='outline'
          className='u-flex u-w-full u-max-w-[13.125rem] u-justify-center u-text-center'
          onClick={trackingOnClick}
        >
          Explore Match
        </StyledButton>
      </div>
    </ModuleCard.Body>
  )
}

export default TopMatchCard
