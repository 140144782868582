/** Image Marquee Component
 *  Returns marquee (infinite reel of horizontal animated items)
 *  If the width of those items is wider than the viewport, otherwise
 *  it returns a simple display of centered images
 */
import { useState } from 'react'
import Marquee from 'react-fast-marquee'
import { useEffectOnce, useEventListener } from 'usehooks-ts'

import { TImageDefinedWidthProps } from '@unionco/alaris-app-types'

import { Img } from 'components'

interface IImageMarquee {
  id: string
  images: TImageDefinedWidthProps[]
}

export const ImageMarquee: React.FC<IImageMarquee> = ({ id, images }) => {
  const [animated, setAnimated] = useState(false)

  const horizontalPadding = 90
  const paddingX = horizontalPadding * 2
  const itemsWidth = images
    .map((img) => img.width + paddingX)
    .reduce((partialSum, a) => partialSum + a, 0)

  const updateAnimated = () => {
    setAnimated(itemsWidth >= window.innerWidth)
  }

  useEffectOnce(() => {
    updateAnimated()
  })

  useEventListener('resize', updateAnimated)

  const logos = images.map((image, index) => {
    return (
      <div
        className='u-mx-[5.625rem] u-flex u-h-full u-items-center u-justify-center'
        key={`${id}ImageReel_Slide_${index}`}
      >
        <Img className='' {...image} />
      </div>
    )
  })

  const content = animated ? (
    <Marquee speed={35} gradient={false}>
      {logos}
    </Marquee>
  ) : (
    <div className='u-flex'>{logos}</div>
  )

  return <div className='u-flex u-w-screen u-justify-center'>{content}</div>
}

export default ImageMarquee
