/** Modules Sidebar
 *  Layout component that controls placement of modules
 *  Currently switches based on a single breakpoint
 *  Using values from IModuleData interface:
 *  container: to determine between main module and side modules
 *  subcontainer?: to group main modules side by side
 */
import { useBreakAtSize } from '@unionco/components'

import { partitionNew } from 'utils'

import { Modules, TModuleData } from 'components'

type TModuleSubContainer = {
  subContainer: number
  items: TModuleData[]
}
type TModuleContainerItem = TModuleData | TModuleSubContainer
type TModuleContainer = TModuleContainerItem[]
type TSidebarModuleContainers = [TModuleData[], TModuleData[]]

interface IDashboardMainSidebarProps {
  modules: TModuleData[]
}

export const ModulesLayoutSideBar: React.FC<IDashboardMainSidebarProps> = ({
  modules
}) => {
  const { aboveBreakPoint } = useBreakAtSize('lg')

  if (!aboveBreakPoint) {
    return (
      <div className='c-flow'>
        {modules.map((mod, index) => {
          return <Modules data={mod} key={`MobileModule_${index}`} />
        })}
      </div>
    )
  }

  const separatedModules = partitionNew(modules, (mod) => {
    if (!mod.container) return false
    const { container } = mod
    const isNum = typeof container === 'number'
    const containerValue = isNum ? container : parseInt(container)
    return containerValue === 1
  }) as TSidebarModuleContainers

  const mainModules = separatedModules[0]
  const groupedModuleData: TModuleContainer = []
  const subContainers: number[] = []

  // Organize modules that have subContainers into sub arrays
  mainModules.forEach((mod) => {
    if (!mod.subContainer) {
      groupedModuleData.push(mod)
    } else {
      const subContainerIndex = groupedModuleData.findIndex(
        (mod) => mod.subContainer
      )
      if (subContainerIndex === -1) {
        subContainers.push(mod.subContainer)
        groupedModuleData.push({ subContainer: mod.subContainer, items: [mod] })
      } else {
        // prettier-ignore
        (
          groupedModuleData[subContainerIndex] as TModuleSubContainer
        ).items.push(mod)
      }
    }
  })

  return (
    <div className='c-withSidebar c-withSidebar--rtl [--sidebar-gutter:theme(spacing.600)] print:u-flex print:u-flex-col'>
      <div className='b-ProfileSideBar | c-flow | u-flow-space--600' >
        {groupedModuleData.map((item, index) => {
          if (item.hasOwnProperty('subContainer') && !!item.subContainer) {
            const { items } = item as TModuleSubContainer
            return (
              <div
                className='c-cluster u-items-stretch'
                key={`Container_${index}`}
              >
                {!!items &&
                  items.map((mod, index) => {
                    return <Modules data={mod} key={`SubModule_${index}`} />
                  })}
              </div>
            )
          }
          return (
            <Modules data={item as TModuleData} key={`MainModule_${index}`} />
          )
        })}
      </div>
      <div className='c-flow | u-flow-space--600'>
        {separatedModules[1].map((mod, index) => {
          return <Modules data={mod} key={`SideModule_${index}`} />
        })}
      </div>
    </div>
  )
}

export default ModulesLayoutSideBar
