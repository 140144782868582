/** Use Auth Fetch
 *  A wrapping hook for useFetch that by default
 *  provides a header with a jwt token provided
 *  from the UserContext
 */
import { useContext } from 'react'
import { useFetch } from 'usehooks-ts'

import type { TState } from '@unionco/alaris-app-types'

import { API_BASE } from 'appConstants'

import { TClientContext, UserContext } from 'context'

export const useAuthFetch = <T>(
  slug: string,
  options?: RequestInit
): TState<T, 'data', 'error'> => {
  const { jwt } = useContext(UserContext) as TClientContext

  const defaultHeader = {
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  }

  const o = options
    ? {
        defaultHeader,
        ...options
      }
    : defaultHeader

  const { data, error } = useFetch<T>(`${API_BASE}/api${slug}`, o)

  return {
    data,
    error
  }
}

export default useAuthFetch
