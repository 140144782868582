import { THelpClickTracking } from '@types'

import { tracking } from './tracking'

type THelpClickTrackingParams = Omit<THelpClickTracking, 'event'>

export const helperTracking = (data: THelpClickTrackingParams): void => {
  tracking<THelpClickTracking>({
    event: 'help_click',
    ...data
  })
}
