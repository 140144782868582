export function capitalizeAndSplit(str: string) {
  // Capitalize the first letter
  const capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1)

  // Split the string by following capital letters
  const regex = /[A-Z][a-z]*/g
  const splitString = capitalizedStr.match(regex) || []

  if (splitString.length === 0) return ''

  return splitString.join(' ')
}
