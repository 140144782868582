/** Section Review Data Fixture
 *
 */
import type { IQuestionReviewSegmentData } from '@unionco/alaris-app-types'

export const sectionReview = {
  title: 'Section Review',
  status: {
    percent: 0,
    statusText: 'notStarted'
  }
} as IQuestionReviewSegmentData
