interface ITickLine {
  left: number
}

export const TickLine: React.FC<ITickLine> = ({ left }) => {
  return (
    <div
      className='u-absolute u-h-6 u-w-[2px] u-bg-secondary-300'
      style={{
        left: `${left}%`,
        top: '50%',
        transform: 'translate(-50%,-50%)'
      }}
    />
  )
}

export default TickLine
