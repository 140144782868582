/** Phase Step Summaries
 *
 */
import { accountSetup, liveSessionSummaryFixture } from './liveSession'

import { questionnaireSummaryFixture } from '@fixtures/phase/questionnaire'

export const phaseStepSummariesFixture = [
  accountSetup,
  questionnaireSummaryFixture,
  questionnaireSummaryFixture,
  liveSessionSummaryFixture
]
