import { v4 as uuidv4 } from 'uuid'

import type { ITitleAndCopyData } from '@unionco/alaris-app-types'

import { paragraphTestData } from '@fixtures/generic'

export const titleAndCopyTestData = (
  title?: string,
  copy?: string
): ITitleAndCopyData => {
  return {
    title: title || 'Title',
    copy: copy || paragraphTestData(),
    key: uuidv4(),
    moduleType: 'titleAndCopy'
  }
}
