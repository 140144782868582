/** Alaris Rep Contact Card
 *  Shown on profile pages, this component displays
 *  an profile picutre, along with the reps name
 *  job title, and contact info CTA
 */
import type { FC } from 'react'

import type { TAlarisRepContactCardData } from '@unionco/alaris-app-types'

import { IButtonPattern, Img, StyledButton } from 'components'

interface IAlarisRepContactCardProps {
  data: TAlarisRepContactCardData
  horizontal?: boolean
  buttonStyle?: 'solid' | 'outline'
  largeHeader?: boolean
  cardVariation?: boolean
}

export const AlarisRepContactCard: FC<IAlarisRepContactCardProps> = ({
  buttonStyle,
  cardVariation,
  data: {
    firstName,
    jobTitle,
    lastName,
    phoneNumber,
    profilePic,
    questionText
  },
  horizontal = false,
  largeHeader = false
}) => {
  const layoutClass = horizontal || cardVariation ? 'c-cluster' : 'c-flow'
  const buttonStyles =
    'solid' === buttonStyle || cardVariation
      ? ({ theme: 'primary', themeStyle: 'solid' } as IButtonPattern)
      : ({ theme: 'secondary', themeStyle: 'outline' } as IButtonPattern)
  const titleSizeClass = largeHeader || cardVariation ? 'u-text-[1.25em]' : ''
  return (
    <div className='c-flow'>
      <p className={`o-subtitle-1 u-text-grayscale-800 ${titleSizeClass}`}>
        {questionText}
      </p>
      <div className={layoutClass}>
        <div className='c-flow'>
          <div className='c-cluster'>
            <div className='u-flex u-aspect-square u-items-center u-overflow-hidden u-rounded-full u-border-2 u-border-primary-500'>
              <Img className='u-w-full' {...profilePic} />
            </div>
            <div className='c-flow | u-flow-space--100'>
              <p className='o-subtitle-1 u-text-grayscale-800'>
                {firstName} {lastName}
              </p>
              <p className='u-text-grayscale-800'>{jobTitle}</p>
            </div>
          </div>
        </div>
        <StyledButton href={`tel:${phoneNumber}`} type='a' {...buttonStyles}>
          Contact {firstName}
        </StyledButton>
      </div>
    </div>
  )
}

export default AlarisRepContactCard
