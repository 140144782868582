import { useState } from 'react'

import { TTextTokenClass } from '@unionco/alaris-app-types'

import { SVGRing } from 'components'

type TStatusRingProps = {
  colorClass?: TTextTokenClass
  layer: boolean
  strokeWidth?: number
  twoColor?: boolean
  secondColorClass?: TTextTokenClass
  hasInnerRing?: boolean
  innerColorClass?: TTextTokenClass
  innerPercent?: number
  innerStrokeWidth?: number
} & (
    | {
      animated?: false
      percent: number
      displayTime?: undefined
    }
    | { animated: true; displayTime: number; percent?: undefined }
  )

export const StatusRing: React.FC<TStatusRingProps> = ({
  animated,
  colorClass = 'u-text-primary-500',
  displayTime,
  layer,
  percent = 0,
  strokeWidth = 8,
  twoColor,
  secondColorClass = 'u-text-white',
  hasInnerRing = false,
  innerColorClass = 'u-text-tertiary-200',
  innerPercent = 0,
  innerStrokeWidth = 8,
}) => {
  const [per, setPer] = useState<number>(percent)

  if (animated) {
    function updatePercent(p: number) {
      if (p >= 100) return
      setTimeout(() => {
        setPer(p + 1)
        updatePercent(p + 1)
      }, (displayTime as number) / 100)
    }
    updatePercent(per)
  }

  const ring = (
    <SVGRing
      className={colorClass}
      percent={per}
      radius={20}
      strokeWidth={strokeWidth}
    />
  )

  const innerRing = (
    <SVGRing
      className={innerColorClass}
      percent={innerPercent}
      radius={20}
      strokeWidth={innerStrokeWidth}
    />
  )

  if (twoColor) {
    return (
      <div className='c-withLayer'>
        <div className='c-layer'>
          {hasInnerRing &&
            <div className='c-layer'>
              {innerRing}
            </div>
          }
          <SVGRing
            className={secondColorClass}
            percent={100}
            radius={20}
            strokeWidth={strokeWidth + 2}
          />
        </div>
        {ring}
      </div>
    )
  }

  if (layer) {
    return <div className='c-layer'>{ring}</div>
  }

  return ring
}

export default StatusRing
