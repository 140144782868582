import { PixelString, RemString } from '@unionco/alaris-app-types'

type RemTypeUnion = number | RemString

function remsToPixels(rems: RemTypeUnion, returnString?: false): number
function remsToPixels(rems: RemTypeUnion, returnString?: true): PixelString
function remsToPixels(
  rems: RemTypeUnion,
  returnString?: boolean
): string | number {
  const remValue = typeof rems === 'string' ? parseInt(rems, 10) : rems
  const pixelsValue = remValue * 16
  if (!returnString) return pixelsValue
  return `${pixelsValue}px`
}

export { remsToPixels }
