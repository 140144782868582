import { Accordion } from '@unionco/components'
import { CaretIcon } from '@unionco/svg-icons'

interface IEntryTitle {
  entryTitle: string
  headerJobTitle?: string | null
  index: number
  saved: boolean
}

export const EntryTitle: React.FC<IEntryTitle> = ({
  entryTitle,
  headerJobTitle,
  index,
  saved
}) => {
  return (
    <Accordion.Button index={index} locked={false} className='c-repel'>
      <div className='c-cluster'>
        <p className='u-text-5 u-text-black'>{entryTitle}</p>
        {headerJobTitle && headerJobTitle !== 'unanswered' && (
          <p className='u-uppercase'>{headerJobTitle}</p>
        )}
      </div>
      <div className='c-cluster'>
        {saved && <p className='u-text-state-good'>Entry Saved</p>}
        <Accordion.Indicator index={index} animation='downToUp'>
          <CaretIcon className='u-text-secondary-500' />
        </Accordion.Indicator>
      </div>
    </Accordion.Button>
  )
}

export default EntryTitle
