/** Top Matches Test Data
 *  Test data for the <Top Matches /> module
 *  used in the profile template
 *  A seller only main module
 */
import { v4 as uuidv4 } from 'uuid'

import type { IMatchesData } from '@unionco/alaris-app-types'

import { shortParagraphFixture } from '@fixtures/generic'
import { placeHolderImageUrl } from '@fixtures/media'

export const topMatchesModuleTestData = {
  moduleType: 'TopMatches',
  title: 'Top Matches',
  items: [
    {
      companyId: 1,
      companyName: 'Mariner Wealth Adivors',
      companyLogo: placeHolderImageUrl['default'],
      key: uuidv4()
    },
    {
      companyId: 2,
      companyName: 'Prime Capital Invesments',
      companyLogo: placeHolderImageUrl['default'],
      key: uuidv4()
    },
    {
      companyId: 3,
      companyName: 'Beacon Pointe',
      companyLogo: placeHolderImageUrl['default'],
      key: uuidv4()
    }
  ],
  key: uuidv4(),
  container: 2,
  info: {
    copy: shortParagraphFixture
  }
} as IMatchesData
