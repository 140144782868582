import { useContext } from 'react'

import { IYourFirmData } from '@unionco/alaris-app-types'

import { currencyAbbr } from '@unionco/utils'

import { IAMContext, TIAMContext } from 'context'

import { StyledButton } from 'components/generic'
import { ModuleCard } from 'components/layout'
import { MetricCard } from 'components/modules'

interface IFirmAtAGlanceProps {
  data: IYourFirmData
}

export const FirmAtAGlance: React.FC<IFirmAtAGlanceProps> = ({
  data: {
    title,
    top3Placements,
    top10Placements,
    totalClosedDeals,
    totalEBITDAAcquired
  }
}) => {
  const {
    IAMData: { companyId }
  } = useContext(IAMContext) as TIAMContext
  const EBITDAData = {
    ...totalEBITDAAcquired,
    value: currencyAbbr(totalEBITDAAcquired.value)
  }

  return (
    <ModuleCard.Body>
      <div className='c-repel'>
        <h2 className='u-font-open-sans'>{title}</h2>
        <StyledButton
          type='a'
          href={`/buyer-at-a-glance?companyId=${companyId}`}
          theme='secondary'
          themeStyle='outline'
        >
          View your firm&apos;s profile
        </StyledButton>
      </div>
      <div className='c-switcher'>
        <div className='c-switcher'>
          <MetricCard
            data={top10Placements}
            classNames={{
              value: 'u-text-6'
            }}
          />
          <MetricCard
            data={top3Placements}
            classNames={{
              value: 'u-text-6'
            }}
          />
        </div>
        <div className='c-switcher'>
          <MetricCard
            data={totalClosedDeals}
            classNames={{
              value: 'u-text-6'
            }}
          />
          <MetricCard
            data={EBITDAData}
            classNames={{
              value: 'u-text-6'
            }}
          />
        </div>
      </div>
    </ModuleCard.Body>
  )
}

export default FirmAtAGlance
