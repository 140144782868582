import { Accordion } from '@unionco/components'
import { useContext } from 'react'

import { INumberAnswer } from '@unionco/alaris-app-types'

import { partitionNew } from 'utils'

import { SidebarContext, getSidebarRoute } from 'components'
import {
  rangeDefaultMaxValue,
  rangeDefaultValue
} from 'components/questionnaire/question/range'

import { IIndexedRangeSegmentData } from '..'
import QuadrantBlock from './QuadrantBlock'

interface IRangeReviewProps {
  segments: IIndexedRangeSegmentData[]
}

/**
 * Max is switching the range from a INumberAnswer to a IBasicAnswer
 * so this will likley break when that is merged in.
 */
// interface IFormattedRangeAnswer extends IBasicAnswer {
export interface IFormattedRangeAnswerData extends INumberAnswer {
  numValue: number
  link: string
}

export const RangeReview: React.FC<IRangeReviewProps> = ({ segments }) => {
  const {
    data: { key: sectionKey },
    pageRoute
  } = useContext(SidebarContext)
  /**
   * Format answers logic
   * 1. Map through segments and add numValue and link to answers
   *    (selectedAnswer is automatically filled out at the question level,
   *     once a user makes a selection for any slider on a given question)
   * 2. Flatten to just answers
   * 3. Sort by value, first partitioning by half, then halves again
   */
  const formattedData = segments.map((s) => {
    const { index: segmentIndex } = s
    const formatedQs = s.questions.map((q) => {
      const formattedAnswers = q.answers.map((a, i) => {
        return {
          ...a,
          numValue: q.selectedAnswer ? q.selectedAnswer[i] : rangeDefaultValue,
          link: getSidebarRoute(pageRoute, sectionKey, segmentIndex, i)
        }
      })
      return {
        ...q,
        answers: formattedAnswers
      }
    })

    return { ...s, questions: formatedQs }
  })
  const allAnswers = formattedData
    .flatMap((s) => s.questions.flatMap((q) => q.answers))
    .sort((a, b) => b.numValue - a.numValue)
  const halfSplitAnswers = partitionNew(
    allAnswers,
    (a) => a.numValue > rangeDefaultMaxValue / 2
  )
  const topQuadrants = partitionNew(
    halfSplitAnswers[0],
    (a) => a.numValue > rangeDefaultMaxValue * 0.75
  )
  const botQuadrants = partitionNew(
    halfSplitAnswers[1],
    (a) => a.numValue > rangeDefaultMaxValue / 4
  )

  const initialOpenItems =
    topQuadrants[0].length !== 0 && topQuadrants[1].length !== 0
      ? [0, 1]
      : [2, 3]

  return (
    <Accordion.Main
      className='c-flow u-flow-space--600'
      multipleOpen
      initialOpenItems={initialOpenItems}
    >
      <QuadrantBlock
        answers={topQuadrants[0]}
        infoCopy={''}
        title='Must have / deal breaker'
        index={0}
        trackingName='must have'
      />
      <div className='o-line' />
      <QuadrantBlock
        answers={topQuadrants[1]}
        infoCopy={''}
        title='Strongly prefer / but not a deal breaker'
        index={1}
        trackingName='strongly prefer'
      />
      <div className='o-line' />
      <QuadrantBlock
        answers={botQuadrants[0]}
        infoCopy={''}
        title='Nice to know'
        index={2}
        trackingName='nice to know'
      />
      <div className='o-line' />
      <QuadrantBlock
        answers={botQuadrants[1]}
        infoCopy={''}
        title='Not a factor one way or another'
        index={3}
        trackingName='not a factor'
      />
    </Accordion.Main>
  )
}

export default RangeReview
