/** Auth BG component
 *
 *  Wrapping component that provides bg image from CMS
 */
import type { FC, ReactNode } from 'react'
import { useContext } from 'react'

import { PreAuthGlobalsContext } from 'context'

interface AuthBGUIProps {
  bgPosition?: 'center' | 'top'
  children: ReactNode
  id?: string
}

export const AuthBGUI: FC<AuthBGUIProps> = ({ bgPosition, children, id }) => {
  const context = useContext(PreAuthGlobalsContext)
  const { loginBackground } = context
  const backgroundPosition = bgPosition === 'center' ? 'center' : 'top'
  return (
    <div id={id} className='c-withLayer | u-bg-gradient-blue-blue'>
      <div
        className={'c-layer'}
        style={{
          backgroundImage: `url(${loginBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: '100%',
          backgroundPosition
        }}
      />
      {children}
    </div>
  )
}

export default AuthBGUI
