import type {
  IQuestionSegmentData,
  ISectionStatus
} from '@unionco/alaris-app-types'

import { calcPercent, setSectionStatusText } from '..'

export const setSegmentStatus = (segment: IQuestionSegmentData) => {
  const numOfQuestions = segment.questions.filter((q) => q.isActive).length
  const numOfAnsweredQuestions = segment.questions.filter(
    (q) => q.answered && q.isActive
  ).length
  const percent = calcPercent(numOfAnsweredQuestions, numOfQuestions)

  return {
    percent,
    statusText: setSectionStatusText(numOfQuestions, numOfAnsweredQuestions)
  } as ISectionStatus
}
