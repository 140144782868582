import { IServicesOfferedData } from '@unionco/alaris-app-types'

import { CheckMarkListItem, ModuleCard } from 'components'

interface IServicesOfferedProps {
  data: IServicesOfferedData
}

/**
 * ServicesOffered - Module component
 *
 * A buyer business main module
 * Grid of checkmarks for services offered by a business
 *
 * @returns UI grid of a static list of services where each
 * service offered by the business is checked, with a blue &
 * white checkmark
 */
export const ServicesOffered: React.FC<IServicesOfferedProps> = ({
  data: { info, items, title }
}) => {
  if (!items || items.length === 0) return <></>

  return (
    <ModuleCard.Body className='u-text-left'>
      <ModuleCard.InfoHeader
        info={info}
        title={title || 'Services Offered'}
        trackingName='services offered module'
      />
      <div className='c-autoGrid'>
        {items.map((item, index) => {
          return (
            <CheckMarkListItem
              className={'u-py-2 print:u-py-0'}
              data={item}
              key={`CheckMarkListItem_${index}`}
            />
          )
        })}
      </div>
    </ModuleCard.Body>
  )
}

export default ServicesOffered
