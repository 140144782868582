/** Wayfinder Drawer Item
 *
 *  Segment level link for wayfinder
 *  Handles adding segment status indicator for instances that need it.
 */
import { ChevronIcon } from '@unionco/svg-icons'
import Link from 'next/link'
import { useContext } from 'react'

import type {
  IPhaseSummaryData,
  IQuestionSegmentData,
  ISectionStatus,
  TQuestionnaireSegmentTypes,
  TSectionStatusText
} from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'
import { getSidebarRoute } from 'components/SidebarTemplate/util'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { SidebarContext } from 'components'

import SegmentStatusIndicator from '../segmentStatusIndicator'

export type TWayfinderIcon = 'chevron' | 'none' | 'status'

interface IWayfinderDrawerItemData {
  content: string
  status?: ISectionStatus
}

interface IWayfinderDrawerItemProps extends IWayfinderDrawerItemData {
  icon: TWayfinderIcon
  sectionIndex: number
  sectionKey: string
  segmentIndex: number
  segmentType?: TQuestionnaireSegmentTypes | 'support'
}

export const WayfinderDrawerItem: React.FC<IWayfinderDrawerItemProps> = ({
  content,
  icon,
  sectionIndex,
  sectionKey,
  segmentIndex,
  segmentType,
  status
}) => {
  const {
    pageLocations: { section: plSecetionIndex, segment: plSegmentIndex },
    pageRoute,
    sidebarData
  } = useContext(SidebarContext)
  const { navTrackingOnClick } = useNavigationClickTracking()

  const drawerIcon = (icon: TWayfinderIcon) => {
    switch (icon) {
      case 'none':
        return <></>
      case 'status': {
        if (status) {
          const currentStep = (sidebarData as IPhaseSummaryData).steps[
            sectionIndex
          ]
          const segment = currentStep.segments[segmentIndex]
          if (!segment) return <></>
          const { questions, status } = segment as IQuestionSegmentData
          const { percent, statusText } = status
          const inProgress =
            sectionIndex === plSecetionIndex && segmentIndex === plSegmentIndex
          const segmentStatus = {
            percent,
            statusText:
              statusText !== 'complete' && inProgress
                ? ('inprogress' as TSectionStatusText)
                : status.statusText
          }
          const isMultiQuestion = questions ? questions.length > 1 : false

          return (
            <SegmentStatusIndicator
              bgColor={'u-bg-black'}
              borderColor={'u-border-secondary-800'}
              isActive={inProgress}
              status={segmentStatus}
              useStatusRing={isMultiQuestion}
            />
          )
        }

        return <></>
      }
      default:
        return <ChevronIcon iconStyle='thin' iconDirection='right' />
    }
  }

  const isActiveItem = segmentIndex === plSegmentIndex
  const itemStyles = isActiveItem
    ? 'u-bg-secondary-300 [&_a]:u-text-primary-700 [&_span]:u-text-primary-700'
    : 'u-group [&_a]:u-text-grayscale-100 [&_span]:u-text-secondary-500'
  const textStyles = isActiveItem ? '' : 'group-hover:u-text-white'

  const questionIndex =
    segmentType === 'questionSegment' || segmentType === 'rangeSegment'
      ? 0
      : null

  return (
    <li className={cx('b-WayfinderAccordionDrawerItem', itemStyles)}>
      <Link
        className='c-repel u-flex-nowrap u-p-400 u-text-3'
        href={getSidebarRoute(
          pageRoute,
          sectionKey,
          segmentIndex,
          questionIndex
        )}
        onClick={() => {
          navTrackingOnClick(content, 'sidebar')
        }}
      >
        <span className={textStyles}>{content}</span>
        {drawerIcon(icon)}
      </Link>
    </li>
  )
}

export default WayfinderDrawerItem
