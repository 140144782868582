export const CalendarIcon: React.FC = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.0386 4H19.0386C20.1386 4 21.0386 4.9 21.0386 6V20C21.0386 21.1 20.1386 22 19.0386 22H5.03857C3.92857 22 3.03857 21.1 3.03857 20L3.04857 6C3.04857 4.9 3.92857 4 5.03857 4H6.03857V3C6.03857 2.45 6.48857 2 7.03857 2C7.58857 2 8.03857 2.45 8.03857 3V4H16.0386V3C16.0386 2.45 16.4886 2 17.0386 2C17.5886 2 18.0386 2.45 18.0386 3V4ZM18.0386 20C18.5886 20 19.0386 19.55 19.0386 19V9H5.03857V19C5.03857 19.55 5.48857 20 6.03857 20H18.0386Z'
        fill='#647381'
      />
      <mask
        id='mask0_2596_32845'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='3'
        y='2'
        width='19'
        height='20'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.0386 4H19.0386C20.1386 4 21.0386 4.9 21.0386 6V20C21.0386 21.1 20.1386 22 19.0386 22H5.03857C3.92857 22 3.03857 21.1 3.03857 20L3.04857 6C3.04857 4.9 3.92857 4 5.03857 4H6.03857V3C6.03857 2.45 6.48857 2 7.03857 2C7.58857 2 8.03857 2.45 8.03857 3V4H16.0386V3C16.0386 2.45 16.4886 2 17.0386 2C17.5886 2 18.0386 2.45 18.0386 3V4ZM18.0386 20C18.5886 20 19.0386 19.55 19.0386 19V9H5.03857V19C5.03857 19.55 5.48857 20 6.03857 20H18.0386Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_2596_32845)'></g>
    </svg>
  )
}

export default CalendarIcon
