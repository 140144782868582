import {
  CaretIcon,
  CheckMarkIcon,
  ChevronIcon,
  CloseIcon,
  InfoIcon,
  XIcon
} from '@unionco/svg-icons'

import { IIconData } from '@unionco/alaris-app-types'

import AddIcon from './add'
import ArrowIcon from './arrow'
import BullseyeIcon from './bullseye'
import CalendarIcon from './calendar'
import ClockIcon from './clock'
import DashboardIcon from './dashbaord'
import FlagIcon from './flag'
import HamburgerIcon from './hamburger'
import NotificationIcon from './notification'
import ListIcon from './list'
import PieChartIcon from './piechart'
import PrinterIcon from './printer'
import QuestionMessageIcon from './questionMessage'
import SearchIcon from './search'
import TeamIcon from './team'

export * from './bullseye'
export * from './calendar'
export * from './clock'
export * from './download'
export * from './flag'
export * from './list'
export * from './loading'
export * from './notification'
export * from './padlock'
export * from './piechart'
export * from './printer'
export * from './questionMessage'
export * from './search'
export * from './social'
export * from './team'
export * from './upload'

export const Icon: React.FC<IIconData> = ({ name, ...props }) => {
  switch (name) {
    case 'account':
      return <></>
    case 'add':
      return <AddIcon {...props} />
    case 'arrow':
      return <ArrowIcon {...props} />
    case 'bullseye':
      return <BullseyeIcon {...props} />
    case 'calendar':
      return <CalendarIcon {...props} />
    case 'caret':
      return <CaretIcon {...props} />
    case 'checkmark':
      return <CheckMarkIcon {...props} />
    case 'chevron':
      return <ChevronIcon {...props} />
    case 'clock':
      return <ClockIcon {...props} />
    case 'close': {
      const { weight } = props
      return <CloseIcon {...props} weight={weight || 'outline'} />
    }
    case 'dashboard':
      return <DashboardIcon {...props} />
    case 'flag':
      return <FlagIcon {...props} />
    case 'hamburger':
      return <HamburgerIcon {...props} />
    case 'info':
      return <InfoIcon {...props} />
    case 'list':
      return <ListIcon {...props} />
    case 'notification':
      return <NotificationIcon {...props} />
    case 'piechart':
      return <PieChartIcon {...props} />
    case 'printer':
      return <PrinterIcon {...props} />
    case 'questionMessage':
      return <QuestionMessageIcon {...props} />
    case 'search':
      return <SearchIcon {...props} />
    case 'team':
      return <TeamIcon {...props} />
    case 'x':
      return <XIcon {...props} />
    default:
      return <></>
  }
}

export default Icon
