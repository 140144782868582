/** Estimated Time
 *  Text based component for dipslaying estimated amount of time for a section
 *  Displayed as `Estimated Time: ${time}min`
 *
 *  This can probably be abstracted to a more generic composition focused on
 *  displaying icon's with text.
 */
import { ClockIcon } from 'components'

interface IEstimatedTimeProps {
  time: number
}

export const EstimatedTimeText: React.FC<IEstimatedTimeProps> = ({ time }) => {
  return (
    <p className='c-cluster | u-cluster-gutter--200'>
      <span className=''>Estimated Time</span>
      <span className='c-withIcon | u-flex u-items-center u-text-primary-500'>
        <ClockIcon />
        <span>
          <time className={'u-pr-1'} dateTime={`PT${time}M`}>
            {time}
          </time>
          <span>min</span>
        </span>
      </span>
    </p>
  )
}

export default EstimatedTimeText
