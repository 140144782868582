import type { IOrganicGrowthData } from '@unionco/alaris-app-types'

import { CheckMarkListItem, ModuleCard, Video } from 'components'

interface IOrganicGrowthProps {
  className?: string
  data: IOrganicGrowthData
}

export const OrganicGrowth: React.FC<IOrganicGrowthProps> = ({
  className,
  data: { info, items, title, video }
}) => {
  if (!items || items.length === 0) return <></>

  return (
    <ModuleCard.Body className={` u-text-left u-min-w-[26rem] u-flex-grow-[3] ${className}`}>
      <ModuleCard.InfoHeader
        info={info}
        title={title || 'Organic Growth'}
        trackingName='organic growth module'
      />
      {video && <Video className='print:u-hidden' data={video} />}
      <div className='c-autoGrid [--auto-grid-min-item-size:10rem]'>
        {items.map((item, index) => {
          return (
            <CheckMarkListItem
              className={'u-py-2'}
              data={item}
              key={`OrganicGrowth_${index}`}
            />
          )
        })}
      </div>
    </ModuleCard.Body>
  )
}

export default OrganicGrowth
