import { IHeatMapPageData } from '@unionco/alaris-app-types'

import { matchesData, topMatchesFixture } from '@fixtures/heatmap/topMatches'
import { topPrioritiesModuleTestData } from '@fixtures/modules'

export const heatMapPageFixture = {
  topMatchesContent: topMatchesFixture,
  matches: matchesData,
  topPriorities: topPrioritiesModuleTestData
} as IHeatMapPageData
