import { ChevronIcon } from '@unionco/svg-icons'
import Link from 'next/link'

import { DashboardPageSlug } from 'appConstants'

interface IBackButtonProps {
  content?: string
  className?: string
}

export const BackButton: React.FC<IBackButtonProps> = ({
  className,
  content
}) => {
  const classes = className ? ` ${className}` : ''

  return (
    <Link
      className={`c-withIcon | u-flex u-items-center u-font-open-sans u-text-white ${classes}`}
      href={DashboardPageSlug}
    >
      <ChevronIcon
        iconStyle='thin'
        iconDirection='left'
        className='u-mr-300 u-h-[1.125rem] u-min-h-[1.125rem]'
      />
      {!!content && <span>{content}</span>}
    </Link>
  )
}

export default BackButton
