/** CIM Test Data
 *
 */
import { IProfileTemplateData } from 'templates'

import {
  businessSummaryModuleTestData,
  clientDemographicsModuleTestData,
  clientExpereienceModuleTestData,
  dataRoomModuleTestData,
  financialSnapshotModuleTestData,
  generalInformationModuleTestData,
  keyRatiosAndMetricsModuleTestData,
  riskFactorModuleTestData,
  teamMemberModuleTestData,
  topMatchesModuleTestData,
  topPrioritiesModuleTestData
} from '@fixtures/modules'

export const CIMPageFixture = {
  companyName: 'Business Name',
  modules: [
    businessSummaryModuleTestData,
    generalInformationModuleTestData,
    topPrioritiesModuleTestData,
    teamMemberModuleTestData,
    clientExpereienceModuleTestData,
    keyRatiosAndMetricsModuleTestData,
    clientDemographicsModuleTestData,
    financialSnapshotModuleTestData,
    riskFactorModuleTestData,
    topMatchesModuleTestData,
    dataRoomModuleTestData
  ]
} as IProfileTemplateData
