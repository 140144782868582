import { TBgTokenClass } from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

export interface IDotLabelProps {
  dotColor: TBgTokenClass
  label: string
}

export type TDotLabels = IDotLabelProps[]

export const DotLabel: React.FC<IDotLabelProps> = ({ dotColor, label }) => {
  return (
    <div className='c-cluster u-flex-nowrap u-text-1 [--cluster-gutter:.75rem]'>
      <div
        className={cx(
          'u-aspect-square u-w-4 u-min-w-[1rem] u-rounded-full',
          dotColor
        )}
      />
      <p className='u-whitespace-nowrap'>{label}</p>
    </div>
  )
}

export default DotLabel
