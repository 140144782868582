import { Accordion } from '@unionco/components'

import { IMatchData } from '@unionco/alaris-app-types'

import { ModuleCard } from 'components'

import MatchAccordionItem from './Item'

interface IItemSettings {
  buttonContent: 'match' | 'revenueAndLocation'
  drawerContent: 'alignments' | 'buyer'
  useCounter: boolean
}

interface IMatchesAccordionProps {
  headerContent?: React.ReactNode
  title: string
  matches: IMatchData[]
  itemSettings?: Partial<IItemSettings>
  initialOpenItems?: number[]
  companyType?: 'buyer' | 'seller'
  matchCountAdjustment: number
}

export const MatchesAccordion: React.FC<IMatchesAccordionProps> = ({
  companyType,
  headerContent,
  initialOpenItems,
  itemSettings = {},
  matchCountAdjustment,
  matches,
  title
}) => {
  const settings = {
    buttonContent: 'match',
    drawerContent: 'alignments',
    useCounter: false,
    ...itemSettings
  } as IItemSettings

  return (
    <ModuleCard.Body className='u-text-left'>
      <ModuleCard.Header title={title}>{headerContent}</ModuleCard.Header>
      <Accordion.Main
        className='c-flow'
        multipleOpen
        initialOpenItems={initialOpenItems}
      >
        {matches.map((m, index) => {
          return (
            <MatchAccordionItem
              companyType={companyType}
              data={m}
              index={index}
              key={`MatchItem_${index}`}
              {...settings}
              matchCountAdjustment={matchCountAdjustment}
            />
          )
        })}
      </Accordion.Main>
    </ModuleCard.Body>
  )
}

export default MatchesAccordion
