/** Login Modal
 *  Modal with login logic using next-auth
 */
import axios from 'axios'
import getConfig from 'next/config'
import type { FC } from 'react'
import { FormEvent, useState } from 'react'

import { API_BASE } from 'appConstants'

import AuthCard from '../AuthCard'
import LoginContent from './LoginContent'

export const LoginCard: FC = () => {
  const [emailSent, setEmailSent] = useState<boolean | undefined>(undefined)
  const emailInputId = 'loginEmail'

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!e || !e.target) return

    const { publicRuntimeConfig } = getConfig()
    const { API_KEY } = publicRuntimeConfig

    const target = e.target as HTMLFormElement
    axios
      .post(
        `${API_BASE}/api/passwordless/send-link`,
        {
          email: target[emailInputId].value,
          username: target[emailInputId].value
        },
        {
          headers: { Authorization: `Bearer ${API_KEY}` }
        }
      )
      .then((response) => {
        const { status } = response
        if (status === 200) {
          setEmailSent(true)
        }
      })
      .catch((error) => {
        setEmailSent(false)
        console.error(error)
      })
  }

  return (
    <AuthCard
      id={'b-loginCard'}
      className='u-flow-space--500 u-w-full u-max-w-[29.5rem]'
    >
      <LoginContent
        emailInputId={emailInputId}
        emailSent={emailSent}
        onSubmit={(e: FormEvent<HTMLFormElement>) => onSubmit(e)}
        resetEmailSent={() => setEmailSent(undefined)}
      />
    </AuthCard>
  )
}

export default LoginCard
