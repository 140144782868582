/** Section Intro
 *
 */
import axios from 'axios'
import { useContext, useEffect} from 'react'

import {
  IPostAuthGlobalData,
  TIconName,
  TPhaseStepType
} from '@unionco/alaris-app-types'

import { API_BASE } from '@appConstants'

import { isCompletedPhase } from '@unionco/alaris-utils'
import { isAdminTracking } from 'utils/tracking'
import { questionnairePageLoadTracking } from 'utils/tracking/pageLoad'
import { questionnaireStartTracking } from 'utils/tracking/questionnaire'

import {
  ConstantsContext,
  IAMContext,
  PreAuthGlobalsContext,
  TClientContext,
  TIAMContext,
  UserContext
} from 'context'

import {
  EstimatedTimeText,
  PrimaryButton,
  SectionStatusIndicator
} from 'components'
import React from 'react'

type TKeyObj = {
  key: string
}

type TLocationObj = TKeyObj & { index: number }

export interface ISectionIntroData {
  backgroundImage?: string
  copy: string
  estimatedTime?: number
  eyebrow: string
  header: string
  icon: TIconName
  link: string
  percent: number | null
  sectionType: TPhaseStepType
  segmentLocation?: {
    phase: TKeyObj
    step: TLocationObj
    segment: TLocationObj
  } | null
  /**
   * Only passed in for dashboard instance, to indicate that
   * a user has opened the step (questionnaire) for the first time.
   */
  segmentProgressionIndex?: number
  inQuestionnaire?: boolean
  segmentTitle: string
}

export const SectionIntro: React.FC<ISectionIntroData> = ({
  backgroundImage,
  copy,
  estimatedTime,
  eyebrow,
  header,
  icon,
  inQuestionnaire,
  link,
  percent,
  sectionType,
  segmentLocation,
  segmentProgressionIndex,
  segmentTitle
}) => {
  const { jwt, userType } = useContext(UserContext) as TClientContext
  const {
    IAMData: { id, progression, companyId, companyType }
  } = useContext(IAMContext) as TIAMContext
  const { pageLoaded, setPageLoadedTrue } = useContext(PreAuthGlobalsContext)
  const { sectionIntroDefaultBgImage } = useContext(
    ConstantsContext
  ) as IPostAuthGlobalData
  const showCompletedMessaging = isCompletedPhase(progression.phase)
  /**
   * Questionnaire Page Load Tracking
   *
   * If not in questionnaire it returns immediately
   */

  useEffect(() => {
    if (!inQuestionnaire || pageLoaded) return
    setPageLoadedTrue()
    questionnairePageLoadTracking({
      questionnaire_title: header,
      segment_title: segmentTitle,
      is_admin_key: isAdminTracking(userType),
      page: 'questionnaire step'
    })
  }, [
    inQuestionnaire,
    pageLoaded,
    setPageLoadedTrue,
    header,
    segmentTitle,
    userType
  ])

  const bgImage = backgroundImage || sectionIntroDefaultBgImage
  const style = bgImage
    ? {
        backgroundImage: `url(${bgImage})`,
        backgroundSize: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center bottom'
      }
    : {}

  const completeIntro = () => {
    if (!segmentLocation) return
    /**
     * Use tracking for Step, but only if it's the user's
     * first time clicking into the questionnaire.
     *
     * Currently this will fire off even if the user is an admin.
     * But implementation to check if user is an admin or not is a much
     * bigger lift. Because progression is still updated by an impersonated user.
     */
    if (segmentProgressionIndex === 0) {
      const isAdmin = isAdminTracking(userType)
      questionnaireStartTracking({ is_admin_key: isAdmin })
    }
    /**
     * Complete the segment
     */
    axios.post(
      `${API_BASE}/api/segment/complete`,
      {
        userId: id,
        ...segmentLocation
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )
  }

  const isLiveSession = sectionType == 'liveSession'
  const sectionPrimaryTextColor = isLiveSession ? 'u-text-grayscale-800' : 'u-text-white'
  const sectionBgColor = isLiveSession ? 'u-bg-gradient-blue-white' : 'u-bg-gradient-blue-black'
  return (
    <div
      className={'b-SectionIntroCard | c-withLayer | u-rounded-md u-border-t-[10px] u-border-primary-500 ' + sectionBgColor}
      data-type={sectionType}
    >
      <div className='c-layer' style={style} />
      <div className={'c-flow | u-z-1 u-relative u-items-center u-py-600 md:u-py-700 lg:u-py-900 ' + sectionPrimaryTextColor}>
        <div className='u-flex u-h-16 u-w-16 u-items-center u-justify-center u-rounded-full u-bg-black'>
          <SectionStatusIndicator
            icon={icon}
            percent={percent}
            size={'u-w-full u-h-full'}
            type={sectionType}
            strokeWidth={3}
          />
        </div>
        <div className='c-flow | u-text-center'>
          <h2 className='o-h1 | u-font-medium'>
            <span className='u-text-[1.13em]'>{header}</span>
          </h2>
          {!showCompletedMessaging && (
            <p className='o-overline | u-flow-space--100'>{eyebrow}</p>
          )}
        </div>
        <p className={'u-max-w-[40rem] u-p-200 u-text-center u-text-5 ' + sectionPrimaryTextColor}>
          {copy}
        </p>
        {estimatedTime && <EstimatedTimeText time={estimatedTime} />}
        {/* TODO: Make text dynamic based on current user progress in section */}
        {!isLiveSession && link && !showCompletedMessaging && (
          <PrimaryButton
            id='questionnaireBegin'
            className={'u-flow-space--600 u-w-full u-max-w-[13.125rem]'}
            type={'a'}
            href={link}
            large
            onClick={completeIntro}
          >
            Begin
          </PrimaryButton>
        )}
      </div>
    </div>
  )
}

export default SectionIntro
