/** Client Demographics
 *
 *  For static y axis ticks in chart-js
 *  @see http://www.java2s.com/example/javascript/chart.js/chartjs-to-have-a-static-scale-for-yaxis.html
 *
 *  For more info on data points see documentation in types
 *
 *  Highlights are shown as Metric Cards
 *  Both "Revenue by Client Segment" and "3 Year AUM Trend"
 *  are shown as bar graphs
 *
 *  TODO:
 *  Padding polish?
 *  text styling polish
 *  Bar graph polish
 *  View more Demographics
 *  Highlight on bargraph
 *  Info component implementation
 */
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip
} from 'chart.js'

import type { IClientDemographicsData } from '@unionco/alaris-app-types'

import { currencyAbbr } from '@unionco/utils'
import { Divider, MetricCard, ModuleCard, ViewMoreSection, TitleAndCopy } from 'components'
import { BarChart } from 'components/Chart'

import { themeColors } from 'styles'

import TrendingRevenue from './TrendingRevenue'
import { customTooltip } from '../customTooltip'
import ThreeYearGrowthMix from './threeYearGrowthMix'

ChartJS.register(
  ArcElement,
  BarElement,
  CategoryScale,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip
)
const lineColors = [themeColors.primary['500']]
const { primary } = themeColors

export interface IClientDemographicsProps {
  data: IClientDemographicsData
}

export const ClientDemographics: React.FC<IClientDemographicsProps> = ({
  data: {
    assetsUnderManagement,
    annualRevenueMix,
    ebitda,
    hhsPerAumRange,
    highlights,
    info,
    revenueData,
    threeYearAumTrend,
    threeYearGrowthMix,
    title,
    trendingRevenueMix,
    clientProfileAndSegmentationParagraphs,
    distributionOfAssets,
    generationalSummaryByAUM
  }
}) => {
  const noMainData =
    (!highlights || highlights.length === 0) &&
    revenueData === null &&
    threeYearAumTrend === null
    
  const data = [
    hhsPerAumRange,
    trendingRevenueMix,
    threeYearGrowthMix,
    assetsUnderManagement,
    annualRevenueMix,
    ebitda,
    distributionOfAssets,
    generationalSummaryByAUM
  ];

  const noViewMoreData = data.every(item => {
    if (item === null || item === undefined) return true;
    if (typeof item === 'string' || Array.isArray(item)) {
      return item.length === 0;
    }
    return false;
  });

  if (noMainData && noViewMoreData) return <></>

  const scaleXStyling = {
    grid: {
      display: false
    }
  }

  const scaleYStyling = {
    border: {
      dash: [5, 8],
      display: false
    }
  }

  const dollarMeasurementStyles = {
    ticks: {
      callback: (value: string | number) => currencyAbbr(value)
    }
  }

  return (
    <ModuleCard.Body>
      <ModuleCard.InfoHeader
        info={info}
        title={title || 'Client Profile & Segmentation'}
        trackingName='client demographics module'
        className='print:u-break-before-avoid-page'
      />
      <Divider title='Highlights' />
      <div className='c-cluster u-justify-between'>
        {highlights.map((metric, index) => {
          return <MetricCard className='print:u-border-2' data={metric} key={`${metric.title}_${index}`} />
        })}
      </div>
      <div className='c-autoGrid [--auto-grid-min-item-size:18rem] print:u-break-after-avoid-page '>
        {revenueData && (
          <div className='c-flow'>
            <Divider title='Revenue by client segment' />
            <BarChart
              data={revenueData}
              options={{
                scales: {
                  x: {
                    ...scaleXStyling
                  },
                  y: {
                    ...scaleYStyling,
                    ...dollarMeasurementStyles
                  }
                },
                plugins: {
                  tooltip: {
                    enabled: false,
                    external: (context) =>
                      customTooltip(
                        context,
                        'revByClientSegment',
                        'Revenue',
                        currencyAbbr
                      )
                  }
                },
                animation: {
                  duration: 0
                },
              }}
            />
            {revenueData.paragraph?.description != '' &&
              (<TitleAndCopy
                title={revenueData.paragraph?.title}
                copy={revenueData.paragraph?.description}
              />)}
          </div>
          
        )}
        {threeYearAumTrend && threeYearAumTrend.labels && (
          <div className='c-flow'>
            <Divider title={threeYearAumTrend.datasets[0].label ? threeYearAumTrend.datasets[0].label : '3-Year AUM Trend'} />
            <BarChart
              data={threeYearAumTrend}
              options={{
                scales: {
                  x: {
                    ...scaleXStyling
                  },
                  y: {
                    ...scaleYStyling,
                    ...dollarMeasurementStyles
                  }
                },
                plugins: {
                  tooltip: {
                    enabled: false,
                    external: (context) =>
                      customTooltip(
                        context,
                        'threeYearAumTrend',
                        'AUM',
                        currencyAbbr
                      )
                  }
                },
                animation: {
                  duration: 0
                },
              }}
            />
            {threeYearAumTrend.paragraph?.description != '' &&
              (<TitleAndCopy
                title={threeYearAumTrend.paragraph?.title}
                copy={threeYearAumTrend.paragraph?.description}
              />)}
          </div>
        )}
      </div>
      {!noViewMoreData && (
        <ViewMoreSection
          openButtonText='View More Demographics'
          closeButtonText='View Less Demographics'
          className='print:u-break-before-all u-h-auto"'
        >
          <div className='c-flow'>
            {hhsPerAumRange && (
              <div className='c-flow'>
                <Divider title="NUMBER OF HOUSEHOLDS BY AUM" />
                <BarChart
                  data={hhsPerAumRange}
                  options={{
                    scales: {
                      x: {
                        ...scaleXStyling
                      },
                      y: {
                        ...scaleYStyling
                      }
                    },
                    plugins: {
                      tooltip: {
                        enabled: false,
                        external: (context) =>
                          customTooltip(
                            context,
                            'numOfHHsPerAumRange',
                            'Households',
                            value => value
                          )
                      }
                    },
                    animation: {
                      duration: 0
                    },
                  }}
                />
                {hhsPerAumRange.paragraph?.description != '' && 
                (<TitleAndCopy
                  title={hhsPerAumRange.paragraph?.title}
                  copy={hhsPerAumRange.paragraph?.description}
                />)}
              </div>
            )}
            {distributionOfAssets && (
              <div className='c-flow print:u-break-before-avoid-page'>
                <Divider title="DISTRIBUTION OF ASSETS" />
                <BarChart
                  data={distributionOfAssets}
                  options={{
                    scales: {
                      x: {
                        title: {
                        },
                        ...scaleXStyling
                      },
                      y: {
                        ...scaleYStyling
                      }
                    },
                    plugins: {
                      tooltip: {
                        enabled: false,
                        external: (context) =>
                          customTooltip(
                            context,
                            'distributionOfAssets',
                            'AUM Total',
                            currencyAbbr
                          )
                      }
                    },
                     animation: {
                      duration: 0
                    },
                  }}
                />
                {distributionOfAssets.paragraph?.description != '' &&
                  (<TitleAndCopy
                    title={distributionOfAssets.paragraph?.title}
                    copy={distributionOfAssets.paragraph?.description}
                  />)}
              </div>
            )}
            {(generationalSummaryByAUM && generationalSummaryByAUM.length > 0) && (
              <div className='c-flow'>
                <Divider title="GENERATIONAL SUMMARY BY AUM" />

                <div className='u-flex u-justify-center u-align-items-center'>
                  <table className='u-w-full'>
                    <thead>
                      <tr>
                        <th className='o-h3 u-p-300 u-pl-400 u-text-center u-font-bold u-text-secondary-800'>Generational Range</th>
                        <th className='o-h3 u-p-300 u-pl-400 u-text-center u-font-bold u-text-secondary-800'>Percentage of RIA AUM</th>
                      </tr>
                    </thead>
                    <tbody>
                      {generationalSummaryByAUM.map((generation, index) => {
                        return (
                          <tr key={`Advisor_row_${generation.generationRange}_${index}`} className='u-border-b u-border-primary-300'>
                            <td className='o-h4 u-p-300 u-pl-400 u-text-center u-font-bold u-text-gray-800'>
                              {generation.generationRange}
                            </td>
                            <td className='u-text-4 u-text-center u-font-light u-text-secondary-900'>
                              {generation.percentageOfRIAAUM}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <div className='c-flow'>
              {trendingRevenueMix && trendingRevenueMix.datasets && (
                <TrendingRevenue
                  data={trendingRevenueMix}
                  options={{
                    scales: {
                      x: {
                        ...scaleXStyling
                      },
                      y: {
                        ...scaleYStyling
                      }
                    }
                  }}/>
              )}
              {threeYearGrowthMix && threeYearGrowthMix.datasets && (
                <ThreeYearGrowthMix data={threeYearGrowthMix} />
              )}
            </div>
            {annualRevenueMix && (
              <div className='c-flow'>
                <Divider className='' title='ANNUAL REVENUE MIX' />
                <BarChart
                  data={{
                    labels: annualRevenueMix.labels,
                    datasets: annualRevenueMix.datasets.map((dataset, index) => {
                      if (index === 0) {
                        return { ...dataset, backgroundColor: primary['800'] };
                      }
                      return dataset;
                    }),
                  }}
                  options={{
                    scales: {
                      x: {
                        stacked: true,
                        ...scaleXStyling
                      },
                      y: {
                        stacked: true,
                        ...scaleYStyling
                      }
                    },
                    plugins: {
                      tooltip: {
                        enabled: false,
                        external: (context) =>
                          customTooltip(context, 'annualRevenueMix', 'MIX')
                      }
                    },
                    animation: {
                      duration: 0 // duration in milliseconds
                    },
                  }}
                />
                {annualRevenueMix.paragraph?.description != '' &&
                  (<TitleAndCopy
                  title={annualRevenueMix.paragraph?.title}
                  copy={annualRevenueMix.paragraph?.description}
                 />)}
              </div>
            )}
            {/* {assetsUnderManagement && (
              <div>
                <Divider title='Assets Under Management' />
                <LineChart
                  data={assetsUnderManagement}
                  options={{
                    scales: {
                      x: {
                        ...scaleXStyling
                      },
                      y: {
                        ...scaleYStyling,
                        ...dollarMeasurementStyles
                      }
                    },
                    plugins: {
                      tooltip: {
                        enabled: false,
                        external: (context) =>
                          customTooltip(
                            context,
                            'revByClientSegment',
                            'Revenue',
                            currencyAbbr
                          )
                      }
                    }
                  }}
                  lineColors={lineColors}
                />
              </div>
            )} */}
            {ebitda && (
              <div className='c-flow'>
                <Divider title='EBITDA' />
                <BarChart
                  data={ebitda}
                  options={{
                    scales: {
                      x: {
                        ...scaleXStyling,
                        ...dollarMeasurementStyles
                      },
                      y: {
                        ...scaleYStyling,
                      }
                    },
                    indexAxis: 'y',
                    plugins: {
                      tooltip: {
                        enabled: false,
                        external: (context) =>
                          customTooltip(
                            context,
                            'revByClientSegment',
                            'EBITDA',
                            currencyAbbr
                          )
                      }
                    },
                    animation: {
                      duration: 0 // duration in milliseconds
                    },
                  }}
                  barColors={[
                    primary['500'],
                    primary['800'],
                    primary['800'],
                    primary['800'],
                    primary['800']
                  ]}
                />
                {ebitda.paragraph?.description != '' &&
                (<TitleAndCopy
                  title={ebitda.paragraph?.title}
                  copy={ebitda.paragraph?.description}
                />)}
              </div>
            )}
            {clientProfileAndSegmentationParagraphs && 
              <div className='c-flow'>
                <Divider title="MORE INFORMATION" />
                {clientProfileAndSegmentationParagraphs.map((item, index) => (
                  <div key={`bottomCopy_${index}`}>
                    <TitleAndCopy
                      title={item.title}
                      copy={item.description}
                    />
                  </div>
                ))}
              </div>
            }
          </div>
        </ViewMoreSection>
      )}
    </ModuleCard.Body>
  )
}

export default ClientDemographics
