/** Anchor component
 *  Next <Link /> with disable
 *  For theme styled anchors utilize the <StyledButton />
 *
 *  Currently only returns an next <Link />, but will eventually
 *  have prop for returning <a />
 */
import Link from 'next/link'

export interface IAnchorProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  type: 'a'
  href: string
  disabled?: boolean
  external?: boolean
}

export const Anchor: React.FC<IAnchorProps> = ({
  children,
  className,
  disabled,
  external = false,
  onClick,
  ...props
}) => {
  const anchorClick = (
    disabled: boolean | undefined,
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (disabled && e) {
      e.preventDefault()
    } else if (onClick) {
      onClick(e)
    }
  }

  const disabledClasses = disabled ? ' u-cursor-not-allowed' : ''

  const internalLink = (
    <Link
      className={`${className} ${disabledClasses} u-flex`}
      onClick={(e) => anchorClick(disabled, e)}
      {...(props as IAnchorProps)}
    >
      {children}
    </Link>
  )

  const externalLink = (
    <a
      className={`${className} ${disabledClasses} u-flex`}
      onClick={(e) => anchorClick(disabled, e)}
      {...(props as IAnchorProps)}
    >
      {children}
    </a>
  )

  return external ? externalLink : internalLink
}

export default Anchor
