/** Sidebar Tempalte Context
 *
 *  This context is the basis for handling routing on the sidebar template
 *  Which takes both phase view data, as well as support
 */
import type { Dispatch, SetStateAction } from 'react'
import { createContext } from 'react'

import type {
  ILocationsIndexesData,
  IPhaseSummaryData,
  IQuestionnaireData,
  ISupportPageSummariesData,
  ISupportSectionData,
  TPhaseStepData,
  TSidebarSectionData
} from '@unionco/alaris-app-types'

export type TQuestionnaireCallback = (
  data: IQuestionnaireData
) => Promise<void | null>

export type ISidebarContext = {
  isPhaseView: boolean
  pageLocations: ILocationsIndexesData //Replace this with a string for a unique key from DB?
  pageRoute: string
  sectionProgressIndex: number
  setSidebarTemplateData: Dispatch<SetStateAction<TSidebarSectionData | null>>
  setSidebarTemplateSidebarData: Dispatch<
    SetStateAction<IPhaseSummaryData | null>
  >
  refetch: () => void
} & (
  | {
      data: TPhaseStepData
      pageName: undefined
      sidebarData: IPhaseSummaryData
      isPhaseView: true
    }
  | {
      data: ISupportSectionData
      pageName: string
      sidebarData: ISupportPageSummariesData
      isPhaseView: false
    }
)

export const SidebarContext = createContext<ISidebarContext>({
  data: {
    icon: 'piechart',
    key: '',
    type: 'support',
    segments: [],
    title: ''
  },
  isPhaseView: false,
  pageLocations: {
    section: 0,
    segment: 0,
    question: 0
  },
  pageName: '',
  pageRoute: '',
  sectionProgressIndex: 0,
  setSidebarTemplateData: () => {
    return undefined
  },
  setSidebarTemplateSidebarData: () => {
    return undefined
  },
  refetch: () => {
    return undefined
  },
  sidebarData: {
    id: 0,
    key: '',
    sections: [],
    title: ''
  }
})

export default SidebarContext
