/** User Context Wrapper
 *  The User Context Provider
 *  Hits the /self end point and is only loaded after session is true
 *  (user has logged in) within the post auth context wrapper
 *
 *  This is used as the defaults for the IAM context, and exists to track
 *  impersonation modes for admins. With the general idea of having two state
 *  variables, one for the actual user data. And the other for the data that application
 *  acts on.
 */
import { createHash } from 'crypto'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useFetch } from 'usehooks-ts'
import axios from 'axios'

import {
  IBuyerData,
  ISellerData,
  TUserData,
  TUserType
} from '@unionco/alaris-app-types'

import {
  API_BASE,
  AdminPanelPageSlug,
  DashboardPageSlug,
  LoginPageSlug
} from 'appConstants'

import { isAdminTracking } from 'utils/tracking'
import { loginTracking } from 'utils/tracking/login'

import { ErrorUI, Loading } from 'components'

import UserContext from './context'

import { signOut } from 'next-auth/react'

interface IUserContextWrapperProps {
  children: React.ReactNode
  jwtToken: string
}

export const UserContextWrapper: React.FC<IUserContextWrapperProps> = ({
  children,
  jwtToken
}) => {
  const router = useRouter()
  const { pathname } = router
  const [displayAgreement, setDisplayAgreement] = useState<boolean>(false)
  const [loginTrackingCompleted, setLoginTrackingCompleted] =
    useState<boolean>(false)
  const [callbackURL, setCallbackURL] = useState<string | null>(null)

  /**
   * Authenticate user with user-permissions Stapi plugin api.
   * JWT token derived from decoded next-auth session token as
   * a part of the previous step in the auth flow.
   */
  const { data, error } = useFetch<TUserData>(`${API_BASE}/api/user/self`, {
    headers: {
      Authorization: `Bearer ${jwtToken}`
    }
  })

  /**
   * Fetch the callback URL from the server.
   * This is used to redirect the user to the correct page after login.
   * If the callback URL is not available, the user will be redirected to the
   * dashboard or admin panel based on their user type.
   * @returns {Promise<string | null>} The callback URL as a string or null if not available
   */
  async function fetchCallbackURL() {
    try {

      const response = await axios.get('/api/callbackURL'); // apps/alaris-app-ui/src/pages/api/callbackURL.ts
      const { status, data } = response;

      if (status === 200 && data.callbackURL) {
        return data.callbackURL; // Return the callbackURL as a string
      }

      return null; // Return null if the condition is not met

    } catch (error) {

      console.error('Error fetching callback URL:', error);
      return null; // Return null in case of an error

    }
  }

  /**
   * Move to union utils, or eventually to a tracking package
   */
  function hash256(string: string) {
    return createHash('sha256').update(string).digest('hex')
  }

  useEffect(() => {
    /**
     * One time per user, fire off the login tracking,
     * and set the loginTrackingCompleted state variable to true
     * so that we don't send it again.
     */
    if (!data || loginTrackingCompleted) return
    let crmId
    switch (data.userType) {
      case 'seller':
        crmId = data.seller ? data.seller.crmid : undefined
      case 'buyer':
        crmId = (data as IBuyerData).buyer
          ? (data as IBuyerData).buyer.crmid
          : undefined
      default:
        break
    }

    loginTracking({
      user_id: `${data.id}`,
      user_email_hashed: hash256(data.email),
      user_crm_id: crmId ? `${crmId}` : undefined,
      user_type: data.userType,
      is_admin_key: isAdminTracking(data.userType)
    })
    setLoginTrackingCompleted(true)
  }, [data, loginTrackingCompleted])

  /** Initalize User Context State Effect
   *
   *  Set state of show agreement
   */
  useEffect(() => {
    if (!data) return
    if (data.showAgreement !== undefined)
      setDisplayAgreement(data.showAgreement)
  }, [data])

  /** Go to dashboard after updating Effect
   *
   */
  useEffect(() => {
    if (!data) return;

    /** 
     * If the user has not accepted the agreement and is on the login page,
     * fetch the callback URL and redirect the user to the appropriate page.
     * 
     * The callback URL is set when the user is redirected to the login page
     * in order to preserve the last page the user was on before logging out
     * or a page that a user was sent a link to but was redirected
     * because the page requires authentication.
     */
    if (!displayAgreement && pathname === LoginPageSlug) {
      fetchCallbackURL().then(callbackURL => {
        let route;
        if (callbackURL && !callbackURL.includes('/login')) {
          route = callbackURL; // Use the fetched callback URL if not null
        } else {
          // Redirect the user to the dashboard or admin panel based on their user type
          route = data.userType === 'admin' ? AdminPanelPageSlug : DashboardPageSlug;
        }
        router.push(route);
      });
    }
  }, [data, displayAgreement, router, pathname]);

  /**
   * If the userFetch hook returns an error, log the error and sign
   * the user out. This will redirect the user to the login page.
   * This is usually caused by a JWT token that is expired.
   */
  if (error) {
    console.error(error)
    signOut()
    return <Loading debug='UserContextWrapper' />
  }

  if (!data) return <Loading debug='UserContextWrapper' />

  const getCompanyInfo = (userType: TUserType) => {
    switch (userType) {
      case 'admin':
        return { companyName: 'Alaris', id: null }
      case 'buyer':
        return (data as IBuyerData).buyer
      case 'seller':
        return (data as ISellerData).seller
      default:
        return { companyName: '', id: null }
    }
  }

  const companyInfo = getCompanyInfo(data.userType)

  const showAgreement = data.userType === 'admin' ? false : displayAgreement

  return (
    <UserContext.Provider
      value={{
        ...data,
        showAgreement: showAgreement,
        jwt: jwtToken,
        companyName: companyInfo.companyName,
        companyId: companyInfo.id,
        setDisplayAgreement,
        ...(data.userType !== 'admin' ? { progression: data.progression } : {})
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export default UserContextWrapper
