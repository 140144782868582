import type { FC } from 'react'
import { useContext } from 'react'

import { ICopyBlockData } from '@unionco/alaris-app-types'

import { AdminPanelPageSlug, DashboardPageSlug } from '@appConstants'

import { IAMContext, TIAMContext } from 'context'

import { StyledButton } from 'components/generic'

interface IWelcomeContentProps {
  data: ICopyBlockData
}

export const WelcomeContent: FC<IWelcomeContentProps> = ({
  data: { buttonText, copy, title }
}) => {
  const {
    IAMData: { userType }
  } = useContext(IAMContext) as TIAMContext
  const buttonData =
    userType === 'admin'
      ? { text: 'To Admin Panel', link: AdminPanelPageSlug }
      : { text: buttonText || 'To Dashboard', link: DashboardPageSlug }
  return (
    <div className='c-flow | u-text-center'>
      {title && <h2>{title}</h2>}
      {copy && <p>{copy}</p>}
      <StyledButton
        className='u-self-center'
        type='a'
        theme='secondary'
        themeStyle='ghost'
        href={AdminPanelPageSlug}
      >
        {buttonData.text}
      </StyledButton>
    </div>
  )
}

export default WelcomeContent
