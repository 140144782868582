/** Select One Question Fixtures
 *  First item uses 3 answers to display answers in single column,
 *  Second use 4 to display answers in 2 columns on larger screens
 */
import type { ISelectMultipleQuestionData } from '@unionco/alaris-app-types'

import { basicAnswersFixture } from '@fixtures/phase/questionnaire'

export const selectMultipleSingleColumnFixture = {
  answered: false,
  answers: basicAnswersFixture({ numOfAnswers: 3 }),
  questionText: 'Select Multiple 1 Column Layout Question?',
  questionType: 'selectMultiple'
} as ISelectMultipleQuestionData

export const selectMultipleDoubleColumnFixture = {
  answered: false,
  answers: basicAnswersFixture({ numOfAnswers: 3 }),
  questionText: 'Select Multiple 2 Column Layout Question?',
  questionType: 'selectMultiple'
} as ISelectMultipleQuestionData
