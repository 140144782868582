/** Basic Answers Fixture
 *  Takes a number to return an array of
 */
import type { IBasicAnswer } from '@unionco/alaris-app-types'

import { placeholderSmallImgFixture } from '@fixtures/media'
import { basicAnswerFixture } from '@fixtures/phase/questionnaire/answer'

interface IBasicAnswerFixture {
  numOfAnswers: number
  useImage?: boolean
}

export const basicAnswersFixture = ({
  numOfAnswers,
  useImage
}: IBasicAnswerFixture): IBasicAnswer[] => {
  const basicAnswers = []
  for (let i = 0; i < numOfAnswers; i++) {
    const img = useImage ? placeholderSmallImgFixture : null
    basicAnswers.push(basicAnswerFixture(`Answer ${i}`, `answer${i}`, img))
  }
  return basicAnswers
}

export default basicAnswersFixture
