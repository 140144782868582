import { v4 as uuidv4 } from 'uuid'

export const custodiansUsedTestData = () => {
  return {
    title: 'Custodians',
    item: [
      {
        title: 'Charles Schwab',
        copy: '$320,000,000',
        key: uuidv4()
      },
      {
        title: 'Fidelity',
        copy: '$120,000,000',
        key: uuidv4()
      }
    ],
    key: uuidv4()
  }
}
