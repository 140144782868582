import { IconSVG } from '@unionco/svg-icons'

import { TSVGProps } from '@unionco/alaris-app-types'

export const DownloadIcon: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <IconSVG viewBoxWidth={14} {...props} ref={undefined}>
      <path
        d='M12.8335 9.1875C12.431 9.18805 12.1051 9.51398 12.104 9.91648V11.6665C12.1034 11.9082 11.9082 12.1034 11.6665 12.104H2.33351C2.09178 12.1034 1.89655 11.9082 1.89601 11.6665V9.91648C1.89601 9.51398 1.56953 9.1875 1.16648 9.1875C0.76398 9.1875 0.4375 9.51398 0.4375 9.91648V11.6665C0.438594 12.7132 1.2868 13.5614 2.33352 13.5625H11.6665C12.7132 13.5614 13.5614 12.7132 13.5625 11.6665V9.91648C13.562 9.51398 13.236 9.18805 12.8335 9.1875H12.8335Z'
        fill='currentColor'
      />
      <path
        d='M7 0.437503C6.5975 0.43805 6.27156 0.763983 6.27102 1.16648V7.77658L3.96648 5.85648C3.6575 5.59946 3.19812 5.64101 2.94 5.95C2.68297 6.25952 2.72452 6.71891 3.03351 6.97648L6.53351 9.89296V9.89351C6.57015 9.91921 6.60898 9.94218 6.65 9.96077C6.67679 9.97882 6.70468 9.99468 6.73367 10.0089C6.9032 10.0805 7.09515 10.0805 7.26469 10.0089C7.29313 9.99468 7.32047 9.97882 7.34672 9.96132C7.38719 9.94273 7.42657 9.91976 7.46321 9.89405L10.9632 6.97757V6.97703C11.1989 6.78125 11.287 6.45859 11.183 6.17039C11.0797 5.88164 10.8068 5.68859 10.5 5.6875C10.3294 5.68805 10.1642 5.74766 10.0335 5.85648L7.72898 7.77658V1.16648C7.72844 0.76398 7.4025 0.438046 7 0.4375L7 0.437503Z'
        fill='currentColor'
      />
    </IconSVG>
  )
}

export default DownloadIcon
