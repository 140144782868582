/**
 * useRevalidate custom hook
 *
 * Custom fetch hook based of usehooks-ts
 * @see https://usehooks-ts.com/react-hook/use-fetch
 *
 * Some inspiration from
 * @see https://www.damiisdandy.com/articles/use-fetch/
 * &
 * @see https://use-http.com/#/
 *
 * Differences:
 * Return includes refetch function, an aliased version of the internal function,
 * which is wrapped in a useCallback
 */
import { useCallback, useEffect, useReducer, useRef } from 'react'

interface State<T> {
  data?: T
  error?: Error
}

type Cache<T> = { [url: string]: T }

// discriminated union type
type Action<T> =
  | { type: 'loading' }
  | { type: 'fetched'; payload: T }
  | { type: 'error'; payload: Error }

export type TRefetchReturn = {
  refetch: (url: string, useCache?: boolean) => Promise<void>
}

export function useRefetch<T = unknown>(
  url?: string,
  options?: RequestInit
): State<T> & TRefetchReturn {
  const cache = useRef<Cache<T>>({})

  // Used to prevent state update if the component is unmounted
  const cancelRequest = useRef<boolean>(false)

  const initialState: State<T> = {
    data: undefined,
    error: undefined
  }

  const fetchReducer = (state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
      case 'loading':
        return { ...initialState }
      case 'fetched':
        return { ...initialState, data: action.payload }
      case 'error':
        return { ...initialState, error: action.payload }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(fetchReducer, initialState)

  /**
   * One way is to write the function with a useCache param
   * Another may be to split out the try catch portion without the cache
   * into a different function.
   * The wrapping would likely be the useCallback version? And the other more basic
   * could be called from there?
   */
  const fetchData = async (url: string, useCache?: boolean) => {
    dispatch({ type: 'loading' })

    // If a cache exists for this url and settting is true, return it
    if (cache.current[url] && useCache) {
      dispatch({ type: 'fetched', payload: cache.current[url] })
      return
    }

    try {
      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error(response.statusText)
      }

      const data = (await response.json()) as T
      cache.current[url] = data
      if (cancelRequest.current) return

      dispatch({ type: 'fetched', payload: data })
    } catch (error) {
      if (cancelRequest.current) return

      dispatch({ type: 'error', payload: error as Error })
    }
  }
  const fetchCallback = useCallback(fetchData, [url])

  useEffect(() => {
    // Do nothing if the url is not given
    if (!url) return

    cancelRequest.current = false
    fetchCallback(url, true)

    // Use the cleanup function for avoiding a possibly...
    // ...state update after the component was unmounted
    return () => {
      cancelRequest.current = true
    }
  }, [url])

  return { ...state, refetch: fetchData }
}
