/** Questionnaire Launcher
 *
 * A combination of a section intro and resume component
 * The section intro is also used directly in the questionnaire,
 * but this instance is used on the dashboard only. And is the primary way
 * for a user to access the questionnaire from the dashboard.
 *
 */
import { useContext, useEffect } from 'react'

import {
  IQuestionIntroSegmentData,
  ISectionStatus,
  TLiveSessionStatus,
  TPhaseStepSummaryData,
  TSectionStatusText,
  ILiveSessionIntroData
} from '@unionco/alaris-app-types'

import { getProgressionIndexes } from '@unionco/alaris-utils'
import { getPhaseStepPercent, getStatusDisplayText } from 'utils'

import { IAMContext, TIAMContext } from 'context'

import SectionIntro from 'components/SectionIntroCard'
import { getSidebarRoute } from 'components/SidebarTemplate'

import ResumeCallout from '../ResumeCallout'

interface IBuyerTimelineProps {
  phaseStepSummaries: TPhaseStepSummaryData[]
}

export const QuestionnaireLauncher: React.FC<IBuyerTimelineProps> = ({
  phaseStepSummaries
}) => {
  const { IAMData, setIAM, showResumeInitially } = useContext(
    IAMContext
  ) as TIAMContext
  const { lastLocation, lastLocationIndexes, progression, userType, timelineComplete } = IAMData

  useEffect(() => {
    if (!lastLocationIndexes) {
      setIAM({
        ...IAMData,
        lastLocationIndexes: getProgressionIndexes(
          phaseStepSummaries,
          lastLocation
        )
      })
    }
  }, [lastLocation, lastLocationIndexes, IAMData, phaseStepSummaries, setIAM])

  const progressionIndexes = getProgressionIndexes(
    phaseStepSummaries,
    progression
  )
  const {
    question: questionProgIndex,
    section: stepProgIndex,
    segment: segmentProgIndex
  } = progressionIndexes
  const { phase, step: stepKey } = progression

  // Step
  const currentStepSummary = phaseStepSummaries[stepProgIndex]
  let { icon, type } = currentStepSummary
  const { segments, status } = currentStepSummary
  let percent = getPhaseStepPercent(type, status)

  // Segment
  let segmentProg = segmentProgIndex;
  let questionProg = questionProgIndex;

  if (segmentProgIndex === 0) {
    segmentProg = 1
    questionProg = 0
  }
  const currentSegment = segments[segmentProgIndex]
  const introSegment = currentStepSummary.segments[0]

  // Step Intro | Segment 0
  let { introCopy } = currentSegment
  let { title: segmentTitle } = currentSegment
  const { introImage, status: introSegmentStatus } = introSegment

  const isISectionStatus = (
    status: ISectionStatus | TLiveSessionStatus
  ): status is ISectionStatus => {
    return (status as ISectionStatus).statusText !== undefined
  }

  const statusAndTime =
    currentStepSummary.type === 'questionnaire'
      ? {
        eyebrow: getStatusDisplayText((status as ISectionStatus).statusText),
        estimatedTime: (introSegment as IQuestionIntroSegmentData)
          .estimatedTime
      }
      : isISectionStatus(status)
        ? { eyebrow: getStatusDisplayText(status.statusText) }
        : {
          eyebrow: getStatusDisplayText(status as unknown as TSectionStatusText)
        } // Fallback to 'unknown' conversion if necessary

  const introStatusText = introSegmentStatus.hasOwnProperty('statusText')
    ? (introSegmentStatus as ISectionStatus).statusText
    : introSegmentStatus

  const segLoc =
    introStatusText !== 'complete'
      ? {
        phase: { key: phase },
        step: { key: stepKey, index: stepProgIndex },
        segment: { key: introSegment.key, index: 0 }
      }
      : null

  // If the timeline is marked as complete, show the completion status.
  if (timelineComplete) {
    introCopy = 'You have completed your timeline.'
    statusAndTime.eyebrow = 'Complete'
    statusAndTime.estimatedTime = undefined
    segmentTitle = 'Timeline'
    type = 'liveSession'
    icon = 'flag'
    percent = 100
  }

  return (
    <>
      {lastLocationIndexes && (
        <ResumeCallout
          pageName={phase}
          progressionIndexes={progressionIndexes}
          lastLocationIndexes={lastLocationIndexes}
          sectionKey={
            lastLocationIndexes
              ? phaseStepSummaries[lastLocationIndexes['section']].key
              : ''
          }
          showOnInitialLoad={showResumeInitially}
        />
      )}
      <SectionIntro
        copy={introCopy}
        {...statusAndTime}
        header={segmentTitle}
        link={`${userType}/${getSidebarRoute(
          phase,
          stepKey,
          segmentProg,
          questionProg
        )}`}
        backgroundImage={introImage}
        icon={icon}
        percent={percent}
        sectionType={type}
        segmentLocation={segLoc}
        segmentProgressionIndex={segmentProg}
        segmentTitle={segmentTitle}
      />
    </>
  )
}
