import { ChartOptions } from 'chart.js'
import { Line } from 'react-chartjs-2'

import { TLineChartData } from '@unionco/alaris-app-types'

import { customTooltip } from 'components/modules/customTooltip'

interface ILineChartProps {
  data: TLineChartData
  lineColors: string[]
  options?: Partial<ChartOptions<'line'>>
}

export const LineChart: React.FC<ILineChartProps> = ({
  data,
  lineColors,
  options
}) => {
  const styledData = {
    ...data,
    datasets: data.datasets.map((set, index) => {
      return {
        ...set,
        borderColor: lineColors[index],
        pointBackgroundColor: lineColors[index],
        pointRadius: 5,
        fill: lineColors[index],
        showLine: true,
        borderWidth: 2, // Ensure the line is visible

      }
    })
  }

  return (
    <Line
      data={styledData}
      options={{
        plugins: {
          tooltip: {
            enabled: false,
            external: (context) =>
              customTooltip(context, 'threeYearGrowthMix', 'TREND')
          }
        },
        ...options
      }}
    />
  )
}

export default LineChart
