/** Section Intro Fixture
 *
 */
import { v4 as uuidv4 } from 'uuid'

import type { IQuestionIntroSegmentData } from '@unionco/alaris-app-types'

export const sectionIntroFixture = {
  title: 'Intro',
  introCopy: 'Intro pargraph text',
  estimatedTime: 5,
  key: uuidv4()
} as IQuestionIntroSegmentData
