/**
 * Expore Match
 */
import {
  TExploreBuyerMatchTracking,
  TExploreMatchTracking,
  TExploreSellerMatchTracking
} from '@types'
import { IAdjacentMatch, TAlignmentGrid } from '@unionco/alaris-app-types'

import { tracking } from './tracking'

export const joinArrayByKey = <T, K extends keyof T>(
  a: T[],
  key: K
): string | undefined => {
  if (!a || a.length <= 0) return undefined
  return a.map((item: T) => item[key]).join(', ')
}

type TAlignedMost = { aligned_most: TAlignmentGrid }

type TExploreMatchesOmittedFieldsBase = 'event' | 'aligned_most'
type TExploreMatchesParams = (
  | Omit<TExploreBuyerMatchTracking, TExploreMatchesOmittedFieldsBase>
  | Omit<TExploreSellerMatchTracking, TExploreMatchesOmittedFieldsBase>
) &
  TAlignedMost

/**
 * exploreMatchTracking
 *
 * @returns void: Tracking data is sent to google analytics
 */
function exploreMatchTracking(data: TExploreMatchesParams): void {
  const topAlignedList = joinArrayByKey(data.aligned_most, 'title')

  tracking<TExploreMatchTracking>({
    event: 'explore_match',
    ...data,
    aligned_most: topAlignedList
  })
}

/**
 * TExploreMatchesOmittedFields
 *
 * event: is ommited because it's auto-filled
 * aligned_most & aligned_least are ommited for a type change
 */
type TExploreMatchesOmittedFields =
  | TExploreMatchesOmittedFieldsBase
  | 'aligned_least'

// #region Buyer Link Tracking
type TExporeMatchTrackingBuyerParams = Omit<
  TExploreBuyerMatchTracking,
  TExploreMatchesOmittedFields
> &
  TAlignedMost & { aligned_least: TAlignmentGrid }

/**
 * exploreBuyerMatchTracking
 *
 * Used for links to a buyer profile
 *
 * @returns void: Tracking data is sent to google analytics
 */
export function exploreBuyerMatchTracking(
  data: TExporeMatchTrackingBuyerParams
): void {
  const leastAlignedList = joinArrayByKey(data.aligned_least, 'title')

  exploreMatchTracking({ ...data, aligned_least: leastAlignedList })
}
// #endregion Buyer Link Tracking

// #region Seller Link Tracking
type TExporeMatchTrackingSellerParams = Omit<
  TExploreSellerMatchTracking,
  TExploreMatchesOmittedFields | 'also_matched_with' | 'company_revenue'
> &
  TAlignedMost & {
    also_matched_with: IAdjacentMatch[]
    company_revenue: string | number
  }

/**
 * exploreSellerMatchTracking
 *
 * Used for links to a seller profile
 */
export function exploreSellerMatchTracking(
  data: TExporeMatchTrackingSellerParams
): void {
  const { also_matched_with: alsoMatchedWith, company_revenue: revenue } = data
  const also_matched_with = joinArrayByKey(alsoMatchedWith, 'companyName')
  const company_revenue: `$${number | string}` =
    typeof revenue === 'string'
      ? revenue.startsWith('$')
        ? (revenue as `$${string}`)
        : `$${revenue}`
      : `$${revenue}`
  exploreMatchTracking({ ...data, also_matched_with, company_revenue })
}
// #endregion Seller Link Tracking
