import { IFinancialSnapshotData } from '@unionco/alaris-app-types'

import { capitalizeAndSplit, formatCurrency } from '@unionco/alaris-utils'

import { ModuleCard, TitleAndCopy} from 'components'

export interface IFinancialSnapshotProps {
  data: IFinancialSnapshotData
}

export const FinancialSnapshot: React.FC<IFinancialSnapshotProps> = ({
  data: {
    paragraphs,
    headings,
    info,
    items,
    title
  },
}) => {
  const highlightBgColor = 'u-bg-primary-200'
  const borderClasses = 'u-border-b u-border-primary-300'

  const formatTitle = (key: string) => {
    switch (key) {
      case 'opex':
      case 'eboc':
      case 'ebitda':
        return key.toUpperCase()
      case 'totalRevenue':
      case 'grossRecurringRevenue':
      case 'grossNonRecurringRevenue':
      case 'proposedRetainedComp':
      default:
        return capitalizeAndSplit(key)
    }
  }

  const titleOrder = ['grossRecurringRevenue', 'grossNonRecurringRevenue', 'totalRevenue', 'opex', 'eboc', 'proposedRetainedComp', 'ebitda'];
  let sortedItems;
  if (items && items.length > 0) {
    sortedItems = items.sort((a, b) => titleOrder.indexOf(a.title) - titleOrder.indexOf(b.title));
  }
  return (
     (sortedItems && sortedItems.length > 0) && (
      <ModuleCard.Body className='c-flow u-pt-500'>
        {sortedItems && sortedItems.length > 0 && (
          <>
            <ModuleCard.InfoHeader
              className='u-px-500'
              info={info}
              title={title || 'Financial Snapshot'}
              trackingName='financial snapshot module'
            />
            <table className='u-table-fixed u-text-center'>
              <thead className='u-font-bold'>
                <tr className={borderClasses}>
                  <th></th>
                  <th
                    className={`u-rounded-t u-py-300 u-text-primary-500 ${highlightBgColor}`}
                  >
                    {headings[0]}
                  </th>
                  <th className='u-py-300'>{headings[1]}</th>
                  <th className='u-py-300'>{headings[2]}</th>
                </tr>
              </thead>
              <tbody>
                {sortedItems
                  ? sortedItems.map((row, index) => {

                    const { title, values } = row
                    const rowIndex = index
                    return (
                      <tr
                        key={`FinancialSnapShot_row_${title}_${index}`}
                        className={borderClasses}
                      >
                        <th className='o-h3 u-p-300 u-pl-400 u-text-left u-font-bold u-text-secondary-800'>
                          {formatTitle(title)}
                        </th>
                        {values.map((v, index) => {
                          const bgColor =
                            index === 0 ? `${highlightBgColor}` : ''
                          return (
                            <td
                              key={`FinancialSnapShot_item_${rowIndex}_${index}`}
                              className={`u-w-[22.5%] u-text-4 u-font-light u-text-secondary-900 ${bgColor}`}
                            >
                              {formatCurrency(v)}
                            </td>
                          )
                        })}
                      </tr>
                    )
                  })
                  : null}
              </tbody>
            </table>
            <div className='u-mt-0 u-h-1 u-bg-primary-300'></div>
          </>
        )}
        {paragraphs && paragraphs.map((item, index) => (
          <div key={`bottomCopy_${index}`}>
            <TitleAndCopy
              title={item.title}
              copy={item.description}
            />
          </div>
        ))}
      </ModuleCard.Body>
    )
  )
}

export default FinancialSnapshot
