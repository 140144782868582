import { TSVGProps } from '@unionco/alaris-app-types'

export const TeamIcon: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <svg
      width='13'
      height='10'
      viewBox='0 0 13 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.92528 1.66702C9.15732 1.66702 8.52083 2.28113 8.40913 3.08302C8.39785 3.16584 8.39164 3.2504 8.39164 3.33613C8.39164 3.37112 8.39277 3.40611 8.39446 3.43994C8.39728 3.49068 8.4018 3.54025 8.40913 3.58924C8.41026 3.6044 8.41139 3.61898 8.41308 3.63414C8.50957 4.76554 9.15001 5.63922 9.92591 5.63922C10.7018 5.63922 11.3422 4.765 11.4387 3.63414C11.4399 3.61898 11.4416 3.6044 11.4427 3.58924C11.4495 3.54025 11.454 3.49068 11.4568 3.43994C11.4591 3.40553 11.4602 3.37112 11.4602 3.33613C11.4602 3.2504 11.4545 3.16526 11.4427 3.08302C11.3298 2.28113 10.6933 1.66702 9.92528 1.66702Z'
        fill='currentColor'
      />
      <path
        d='M11.2535 5.43295C11.2502 5.43237 11.2462 5.43237 11.2428 5.43178C11.2146 5.42828 11.1858 5.42478 11.1576 5.42187C11.1576 5.42478 11.1565 5.4277 11.1565 5.43062C11.1559 5.43936 11.1548 5.44753 11.1537 5.45686C11.1486 5.50585 11.1345 5.55367 11.1119 5.59974C10.963 5.98757 10.4805 6.72297 10.1961 7.1405L10.1374 6.27329C10.2204 6.22955 10.292 6.15665 10.3378 6.07208C10.3705 6.01142 10.3908 5.94494 10.3908 5.87729C10.3908 5.87729 10.1786 5.9741 9.96253 5.9741C9.95011 5.9741 9.9377 5.9741 9.92529 5.97293C9.91287 5.9741 9.90046 5.9741 9.88804 5.9741C9.67193 5.9741 9.45978 5.87729 9.45978 5.87729C9.45978 5.94494 9.48009 6.01142 9.51282 6.07208C9.55852 6.15664 9.63019 6.22954 9.71313 6.27329L9.65501 7.1405C9.37062 6.72293 8.88818 5.98751 8.73922 5.59974C8.71722 5.55367 8.70311 5.50585 8.69747 5.45686C8.69634 5.44811 8.69465 5.43995 8.69465 5.43062C8.69465 5.4277 8.69352 5.42478 8.69352 5.42187C8.66531 5.42478 8.63653 5.4277 8.60832 5.43178C8.60493 5.43237 8.60098 5.43295 8.5976 5.43295C8.21729 5.48777 7.87196 5.65224 7.58926 5.8925C8.14675 6.49086 8.48699 7.3015 8.48699 8.17975C8.48699 8.35646 8.47119 8.51625 8.4458 8.66264C8.58573 8.66556 8.73076 8.66556 8.88028 8.66556H10.9703C12.0915 8.66556 13 8.66847 13 7.50966C13.0006 6.45117 12.241 5.57463 11.2535 5.43295Z'
        fill='currentColor'
      />
      <path
        d='M7.90705 8.18068C7.90705 6.81833 6.93032 5.69218 5.6607 5.50959C5.65619 5.509 5.65168 5.509 5.64716 5.50784C5.61048 5.50317 5.57437 5.49851 5.5377 5.49501C5.5377 5.49851 5.53657 5.50259 5.53657 5.50609C5.536 5.51717 5.53431 5.52825 5.53262 5.53991C5.52585 5.6029 5.50779 5.66413 5.47901 5.72362C5.28717 6.22225 4.66704 7.16762 4.30197 7.70481L4.22693 6.58973C4.33357 6.53316 4.42555 6.43927 4.48422 6.33079C4.52654 6.25264 4.5525 6.16749 4.5525 6.0806C4.5525 6.0806 4.27996 6.2054 4.00178 6.2054C3.98598 6.2054 3.97018 6.2054 3.95439 6.20423C3.93859 6.2054 3.92279 6.2054 3.90699 6.2054C3.62881 6.2054 3.35627 6.0806 3.35627 6.0806C3.35627 6.16749 3.38223 6.25263 3.42455 6.33079C3.48323 6.43926 3.57521 6.53316 3.68184 6.58973L3.6068 7.70481C3.24173 7.16768 2.62104 6.22232 2.42919 5.72362C2.40042 5.66472 2.38236 5.6029 2.37559 5.53991C2.37446 5.52883 2.3722 5.51775 2.37164 5.50609C2.37164 5.50259 2.37051 5.49851 2.37051 5.49501C2.33383 5.49851 2.29716 5.50259 2.26104 5.50784C2.25653 5.509 2.25145 5.509 2.2475 5.50959C0.976788 5.69213 0 6.81827 0 8.18068C0 9.66316 1.15786 9.66666 2.58942 9.66666H5.31812C6.74965 9.66666 7.90705 9.66316 7.90705 8.18068Z'
        fill='currentColor'
      />
      <path
        d='M3.95345 5.77364C4.5053 5.77364 5.0041 5.42897 5.36015 4.87494C5.64736 4.42821 5.84259 3.84503 5.89846 3.19534C5.90016 3.17609 5.90185 3.15685 5.90298 3.1376C5.91201 3.07462 5.91765 3.01047 5.9216 2.94573C5.92386 2.9014 5.92555 2.85708 5.92555 2.81217C5.92555 2.70195 5.91821 2.59289 5.90298 2.48675C5.75853 1.45625 4.94091 0.666656 3.95348 0.666656C2.96603 0.666656 2.14785 1.45631 2.00397 2.48675C1.9893 2.59289 1.98197 2.70195 1.98197 2.81217C1.98197 2.85708 1.98366 2.90199 1.98592 2.94573C1.9893 3.01046 1.99551 3.07461 2.00454 3.1376C2.00623 3.15685 2.00736 3.17609 2.00962 3.19534C2.06491 3.84503 2.26015 4.42821 2.54793 4.87494C2.9034 5.42956 3.40161 5.77364 3.95345 5.77364Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default TeamIcon
