/** Get Phase Step Percent Util
 *  Checks phase step type to return relevant
 *  step status.
 */
import type { ISectionStatus, TPhaseStepType } from '@unionco/alaris-app-types'

import { StepType } from '@unionco/alaris-utils'

export const getPhaseStepPercent = (
  type: TPhaseStepType,
  status: ISectionStatus | string
) => {
  switch (type) {
    case StepType.QUESTIONNAIRE:
      // TODO: make status required
      return status ? (status as ISectionStatus).percent : 0
    case StepType.LIVE_SESSION:
      return status === 'complete' ? 100 : 0
    default:
      return 0
  }
}
