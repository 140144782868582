/** Data Room Test Data
 *  Test data for the <DataRoom /> module
 *  used in the profile template
 *  A shared side module
 */
import { v4 as uuidv4 } from 'uuid'

import type { IDataRoomData } from '@unionco/alaris-app-types'

import { shortParagraphFixture } from '@fixtures/generic'

// #region - Download Item Test Data
const docxDownloadTestData = () => {
  return {
    title: 'Lorem Ipsum',
    extension: '.docx',
    href: '#',
    key: uuidv4()
  }
}

const jpgDownloadTestData = () => {
  return {
    title: 'Lorem Ipsum',
    extension: '.jpg',
    href: '#',
    key: uuidv4()
  }
}

const pdfDownloadTestData = () => {
  return {
    title: 'Lorem Ipsum',
    extension: '.pdf',
    href: '#',
    key: uuidv4()
  }
}

const xlsxDownloadTestData = () => {
  return {
    title: 'Lorem Ipsum',
    extension: '.xlsx',
    href: '#',
    key: uuidv4()
  }
}
// #endregion - Download Item Test Data

const downloadListTestData = () => {
  return {
    title: 'List Title',
    items: [
      jpgDownloadTestData(),
      pdfDownloadTestData(),
      docxDownloadTestData(),
      xlsxDownloadTestData()
    ],
    key: uuidv4()
  }
}

export const dataRoomModuleTestData = {
  moduleType: 'DataRoom',
  title: 'Data Room',
  items: {
    items: [
      downloadListTestData(),
      downloadListTestData(),
      downloadListTestData(),
      downloadListTestData(),
      downloadListTestData(),
      downloadListTestData(),
      downloadListTestData(),
      downloadListTestData(),
      downloadListTestData()
    ]
  },
  key: uuidv4(),
  container: 2,
  info: {
    copy: shortParagraphFixture
  }
} as IDataRoomData
