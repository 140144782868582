/** Print Layout
 * Alternative layout used for printing the CIM/AAG
 *  triggered by boolean value 'print' that gets set when print button is clicked
 *  Print gets set back to false when print dialog is closed
 */

import { useBreakAtSize } from '@unionco/components'
import { Modules, TModuleData } from 'components'

type TModuleSubContainer = {
    subContainer: number
    items: TModuleData[]
}
type TModuleContainerItem = TModuleData | TModuleSubContainer
type TModuleContainer = TModuleContainerItem[]

interface IDashboardMainSidebarProps {
    modules: TModuleData[]
}

export const PrintLayout: React.FC<IDashboardMainSidebarProps> = ({
    modules
}) => {
    const { aboveBreakPoint } = useBreakAtSize('lg')

    if (!aboveBreakPoint) {
        return (
            <div className='c-flow'>
                {modules.map((mod, index) => {
                    return <Modules data={mod} key={`MobileModule_${index}`} />
                })}
            </div>
        )
    }

    const mainModules = modules
    const groupedModuleData: TModuleContainer = []
    const subContainers: number[] = []

    // Organize modules that have subContainers into sub arrays
        mainModules.forEach((mod) => {
        if (!mod.subContainer) {
            groupedModuleData.push(mod)
        } else {
            const subContainerIndex = groupedModuleData.findIndex(
                (mod) => mod.subContainer
            )
            if (subContainerIndex === -1) {
                subContainers.push(mod.subContainer)
                groupedModuleData.push({ subContainer: mod.subContainer, items: [mod] })
            } else {
                // prettier-ignore
                (
                    groupedModuleData[subContainerIndex] as TModuleSubContainer
                ).items.push(mod)
            }
        }
    })

    return (
            <div>
            {groupedModuleData.map((item, index) => {
                if (item.hasOwnProperty('subContainer') && !!item.subContainer) {
                    const { items } = item as TModuleSubContainer
                    return (
                        <div
                            className='c-cluster u-items-stretch'
                            key={`Container_${index}`}
                        >
                            {!!items &&
                                items.map((mod, index) => {
                                    return <Modules data={mod} key={`SubModule_${index}`} />
                                })}
                        </div>
                    )
                }
                return (
                    <Modules data={item as TModuleData} key={`MainModule_${index}`} />
                )
            })}
            </div>
    )
}

export default PrintLayout
