/** Select One Fixture
 *
 *  See bottom for ouput
 */
import { v4 as uuidv4 } from 'uuid'

import type { IQuestionSegmentData } from '@unionco/alaris-app-types'

import {
  selectOneDoubleColumnQuestionFixture,
  selectOneSingleColumnQuestionFixture
} from '@fixtures/phase/questionnaire/question'

export const selectOneTestSegmentFixture = {
  title: 'Segment 1: Single Select',
  introCopy: 'Progression based intro copy',
  status: {
    percent: 0,
    statusText: 'notStarted'
  },
  questions: [
    selectOneSingleColumnQuestionFixture,
    selectOneDoubleColumnQuestionFixture
  ],
  key: uuidv4(),
  type: 'questionSegment',
  isAlarisOnly: false,
  isActive: true
} as IQuestionSegmentData

/** Output
 {
    segmentTitle: 'Segment 1: Single Select',
    introCopy: 'Progression based intro copy',
    status: {
    percent: 0,
    statusText: 'notStarted'
    } as ISectionStatus,
    questions: [
    {
        answered: false,
        answers: [
        {
            label: 'Answer 1',
            value: 'answer1'
        },
        {
            label: 'Answer 2',
            value: 'answer2'
        },
        {
            label: 'Answer 3',
            value: 'answer3'
        }
        ],
        questionType: 'selectOne',
        questionText: 'Select One Single Column Layout?',
        selectedAnswer: null
    },
    {
        answered: false,
        answers: [
        {
            label: 'Q2 Answer 1',
            value: 'answer1'
        },
        {
            label: 'Q2 Answer 2',
            value: 'answer2'
        },
        {
            label: 'Q2 Answer 3',
            value: 'answer3'
        },
        {
            label: 'Q2 Answer 4',
            value: 'answer4'
        }
        ],
        questionType: 'selectOne',
        questionText: 'Select One 2 Column Layout Question?',
        selectedAnswer: null
    }
    ]
}
*/
