/** Dashboard Header
 *  Navigation (header) component, specific
 *  to the dashboard template.
 *
 *  Displays company name, Alaris logo, help button, and account dropdown component.
 */
import { useBreakAtSize } from '@unionco/components'

import { TPageName } from '@appConstants'

import { AccountDropdown, NotificationMenu, IconHeader, Logo, SupportLink } from 'components'

export interface IDashboardHeaderProps {
  eyebrow: string
  headerText: string
  pageName: TPageName
}

export const DashboardHeader: React.FC<IDashboardHeaderProps> = ({
  eyebrow,
  headerText,
  pageName
}) => {
  const { aboveBreakPoint: tabletLayout } = useBreakAtSize('sm')
  const { aboveBreakPoint: desktopLayout } = useBreakAtSize('md')

  return (
    <nav className='c-wrapper | u-flex u-items-center u-justify-between u-py-700 u-text-white'>
      <IconHeader
        icon='dashboard'
        eyebrow={eyebrow}
        header={headerText}
        dark
        isLink={false}
      />
      {desktopLayout && <Logo />}
      <div className='c-cluster'>
        {tabletLayout && <SupportLink theme='light' />}
        <NotificationMenu />
        <AccountDropdown mobileLayout={!tabletLayout} pageName={pageName} />
      </div>
    </nav>
  )
}

export default DashboardHeader
