import { useContext, useEffect, useMemo, useState, useRef, useCallback } from 'react'

import type {
  IQuestionSegmentData,
  IQuestionnaireData,
  ISelectOneQuestionData
} from '@unionco/alaris-app-types'

import { inputBgClasses, questionLayoutClasses } from '../util'
import { debounce } from '@unionco/utils'
import { getCurrentQuestionData, submissionSuccessful } from 'components/questionnaire/util'
import { getCurrentSegmentData } from 'utils'
import { isAdminTracking } from 'utils/tracking'
import { questionSubmitTracking } from 'utils/tracking/questionnaire'

import { TUserContext, UserContext } from 'context'

import { SidebarContext } from 'components'

import { QuestionContext } from '../context'
import SelectAnswer from '../selectAnswer'

interface ISelectOneProps {
  data: ISelectOneQuestionData
}

export const SelectOneQuestion: React.FC<ISelectOneProps> = ({
  data: { answers, otherAnswer, selectedAnswer }
}) => {
  const { userType } = useContext(UserContext) as TUserContext
  const { submitAnswer } = useContext(QuestionContext)
  const { data, pageLocations } = useContext(SidebarContext)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [currentSelected, setCurrentSelected] = useState<number | null>(selectedAnswer)
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)


  const updateAnswer = async (answer: number | null) => {
    setCurrentSelected(answer)

    const newState = { ...data } as IQuestionnaireData
    const currentSegment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData
      ; (
        currentSegment.questions[
        pageLocations['question'] as number
        ] as ISelectOneQuestionData
      ).selectedAnswer = answer
    newState.segments[pageLocations['segment']] = currentSegment
    const result = await submitAnswer(true, newState, pageLocations)
    if (submissionSuccessful(result)) {
      setSubmitted(true)
    }
  }

  const changeAnswer = async (value: string) => {
    const newState = { ...data } as IQuestionnaireData
    const currentSegment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData
    const currentQuestion = getCurrentQuestionData(
      pageLocations,
      newState
    ) as ISelectOneQuestionData


    currentQuestion.otherAnswer = value
    newState.segments[pageLocations['segment']] = currentSegment

    const result = await submitAnswer(true, newState, pageLocations)
    if (submissionSuccessful(result)) {
      setSubmitted(true)
    }
  }


  const onChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e || !e.target) return
      const { target } = e
      const newValue = (target as HTMLInputElement).value

      changeAnswer(newValue)

    }),
    [changeAnswer]
  )

  const debouncedTrackingHandler = useMemo(
    () =>
      debounce((currentSelected: number | null) => {
        if (typeof currentSelected === 'number') {
          const answerTracking = {
            [`answer_${currentSelected}`]: answers[currentSelected].label
          }
          const isAdmin = isAdminTracking(userType)

          questionSubmitTracking({
            is_admin_key: isAdmin,
            ...answerTracking
          })
          setSubmitted(false)
        }
      }, 2000),
    [answers, userType]
  )

  useEffect(() => {
    if (!submitted) return
    debouncedTrackingHandler(currentSelected)
  }, [debouncedTrackingHandler, currentSelected, submitted])

  const layoutClasses = questionLayoutClasses(answers.length)

  return (
    <form className={`${layoutClasses}`}>
      {answers.map((answer, index) => {
        const { label, value } = answer
        const checked = currentSelected === index
        const inputBgColor = inputBgClasses(checked)

        return (
          <SelectAnswer
            componentName='SelectOne'
            className='u-flex-nowrap'
            key={`questionnaire${pageLocations['section']}_segment${pageLocations['segment']}_SelectOne_${index}`}
            onClick={() => updateAnswer(index)}
            checked={checked}
          >
            <div className='u-w-full u-flex u-flex-nowrap u-items-center u-text-left'>
              {/* TODO: Replace name with segment id */}
              <input
                className={'u-hidden'}
                title={`answer${index}`}
                name={`SelectOne_${index}`}
                type='radio'
                value={value}
                checked={checked}
                onChange={() => {
                  return undefined
                }}
              />

              <div
                className={`${inputBgColor} u-aspect-square u-h-[1.875rem] u-w-[1.875rem] u-rounded-full u-border-[0.46875rem] u-border-white u-mr-4`}
              />
              {checked == true && label == 'Other' ? (
                <div className='u-w-full'>
                  <input
                    ref={inputRef as React.RefObject<HTMLInputElement>}
                    type="text"
                    onChange={(e) => onChange(e)}
                    onClick={(e) => e.stopPropagation()} //Makes the input field clickable without triggering the updateAnswers function
                    className='u-w-full u-font-normal u-p-2'
                    required={true}
                    defaultValue={otherAnswer}
                    placeholder="Please Explain."

                  />
                </div>) : (
                <label htmlFor={`answer${index}`}>
                  {label}
                </label>)
              }
            </div>
          </SelectAnswer>
        )
      })}
    </form>
  )
}

export default SelectOneQuestion
