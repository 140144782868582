/** Services Offered Test Data
 *  Test data for the <ServicesOffered /> profile module
 *  used in the profile template
 *  A seller only side module
 */
import { v4 as uuidv4 } from 'uuid'

import type { IOrganicGrowthData } from '@unionco/alaris-app-types'

import {
  checkmarkListItemTestData,
  shortParagraphFixture
} from '@fixtures/generic'

export const organicGrowthModuleTestData = {
  moduleType: 'OrganicGrowth',
  title: 'Organic Growth',
  items: [
    checkmarkListItemTestData(true),
    checkmarkListItemTestData(),
    checkmarkListItemTestData(true),
    checkmarkListItemTestData(true),
    checkmarkListItemTestData(),
    checkmarkListItemTestData(),
    checkmarkListItemTestData(),
    checkmarkListItemTestData(true),
    checkmarkListItemTestData(),
    checkmarkListItemTestData(true)
  ],
  key: uuidv4(),
  container: 1,
  subContainer: 1,
  info: {
    copy: shortParagraphFixture
  }
} as IOrganicGrowthData
