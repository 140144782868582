import { IconSVG } from '@unionco/svg-icons'

import { TSVGProps } from '@unionco/alaris-app-types'

export const PieChartIcon: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <IconSVG
      viewBoxWidth={13}
      viewBoxHeight={14}
      style={{
        transform: 'translate(5%,-2%)'
      }}
      {...props}
      ref={undefined}
    >
      <path
        d='M5.98756 0.666656C5.71081 0.666656 5.48667 0.666656 5.48667 0.666656V1.66843C3.95898 1.80179 2.54018 2.51492 1.52029 3.66011C0.500982 4.80589 -0.0424766 6.2979 0.00259518 7.83118C0.0470483 9.36389 0.675659 10.8221 1.76011 11.9065C2.84455 12.991 4.30269 13.6197 5.83548 13.6641C7.36882 13.7091 8.86084 13.1657 10.0065 12.1464C11.1517 11.1264 11.8649 9.70765 11.9982 8.17999H12.4991C12.6319 8.17999 13 8.17995 13 8.17995C13 8.17995 13 7.81184 13 7.67911C13 5.81949 12.2612 4.03586 10.9464 2.72025C9.631 1.40543 7.84717 0.666656 5.98756 0.666656ZM6.48845 1.66843C7.91159 1.78301 9.24773 2.40035 10.257 3.40959C11.2663 4.41883 11.8836 5.75502 11.9982 7.17818H6.48845V1.66843ZM5.98756 12.6879C4.25762 12.6879 2.64981 11.7957 1.73507 10.327C0.820956 8.85872 0.728921 7.02239 1.49152 5.46956C2.25474 3.91681 3.76563 2.86815 5.48675 2.69521V7.67907C5.48675 7.8118 5.48675 8.17995 5.48675 8.17995C5.48675 8.17995 5.8549 8.17995 5.98763 8.17995H10.9965C10.8719 9.41965 10.2896 10.5685 9.36353 11.402C8.43752 12.2359 7.23345 12.6942 5.98756 12.6879Z'
        fill='white'
      />
    </IconSVG>
  )
}

export default PieChartIcon
