import React from 'react'

import { IContent } from '@unionco/alaris-app-types'

import { numberCountDisplayText } from 'utils'

interface IPriorityListProps {
  items: IContent[]
}

export const PriorityList: React.FC<IPriorityListProps> = ({items}) => {
  return (
    <>
      {items.map((item, index) => {
        const { content } = item
        const displayNumber = index + 1
        return (
          <React.Fragment key={`SellerTopPriorityItem_${index}`}>
            {index !== 0 && <div className='o-line u-my-2 u-m-auto' />}
            <div className=' u-flex u-text-left print:u-p-1'>
              <p className='o-h3 u-m-1 u-font-bold u-text-primary-500 print:u-mr-2'>
                {numberCountDisplayText(displayNumber)}
              </p>
              <p className='u-font-open-sans u-m-1 u-text-nowrap u-text-secondary-800]'>{content}</p>
            </div>
          </React.Fragment>
        )
      })}
    </>
  )
}
