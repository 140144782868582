import { Input } from '@unionco/components'
import { PlusMinusIcon } from '@unionco/svg-icons'
import axios, { AxiosResponse } from 'axios'
import { useCallback, useContext, useEffect, useState } from 'react'
import tw from 'twin.macro'
import { v4 as uuid } from 'uuid'

import {
  IDocumentUploadAnswer,
  IDocumentUploadQuestionData,
  IQuestionSegmentData,
  IQuestionnaireData
} from '@unionco/alaris-app-types'

import { API_BASE } from '@appConstants'
import { cx } from '@unionco/utils'
import { getCurrentQuestionData } from 'components/questionnaire/util'
import { getCurrentSegmentData } from 'utils'

import { IAMContext, TClientContext, TIAMContext, UserContext } from 'context'

import { SidebarContext } from 'components/SidebarTemplate'
import { StyledButton } from 'components/generic'

import { QuestionContext } from '../context'

interface IDocumentUploadQuestionProps {
  data: IDocumentUploadQuestionData
}

export const DocumentUploadQuestion: React.FC<IDocumentUploadQuestionProps> = ({
  data: { answers: userFileNames }
}) => {
  // Context
  const { jwt } = useContext(UserContext) as TClientContext
  const {
    IAMData: { companyId }
  } = useContext(IAMContext) as TIAMContext
  const { data: stepData, pageLocations } = useContext(SidebarContext)
  const { submitAnswer } = useContext(QuestionContext)
  // Local State:
  const [fileNames, setFileNames] = useState<IDocumentUploadAnswer[] | null>(
    null
  )

  async function saveEntry(updatedAnswers: IDocumentUploadAnswer[]) {
    const newState = { ...stepData } as IQuestionnaireData
    const currentSegment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData
    const currentQuestion = getCurrentQuestionData(
      pageLocations,
      newState
    ) as IDocumentUploadQuestionData
    currentQuestion.answers = updatedAnswers
    newState.segments[pageLocations['segment']] = currentSegment

    /**
     * Submit answers
     */
    const result = (await submitAnswer(
      true,
      newState,
      pageLocations
    )) as AxiosResponse<unknown, unknown>

    /**
     * Update local state if successful
     */
    if (result.status === 200) {
      const newState = [...updatedAnswers]
      const unsavedFiles = newState.filter((file) => !file.saved)
      if (unsavedFiles) {
        newState.map((entry) => {
          entry.saved = true
          return entry
        })
        setFileNames(newState)
      }
    }
  }

  /**
   * Takes a file array from user and index of currently selected file, uploads file via post request
   * @param files 
   * @param index 
   */
  const uploadDocument = (files: FileList | null, index: number) => {
    if (!files) return
    const data = new FormData()
    if (fileNames) {
      data.append('files', files[0], fileNames[index]?.name)
    } else {
      data.append('files', files[0])
    }
    data.append('path', `${companyId}`)
    data.append('fileInfo', '{ "caption": "", "alternativeText": "" }')
    axios
      .post(`${API_BASE}/api/upload`, data, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
      .then((res) => {
        if (!fileNames) return
        const { data } = res

        const { id, url } = data[0]
        const newState = [...fileNames]
        newState[index] = { ...newState[index], url, mediaId: id }
        saveEntry(newState)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  /**
   * Gets current file name, updates state, and starts document upload process
   * @param files files being uploaded by user
   * @param index index of current file in files array
   */
  const updateFileName = (files: FileList | null, index: number) => {
    // Set state for fileNames and new file
    if (!fileNames) return
    const newState = [...fileNames]
    if (!files) return
    newState[index].name = files[0].name

    if (newState[index].mediaId) {
      const data = new FormData()
      data.append('fileInfo', JSON.stringify({ name: newState[index].name }))

      axios
        .post(`${API_BASE}/api/upload?id=${newState[index].mediaId}`, data, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
        .then((res) => {
          console.log('res', res)
        })
        .catch((err) => {
          console.error(err)
        })
    }
    // After getting filename call function to finishing upload document
    uploadDocument(files, index)
  }

  /**
   * Load user answers
   */
  useEffect(() => {
    if (!userFileNames) {
      setFileNames([
        { id: uuid(), name: '', url: '', saved: false, mediaId: '' }
      ])
    }
    setFileNames(userFileNames)
  }, [userFileNames])

  /**
   * Displays file upload status message
   */
  const statusText = ({ name, url }: IDocumentUploadAnswer) => {
    const textStyling = 'u-text-4 u-font-open-sans u-font-bold'
    // If no url 'No document'
    if (!url)
      return (
        <div className={cx('u-text-state-danger', textStyling)} >
          {'Not uploaded'}
        </div>
      )
    // If document upload was attempted but missing file name
    if ((url && !name) || (url && name === ''))
      return <p className={cx('u-text-state-danger', textStyling)}>Please reselect file</p>
    // If document was uploaded successfully
    return <p className={cx('u-text-state-good', textStyling)}>Success! File uploaded</p>
  }

  return (
    <div className='c-flow u-flow-space--300 u-items-center'>
      <div className='c-autoGrid u-w-full [--auto-grid-min-item-size:24rem]'>
        {fileNames &&
          fileNames.map((doc, index) => {
            const { id, name, url } = doc
            const validUrl = url && url !== ''
            return (
              <div className='c-flow u-items-start' key={`${id}`}>
                <div className='c-repel u-w-full u-rounded' style={{ backgroundColor: '#f5f3f3' }}>
                  <div className='c-withLayer'>
                    <StyledButton
                      type='button'
                      theme='primary'
                      themeStyle='solid'
                      roundingClass='u-square-full'
                    >
                      Choose file
                    </StyledButton>
                    <form className='c-layer'>
                      <input
                        type='file'
                        className='u-aspect-square u-h-full u-w-full u-cursor-pointer u-opacity-0'
                        name='document'
                        onChange={(e) => {
                          updateFileName(e.target.files, index)
                        }}
                      />
                    </form>
                  </div>
                  <div className='c-repel !u-px-200  u-font-open-sans u-text-4' style={{ overflow: 'auto', whiteSpace: 'nowrap' }}>
                    {fileNames[index].name ? fileNames[index].name : <p>No file selected</p>}
                  </div>
                </div>
                <div className='c-repel u-w-full'>
                  <div className='c-repel !u-px-50  u-font-open-sans' >
                    {<p>File Upload Status:</p>}
                    {statusText(doc)}
                  </div>
                  <StyledButton
                    type='button'
                    theme='primary'
                    themeStyle='outline'
                    className='u-rounded-full !u-py-100 !u-px-400'
                    onClick={() => {
                      const newState = [...fileNames]
                      newState.splice(index, 1)
                      saveEntry(newState)
                    }}
                  >
                    Remove
                  </StyledButton>
                </div>
              </div>
            )
          })}
      </div>
      <StyledButton
        type='button'
        theme='primary'
        themeStyle='solid'
        className='!u-p-0'
        onClick={() => {
          const newState = fileNames ? [...fileNames] : []
          newState.push({
            id: uuid(),
            name: '',
            url: '',
            saved: false,
            mediaId: ''
          })
          saveEntry(newState)
        }}
      >
        <PlusMinusIcon open noCircle cssClasses={tw`u-w-7 u-aspect-square`} />
      </StyledButton>
    </div>
  )
}
