export const formatCurrencyAbbr = (
  value: number,
  foldValue: number,
  foldLetter: string,
  currencySign = '$'
): string => {
  if (value % foldValue === 0)
    return `${currencySign}${value / foldValue}${foldLetter}`
  return `${currencySign}${(value / foldValue).toFixed(1)}${foldLetter}`
}
