/**
 * Login Tracking util function
 */
import { TLoginTracking } from '@types'

import { tracking } from './tracking'

type TLoginTrackingParams = Omit<TLoginTracking, 'event'>

export const loginTracking = (data: TLoginTrackingParams): void => {
  tracking<TLoginTracking>({
    event: 'login',
    ...data
  })
}
