import { Input } from '@unionco/components'

import { fieldIsValid } from '../utils'
import { cx } from '@unionco/utils'

import LabelAndInput from '../labelAndInput'
import { inputStyling } from '../mod'

interface IBaseSalaryInputProps {
  defaultValue: string
  validation: null | true | string[]
  required: boolean
}

export const BaseSalaryInput: React.FC<IBaseSalaryInputProps> = ({
  defaultValue,
  validation,
  required
}) => {
  return (
    <LabelAndInput
      label='Base Salary'
      className='u-basis-[calc(33%-.5rem)]'
      required={required}
    >
      <Input
        id=''
        className={cx('u-w-full', fieldIsValid('baseSalary', validation))}
        classNames={inputStyling}
        defaultValue={defaultValue}
        placeholder='Base Salary'
        step='1'
        name='baseSalary'
        type='number'
        required={required}
      />
    </LabelAndInput>
  )
}

export default BaseSalaryInput
