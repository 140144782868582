/** Styled Markdown
 *  A styled version of the markdown component from
 *  @see https://github.com/remarkjs/react-markdown
 */
import { cx } from '@algolia/ui-components-shared'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'

interface IStyledMarkDown {
  content: string
  paragraphTextAlign?: 'left' | 'center'
}

export const StyledMarkDown: React.FC<IStyledMarkDown> = ({
  content,
  paragraphTextAlign = 'left'
}) => {
  const textAlignmentClass =
    paragraphTextAlign === 'center' ? 'u-text-center' : 'u-text-left'
  const paragraphStyles = `[&_p]:u-text-secondary-800 ${textAlignmentClass}`

  const listStyles = '[&_ul]:u-list-disc [&_ul]:u-ml-[1rem]'
  const strongStyles = '[&_strong]:u-font-bold [&_strong]:u-text-secondary-900'
  return (
    <ReactMarkdown
      className={cx(
        'b-Markdown | c-flow | u-flow-space-inner--200 !u-list-disc u-text-left u-text-4 u-text-secondary-800',
        paragraphStyles,
        listStyles,
        strongStyles
      )}
    >
      {content}
    </ReactMarkdown>
  )
}

export default StyledMarkDown
