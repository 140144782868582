import { IMatchCardData } from '@unionco/alaris-app-types'

import { UserRoles } from '@unionco/alaris-utils'

import { Img } from 'components'

interface IMatchLinkProps {
  data: IMatchCardData
  isTopMatch?: boolean
  companyTypeForHref?: 'buyer' | 'seller'
}

export const MatchLink: React.FC<IMatchLinkProps> = ({
  companyTypeForHref,
  data: { companyId, companyLogo: logo, companyName, subText },
  isTopMatch
}) => {
  const validImg = logo && logo !== ''
  const href =
    companyTypeForHref === UserRoles.SELLER
      ? `/cim?companyId=${companyId}`
      : `/buyer-at-a-glance?companyId=${companyId}`
  return (
    <div className='print:u-w-1/3 print:u-flex print:u-flex-row'>
    <a
      className='u-flex u-items-center u-rounded u-bg-primary-300 u-p-400'
      href={href}
    >
      {validImg && (
        <div className='u-mr-200 u-aspect-square u-max-w-[2.5rem] u-rounded-full u-p-100'>
          <div className='u-flex u-h-full u-w-full u-items-center u-rounded-full u-bg-white'>
            <Img src={logo} alt='' width={150} height={150} />
          </div>
        </div>
      )}
      <div>
        <p>{companyName}</p>
        {isTopMatch && (
          <p className='o-body-2 u-font-semibold u-text-primary-500'>
            Top Match
          </p>
        )}
        {subText && <p className='u-text-2'>{subText}</p>}
      </div>
    </a>
  </div>
  )
}

export default MatchLink
