interface IDBItem {
  key: string
}

function getIndexFromKey(collection: IDBItem[], key: string): number {
  return collection.findIndex((obj: IDBItem) => {
    return obj.key === key
  })
}

export { getIndexFromKey }
