import {
  IEmployeeData,
  IFounderData,
  IInputLabelValue,
  INonOwnerData,
  IRespondentSummary,
  ITeamMemberData,
  TPercentMax100
} from '@unionco/alaris-app-types'

import { placeHolderImageUrl } from '@fixtures/media'

export const teamMemberTestData = {
  age: 40,
  firstName: 'First',
  lastName: 'Last',
  title: 'Job, Title',
  profilePic: {
    url: placeHolderImageUrl['default'],
    height: 50,
    width: 50
  }
} as ITeamMemberData

// TODO: Make generic along with type?
export const founderTestData = {
  transactionGoal:  [{value: 'Transaction Goal', label: 'Transaction Goal'}],
  ownership: 50 as TPercentMax100,
  involvedInBusiness: true,
  glidePath: '',
  hhManaged: 0,
  aumManaged: 0,
  revenueManaged: 0,
  ebitdaContribution: 0,
  ...teamMemberTestData
} as IFounderData

export const employeeTestData = {
  ...teamMemberTestData,
  baseSalary: 100000,
  jobDescription: 'Job Description'
} as IEmployeeData

export const nonOwnerTestData = {
  ...teamMemberTestData,
  baseSalary: 100000,
  jobDescription: 'Job Description',
  aumManaged: 0,
  revenueManaged: 0
} as INonOwnerData

export const respondentSummary = {
  firstName: 'First',
  lastName: 'Last',
  jobTitle: 'ceo',
  profilePic: placeHolderImageUrl['default'],
  progression: 'current step'
} as IRespondentSummary
