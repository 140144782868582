import React from 'react'

import { IAdvisorValuePropositionModuleData } from '@unionco/alaris-app-types'

import { ModuleCard, TitleAndCopy, Video } from 'components'

interface IAdvisorValuePropositionModuleProps {
    data: IAdvisorValuePropositionModuleData
}

export const AdvisorValuePropositionModule: React.FC<
    IAdvisorValuePropositionModuleProps
> = ({ data: { info, items, title } }) => {
    if (!items || items.length === 0) return <></>

    return (
        <ModuleCard.Body
        className='print:u-break-before-page'
        >
            <ModuleCard.InfoHeader
                info={info}
                title={title || 'Advisor Value Proposition'}
                trackingName='advisor value proposition module'
            />
            {items.map((item, index) => {
                const content = <TitleAndCopy title={item.title} copy={item.value} />

                return (
                    <React.Fragment key={`TitleAndCopy_${index}`}>
                        {index !== 0 && <div className='o-line' />}
                        {content}
                    </React.Fragment>
                )
            })}
        </ModuleCard.Body>
    )
}

export default AdvisorValuePropositionModule
