import React from 'react'

import { ILeaderData } from '@unionco/alaris-app-types'

import { Img } from 'components'

import { TeamMemberHeader } from '../TeamMemberHeader'

export const LeaderCard: React.FC<ILeaderData> = ({
  biography,
  firstName,
  lastName,
  profilePic,
  title
}) => {
  const validImg =
    profilePic && profilePic && profilePic.url && profilePic.url !== ''

  return (
    <div className='u-flex u-flex-wrap u-w-full u-items-start u-justify-center u-mt-2'>
      <div className='u-w-full print:u-break-after-avoid-page'>
        {validImg && (
          <Img
            className='u-w-3/4'
            width={profilePic.width}
            height={profilePic.height}
            src={profilePic.url}
            alt=''
          />
        )}
        <TeamMemberHeader
          firstName={firstName}
          lastName={lastName}
          title={title}
        />
        <div className='u-mt-4 u-w-4/5'>
          <p className='u-text-2 print:u-break-inside-avoid-page'>
            <span className='u-font-bold'>Biography</span>
            <br />
            <span className='print:u-break-inside-avoid-page'>{biography}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default LeaderCard
