/** IAM Context
 *
 * Defaults to user settings, but is also leveraged by admin users to impersonate
 * other non-admin users
 */
import { createContext } from 'react'

import {
  ILocationsIndexesData,
  IPanelPhaseData,
  IUserProgressionKeysData,
  TUserType
} from '@unionco/alaris-app-types'

export type TImpersonationMode = 'presentation' | 'impersonation' | 'adminControl' | null

export interface IIAMData {
  companyId: number | null
  companyName: string
  companyType?: 'buyer' | 'seller'
  userType: TUserType
  id: number
  impersonationMode: TImpersonationMode
  progression: IUserProgressionKeysData
  lastLocation: IUserProgressionKeysData
  lastLocationIndexes?: ILocationsIndexesData
  timelineComplete: boolean
  firstName: string
  lastName: string
  isPrimaryUser: boolean
}

export type TIAMContext = {
  IAMData: IIAMData
  impersonateUser: (
    id: number,
    mode: TImpersonationMode,
    isPrimaryUser?: boolean,
    jumpToPhase?: IPanelPhaseData
  ) => void
  showResumeInitially: boolean
  setShowResumeInitially: React.Dispatch<React.SetStateAction<boolean>>
  setIAM: React.Dispatch<React.SetStateAction<IIAMData>>
  setReload: React.Dispatch<React.SetStateAction<boolean>>
}

const defaultIUserProgressionKeysDataState = {
  phase: '',
  step: '',
  segment: '',
  question: ''
} as IUserProgressionKeysData

const defaultLocationsData = {
  section: 0,
  segment: 0,
  question: null
}

export const IAMDataDefault = {
  companyId: 0,
  companyName: '',
  id: 0,
  impersonationMode: null,
  userType: 'admin',
  progression: defaultIUserProgressionKeysDataState,
  lastLocation: defaultIUserProgressionKeysDataState,
  lastLocationIndexes: defaultLocationsData,
  isPrimaryUser: false,
} as IIAMData

export const IAMContext = createContext<TIAMContext | null>(null)
