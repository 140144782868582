import { v4 as uuidv4 } from 'uuid'

import type { ITitledListItemData } from '@unionco/alaris-app-types'

export const titledListItemTestData = (): ITitledListItemData => {
  return {
    title: 'Title',
    item: 'subtext',
    key: uuidv4()
  }
}

export function titledListItemWithInfoTestData(): ITitledListItemData {
  return {
    title: 'Title',
    item: 'subtext',
    info: {
      copy: 'Info reveal'
    },
    key: uuidv4()
  }
}
