/** Client Experiences Test Data
 *  Test data for the <ClientExperiences /> module
 *  used in the profile template
 *  A seller only main module
 */
import { v4 as uuidv4 } from 'uuid'

import type { IClientExperienceModuleData } from '@unionco/alaris-app-types'

import { paragraphTestData, shortParagraphFixture } from '@fixtures/generic'

export const clientExpereienceModuleTestData = {
  moduleType: 'ClientExperiences',
  title: 'Client Experience',
  items: [
    {
      title: 'Title',
      value: paragraphTestData(),
      key: uuidv4()
    },
    {
      title: 'Title',
      value: paragraphTestData(),
      key: uuidv4()
    },
    {
      title: 'Title',
      value: paragraphTestData(),
      key: uuidv4()
    }
  ],
  key: uuidv4(),
  container: 1,
  info: {
    copy: shortParagraphFixture
  }
} as IClientExperienceModuleData
