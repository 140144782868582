/** Collapsible Section
 *
 */
import { Accordion } from '@unionco/components'
import { CaretIcon } from '@unionco/svg-icons'

import { themedGhostStyle } from 'components/generic/StyledButton/utils'

import Divider from '../Divider'

interface ICollapsibleSection {
  children: React.ReactNode
  title: string
}

export const CollapsibleSection: React.FC<ICollapsibleSection> = ({
  children,
  title
}) => {
  return (
    <Accordion.Main className='print:u-break-inside-avoid-page' initialOpenItems={[0]}>
      <Accordion.Item index={0}>
        <div className='u-flex '>
          <Divider title={title} className='u-grow print:u-break-after-avoid' />
          <Accordion.Button
            index={0}
            locked={false}
            className={`u-flex u-items-center u-px-400 u-text-center u-text-2 u-font-bold u-uppercase u-tracking-[.25em] print:u-hidden ${themedGhostStyle(
              'dark',
              false
            )}`}
          >
            <span className='u-mr-2 u-font-normal'>Collapse</span>
            <Accordion.Indicator index={0} animation='upToDown'>
              <CaretIcon className='[--icon-size:.75rem]' />
            </Accordion.Indicator>
          </Accordion.Button>
        </div>
        <Accordion.Drawer index={0}>
          <div className='u-py-400'>{children}</div>
        </Accordion.Drawer>
      </Accordion.Item>
    </Accordion.Main>
  )
}

export default CollapsibleSection
