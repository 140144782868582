import {
  TCompleteStatus,
  TIncompleteStatus,
  TInprogressStatus,
  TNotStartedStatus,
  TSellerTransactionStatusActive,
  TSellerTransactionStatusSold
} from '@unionco/alaris-app-types'

export const StatusText = {
  COMPLETE: {
    label: 'Complete',
    value: 'complete' as TCompleteStatus
  },
  IN_PROGRESS: {
    label: 'In progress',
    value: 'inprogress' as TInprogressStatus
  },
  INCOMPLETE: {
    label: 'Incomplete',
    value: 'incomplete' as TIncompleteStatus
  },
  NOT_STARTED: {
    label: 'Not started',
    value: 'notStarted' as TNotStartedStatus
  }
}

export const SellerTransactionStatus = {
  ACTIVE: 'active' as TSellerTransactionStatusActive,
  SOLD: 'sold' as TSellerTransactionStatusSold
}
