import { Input } from '@unionco/components'

import { fieldIsValid } from '../utils'
import { cx } from '@unionco/utils'

import LabelAndInput from '../labelAndInput'
import { inputStyling } from '../mod'

interface IOwnershipInputProps {
  defaultValue: string
  validation: null | true | string[]
}

export const OwnershipInput: React.FC<IOwnershipInputProps> = ({
  defaultValue,
  validation
}) => {
  const name = 'ownership'
  return (
    <LabelAndInput
      label='Ownership %'
      required
      className='u-w-full u-basis-[calc(33%-.5rem)]'
    >
      <Input
        id=''
        className={cx('u-w-full', fieldIsValid(name, validation))}
        classNames={inputStyling}
        placeholder='Percentage'
        defaultValue={defaultValue}
        type='number'
        min={0}
        max={100}
        step='0.01'
        name={name}
        required
      />
    </LabelAndInput>
  )
}

export default OwnershipInput
