/** Number Count Display Text
 *
 * Takes a number and returns a string with a suffix
 *
 * Currently works for numbers 1-10
 * Logic needs to be adjusted to account for numbers 11-20 using `th`
 * where 20's - 90's use `st`, `nd`, `rd`
 */

export const numberCountDisplayText: (n: number) => string = (number) => {
  switch (number as unknown as number) {
    case 1:
      return '1st'
    case 2:
      return '2nd'
    case 3:
      return '3rd'
    default:
      return `${number}th`
  }
}

export default numberCountDisplayText
