import { DateTime } from 'luxon'

import { TPhaseStepSummaryData } from '@unionco/alaris-app-types'

export const timelineStepStatus = (
  stepNum: number,
  currentSectionIndex: number
) => {
  switch (true) {
    case stepNum < currentSectionIndex:
      return 'complete'
    case stepNum === currentSectionIndex:
      return 'current'
    default:
      return 'future'
  }
}

export const getDateTimeText = (summary: TPhaseStepSummaryData) => {
  const { type } = summary
  return type === 'liveSession'
    ? summary.segments[0].dateScheduled
      ? DateTime.fromISO(summary.segments[0].dateScheduled).toFormat('D')
      : 'Not scheduled'
    : summary.segments[0].estimatedTime
    ? summary.segments[0].estimatedTime.toString()
    : ''
}
