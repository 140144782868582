/** Support Page Fixture
 *
 */
import { v4 as uuidv4 } from 'uuid'

import type {
  ISupportSectionData,
  ISupportSegmentData,
  TSupportFAQData,
  TSupportItemData
} from '@unionco/alaris-app-types'

import { paragraphTestData } from '@fixtures/generic'
import { testVideoLink } from '@fixtures/media'

export const supportItemContent = {
  copy: `${paragraphTestData()}

  

  Paragraph after break`
} as TSupportItemData

export const supportItemFixture = {
  title: 'Accordion Button Title',
  faqContent: [supportItemContent]
} as TSupportFAQData

export const supportItemWithVdieoFixture = {
  title: 'Test item with Video',
  faqContent: [{ ...supportItemContent, src: testVideoLink }]
} as TSupportFAQData

export const supportSegmentFixture = (title?: string, header?: string) => {
  return {
    title: title || 'FAQ Sidebar Title',
    eyebrow: 'Support | FAQS',
    header: header || 'Valuation & Deal Structure',
    copy: 'Feugiat congue porttitor justo arcu.',
    faqs: [
      supportItemFixture,
      supportItemWithVdieoFixture,
      supportItemFixture,
      supportItemFixture
    ],
    type: 'support'
  } as ISupportSegmentData
}

export const supportSectionFixture = {
  icon: 'questionMessage',
  headerIconOverride: 'team',
  title: 'FAQ Categories',
  type: 'support',
  segments: [
    supportSegmentFixture(),
    supportSegmentFixture('segment 2', 'header 2'),
    supportSegmentFixture('segment 3', 'header 3'),
    supportSegmentFixture('segment 4', 'header 4'),
    supportSegmentFixture('segment 5', 'header 5')
  ],
  key: 'support'
} as ISupportSectionData

export const supportPageFixture = {
  id: 0,
  key: 'support-summary-data',
  sections: [supportSectionFixture],
  title: 'FAQ Categories'
}
