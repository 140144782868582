/** Historic Growth Statistics Test Data
 *  Test data for the <HistoricGrowthStatistics /> profile module
 *  used in the profile template
 *  A buyer main module
 */
//'types/modules/historicGrowth'
import { v4 as uuidv4 } from 'uuid'

import type { IHistoricGrowthStatisticsData } from '@unionco/alaris-app-types'

import { VideoBlockTestData, shortParagraphFixture } from '@fixtures/generic'

export const historicGrowthStatisticsTestData = {
  moduleType: 'HistoricGrowthStatistics',
  title: 'Historic Growth Statistics',
  videoBlock: VideoBlockTestData(),
  assetsUnderManagement: {
    labels: ['2019', '2020', '2021', '2022'],
    datasets: [
      {
        // borderWidth: 1,
        data: [
          { x: 1, y: 10 },
          { x: 2, y: 13 },
          { x: 3, y: 23 },
          { x: 4, y: 36 }
        ],
        label: ''
      }
    ]
  },
  ebitda: {
    labels: ['2022', '2021', '2020', '2019'],
    datasets: [
      {
        borderWidth: 1,
        data: [11250000, 10000000, 8750000, 3800000],
        label: ''
      }
    ]
  },
  key: uuidv4(),
  container: 1,
  info: {
    copy: shortParagraphFixture
  }
} as IHistoricGrowthStatisticsData
