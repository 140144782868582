import React from 'react'

import { INonOwnerData } from '@unionco/alaris-app-types'

import { displayNumberWithCommas, displayNumberAsPercent } from '@unionco/alaris-utils'

import { Img } from 'components/generic'

import { TeamMemberHeader } from '../TeamMemberHeader'

export const NonOwnerCard: React.FC<INonOwnerData> = ({
  age,
  aumManaged,
  baseSalary,
  firstName,
  jobDescription,
  lastName,
  profilePic,
  revenueManaged,
  title
}) => {
  const validImg =
    profilePic && profilePic && profilePic.url && profilePic.url !== ''

  const stats = [
    { displayText: 'Role in the Firm', value: jobDescription },
    {
      displayText: 'Total Compensation',
      value: baseSalary && `$${displayNumberWithCommas(baseSalary)}`
    },
    {
      displayText: 'Revenue Managed',
      value: revenueManaged && `${displayNumberAsPercent(revenueManaged)}`
    },
    {
      displayText: 'AUM Managed',
      value: aumManaged && `${displayNumberAsPercent(aumManaged)}`
    },
  ]
  return (
    <div className='page:u-break-inside-avoid-page'>
      {validImg && (
        <div className='u-aspect-square u-w-full u-max-w-[8rem]'>
          <Img
            className='u-w-full'
            width={profilePic.width}
            height={profilePic.height}
            src={profilePic.url}
            alt=''
          />
        </div>
      )}
      <div className='print:u-break-after-avoid-page'>
        <TeamMemberHeader
          age={age}
          firstName={firstName}
          lastName={lastName}
          title={title}
        />
        {stats.map(({ displayText, value }, index) => {
          if (!value || value === '') {
            return <React.Fragment key={`InvalidStat_${index}`} />
          }

          return (
            <p className='u-text-2' key={`StatItem_${index}`}>
              <span className='u-font-bold'>{displayText}: </span>
              <span>{value}</span>
            </p>
          )
        })}
      </div>
    </div>
  )
}

export default NonOwnerCard
