/**
 * Dev note:
 * Sean: I think I need to convert the colors back into variables,
 * so they can be overridden through higher level settings. They
 * originally all were setup as var(--var-name, #value). But I had changed it
 * over to just the value at some point. Having trouble remembering why, think it
 * may have been in order to directly use the values from the config within js.
 * But now that twin macro is in the packages, I think that would work as a better
 * pattern than utilizing the values directly. Some places to check are markdown (since classes may not be able to be applied, as well as the styled select since it comes from a library)
 */

const {
  config: {
    prefix,
    theme: {
      screens: defaultScreenSettings,
      gutter: defaultGutterSettings,
      extend: { spacing: defaultSpacingExtension },
      minWidth: defaultMinWidthSettings,
      fontSize: defaultFontSize
    }
  },
  values: { gutterDefault }
} = require('../../design-system/default/main.cjs')

module.exports = {
  content: ['./src/**/*.{js,ts,jsx,tsx,css,scss}'],
  prefix,
  theme: {
    screens: defaultScreenSettings,
    gutter: defaultGutterSettings,
    fontSize: defaultFontSize,
    minWidth: defaultMinWidthSettings,
    imageSize: {
      100: 'clamp(8rem, 6.125rem + 5vw, 10.625rem)',
      200: 'clamp(15rem, 13.429rem + 4.19vw, 17.2rem)',
      300: 'clamp(20rem, 16.429rem + 9.524vw, 25rem)'
    },
    measure: {
      default: '60ch'
    },
    extend: {
      spacing: defaultSpacingExtension,
      colors: {
        grayscale: {
          100: 'var(--color-grayscale-100, #ffffff)',
          500: '#A3ABB3',
          600: '#dae0ea',
          700: '#2A2A37',
          800: '#050505',
          900: 'var(--color-grayscale-900, #040404)'
        },
        blue: 'var(--color-blue, #366B97)',
        navy: 'var(--color-navy, #254077)',
        purple: 'var(--color-tertiary-100, #4B48AC)',
        primary: {
          200: '#F7F8FD',
          300: '#F1F3FB',
          500: '#2C76F0',
          600: '#393947',
          700: '#040404',
          800: '#153770'
        },
        secondary: {
          100: 'var(--color-secondary-100, #FFFFFF)',
          200: 'var(--color-secondary-200, #F4FAFF)',
          300: '#D8E2F3',
          400: '#DFE3F0',
          500: '#7991B8',
          800: '#647381',
          900: '#48505E'
        },
        tertiary: {
          200: '#254077',
          300: '#A3ABB3'
        },
        green: {
          100: '#D7EFE1',
          200: '#B8E2CA',
          300: '#9AD6B3',
          400: '#6eb48b'
        },
        state: {
          danger: 'var(--color-state-danger, #F02C2C)',
          warning: 'var(--color-state-warning, #F09D29)',
          good: 'var(--color-state-good, #35AD67)'
        }
      },
      backgroundImage: {
        'gradient-blue-purple':
          'linear-gradient(118.96deg, #DEEDFF 5.12%, #F5F5F5 53.86%, #E3E6FA 94.55%)',
        'gradient-blue-teal':
          'linear-gradient(118.96deg, #D9E7F8 5.12%, #F4F4F4 53.86%, #DFFCFE 94.55%)',
        'gradient-blue-blue':
          'linear-gradient(118.96deg, #D9E7F8 5.12%, #F4F4F4 53.86%, #D2EAFF 94.55%)',
        'gradient-grayscale-radial':
          'radial-gradient(391.54% 96.34% at -104.55% 3.66%, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
        'gradient-blue-black':
          'linear-gradient(175.92deg, #121727 0%, #171524 33.47%, #131920 64.9%, #0D0F14 92.3%);',
        'gradient-blue-white':
          'linear-gradient(175.92deg, #dfe3f2 0%, #dad8eb 33.47%, #dce2e8 64.9%, #e1e5eb 92.3%);'
      }
    },
    fontFamily: {
      'open-sans': ['open-sans', 'sans-serif']
    },
    variables: {
      DEFAULT: {
        color: {
          state: {
            danger: '#F02C2C'
          }
        },
        sidebar: {
          'content-min-width': '50%',
          gutter: gutterDefault,
          width: 'auto'
        }
      }
    }
  },
  plugins: [require('@mertasan/tailwindcss-variables')]
}
