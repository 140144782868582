/** Phase Fixture
 *  Phase is a generic larger concept term used for both
 *  the buyer and seller processes. It's possible for seller or
 *  buyer to have multiple phases. A phase may include multiple
 *  questionnaires or live sessions.
 */
import { liveSessionFixture } from '@fixtures/phase'
import { testQuestionnaireFixture } from '@fixtures/phase/questionnaire'

export const phaseStepsFixture = [
  liveSessionFixture,
  testQuestionnaireFixture,
  testQuestionnaireFixture,
  liveSessionFixture
]
