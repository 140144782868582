/** Confidentiality Agreement Query Component
 *
 */
import type { IConfidentialityAgreementData } from '@unionco/alaris-app-types'

import { useAuthFetch } from 'hooks'

import ErrorUI from 'components/Error'
import { Loading } from 'components/Loading'

import { ConfidentialityAgreementUI } from './ConfidentialityAgreement'

export const ConfidentialityAgreement = () => {
  const { data, error } =
    useAuthFetch<IConfidentialityAgreementData>(`/user-agreements`)
  if (error) return <ErrorUI error={error} />
  if (!data) return <Loading debug='ConfidentialityAgreement' />

  return <ConfidentialityAgreementUI data={data} />
}

export default ConfidentialityAgreement
