/** Custodian Segment Fixture
 *
 *  See bottom for ouput
 */
import { v4 as uuidv4 } from 'uuid'

import type { IQuestionSegmentData } from '@unionco/alaris-app-types'

import { custodianQuestionFixture } from '@fixtures/phase/questionnaire/question'

export const custodianSegmentFixture = {
  questions: [custodianQuestionFixture],
  title: 'Segment 3: Custodian',
  introCopy: 'Progression based intro copy',
  status: {
    percent: 0,
    statusText: 'notStarted'
  },
  selectedAnswer: null,
  key: uuidv4(),
  type: 'questionSegment',
  isAlarisOnly: false,
  isActive: true
} as IQuestionSegmentData

/** Output
 {
    title: 'Segment 3: Custodian',
    introCopy: 'Progression based intro copy',
    status: {
      percent: 0,
      statusText: 'notStarted'
    },
    questions: [
      {
        answers: [
          {
            label: 'Custodian 1 name',
            value: 'answer1',
            image: placeholderSmallImgFixture
          },
          {
            label: 'Custodian 2 name',
            value: 'answer1',
            image: placeholderSmallImgFixture
          },
          {
            label: 'Custodian 3 name',
            value: 'answer1',
            image: placeholderSmallImgFixture
          },
          {
            label: 'Custodian 4 name',
            value: 'answer1',
            image: placeholderSmallImgFixture
          }
        ],
        answered: false,
        questionType: 'custodian',
        questionText: 'Custodian Question',
        selectedAnswer: null
      }
    ]
  }
*/
