export const DashboardIcon: React.FC = () => {
  return (
    <svg
      width='18'
      height='20'
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.64022 0.5H1.62462C0.751176 0.5 0.0410156 1.21016 0.0410156 2.0836V9.754C0.0410156 10.6274 0.751176 11.3376 1.62462 11.3376H6.64022C7.51366 11.3376 8.22382 10.6274 8.22382 9.754V2.0836C8.22382 1.21016 7.51288 0.5 6.64022 0.5ZM7.02382 9.754C7.02382 9.96494 6.85194 10.1376 6.64022 10.1376H1.62462C1.41368 10.1376 1.24102 9.96572 1.24102 9.754V2.0836C1.24102 1.87188 1.41289 1.7 1.62462 1.7H6.64022C6.85116 1.7 7.02382 1.87188 7.02382 2.0836V9.754Z'
        fill='white'
      />
      <path
        d='M6.64022 12.498H1.62462C0.751176 12.498 0.0410156 13.2082 0.0410156 14.0816V17.9168C0.0410156 18.7903 0.751176 19.5004 1.62462 19.5004H6.64022C7.51366 19.5004 8.22382 18.7903 8.22382 17.9168V14.0816C8.22382 13.2082 7.51288 12.498 6.64022 12.498ZM7.02382 17.9168C7.02382 18.1278 6.85194 18.3004 6.64022 18.3004H1.62462C1.41368 18.3004 1.24102 18.1286 1.24102 17.9168V14.0816C1.24102 13.8707 1.41289 13.698 1.62462 13.698H6.64022C6.85116 13.698 7.02382 13.8699 7.02382 14.0816V17.9168Z'
        fill='white'
      />
      <path
        d='M16.3765 8.66211H11.3609C10.4875 8.66211 9.77734 9.37227 9.77734 10.2457V17.9161C9.77734 18.7895 10.4875 19.4997 11.3609 19.4997H16.3765C17.25 19.4997 17.9601 18.7895 17.9601 17.9161V10.2457C17.9601 9.37227 17.2492 8.66211 16.3765 8.66211ZM16.7601 17.9161C16.7601 18.127 16.5883 18.2997 16.3765 18.2997H11.3609C11.15 18.2997 10.9773 18.1278 10.9773 17.9161V10.2457C10.9773 10.0348 11.1492 9.86211 11.3609 9.86211H16.3765C16.5875 9.86211 16.7601 10.034 16.7601 10.2457V17.9161Z'
        fill='white'
      />
      <path
        d='M16.3765 0.5H11.3609C10.4875 0.5 9.77734 1.21016 9.77734 2.0836V5.9188C9.77734 6.79224 10.4875 7.5024 11.3609 7.5024H16.3765C17.25 7.5024 17.9601 6.79224 17.9601 5.9188V2.0836C17.9601 1.21016 17.2492 0.5 16.3765 0.5ZM16.7601 5.9188C16.7601 6.12974 16.5883 6.3024 16.3765 6.3024H11.3609C11.15 6.3024 10.9773 6.13052 10.9773 5.9188V2.0836C10.9773 1.87188 11.1492 1.7 11.3609 1.7H16.3765C16.5875 1.7 16.7601 1.87188 16.7601 2.0836V5.9188Z'
        fill='white'
      />
    </svg>
  )
}

export default DashboardIcon
