import type {
  ILocationsIndexesData,
  IQuestionnaireSummaryData,
  TQuestionnaireSegmentTypeData,
  TSidebarSectionData,
  TSidebarSectionSumamryData,
  TSidebarSegmentData,
  TSidebarSegmentSummaryData
} from '@unionco/alaris-app-types'

/**
 * Get Current Segment Data
 *
 * A better way to write this may have involved the use of generics.
 * And the "is" typescript operator.
 *
 * Where a return type could of been passed in so that it accessed the correct data
 * and type checked the segment type from the same function.
 */
function getCurrentSegmentData(
  pageLocations: ILocationsIndexesData,
  data: TSidebarSectionData
): TSidebarSegmentData
function getCurrentSegmentData(
  pageLocations: ILocationsIndexesData,
  data: TSidebarSectionSumamryData
): TSidebarSegmentSummaryData
function getCurrentSegmentData(
  pageLocations: ILocationsIndexesData,
  data: IQuestionnaireSummaryData
): TQuestionnaireSegmentTypeData
function getCurrentSegmentData(
  pageLocations: ILocationsIndexesData,
  data: TSidebarSectionData | TSidebarSectionSumamryData
): TSidebarSegmentData | TSidebarSegmentSummaryData | null {
  if (!data.segments) return null
  return data.segments[pageLocations['segment']]
}
export { getCurrentSegmentData }
