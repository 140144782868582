/** Section Status Indicator
 *
 */
import { CheckMarkIcon } from '@unionco/svg-icons'

import type {
  TIconName,
  TPhaseStepType,
  TSectionType
} from '@unionco/alaris-app-types'

import { StepType } from '@unionco/alaris-utils'

import { Icon, StatusRing, TTimelineStepStatus } from 'components'

import { styleBorderGlow } from 'styles'

type TSectionStatusIndicatorProps = {
  icon: TIconName
  percent: number | null
  size?: string
  strokeWidth?: number
} & (
    | {
      isTimeline: true
      phaseStepStatus: TTimelineStepStatus
      type: TPhaseStepType
    }
    | {
      isTimeline?: false
      phaseStepStatus?: undefined
      type: TSectionType
    }
  )

export const SectionStatusIndicator: React.FC<TSectionStatusIndicatorProps> = ({
  icon,
  isTimeline,
  percent,
  phaseStepStatus,
  size,
  strokeWidth = 6,
  type
}) => {
  const isQuestionnaire = type === StepType.QUESTIONNAIRE
  const sizeClasses = size ? size : 'u-h-9 u-w-9 u-min-w-[2.25rem]'
  const classes = `${sizeClasses} u-border-4 u-rounded-full`
  const borderStyling = isTimeline ? `u-bg-secondary-300` : 'u-bg-secondary-900'

  const iconProgressIndicator = (
    <div
      className={`b-SectionStatusIndicator | c-withLayer | u-rounded-full  u-p-[3px]  [--icon-size:0.875rem] ${borderStyling} ${sizeClasses}`}
      style={{
        filter: isTimeline ? styleBorderGlow : ''
      }}
    >
      {!!(typeof percent === 'number') && (
        <StatusRing layer percent={percent} strokeWidth={strokeWidth} />
      )}
      <div className='u-flex u-h-full u-w-full u-items-center u-justify-center u-rounded-full u-bg-primary-700'>
        <Icon name={icon} className='u-aspect-square u-w-full u-max-w-[40%]' />
      </div>
    </div>
  )

  if (isTimeline) {
    switch (phaseStepStatus) {
      case 'complete':
        return (
          <div
            className={`${classes} u-flex u-items-center u-justify-center u-border-primary-500 u-bg-primary-500 u-text-white`}
          >
            <CheckMarkIcon className='u-w-3/5' curved />
          </div>
        )
      case 'current':
        return iconProgressIndicator
      default:
        return (
          <div className={`${classes} u-border-secondary-300 u-bg-white`} />
        )
    }
  }

  return iconProgressIndicator
}

export default SectionStatusIndicator
