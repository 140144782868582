type TCreateBooleanArrayFromNumber = (
  num: number,
  defaultValue?: boolean
) => boolean[]

export const createBooleanArrayFromNumber: TCreateBooleanArrayFromNumber = (
  num,
  defaultValue = false
) => {
  const result = []
  for (let i = 0; i <= num; i++) {
    result.push(defaultValue)
  }
  return result
}
