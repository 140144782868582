/** FAQ Section
 *  FAQ Section UI with API call
 */
import type { IFAQSectionData } from '@unionco/alaris-app-types'

import { useAuthFetch } from 'hooks'

import { Loading } from 'components'

import { FAQSectionUI } from './UI'

interface IFAQSectionProps {
  relationKey: string
}

export const FAQSection: React.FC<IFAQSectionProps> = ({
  relationKey
}) => {

  const { data, error } = useAuthFetch<IFAQSectionData>(
    `/related-faq?moduleFormat=FAQSection&relationKey=${relationKey}`
  )

  if (error) return <></>
  if (!data) return <Loading debug='FAQSection' />

  return <FAQSectionUI data={data} />
}

export default FAQSection
