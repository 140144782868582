import { formatCurrencyAbbr } from './formatCurrencyAbbr'

type TCurrencyAbbr = (value: string | number, currencySign?: string) => string
export const currencyAbbr: TCurrencyAbbr = (value, currencySign = '$') => {
  const num =
    typeof value === 'string' ? parseInt(value.replace(/,/g, '')) : value

  if (isNaN(num)) return value as string

  /**
   * This could be better written recursivly, dividing by a value like 1000,
   * then checking if that value is larger then a similar value.
   */
  if (num >= 1000000000) {
    return formatCurrencyAbbr(num, 1000000000, 'B', currencySign)
  } else if (num >= 1000000) {
    const val = formatCurrencyAbbr(num, 1000000, 'M', currencySign)
    return val
  } else if (num >= 1000) {
    return formatCurrencyAbbr(num, 1000, 'K', currencySign)
  } else {
    return `\$${num}`
  }
}
