interface ITimelineProps {
  itemHalfWidth: number
  percent: number
  spaceBetween: number
  useActive?: boolean | 0
}

export const TimelineLine: React.FC<ITimelineProps> = ({
  itemHalfWidth,
  percent,
  spaceBetween,
  useActive = false
}) => {
  return (
    <div
      className='c-layer u-h-1 u-bg-secondary-300'
      style={{
        width: spaceBetween,
        top: itemHalfWidth,
        left: `calc(50% + ${itemHalfWidth}px)`
      }}
    >
      {!!useActive && (
        <div
          className='u-h-full u-bg-primary-500'
          style={{
            width: `${percent}%`
          }}
        />
      )}
    </div>
  )
}
