import { useRouter } from 'next/router'
import { useContext } from 'react'

import { TPhaseStepData, TState } from '@unionco/alaris-app-types'

import { API_BASE } from '@appConstants'

import { IAMContext, TIAMContext } from 'context'

import useAuthRefetch from './useAuthRefetch'

export const usePhaseStepData = (): TState<
  TPhaseStepData,
  'phaseStepData',
  'phaseStepError'
> & { refetch: () => void } => {
  const {
    query: { section: stepKey }
  } = useRouter()

  const {
    IAMData: { id, impersonationMode, isPrimaryUser }
  } = useContext(IAMContext) as TIAMContext
  // If non progression step is defined fetch that data
  // Otherwise use users progression step
  /**
   * If user is not in assessment (impersonation) mode or is not the primary user
   * Omit the assessment questions
   */
  const omitAssessmentQuestions =
    impersonationMode !== 'impersonation' || !isPrimaryUser
  const endpoint = `/steps?key=${stepKey}&userId=${id}&omitAssessmentQuestions=${omitAssessmentQuestions}`
  const { data, error, refetch } = useAuthRefetch<TPhaseStepData>(endpoint)

  return {
    phaseStepData: data,
    phaseStepError: error,
    refetch: () => refetch(`${API_BASE}/api${endpoint}`, false)
  }
}
