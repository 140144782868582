// Should we consider using reportWebVitals for events?
// https://nextjs.org/docs/pages/building-your-application/optimizing/analytics#sending-results-to-analytics
export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID

export const pageload = (url: string) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'dataLayer-initialized',
    hit_time_stamp: new Date().toISOString(),
    questionnaire_title: '<questionnaire category user has reached>',
    segment_title: '<segment title user has reached in questionnaire>',
    question_step:
      '<The number of the question a user has reached in a segment of the questionnaire>',
    question_text:
      '<name of the question a user has reached in a segment of the questionnaire>',
    session_id: '<identifier for a user’s session>'
  })
  console.log('pageload: ' + url)
}
