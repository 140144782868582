import React from 'react'

import { IFounderData } from '@unionco/alaris-app-types'

import {
  displayNumberAsPercent,
  displayNumberWithCommas
} from '@unionco/alaris-utils'

import { Img } from 'components'

import { TeamMemberHeader } from '../TeamMemberHeader'

export const FounderCard: React.FC<IFounderData> = ({
  age,
  aumManaged,
  ebitdaContribution,
  firstName,
  glidePath,
  hhManaged,
  jobDescription,
  lastName,
  ownership,
  profilePic,
  revenueManaged,
  title,

  // transactionGoal
}) => {
  const stats = [
    // Currently transaction goal is not displaying 
    // because Strapi type in founders.json has it's
    // type set to "string" instead of "json"
    // { displayText: 'Transaction Goal', value: transactionGoal },
    {
      displayText: 'Role in the Firm',
      value: jobDescription
    },
    {
      displayText: 'Ownership Percentage',
      value: ownership && `${displayNumberAsPercent(ownership)}`
    },

    // { displayText: 'Primary Goals', 
    //   value: transactionGoal && transactionGoal },

    { displayText: 'Glidepath', value: glidePath },

    {
      displayText: '% Households Managed',
      value: hhManaged && `${displayNumberAsPercent(hhManaged)}`
    },
    {
      displayText: 'AUM Managed',
      value: aumManaged && `${displayNumberAsPercent(aumManaged)}`
    },
    {
      displayText: 'Revenue Managed',
      value: revenueManaged && `${displayNumberAsPercent(revenueManaged)}`
    },
    {
      displayText: 'EBITDA Contribution',
      value:
        ebitdaContribution && `$${displayNumberWithCommas(ebitdaContribution)}`
    }
  ]
  const validImg =
    profilePic && profilePic && profilePic.url && profilePic.url !== ''

  return (
    <div className='u-flex u-flex-wrap u-items-start u-w-full u-justify-center'>
      <div className='u-w-full'>
        {validImg && (
          <Img
            className='u-w-3/4'
            width={profilePic.width}
            height={profilePic.height}
            src={profilePic.url}
            alt=''
          />
        )}
        <div className='u-flow-space--300 u-mt-4'>
          <TeamMemberHeader
            age={age}
            firstName={firstName}
            lastName={lastName}
            title={title}
          />
          <div className='u-mt-2 u-w-4/5'>
            {stats.map(({ displayText, value }, index) => {
              if (!value || value === '') {
                return <React.Fragment key={`InvalidStat_${index}`} />
              }

              return (
                <p className='u-text-2' key={`StatItem_${index}`}>
                  <span className='u-font-bold'>{displayText}: </span>
                  <span>{value}</span>
                </p>
              )
            })}
          </div>
        </div>
      </div>

    </div>
  )
}

export default FounderCard
