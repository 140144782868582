/**
 * Your Firm At A Glance Module data
 *
 * Used on buyer dashboard
 */
import { v4 as uuid } from 'uuid'

const top10Placements = {
  title: `Times you've matched in top 10`,
  value: '32',
  key: uuid()
}

const top3Placements = {
  title: `Times you've matched in top 10`,
  value: '12',
  key: uuid()
}

const totalClosedDeals = {
  title: `Times you've matched in top 10`,
  value: '5',
  key: uuid()
}

const totalEBITDAAcquired = {
  title: `Times you've matched in top 10`,
  value: '1630000',
  key: uuid()
}

export const YourFirmsMetricsAtAGlanceFixture = {
  title: 'Your Firm Metrics at a glance',
  top10Placements: top10Placements,
  top3Placements: top3Placements,
  totalClosedDeals: totalClosedDeals,
  totalEBITDAAcquired: totalEBITDAAcquired
}
