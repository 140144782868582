/** Business Summary Module
 *  Both buyer and seller main module
 *  Always at top.
 *  Contains Business logo, name, website link,
 *  short overveiw description.
 *
 *  And an option for user to print the page.
 *
 *  TODO: Profile print styles
 */
import { Card } from '@unionco/components'
import React from 'react'

import type { IBusinessSummaryModuleData } from '@unionco/alaris-app-types'


import { Img, Video } from 'components'

export interface IBusinessSummaryProps {
  data: IBusinessSummaryModuleData
}

export const BusinessSummary: React.FC<IBusinessSummaryProps> = ({
  data: {
    companyName,
    description,
    logo,
    video,
    website: { href }
  }
}) => {
  const validImg = logo && logo.src && logo.src !== ''

  if (!companyName && !description && !validImg && !href) return <></>

  return (
    <Card className='c-flow | u-flow-space--500 u-p-700 print:u-p-0'>
      <div className='u-w-full print:u-w-1/2 print:u-mx-auto'>
        <div className='u-w-full'>
          {validImg && (
            <div className=' u-flex u-items-center u-justify-center print:u-my-2'>
              <Img className='u-object-contain u-object-scale-down' {...logo} />
            </div>
          )}
          <div className='c-flow | u-flow-space--200'>
          </div>
        </div>
      </div>
      <div className='u-text-secondary-800 print:u-pb-2'>{description}</div>
      {video && (
        <div className='c-cluster [--cluster-horizontal-alignment:center] print:u-hidden'>
          <Video data={video} />
        </div>
      )}
    </Card>
  )
}

export default BusinessSummary
