/** Questionnaire Summary Fixture
 *
 */
import type { IQuestionnaireSummaryData } from '@unionco/alaris-app-types'

export const questionnaireSummaryFixture = {
  icon: 'piechart',
  title: 'Questionnaire Name',
  status: {
    percent: 0,
    statusText: 'notStarted'
  },
  type: 'questionnaire'
} as IQuestionnaireSummaryData
