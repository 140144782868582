import { RefObject } from 'react'

import {
  TPhaseStepSummaryData,
  TSectionStatusText
} from '@unionco/alaris-app-types'

import { getDateTimeText, timelineStepStatus } from './utils'
import { getPhaseStepPercent } from 'utils'

import SectionStatusIndicator from 'components/sectionStatusIndicator'
import { SwiperSlide, SwiperUI } from 'components/swiper'

import { TimelineLine } from './TimelineLine'
import { TimelineStatusText } from './TimelineStatusText'

interface ITimelineProgressionMobileUI {
  currentSectionIndex: number
  spaceBetweenCircles: number[]
  statusCircleRadius: number
  title: string
  timelineCircleRefs: RefObject<HTMLDivElement>[] | null
  timelineItems: TPhaseStepSummaryData[]
}

const getMobileStatusTitle = (status: TSectionStatusText) => {
  switch (status) {
    case 'complete':
      return 'Completed Step'
    case 'inprogress':
      return 'Current Step'
    default:
      return 'Future Step'
  }
}

export const TimelineProgressionMobileUI: React.FC<
  ITimelineProgressionMobileUI
> = ({
  currentSectionIndex,
  spaceBetweenCircles,
  statusCircleRadius,
  timelineCircleRefs,
  timelineItems,
  title
}) => {
  const lastStepIndex = timelineItems ? timelineItems.length - 1 : 0
  return (
    <div className='c-flow | u-rounded u-bg-white u-px-700 u-py-500'>
      <h2 className='u-text-center u-font-open-sans'>{title}</h2>
      <div className='c-withLayer | u-flex u-justify-between'>
        {timelineItems &&
          timelineItems.map(
            ({ icon, status: { statusText }, title, type }, index) => {
              const percent = getPhaseStepPercent(type, statusText)
              const usePercent = percent && percent !== 0
              // console.log('icon: ', icon)
              // console.log('currentSectionIndex: ', currentSectionIndex)
              return (
                <div className='c-withLayer' key={`${title}_${index}`}>
                  {index !== lastStepIndex && (
                    <TimelineLine
                      itemHalfWidth={statusCircleRadius}
                      spaceBetween={spaceBetweenCircles[index]}
                      useActive={usePercent}
                      percent={percent}
                    />
                  )}
                  <div
                    ref={
                      (timelineCircleRefs && timelineCircleRefs[index]) || null
                    }
                  >
                    <SectionStatusIndicator
                      icon={icon}
                      isTimeline
                      percent={percent}
                      phaseStepStatus={timelineStepStatus(
                        index,
                        currentSectionIndex
                      )}
                      type={type}
                    />
                  </div>
                </div>
              )
            }
          )}
      </div>
      <SwiperUI slidesPerView={1} navigation>
        {timelineItems.map((item, index) => {
          const {
            status: { statusText },
            title,
            type
          } = item
          return (
            <SwiperSlide key={`Slide_${index}`}>
              <div className='u-flex u-w-full u-justify-center u-text-center'>
                <div className='c-flow u-flow-space--200'>
                  <p className='u-uppercase'>
                    {getMobileStatusTitle(statusText)}
                  </p>
                  <h3 className='u-font-bold'>{title}</h3>
                  <TimelineStatusText
                    statusText={statusText}
                    stepType={type}
                    dateTimeText={getDateTimeText(item)}
                  />
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </SwiperUI>
    </div>
  )
}
