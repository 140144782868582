/** Constants Context Wrapper
 *
 *  Top Level API Context Component
 *  Grouped with user context wrapper in top level PostAuthGlobalContext
 *  which checks the session before wrapping the application with
 *  both the user context and global constants (this) context.
 */
import { IPostAuthGlobalData } from '@unionco/alaris-app-types'

import { useAuthFetch } from 'hooks'

import { ErrorUI, Loading } from 'components'

import PostAuthGlobalContext from './context'

interface IConstantsContextWrapper {
  children: React.ReactNode
}

export const ConstantsContextWrapper: React.FC<IConstantsContextWrapper> = ({
  children
}) => {
  const { data, error } = useAuthFetch<IPostAuthGlobalData>(`/global`)

  if (error) return <ErrorUI error={error} page />
  if (!data) return <Loading debug='ConstantsContextWrapper' />

  return (
    <PostAuthGlobalContext.Provider value={data}>
      {children}
    </PostAuthGlobalContext.Provider>
  )
}

export default ConstantsContextWrapper
