/** Question Controls Component
 *
 *  This component is displayed for each question
 *  type in the questionnaire
 *  it handles the bulk of navigation / routing
 *  as well as question submission
 */
import { CaretIcon } from '@unionco/svg-icons'
import { useContext } from 'react'

import type {
  ILocationsIndexesData,
  IPhaseSummaryData,
  IQuestionSegmentData,
  IQuestionnaireData
} from '@unionco/alaris-app-types'

import {
  getAdjacentLocationsIndexes,
  getSidebarRoute
} from 'components/SidebarTemplate/util'
import {
  clearSelectedAnswers,
  getCurrentQuestionData
} from 'components/questionnaire/util'
import { getCurrentSegmentData } from 'utils'
import { isAdminTracking } from 'utils/tracking'
import { questionnaireNavigationTracking } from 'utils/tracking/questionnaire'

import { TUserContext, UserContext } from 'context'

import { QuestionnaireContext, SidebarContext, StyledButton } from 'components'

import { TSubmitAnswer } from '../question'
import { useRouter } from 'next/router'


interface IQuestionControls {
  isReview?: boolean
  submitCallback?: TSubmitAnswer | (() => void)
  handleReload?: () => void
}

export const QuestionControls: React.FC<IQuestionControls> = ({
  isReview,
  submitCallback
}) => {
  const { userType } = useContext(UserContext) as TUserContext
  const { data, pageLocations, pageRoute, sidebarData } =
    useContext(SidebarContext)
  const { submitQuestionnaire } = useContext(QuestionnaireContext)
  const phaseSummary = sidebarData as IPhaseSummaryData
  const phaseSteps = phaseSummary.steps
  const questionnaireData = data as IQuestionnaireData
  const currentSegment = getCurrentSegmentData(
    pageLocations,
    questionnaireData
  ) as IQuestionSegmentData
  const currentQuestion = !isReview
    ? getCurrentQuestionData(pageLocations, questionnaireData)
    : null

  //Check if the notification question is present
  const router = useRouter()
  let notificationQuestion = false
  if (router.asPath.includes('notification=true')) {
    notificationQuestion = true
  }

  // #region Button Functions
  const buttonRoute = (updatedLocation: ILocationsIndexesData) => {
    return getSidebarRoute(
      pageRoute,
      questionnaireData.key,
      updatedLocation['segment'],
      updatedLocation['question']
    )
  }

  const nextButtonText: (
    numOfQuestions: number,
    qIndexes: ILocationsIndexesData
  ) => string = (numOfQuestions, nextLocationIndexes) => {
    if (currentQuestion && !currentQuestion.answered) return 'Skip For Now'

    if (
      numOfQuestions > 1 &&
      (pageLocations['question'] as number) + 1 !== numOfQuestions
    ) {
      return 'Continue'
    }

    const nextSection = phaseSteps[nextLocationIndexes['section']]
    return nextSection
      ? `Next: ${nextSection.segments[nextLocationIndexes['segment']].title}`
      : 'Review'
  }
  // #endregion Button Functions

  // #region Tracking Functions
  const isAdmin = isAdminTracking(userType)

  const previousTracking = () =>
    questionnaireNavigationTracking({
      is_admin_key: isAdmin,
      element_name: 'previous'
    })

  const clearAnswersTracking = () =>
    questionnaireNavigationTracking({
      is_admin_key: isAdmin,
      element_name: 'clear_answers'
    })

  const skipTracking = () =>
    questionnaireNavigationTracking({
      is_admin_key: isAdmin,
      element_name: 'skip'
    })

  const nextTracking = () =>
    questionnaireNavigationTracking({
      is_admin_key: isAdmin,
      element_name: 'next'
    })
  // #endregion Tracking Functions

  // #region Questionnaire state management functions
  const clearAnswer = () => {
    if (!!submitCallback) {
      const newState = clearSelectedAnswers(pageLocations, questionnaireData)
      submitCallback(false, newState, pageLocations)
      clearAnswersTracking()
    }
  }
  // #endregion Questionnaire state management functions

  const rightSideButtons = (isReview: boolean | undefined) => {
    if (!isReview && !notificationQuestion) {
      const numOfQuestions = currentSegment.questions.length
      const isAnswered =
        currentSegment.questions[pageLocations['question'] as number].answered
      const btnText = nextButtonText(numOfQuestions, nextLocationIndexes)
      const trackingFunction = btnText === 'Skip' ? skipTracking : nextTracking

      return (
        <>
          {isAnswered && (
            <StyledButton
              onClick={clearAnswer}
              theme='secondary'
              themeStyle='ghost'
              type='button'
            >
              Clear Answers
            </StyledButton>
          )}
          <StyledButton type='a' href={nextRoute} onClick={trackingFunction}>
            {btnText}
          </StyledButton>
        </>
      )
    } else {
      const submitDisabled =
        questionnaireData.status.statusText !== 'complete' ? true : false
      return (
        <>
          <StyledButton
            href={'/dashboard'}
            theme='secondary'
            themeStyle='ghost'
            type='a'
          >
            Back to dashboard
          </StyledButton>
          <StyledButton
            // href={'/dashboard'}
            type='button'
            onClick={submitQuestionnaire}
            disabled={submitDisabled}
          >
            Submit
          </StyledButton>
        </>
      )
    }
  }

  // Update Application state
  const previousSectionIndexes = getAdjacentLocationsIndexes({
    summaryData: (sidebarData as IPhaseSummaryData).steps,
    sectionData: questionnaireData,
    locations: pageLocations,
    modifier: -1
  })
  const nextLocationIndexes = getAdjacentLocationsIndexes({
    summaryData: (sidebarData as IPhaseSummaryData).steps,
    sectionData: questionnaireData,
    locations: pageLocations,
    modifier: 1
  })
  // Link Routes
  const previousRoute = buttonRoute(previousSectionIndexes)
  const nextRoute = buttonRoute(nextLocationIndexes)

  if (notificationQuestion) {
    return (
      <>
        <div className='o-line'></div>
        <div className='c-repel'>
          <StyledButton
            href={'/dashboard'}
            theme='primary'
            themeStyle='solid'
            type='a'
          >
            Back to dashboard
          </StyledButton>
        </div>
      </>
    )
  }
  else {
    return (
      <>
        <div className='o-line'></div>
        <div className='c-repel'>
          <StyledButton
            className='c-cluster | u-cluster-gutter-200 u-flex'
            href={previousRoute}
            theme='secondaryAlt'
            themeStyle='outline'
            type='a'
            onClick={() => previousTracking()}
          >
            <CaretIcon
              className={'u-text-secondary-500 group-hover:u-text-white'}
              iconDirection='left'
            />
            <span>Previous</span>
          </StyledButton>
          <div className='c-cluster'>{rightSideButtons(isReview)}</div>
        </div>
      </>
    )
  }
}

export default QuestionControls
