import { IconSVG } from '@unionco/svg-icons'

import { TSVGProps } from '@unionco/alaris-app-types'

export const PrinterIcon: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <IconSVG viewBoxWidth={10} {...props} ref={undefined}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.72407 2.16203V3.13439H8.6054C9.23015 3.13439 9.73853 3.62083 9.73853 4.2197V8.8134C9.73853 9.41178 9.23013 9.89871 8.6054 9.89871H1.39538C0.770103 9.89871 0.261719 9.41177 0.261719 8.8134V4.2197C0.261719 3.62081 0.770117 3.13439 1.39538 3.13439H2.27565V1.15511C2.27565 0.7511 2.65335 0.421898 3.11713 0.421898H7.44089V0.693096V0.421898C7.70809 0.421898 7.94185 0.51603 8.11716 0.694112C8.52091 1.10371 8.4885 1.82573 8.48371 1.90664L8.46778 2.16206L7.72407 2.16203ZM7.72407 1.0874V1.61912H7.90789C7.88823 1.439 7.83776 1.21969 7.72407 1.0874ZM7.15777 1.80841V4.15606H2.84258V1.15503C2.84258 1.05174 2.96795 0.964737 3.11722 0.964737H7.15784L7.15777 1.80841ZM7.72407 4.15606V3.67675H8.6054C8.91778 3.67675 9.17171 3.92047 9.17171 4.21966V8.81336C9.17171 9.11255 8.91778 9.35577 8.6054 9.35577H1.39538C1.08301 9.35577 0.828541 9.11255 0.828541 8.81336V4.21966C0.828541 3.92047 1.08301 3.67675 1.39538 3.67675H2.27565V4.15606H2.01162V4.69847H7.99495V4.15606H7.72407ZM4.5616 2.28151H6.32643V1.7391H4.5616V2.28151ZM4.5616 3.4111H6.32643V2.86869H4.5616V3.4111ZM3.54959 2.28151H4.10154V1.7391H3.54959V2.28151ZM3.54959 3.4111H4.10154V2.86869H3.54959V3.4111ZM7.51635 8.39908C7.23427 8.39908 7.00583 8.18029 7.00583 7.91011C7.00583 7.63993 7.23427 7.42062 7.51635 7.42062C7.79791 7.42062 8.02687 7.63993 8.02687 7.91011C8.02687 8.18029 7.79791 8.39908 7.51635 8.39908ZM2.01159 8.18131H5.0004V7.63839H2.01159V8.18131Z'
        fill='currentColor'
      />
    </IconSVG>
  )
}

export default PrinterIcon
