import { useContext } from 'react'

import { TPageName } from '@appConstants'

import { isAdminTracking } from 'utils/tracking'
import { navigationClickTracking } from 'utils/tracking/navigationClick'

import { TUserContext, UserContext } from 'context'

type TModuleNames =
  | 'acquisition model'
  | 'advisor value proposition'
  | 'client demographics'
  | 'client profiles'
  | 'client experiences'
  | 'seeking partnership'
  | 'data room'
  | 'financial snapshot'
  | 'general information'
  | 'historic growth data'
  | 'historic growth statistics'
  | 'key ratios and metrics'
  | 'matches'
  | 'missed deals'
  | 'organic growth'
  | 'points of alignment'
  | 'risk factors'
  | 'services offered'
  | 'team members'
  | 'top priorities'
  | 'titled video'
  | 'investment management'
  | 'autonomy profile'

export type TModuleTrackingName = `${TModuleNames} module`

export type TRangeReviewQuadrantTrackingName =
  | 'must have'
  | 'strongly prefer'
  | 'nice to know'
  | 'not a factor'
type TRangeReview = `range review ${TRangeReviewQuadrantTrackingName}`

type TTrackingLocations =
  | TPageName
  | TModuleTrackingName
  | 'account dropdown'
  | 'owner form question additional notes'
  | TRangeReview
  | 'sidebar'
  | 'unlockable matches'
  | 'unlockable valuation estimate'

/**
 * Function type inside of return should be moved to package
 * is currently duplicated between FE and val calc
 */
type TUseNavigationClickTrackingReturn = {
  navTrackingOnClick: (
    elName: string,
    elLocation: TTrackingLocations | string
  ) => void
}

/**
 * useNavigationClickTracking
 *
 * hook wrapper for navigationClickTracking
 *
 * @returns navTrackingOnClick function for navigation tracking
 */
export const useNavigationClickTracking =
  (): TUseNavigationClickTrackingReturn => {
    const { userType } = useContext(UserContext) as TUserContext

    const navTrackingOnClick = (elName: string, elLocation: string) => {
      navigationClickTracking({
        element_name: elName,
        element_location: elLocation,
        is_admin_key: isAdminTracking(userType)
      })
    }

    return { navTrackingOnClick }
  }
