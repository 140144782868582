/**
 *  Custodian Question
 */
import type { ISearchQuestionData } from '@unionco/alaris-app-types'

export const custodianQuestionFixture = {
  answered: false,
  questionText: 'Custodian Question?',
  questionType: 'search',
  selectedAnswer: null,
  searchIndex: 'custodian',
  searchInstruction: 'Ex: search custodian 1, custodian 2, etc.',
  searchPlaceholder: 'Search custodians'
} as ISearchQuestionData
