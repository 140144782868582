/** Title And Copy
 *  Vertically stacked title (or header) and copy text
 */

interface ITitleAndCopyProps {
  copy?: string
  title?: string
  titleClassName?: string
}

export const TitleAndCopy: React.FC<ITitleAndCopyProps> = ({
  copy,
  title,
  titleClassName
}) => {
  if (!title && !copy) return <></>

  const titleClasses = titleClassName ? titleClassName : ''

  return (
    <div className='c-flow print:u-py-0 print:u-my-2'>
      {title && <p className={`o-subtitle-1 u-text-gray-750 print:u-mb-0 ${titleClasses}`}>{title}</p>}
      {copy && <p className='print:u-mt-0 print'>{copy}</p>}
    </div>
  )
}

export default TitleAndCopy
