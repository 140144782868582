/** Search Question
 *
 */
import { XIcon } from '@unionco/svg-icons'
import { CheckMarkIcon } from '@unionco/svg-icons'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useClickAnyWhere, useEffectOnce } from 'usehooks-ts'

import type {
  IQuestionSegmentData,
  IQuestionnaireData,
  ISearchAnswerData,
  ISearchQuestionData,
  TImageProps,
  TMeiliSearchAnswerData
} from '@unionco/alaris-app-types'

import { inputBgClasses, questionLayoutClasses } from '../util'
import { debounce } from '@unionco/utils'
import {
  getCurrentQuestionData,
  submissionSuccessful
} from 'components/questionnaire/util'
import { getCurrentSegmentData, partitionNew } from 'utils'
import { isAdminTracking } from 'utils/tracking'
import { questionSubmitTracking } from 'utils/tracking/questionnaire'

import { TUserContext, UserContext } from 'context'

import { DropdownHits } from 'components/Instantsearch/Hits/DropdownHits'
import { SidebarContext } from 'components/SidebarTemplate'
import { Img, StyledButton } from 'components/generic'

import { QuestionContext } from '../context'
import SelectAnswer from '../selectAnswer'
import SearchResult from './SearchResult'
import StyledSearchBox from './StyledSearchBox'
import { SearchQuestionContext } from './context'

export type TSearchAnswer = Partial<ISearchAnswerData> & {
  name: string
  searched: boolean
}

type TSearchItemsState = TSearchAnswer[] | null

interface ISearchQuestionUIProps {
  popularAnswers: TMeiliSearchAnswerData[]
}

/**
 * Answer type to tell difference between how an answer
 * was input.
 * default = checkbox selection, meaning no need to check if it's a pop answer
 * searched = selected from search dropdown menu, this needs to be checked to see if it is a pop answer
 * custom = a manual entry from search dropdown menu, no need to check if it's a pop answer
 *
 */
type TSearchAnswerInputType = 'default' | 'searched' | 'custom'

export const SearchQuestionUI: React.FC<ISearchQuestionUIProps> = ({
  popularAnswers
}) => {
  const { userType } = useContext(UserContext) as TUserContext
  const { currentQuestion, submitAnswer } = useContext(QuestionContext)
  const { data: stepData, pageLocations } = useContext(SidebarContext)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const { setSearchValue } = useContext(SearchQuestionContext)
  const {
    otherAnswer,
    searchInstruction,
    searchPlaceholder,
    searchedAnswer,
    selectedAnswer
  } = currentQuestion as ISearchQuestionData

  // Selected answers for the question
  const [currentlySelected, setCurrentlySelected] =
    useState<TSearchItemsState>(null)
  const [nonDefaultAnswers, setNonDefaultAnswers] =
    useState<TSearchItemsState>(null)
  const [showHits, setShowHits] = useState<boolean>(false)
  const searchRef = useRef<HTMLDivElement>(null)

  const updateAnswers = async (
    item: ISearchAnswerData | TSearchAnswer,
    answerInputType: TSearchAnswerInputType
  ) => {
    const nonDefault =
      answerInputType === 'custom' ||
      -1 === popularAnswers.findIndex((i) => i.id === item.id)
    let currentSelections = currentlySelected ? [...currentlySelected] : null
    if (!currentSelections) {
      currentSelections = []
    }

    const selectedIndex = currentSelections.findIndex(
      (i) => i.name === item.name
    )
    const selected = -1 !== selectedIndex

    if (selected) {
      // Make null if unselecting the only selectedAnswer
      if (currentSelections.length === 1) {
        currentSelections = null
      } else {
        // or remove item from array
        currentSelections.splice(selectedIndex, 1)
      }
    } else {
      // Add item if not unselecting
      currentSelections.push(item)
      currentSelections.sort((a, b) => a.name.localeCompare(b.name))
    }
    // Update local component state
    setCurrentlySelected(currentSelections)

    // Update application state of questionnaire
    const newState = { ...stepData } as IQuestionnaireData
    const currentSegment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData
    const currentQuestion = getCurrentQuestionData(
      pageLocations,
      newState
    ) as ISearchQuestionData
    if (!currentQuestion.selectedAnswer) currentQuestion.selectedAnswer = []
    currentQuestion.selectedAnswer = currentSelections

    // If update is a nondefault then also update searchedAnswers
    // submit searched answers to otherAnswers
    if (nonDefault) {
      let newNonDefaults = nonDefaultAnswers

      if (!newNonDefaults) {
        newNonDefaults = []
      }

      const itemIndex = newNonDefaults.findIndex((i) => i.name === item.name)
      const selected = -1 !== itemIndex

      if (selected) {
        // Make null if unselecting the only non-default answer
        if (newNonDefaults.length === 1) {
          newNonDefaults = null
        } else {
          // or remove item from array
          newNonDefaults.splice(itemIndex, 1)
        }
      } else {
        newNonDefaults.push(item)
        newNonDefaults.sort((a, b) => a.name.localeCompare(b.name))
      }

      setNonDefaultAnswers(newNonDefaults)

      if (newNonDefaults) {
        const partitionedDefaults = partitionNew(
          newNonDefaults,
          (a: TSearchAnswer) => a.searched
        )
        currentQuestion.searchedAnswer = partitionedDefaults[0]
        currentQuestion.otherAnswer = partitionedDefaults[1]
      } else {
        currentQuestion.searchedAnswer = null
        currentQuestion.otherAnswer = null
      }
    }

    const answered = !!currentSelections

    newState.segments[pageLocations['segment']] = currentSegment
    const result = await submitAnswer(answered, newState, pageLocations)
    setShowHits(false)

    if (submissionSuccessful(result)) {
      setSubmitted(true)
    }

    // Add popularity submission
    // if (item.id) {
    //   axios.post(`/api/${data.searchIndex}/update`, {
    //     headers: {
    //       Authorization: `Bearer ${jwt}`
    //     }
    //   })
    // }
  }

  /**
   * Fill in users answers
   */
  useEffect(() => {
    setCurrentlySelected(selectedAnswer)
  }, [selectedAnswer, setCurrentlySelected])

  /**
   * Query Hook
   * For tracking changes to the query to hold in state for
   * controlling display logic of hits.
   */
  const queryHook = (query: string, search: (value: string) => void) => {
    if (query === '') {
      setSearchValue('')
      setShowHits(false)
    }
    search(query)
  }

  /**
   * Get input value
   */
  const getSearchValue = () => {
    if (!searchRef.current) return
    return searchRef.current.getElementsByTagName('input')[0].value
  }

  /**
   * Open hits on keydown effect
   * To ensure that menu opens if user starts typing again
   */
  useEffectOnce(() => {
    if (!searchRef.current) return
    searchRef.current.addEventListener('keydown', () => setShowHits(true))
  })

  /**
   *  Set non-default answers
   *  Which holds both searched and custom answers
   */
  useEffect(() => {
    if (!otherAnswer && !searchedAnswer) setNonDefaultAnswers(null)
    if (otherAnswer && !searchedAnswer) setNonDefaultAnswers(otherAnswer)
    if (!otherAnswer && searchedAnswer) setNonDefaultAnswers(searchedAnswer)
    if (otherAnswer && searchedAnswer)
      setNonDefaultAnswers([...searchedAnswer, ...otherAnswer])
  }, [otherAnswer, searchedAnswer, setNonDefaultAnswers])

  /**
   * Update Search State
   * callback function used to update state held in parent component
   * that will load back into the searchbar even if that component is
   * reloads.
   */
  const updateSearchState = () => {
    const value = getSearchValue()
    if (value) setSearchValue(value)
  }

  /**
   * Use Clickanywhere effect
   * Used to close search dropdown when it's clicked off of
   */
  useClickAnyWhere((e) => {
    if (!searchRef.current) return
    const { current } = searchRef
    const { target } = e
    if (target && current.contains(target as HTMLElement) && !showHits) {
      setShowHits(true)
    } else if (showHits) {
      updateSearchState()
      setShowHits(false)
    }
  })

  /**
   * Debounded Tracking
   */
  const deboucnedTrackingHandler = useMemo(
    () =>
      debounce(
        (
          currentlySelected: TSearchItemsState,
          nonDefaultAnswers: TSearchItemsState
        ) => {
          if (!!currentlySelected || !!nonDefaultAnswers) {
            const allAnswers = [
              ...(currentlySelected || []),
              ...(nonDefaultAnswers || [])
            ]

            /**
             * This could be a util for filtering down arrays of objects by a unquie key
             */
            const uniqueAnswers = allAnswers.filter((item, index, self) => {
              const currentIndex = self.findIndex(
                (other) => other.name === item.name
              )
              return currentIndex === index
            })

            const isAdmin = isAdminTracking(userType)
            const answerTracking: { [K: string]: string } = {}
            uniqueAnswers.forEach((answer) => {
              const { name } = answer
              answerTracking[name] = name
            })
            questionSubmitTracking({
              is_admin_key: isAdmin,
              ...answerTracking
            })
            setSubmitted(false)
          }
        },
        2000
      ),
    [userType]
  )

  useEffect(() => {
    if (!submitted) return
    deboucnedTrackingHandler(currentlySelected, nonDefaultAnswers)
  }, [
    currentlySelected,
    deboucnedTrackingHandler,
    nonDefaultAnswers,
    submitted
  ])

  const layoutClasses = questionLayoutClasses(popularAnswers.length)

  const noHits = (
    <div className='c-repel u-p-4'>
      <p>No results found - Select to add manaully</p>
      <StyledButton
        type='button'
        theme='secondary'
        themeStyle='outline'
        onClick={() => {
          const value = getSearchValue()
          if (value && value !== '') {
            updateAnswers({ name: value, searched: false }, 'custom')
            setShowHits(false)
          }
        }}
      >
        Add Entry
      </StyledButton>
    </div>
  )

  return (
    <div>
      <div className='u-py-700'>
        <div className='c-flow'>
          <div className='c-withLayer'>
            <div ref={searchRef} onClick={(e) => e.stopPropagation}>
              <StyledSearchBox
                placeholder={searchPlaceholder}
                queryHook={queryHook}
                className='u-flex-grow u-border-secondary-500'
              />
              {showHits && (
                <div className='c-layer | u-top-[calc(100%-5px)] u-z-[5]'>
                  <div className='u-max-h-[15rem] u-w-full u-overflow-auto u-bg-primary-300'>
                    <DropdownHits
                      noHitsComponent={noHits}
                      hitComponent={(data) => {
                        const {
                          hit: { id, logo, name, popularity }
                        } = data
                        const img = {
                          src: logo ? logo : '',
                          alt: '',
                          width: 100,
                          height: 100
                        } as TImageProps
                        const answer = {
                          id,
                          name,
                          popularity,
                          searched: true
                        } as TSearchAnswer
                        return (
                          <SearchResult
                            content={name as string}
                            img={img}
                            onClick={() => {
                              updateAnswers(answer, 'searched')
                            }}
                          />
                        )
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          {nonDefaultAnswers && (
            <div className='c-cluster'>
              {nonDefaultAnswers.map((a, index) => {
                const key = `${a.name}_${index}`
                return (
                  <div
                    key={key}
                    className='c-repel u-rounded u-bg-primary-300 u-px-400 u-py-200 u-text-secondary-800'
                  >
                    <button
                      onClick={() => updateAnswers(a, 'searched')}
                      className='u-rounded-full u-bg-white u-p-100'
                    >
                      <XIcon className='[--icon-size:.6rem]' />
                    </button>
                    {a.name}
                  </div>
                )
              })}
            </div>
          )}
          <p className='u-text-3 u-text-secondary-800'>{searchInstruction}</p>
        </div>
      </div>
      <form className={layoutClasses}>
        {popularAnswers.map((answer) => {
          const { id, logo, name } = answer
          const checked = currentlySelected
            ? currentlySelected.some((item) => item.id === answer.id)
            : false
          const inputBgColor = inputBgClasses(checked)
          return (
            <SelectAnswer
              componentName='searchSelectMultiple'
              key={`${id}_${name}`}
              onClick={() => updateAnswers(answer, 'default')}
              checked={checked}
              composition={logo ? 'repel' : 'cluster'}
            >
              <input
                className={'u-hidden'}
                title={`answer${id}`}
                name={`SearchSelect_${id}`}
                type='checkbox'
                value={id}
                checked={checked}
                onChange={() => {
                  return undefined
                }}
              />
              <div className='c-cluster' tabIndex={0}>
                <div
                  className={`${inputBgColor} u-flex u-h-[1.875rem] u-w-[1.875rem] u-items-center u-justify-center u-rounded u-border-[0.25rem] u-border-white`}
                >
                  {checked && (
                    <div className='u-h-3/5 u-w-3/5 u-text-white'>
                      <CheckMarkIcon />
                    </div>
                  )}
                </div>
                <label htmlFor={`answer${id}`}>{name}</label>
              </div>
              {logo && (
                <div>
                  <Img alt='' src={logo} width={50} height={50} />
                </div>
              )}
            </SelectAnswer>
          )
        })}
      </form>
    </div>
  )
}

export default SearchQuestionUI
