import type { IVideoBlockData } from '@unionco/alaris-app-types'

import { titleAndCopyTestData, videoTestData } from '@fixtures/generic'

export const VideoBlockTestData = (): IVideoBlockData => {
  return {
    video: videoTestData,
    ...titleAndCopyTestData()
  }
}
