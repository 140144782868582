/**
 * Video tracking
 *
 * Tracks when a user plays a video
 */
import { TVideoStartTracking } from '@types'

import { tracking } from './tracking'

type TVideoStartTrackingParams = Omit<TVideoStartTracking, 'event'>

export const videoStartTracking = (data: TVideoStartTrackingParams): void => {
  tracking<TVideoStartTracking>({
    event: 'video_start',
    ...data
  })
}
