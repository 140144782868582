import { IconSVG } from '@unionco/svg-icons'

import { TSVGProps } from '@unionco/alaris-app-types'

export const HamburgerIcon: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <IconSVG
      viewBoxWidth={48}
      viewBoxHeight={32}
      xmlns='http://www.w3.org/2000/svg'
      style={{
        transform: 'translate(5%,2%)'
      }}
      {...props}
      ref={undefined}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.66667 5.33333C1.2 5.33333 0 4.13333 0 2.66667C0 1.2 1.2 0 2.66667 0H45.3333C46.8 0 48 1.2 48 2.66667C48 4.13333 46.8 5.33333 45.3333 5.33333H2.66667ZM2.66667 18.6667H45.3333C46.8 18.6667 48 17.4667 48 16C48 14.5333 46.8 13.3333 45.3333 13.3333H2.66667C1.2 13.3333 0 14.5333 0 16C0 17.4667 1.2 18.6667 2.66667 18.6667ZM2.66667 32H45.3333C46.8 32 48 30.8 48 29.3333C48 27.8667 46.8 26.6667 45.3333 26.6667H2.66667C1.2 26.6667 0 27.8667 0 29.3333C0 30.8 1.2 32 2.66667 32Z'
        fill='currentColor'
      />
    </IconSVG>
  )
}

export default HamburgerIcon
