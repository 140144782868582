/** Test Questionnaire Fixture
 *
 *  A full questionnaire with mock data
 *
 *  Replacing pageLocations with strings
 *  Currently page locations is based of off the index of the arrays for
 *  categories, segments, and questions
 *  In order to switch the routing over to strings (or UUID's aka keys)
 *  We could possibly reverse look up the index based on an include()
 *  Or add an order key value pair that's equal to the index, which
 *  wouldn't have to come from the CMS, it could be provided on the FE
 */
import type { TQuestionnaireSegmentsData } from '@unionco/alaris-app-types'

import {
  questionnaireSummaryFixture,
  sectionReview
} from '@fixtures/phase/questionnaire'
import { sectionIntroFixture } from '@fixtures/phase/questionnaire/sectionIntro'
import {
  custodianSegmentFixture,
  rangeSegmentFixture,
  selectMultipleSegmentFixture,
  selectOneTestSegmentFixture,
  yesNoSegmentFixture
} from '@fixtures/phase/questionnaire/segment'

export const testQuestionnaireFixture = {
  ...questionnaireSummaryFixture,
  segments: [
    sectionIntroFixture,
    selectOneTestSegmentFixture,
    selectMultipleSegmentFixture,
    custodianSegmentFixture,
    yesNoSegmentFixture,
    rangeSegmentFixture,
    sectionReview
  ] as TQuestionnaireSegmentsData
}
