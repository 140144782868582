/** Questionnaire Review
 *  Definition: The last segment of a given questinnaire (top level accordion item)
 *  Uses Questionnaire Context to pull in current state and display's a summary to the user
 */
import { InfoIcon } from '@unionco/svg-icons'
import { useContext, useEffect } from 'react'
import tw from 'twin.macro'

import {
  IQuestionSegmentData,
  IQuestionnaireData,
  IRangeSegmentData,
  TQuestionData,
  TQuestionSegmentData,
  TSectionStatusText
} from '@unionco/alaris-app-types'

import { getStatusDisplayText, partitionNew } from 'utils'
import { isAdminTracking } from 'utils/tracking'
import { questionnairePageLoadTracking } from 'utils/tracking/pageLoad'

import { PreAuthGlobalsContext, TUserContext, UserContext } from 'context'

import { SidebarContext } from 'components'

import SegmentHeader from '../../SidebarTemplate/segmentHeader'
import SegmentStatusIndicator from '../../SidebarTemplate/wayfinder/wayfinderAccordion/segmentStatusIndicator'
import QuestionControls from '../controls'
import RangeReview from './RangeReview'
import ReviewQuestionCard from './questionCard'

type TWithIndex = { index: number }
export type IIndexedQuestionSegmentData = IQuestionSegmentData & TWithIndex
export type IIndexedRangeSegmentData = IRangeSegmentData & TWithIndex

interface IQuestionnaireReviewProps {
  segmentTitle: string
}

export const QuestionnaireReview: React.FC<IQuestionnaireReviewProps> = ({
  segmentTitle
}) => {
  const { pageLoaded } = useContext(PreAuthGlobalsContext)
  const { userType } = useContext(UserContext) as TUserContext
  const { data, pageLocations } = useContext(SidebarContext)
  const currentQuestionnaireIndex = pageLocations['section']
  const { status } = data as IQuestionnaireData
  const header = data.title
  const currentSegments = data.segments
  const questionSegments = currentSegments.slice(
    1,
    -1
  ) as TQuestionSegmentData[]

  /**
   * Questionnaire Page Load Tracking
   *
   * If not in questionnaire it returns immeditately
   */
  useEffect(() => {
    if (pageLoaded) return
    questionnairePageLoadTracking({
      questionnaire_title: header,
      segment_title: segmentTitle,
      is_admin_key: isAdminTracking(userType),
      page: 'questionnaire step'
    })
  }, [header, pageLoaded, segmentTitle, userType])

  const separatedSegments = partitionNew(
    questionSegments.map((s, i) => {
      return { ...s, index: i + 1 }
    }),
    (seg: TQuestionSegmentData) => {
      return seg.type !== 'rangeSegment'
    }
  ) as [IIndexedQuestionSegmentData[], IIndexedRangeSegmentData[]]

  const statusTextStyling = (statusText: TSectionStatusText) => {
    switch (statusText) {
      case 'notStarted':
      case 'incomplete':
        return 'u-text-state-danger'
      default:
        return 'u-text-state-good'
    }
  }

  const segementHeaderText = (
    statusText: TSectionStatusText,
    questions: TQuestionData[]
  ) => {
    // If no question's answered 'Not Started' (display status text)
    if (statusText === 'notStarted') return <p>Not Started</p>

    const numOfQuestions = questions.length
    const numOfAnsweredQuestions = questions.filter((q) => q.answered).length

    if (numOfQuestions > 1) {
      return (
        <p className='u-text-secondary-800'>
          {`${numOfAnsweredQuestions} of ${numOfQuestions} Questions Answered`}
        </p>
      )
    }

    // If singular question no text
    return <></>
  }

  const { statusText } = status

  return (
    <div className='c-flow | u-bg-white u-p-700 u-text-center'>
      <SegmentHeader
        eyebrow='Review your answers'
        header={header}
        supportText={getStatusDisplayText(statusText)}
        supportTextClasses={`u-uppercase u-font-bold ${statusTextStyling(
          statusText
        )}`}
      />
      <div className='c-flow | u-py-500'>
        {separatedSegments[0].length > 0 && (
          <div className='c-flow'>
            {separatedSegments[0].map((segment) => {
              const { index, questions, status, title } = segment
              const questionSegmentIndex = index
              const { statusText } = status
              const segmentStatusText = statusText
              const isMultiQuestion = questions.length > 1

              return (
                <div
                  className='b-reviewSegment | c-flow'
                  key={`ReviewSegment_${questionSegmentIndex}`}
                >
                  <div className='c-repel'>
                    <div className={'c-cluster'}>
                      <SegmentStatusIndicator
                        bgColor={'u-bg-primary-300'}
                        borderColor={'u-border-primary-300'}
                        status={status}
                        useStatusRing={isMultiQuestion}
                      />
                      <div className='u-flex'>
                        <h4 className='u-mr-2 u-font-bold'>{title}</h4>
                        <InfoIcon
                          iconStyle='outline'
                          cssClasses={tw`u-text-secondary-800`}
                        />
                      </div>
                    </div>
                    <div>
                      {segementHeaderText(segmentStatusText, questions)}
                    </div>
                  </div>
                  <div className='b-reviewSegment__body | c-flow'>
                    {segmentStatusText !== 'notStarted' &&
                      questions.map((question, index) => {
                        return (
                          <ReviewQuestionCard
                            key={`${question.questionText}_${index}`}
                            question={question}
                            questionnaireIndex={currentQuestionnaireIndex}
                            segmentIndex={questionSegmentIndex}
                            index={index}
                          />
                        )
                      })}
                  </div>
                </div>
              )
            })}
          </div>
        )}
        {separatedSegments[1].length > 0 && (
          <div className='c-flow u-pt-300'>
            {<RangeReview segments={separatedSegments[1]} />}
          </div>
        )}
      </div>
      <QuestionControls isReview />
    </div>
  )
}

export default QuestionnaireReview
