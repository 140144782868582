import resolveConfig from 'tailwindcss/resolveConfig'

import { IScreens, ITailwindConfig } from '@unionco/alaris-app-types'

import { remsToPixels } from 'utils'
import { filterObject } from 'utils/object'

import tailwindConfig from '../../tailwind.config.cjs'

interface IBreakpointsValues {
  [key: string]: number
}

const fullConfig = resolveConfig(tailwindConfig)

export const tailwindTheme = fullConfig.theme

const {
  theme: { colors, screens }
} = fullConfig as unknown as ITailwindConfig

// #region - Breakpoints / Screens
export const breakpointStrings = screens
const minimumBreakpoints = filterObject(breakpointStrings, ([key]) => {
  const k = typeof key === 'number' ? key.toString() : key
  return !!!k.includes('max')
}) as IScreens

const minimumBreakpointsPixelValues = {} as IBreakpointsValues
for (const key in minimumBreakpoints) {
  minimumBreakpointsPixelValues[key] = remsToPixels(
    minimumBreakpoints[key] as `${number}rem`
  )
}

const themeColors = colors as ITailwindConfig
// #endregion - Breakpoints / Screens

export { minimumBreakpointsPixelValues, themeColors }
