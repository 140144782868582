import { TDirection, TSVGProps } from '@unionco/alaris-app-types'

interface IArrowIcon extends TSVGProps {
  iconDirection?: TDirection
}

const ArrowIconUp: React.FC = ({ ...props }) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 32 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.2563 45.5239V9.56999L5.3939 17.4324C4.3292 18.497 2.60931 18.497 1.54462 17.4324C0.47992 16.3677 0.47992 14.6478 1.54462 13.5831L14.0753 1.05243C15.14 -0.0122675 16.8598 -0.0122675 17.9245 1.05243L30.4552 13.5831C31.5199 14.6478 31.5199 16.3677 30.4552 17.4324C29.3905 18.497 27.6706 18.497 26.6059 17.4324L18.7162 9.56999V45.5239C18.7162 47.0254 17.4877 48.2539 15.9862 48.2539C14.4848 48.2539 13.2563 47.0254 13.2563 45.5239Z'
        fill='#48505E'
      />
    </svg>
  )
}

const ArrowIconRight: React.FC = ({ ...props }) => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 48 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2.72999 12.5102H38.6839L30.8216 4.6478C29.7569 3.58311 29.7569 1.86322 30.8216 0.798521C31.8863 -0.266174 33.6061 -0.266174 34.6708 0.798521L47.2015 13.3292C48.2662 14.3939 48.2662 16.1137 47.2015 17.1784L34.6708 29.7091C33.6061 30.7738 31.8863 30.7738 30.8216 29.7091C29.7569 28.6444 29.7569 26.9245 30.8216 25.8598L38.6839 17.9701H2.72999C1.22849 17.9701 0 16.7416 0 15.2402C0 13.7387 1.22849 12.5102 2.72999 12.5102Z'
        fill='#48505E'
      />
    </svg>
  )
}

const ArrowIconDown: React.FC = ({ ...props }) => {
  return (
    <svg
      width='100%'
      height='49'
      viewBox='0 0 32 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.7435 2.98389V38.9378L26.6059 31.0755C27.6706 30.0108 29.3905 30.0108 30.4552 31.0755C31.5199 32.1402 31.5199 33.8601 30.4552 34.9247L17.9245 47.4554C16.8598 48.5201 15.1399 48.5201 14.0753 47.4554L1.54462 34.9247C0.47992 33.8601 0.47992 32.1402 1.54462 31.0755C2.60931 30.0108 4.3292 30.0108 5.3939 31.0755L13.2836 38.9378V2.98389C13.2836 1.4824 14.5121 0.253906 16.0135 0.253906C17.515 0.253906 18.7435 1.4824 18.7435 2.98389Z'
        fill='#48505E'
      />
    </svg>
  )
}

const ArrowIconLeft: React.FC = ({ ...props }) => {
  return (
    <svg
      width='48'
      height='31'
      viewBox='0 0 48 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M2.72999 12.5102H38.6839L30.8216 4.6478C29.7569 3.58311 29.7569 1.86322 30.8216 0.798521C31.8863 -0.266174 33.6061 -0.266174 34.6708 0.798521L47.2015 13.3292C48.2662 14.3939 48.2662 16.1137 47.2015 17.1784L34.6708 29.7091C33.6061 30.7738 31.8863 30.7738 30.8216 29.7091C29.7569 28.6444 29.7569 26.9245 30.8216 25.8598L38.6839 17.9701H2.72999C1.22849 17.9701 0 16.7416 0 15.2402C0 13.7387 1.22849 12.5102 2.72999 12.5102Z'
        fill='#48505E'
      />
    </svg>
  )
}

export const ArrowIcon: React.FC<IArrowIcon> = ({
  direction = 'right',
  ...props
}) => {
  switch (direction) {
    case 'up':
      return <ArrowIconUp {...props} />
    case 'right':
      return <ArrowIconRight {...props} />
    case 'down':
      return <ArrowIconDown {...props} />
    case 'left':
      return <ArrowIconLeft {...props} />
    default:
      return <></>
  }
}

export default ArrowIcon
