import { Card } from '@unionco/components'
import type { FC, ReactNode } from 'react'

interface IAuthCardProps {
  children: ReactNode
  className?: string
  id?: string
}

export const AuthCard: FC<IAuthCardProps> = ({ children, className, id }) => {
  const defaultClasses =
    'c-box c-flow | u-bg-white u-px-800 u-py-500 lg:u-py-600 xl:u-py-700'
  const classes = className ? `${defaultClasses} ${className}` : defaultClasses
  return (
    <Card id={id} className={classes}>
      {children}
    </Card>
  )
}

export default AuthCard
