import {
  IMatchData,
  IRingWithTitle,
  TAlignmentGrid,
  TAlignmentMatch
} from '@unionco/alaris-app-types'

import { placeHolderImageUrl, shortParagraphFixture } from '..'

import { randomPercent } from '@fixtures/number'

function ringWithTitleData(relevancePercent = 0, percent = 50, title = 'Title'): IRingWithTitle {
  return {
    relevancePercent,
    percent,
    title
  }
}

export const alignmentGridData = [
  ringWithTitleData(randomPercent(), randomPercent(), 'Title'),
  ringWithTitleData(randomPercent(), randomPercent(), 'Title'),
  ringWithTitleData(randomPercent(), randomPercent(), 'Title')
] as TAlignmentGrid

export const alignmentMatchData = [
  alignmentGridData,
  alignmentGridData
] as TAlignmentMatch

export const companyHeader = {
  companyLogo: placeHolderImageUrl['default'],
  companyName: 'Company Name'
}

export const matchData = {
  alignments: alignmentMatchData,
  description: shortParagraphFixture,
  matchConfidence: randomPercent(),
  matchConfidenceLabel: 'range-range',
  revenue: '1600000',
  location: 'Somewhere, ST',
  websiteName: 'www.google.com',
  websiteLink: 'www.google.com',
  adjacentMatches: [companyHeader, companyHeader],
  ...companyHeader
} as IMatchData

export const matchesData = [
  matchData,
  matchData,
  matchData,
  matchData,
  matchData,
  matchData,
  matchData,
  matchData,
  matchData,
  matchData
]

export const topMatchesFixture = {
  copy: shortParagraphFixture,
  title: 'Review Your Top Matches'
}
