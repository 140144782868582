/** Key Ratios And Metrics Test Data
 *  Test data for the <KeyRatiosAndMetrics /> module
 *  used in the profile template
 *  A seller only main module
 */
import { v4 as uuidv4 } from 'uuid'

import type { IKeyRatiosAndMetricsData } from '@unionco/alaris-app-types'

import { basicMetricColumnData, basicMetricTestData } from '@fixtures/business'
import { shortParagraphFixture } from '@fixtures/generic'

export const keyRatiosAndMetricsModuleTestData = {
  moduleType: 'KeyRatiosAndMetrics',
  title: 'Key Ratios and Metrics',
  topRevenueMetrics: [
    basicMetricTestData(),
    basicMetricTestData(),
    basicMetricTestData(),
    basicMetricTestData()
  ],
  assetsUnderManagement: basicMetricColumnData,
  otherRevenueMetrics: basicMetricColumnData,
  key: uuidv4(),
  container: 1,
  info: {
    copy: shortParagraphFixture
  }
} as IKeyRatiosAndMetricsData
