import React from 'react'

import { IDepartmentHeadData } from '@unionco/alaris-app-types'

import { Img } from 'components/generic'

import { TeamMemberHeader } from '../TeamMemberHeader'

export const DepartmentHeadCard: React.FC<IDepartmentHeadData> = ({
  firstName,
  jobDescription,
  lastName,
  profilePic,
  title
}) => {
  const validImg =
    profilePic && profilePic && profilePic.url && profilePic.url !== ''

  const stats = [{ displayText: 'Role in the Firm', value: jobDescription }]
  return (
    <div className='c-cluster c-cluster-top'>
      {validImg && (
        <div className='u-aspect-square u-w-full u-max-w-[4rem]'>
          <Img
            className='u-w-full'
            width={profilePic.width}
            height={profilePic.height}
            src={profilePic.url}
            alt=''
          />
        </div>
      )}
      <div className=''>
        <TeamMemberHeader
          firstName={firstName}
          lastName={lastName}
          title={title}
        />
        {stats.map(({ displayText, value }, index) => {
          if (!value || value === '') {
            return <React.Fragment key={`InvalidStat_${index}`} />
          }

          return (
            <p className='u-text-2' key={`StatItem_${index}`}>
              <span className='u-font-bold'>{displayText}: </span>
              <span>{value}</span>
            </p>
          )
        })}
      </div>
    </div>
  )
}

export default DepartmentHeadCard
