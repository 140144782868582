/** Video Run Time Pill
 *  A small time indicator how how long a video is
 *  displayed in the <Video /> component under the video
 *  on the right hand side
 */

interface IVideoRunTimeProps {
  runTime: number // as seconds
}

export const VideoRunTime: React.FC<IVideoRunTimeProps> = ({ runTime }) => {
  //   const durationTime = duration(runTime, 'seconds')
  const minutes = Math.floor(runTime / 60)
  const seconds = runTime % 60

  return (
    <time
      dateTime={`PT${runTime}S`}
      className='u-text-bold u-rounded u-bg-grayscale-600 u-p-1 u-text-2 u-text-secondary-800'
    >
      {minutes.toString().padStart(2, '0')}:
      {seconds.toString().padStart(2, '0')}
    </time>
  )
}

export default VideoRunTime
