/** TimelineProgression
 *  Top dashbaord component that displays a users
 *  progess for their respective business type,
 *  with in a current phase.
 *
 *  Visullay it displays a phase title, with link to Alaris site
 *  user full timeline. Below it displays the actual timeline of
 *  timeline items.
 */
import { useBreakAtSize } from '@unionco/components'
import { RefObject, createRef, useCallback, useEffect, useState } from 'react'

import type { TPhaseStepSummaryData } from '@unionco/alaris-app-types'

import { TimelineProgressionMobileUI } from './MobileUI'
import { TimelineProgressionUI } from './UI'

export * from './Timelineitems'

export interface ITimelineProgession {
  currentPhaseName: string
  currentSectionIndex: number
  phaseStepSummaries: TPhaseStepSummaryData[]
}

export const TimelineProgression: React.FC<ITimelineProgession> = ({
  currentPhaseName,
  currentSectionIndex,
  phaseStepSummaries
}) => {
  const { aboveBreakPoint } = useBreakAtSize('lg')
  const [timelineCircleRefs, setTimelineCircleRefs] = useState<
    RefObject<HTMLDivElement>[] | null
  >(null)
  const [statusCircleRadius, setStatusCircleRadius] = useState(0)
  const [spaceBetweenCircles, setSpaceBetweenCircles] = useState([0])

  const updateRefs = (
    refs: RefObject<HTMLDivElement>[] | null,
    numOfRefs: number
  ) => {
    return Array(numOfRefs)
      .fill(undefined)
      .map((_, i) => (refs ? refs[i] : createRef<HTMLDivElement>()))
  }

  const updateTimelineSizes = useCallback(() => {
    if (timelineCircleRefs) {
      const refs = [...timelineCircleRefs]
      const firstCircle = refs[0].current
      const circleRadius = firstCircle ? firstCircle.clientWidth / 2 : 0
      setStatusCircleRadius(circleRadius)
      const items = refs.slice(0, -1)
      const spaces = items.map((item, index) => {
        const { current } = item as RefObject<HTMLDivElement>
        const nextItem = refs[index + 1].current as HTMLDivElement
        if (current && nextItem) {
          const rect = current.getBoundingClientRect()
          const right = rect.right
          const left = nextItem.getBoundingClientRect().left
          return left - right
        }
        return 0
      })
      setSpaceBetweenCircles(spaces)
    }
  }, [timelineCircleRefs])

  // Refs Effect
  useEffect(() => {
    const numOfTimelineItems = phaseStepSummaries.length
    setTimelineCircleRefs((refs) => updateRefs(refs, numOfTimelineItems))
  }, [phaseStepSummaries])

  // Update timeline effect
  useEffect(() => {
    updateTimelineSizes()
  }, [phaseStepSummaries, updateTimelineSizes])

  // Resize effect
  useEffect(() => {
    window.addEventListener('resize', updateTimelineSizes)
  }, [phaseStepSummaries, updateTimelineSizes])

  if (aboveBreakPoint) {
    return (
      <TimelineProgressionUI
        currentSectionIndex={currentSectionIndex}
        spaceBetweenCircles={spaceBetweenCircles}
        statusCircleRadius={statusCircleRadius}
        title={currentPhaseName}
        timelineItems={phaseStepSummaries}
        timelineCircleRefs={timelineCircleRefs}
      />
    )
  }

  return (
    <TimelineProgressionMobileUI
      currentSectionIndex={currentSectionIndex}
      spaceBetweenCircles={spaceBetweenCircles}
      statusCircleRadius={statusCircleRadius}
      title={currentPhaseName}
      timelineItems={phaseStepSummaries}
      timelineCircleRefs={timelineCircleRefs}
    />
  )
}

export default TimelineProgression
