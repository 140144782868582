export const FE_URL = process.env.NEXT_PUBLIC_FE_URL
export const API_BASE = process.env.NEXT_PUBLIC_API_BASE
export const API_BASE_PROD = process.env.NEXT_PUBLIC_API_BASE_PROD
export const MEILI_KEY = process.env.NEXT_PUBLIC_MEILI_FE_KEY
export const MEILI_HOST = process.env.NEXT_PUBLIC_MEILI_HOST
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV

/** Page Routes
 *  The page routes listed below should match the file names
 *  inside of the /pages directory since that's how next.js
 *  generates them.
 */

/** Unprotected Routes
 *  These need to be added to array in <Authentication />
 */
export const LoginPageSlug = '/login'

/** Protected Routes
 * These will not be accessible to non-authenticated users
 *
 * Both buyer and seller are setup with suffix /[pid] since
 * this is how the pathname variable is seen from next.js's router
 */
export const AdminPanelPageSlug = '/admin-panel'
export const BuyerQuestionnairePageSlug = '/buyer/[pid]'
export const DashboardPageSlug = '/dashboard'
export const SellerQuestionnairePageSlug = '/seller/[pid]'
export const SupportPageSlug = '/support'
export const ValuationCalculatorPageSlug = '/valuation-calculator'
export const TimelineConfigurationSlug = '/timeline-configuration'
export const DropboxSlug = '/dropbox'
export const MatchesPageSlug = '/matchesv7'

/**
 * Used for account dropdown el location
 */
export type TBuyerProfileTrackingPageName = 'buyer profile page'
export type TBuyerQuestionnaireTrackingPageName = 'buyer questionnaire page'
export type TSellerProfileTrackingPageName = 'seller profile page'
export type TSellerQuestionnaireTrackingPageName = 'seller questionnaire page'
export type TSupportPageTrackingName = 'support page'
export type TPageName =
  | TBuyerProfileTrackingPageName
  | TBuyerQuestionnaireTrackingPageName
  | 'dashboard page'
  | TSellerProfileTrackingPageName
  | TSellerQuestionnaireTrackingPageName
  | TSupportPageTrackingName
  | 'valuation calculator'
  | 'sellers matches'
  | 'matchesv7'
  | 'admin panel page'
  | 'timeline configuration'
  | 'dropbox'
