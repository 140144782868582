import { ThemeProvider } from '@emotion/react'
import { Flyouts, Modal } from '@unionco/components'
import { SessionProvider } from 'next-auth/react'
import type { AppProps } from 'next/app'
import { useEffect } from 'react'
import tw from 'twin.macro'

import { PreAuthGlobalsContextWrapper } from 'context/PreAuthGlobals'

import { Authentication } from 'components'

import { pageload } from '../header/gtm'
import { MarkerIO } from '../header/markerio'
import '../styles/app.scss'

const theme = {}

export default function App({
  Component,
  pageProps: { session, ...pageProps }
}: AppProps) {
  useEffect(() => {
    pageload(window.location.pathname)
  }, [])

  return (
    <>
      <MarkerIO
        prodHost={process.env.NEXT_PUBLIC_PROD_HOST || ''}
        projectId='654b09ed5cac78caacc58dfc'
      >
        <ThemeProvider theme={theme}>
          <SessionProvider session={session}>
            <PreAuthGlobalsContextWrapper>
              <Modal.Main>
                <Flyouts cssClasses={tw`u-border-primary-500`}>
                  <Authentication>
                    <Component {...pageProps} />
                  </Authentication>
                </Flyouts>
              </Modal.Main>
            </PreAuthGlobalsContextWrapper>
          </SessionProvider>
        </ThemeProvider>
      </MarkerIO>
    </>
  )
}
