import { IFreeResponseQuestionData } from '@unionco/alaris-app-types';
import React from 'react';
import FreeResponseInput from './FreeResponseInput';

interface IFreeResponseQuestionProps {
  data: IFreeResponseQuestionData;
}

const FreeResponseQuestion: React.FC<IFreeResponseQuestionProps> = ({ data }) => {
  const { answers, triggerQuestionType } = data;

  let inputs = 0;
  let lastNYears: number[] = [];

  // Check if it's a multi-input question
  if (triggerQuestionType?.includes('multiInputFreeResponse')) {
    const triggerQuestionTypeParts = triggerQuestionType.split('.');
    // Case statement to handle different types of multi-input questions for easy extensibility
    // Currently only supports lastNYears
    switch (triggerQuestionTypeParts[1]) {
      case 'lastNYears':
        // Extract the number of years
        inputs = parseInt(triggerQuestionTypeParts[2], 10);
        // Calculate last n years
        const currentYear = new Date().getFullYear();
        lastNYears = Array.from({ length: inputs }, (_, i) => currentYear - i);
        break;
      default:
        break;
    }
  }

  // Function to render input years
  const renderInputYears = () => {
    return (
      inputs > 0 && // Render only if there are inputs
      lastNYears.map((year, index) => (
        <FreeResponseInput
          data={{
            label: `${year}`, // Use year as label
            inputType: 'shortText',
            value: answers[index] ? String(answers[index].value) : '' // Use answer value if available
          }}
          index={index}
          key={`FreeResponse_${index}`}
        />
      ))
    );
  };

  return (
    <div className='c-flow'>
      {/* Render input years if available, otherwise render answers */}
      {renderInputYears() || (
        answers.length > 0 ? (
          answers.map((answer, index) => (
            <FreeResponseInput
              data={answer}
              index={index}
              key={`FreeResponse_${index}`}
            />
          ))
        ) : (
          // Render a default input if no answers are available
          <FreeResponseInput
            data={{
              label: 'Please enter your response',
              inputType: 'shortText',
              value: ''
            }}
            index={0}
            key={`FreeResponse_${0}`}
          />
        )
      )}
    </div>
  );
}

export default FreeResponseQuestion;
