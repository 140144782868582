import { createContext } from 'react'

interface IQuestionnaireContext {
  submitQuestionnaire: () => void
}

const initQuestionnaireContext = {
  submitQuestionnaire: () => {
    return undefined
  }
}

export const QuestionnaireContext = createContext<IQuestionnaireContext>(
  initQuestionnaireContext
)
