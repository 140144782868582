/*
 * Reading on global context:
 * https://episyche.com/blog/how-to-use-context-api-in-a-nextjs-app
 *
 * Combines global constants along with user context.
 */
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import { useSession } from 'next-auth/react'

import { MEILI_HOST, MEILI_KEY } from '@appConstants'

import {
  AgreementWrapper,
  ConstantsContextWrapper,
  IAMContextWrapper
} from 'context'
import { UserContextWrapper } from 'context/user'

import { Loading } from 'components'

interface IPostAuthGlobalContext {
  children: React.ReactNode
}

export const searchClient = instantMeiliSearch(
  MEILI_HOST as string,
  MEILI_KEY as string
)

export const PostAuthGlobalContext: React.FC<IPostAuthGlobalContext> = ({
  children
}) => {
  const { data: session } = useSession()

  if (session) {
    return (
      <UserContextWrapper jwtToken={session.jwt}>
        <IAMContextWrapper>
          <AgreementWrapper>
            <ConstantsContextWrapper>{children}</ConstantsContextWrapper>
          </AgreementWrapper>
        </IAMContextWrapper>
      </UserContextWrapper>
    )
  }

  return <Loading debug='PostAuthGlobalContext' />
}
export default PostAuthGlobalContext
