import { formatCurrencyAbbr } from './formatCurrencyAbbr'

type TCurrencyByFold = (value: number | string) => string
export const formatCurrencyByFold: TCurrencyByFold = (value) => {
  const num =
    typeof value === 'string' ? parseInt(value.replace(/,/g, '')) : value

  if (isNaN(num)) return value as string

  if (num >= 1000000000) {
    return formatCurrencyAbbr(num, 1000000000, ' Billion', '')
  } else if (num >= 1000000) {
    const val = formatCurrencyAbbr(num, 1000000, ' Million', '')
    return val
  } else if (num >= 1000) {
    return formatCurrencyAbbr(num, 1000, ' Thousand', '')
  } else {
    return `\$${num}`
  }
}
