/** Yes No Question Fixture
 *
 */
import type { IYesNoQuestionData } from '@unionco/alaris-app-types'

import { placeholderSmallImgFixture } from '@fixtures/media'
import { basicAnswerFixture } from '@fixtures/phase/questionnaire'

export const yesNoQuestionFixture = {
  answered: false,
  answers: [
    basicAnswerFixture(
      'Yes sub label text',
      `answer1`,
      placeholderSmallImgFixture
    ),
    basicAnswerFixture(
      'No sub label text',
      `answer2`,
      placeholderSmallImgFixture
    )
  ],
  questionType: 'yesNo',
  questionText: 'Yes/No Question?'
} as IYesNoQuestionData
