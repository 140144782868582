import { ITeamMembersModuleData } from '@unionco/alaris-app-types'

import { Divider, ModuleCard, TitleAndCopy } from 'components'

import { CollapsibleSection, ViewMoreSection } from '../components'
import DepartmentHeadCard from './DepartmentHeadCard'
import EmployeeCard from './EmployeeCard'
import FounderCard from './FounderCard'
import LeaderCard from './LeaderCard'
import NonOwnerCard from './NonOwnerCard'


export interface ITeamMembersModuleProps {
  data: ITeamMembersModuleData
}

/**
 * TeamMembersModule - module component
 *
 * @returns UI for displaying people involved with the business
 * data comes from form question types within the questionnaire
 */
export const TeamMembersModule: React.FC<ITeamMembersModuleProps> = ({
  data: {
    category1,
    category2,
    category3,
    departmentHeads,
    employees,
    founders,
    info,
    leaders,
    nonOwners,
    title,
    advisoryAUMByAdvisor
  }
}) => {
  const employeePresent = employees && employees.length > 0
  const nonOwnerPresent = nonOwners && nonOwners.length > 0
  const founderPresent = founders && founders.length > 0
  const leaderPresent = leaders && leaders.length > 0
  const departmentHeadPresent = departmentHeads && departmentHeads.length > 0
  const advisoryAUMByAdvisorPresent = advisoryAUMByAdvisor && advisoryAUMByAdvisor.advisoryAUM.length > 0


  if (
    !departmentHeadPresent &&
    !leaderPresent &&
    !employeePresent &&
    !nonOwnerPresent &&
    !founderPresent &&
    !advisoryAUMByAdvisorPresent
  )
    return <></>

  const revealContent = false
  return (
    <ModuleCard.Body>
      <ModuleCard.InfoHeader
        info={info}
        title={title || 'Team Members'}
        key='TeamMemberInfo'
        trackingName='team members module'
      />
      <Divider title={category1} key='FounderDivider' />
      <div className='u-flex u-w-full u-flex-wrap'>
        {founders &&
          founders.map((founder, index) => {
            return <div className='u-w-1/3 print:u-break-after-page' key={`FounderCard_${index}`}><FounderCard {...founder} key={`FounderCard_${index}`} /></div>
          })}
        {leaders &&
          leaders.map((leader, index) => {
            return <div className='u-w-1/3 print:u-break-inside-avoid' key={`LeaderCard${index}`}> <LeaderCard {...leader} key={`LeaderCard_${index}`} /></div>
          })}
      </div>

      {nonOwnerPresent && (
        <CollapsibleSection title={category2}>
          <div className='c-autoGrid | [--auto-grid-min-item-size:14rem]'>
            {nonOwners.map((nonOwner, index) => {
              return (
                <NonOwnerCard {...nonOwner} key={`NonOwnerCard_${index}`} />
              )
            })}
          </div>
        </CollapsibleSection>
      )}
      {departmentHeadPresent && (
        <CollapsibleSection title={category2}>
          <div className='c-autoGrid | [--auto-grid-min-item-size:14rem]'>
            {departmentHeads.map((departmentHead, index) => {
              return (
                <DepartmentHeadCard
                  {...departmentHead}
                  key={`NonOwnerCard_${index}`}
                />
              )
            })}
          </div>
        </CollapsibleSection>
      )}
      {employeePresent && (
        <div className='print:u-break-inside-avoid'>
          <CollapsibleSection title={category3}>
            <div className='c-autoGrid | [--auto-grid-min-item-size:14rem]'>
              {employees.map((employee, index) => {
                return (
                  <EmployeeCard {...employee} key={`EmployeeCard_${index}`} />
                )
              })}
            </div>
          </CollapsibleSection>
        </div>
      )}
      {revealContent && (
        <>
          <div className='o-line' />
          <ViewMoreSection
            openButtonText='Section Expand'
            closeButtonText='Section Close'
          >
            <div key='RevealContent'>Reveal Content</div>
          </ViewMoreSection>
        </>
      )}
      {advisoryAUMByAdvisorPresent && (
        <div className='c-flow'>
          <Divider title="ADVISORY AUM BY ADVISOR" />
          <div className='u-flex u-justify-center u-align-items-center'>
            <table className='u-w-full'>
              <thead>
                <tr>
                  <th className='o-h3 u-p-300 u-pl-400 u-text-center u-font-bold u-text-secondary-800'>Advisor Name</th>
                  <th className='o-h3 u-p-300 u-pl-400 u-text-center u-font-bold u-text-secondary-800'>Advisory AUM</th>
                </tr>
              </thead>
              <tbody>
                {advisoryAUMByAdvisor.advisoryAUM.map((advisor, index) => {
                  return (
                    <tr key={`Advisor_row_${advisor.advisorName}_${index}`} className='u-border-b u-border-primary-300'>
                      <td className='o-h4 u-p-300 u-pl-400 u-text-center u-font-bold u-text-gray-800'>
                        {advisor.advisorName}
                      </td>
                      <td className='u-text-4 u-text-center u-font-light u-text-secondary-900'>
                        {advisor.advisoryAUM}
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {advisoryAUMByAdvisor.paragraph?.description != '' &&
            (<TitleAndCopy
            title={advisoryAUMByAdvisor.paragraph?.title}
            copy={advisoryAUMByAdvisor.paragraph?.description}
            />)}
        </div>
      )}
    </ModuleCard.Body>
  )
}

export default TeamMembersModule
