/**
 * Page Load
 *
 * [@seandepottey]:
 * Function overloads may have been a better choice here,
 * as opposed to totally seperate functions.
 */
import {
  TPageLoadTracking,
  TQuestionPageTracking,
  TQuestionnairePageTracking
} from '@types'

import { getSessionIdFromGACookie } from '@unionco/utils'

import { tracking } from './tracking'

/**
 * TPageLoadTrackingFunctionDefaults
 *
 * Key values that are not needed when calling the function,
 * used as ommitted items in `Params` types
 */
type TPageLoadTrackingFunctionDefaults = 'event' | 'session_id'

type TPageLoadTrackingParams = Omit<
  TPageLoadTracking,
  TPageLoadTrackingFunctionDefaults
>

/**
 * Generic Page Load Tracking
 * For all non-questionnaire pages
 *
 * Auto fills the session_id
 */
export const pageLoadTracking = (data: TPageLoadTrackingParams): void => {
  tracking<TPageLoadTracking>({
    event: 'dataLayer-initialized',
    session_id: getSessionIdFromGACookie(),
    ...data
  })
}

type TTQuestionnairePageTrackingParams = Omit<
  TQuestionnairePageTracking,
  TPageLoadTrackingFunctionDefaults
>

/**
 * Questionnaire Page Load Tracking
 * For questionnaire intro and review pages
 */
export const questionnairePageLoadTracking = (
  data: TTQuestionnairePageTrackingParams
): void => {
  tracking<TQuestionnairePageTracking>({
    event: 'dataLayer-initialized',
    session_id: getSessionIdFromGACookie(),
    ...data
  })
}

type TQuestionPageTrackingParams = Omit<
  TQuestionPageTracking,
  TPageLoadTrackingFunctionDefaults
>

/**
 * Question Page Load Tracking
 * For questionnaire question pages
 */
export const questionPageLoadTracking = (
  data: TQuestionPageTrackingParams
): void => {
  tracking<TQuestionPageTracking>({
    event: 'dataLayer-initialized',
    session_id: getSessionIdFromGACookie(),
    ...data
  })
}
