import { Input } from '@unionco/components'

import { IInputValue } from '@unionco/alaris-app-types'

import { fieldIsValid } from '../utils'
import { cx } from '@unionco/utils'

import LabelAndInput from '../labelAndInput'
import { inputStyling } from '../mod'

interface IFullNameInputProps {
  firstName: IInputValue
  lastName: IInputValue
  validation: null | true | string[]
}

export const NameInputs: React.FC<IFullNameInputProps> = ({
  firstName,
  lastName,
  validation
}) => {
  const firstFieldName = 'firstName'
  const lastFieldName = 'lastName'

  return (
    <div className='c-switcher u-basis-[calc(66%-.75rem)] u-flex-nowrap [--cluster-gutter:1.75rem]'>
      <LabelAndInput label='First Name' required className='u-flex-grow'>
        <Input
          id=''
          className={cx(' u-w-full', fieldIsValid(firstFieldName, validation))}
          classNames={inputStyling}
          placeholder='First Name'
          defaultValue={firstName.value}
          name={firstFieldName}
          required
        />
      </LabelAndInput>
      <LabelAndInput label='Last Name' required className='u-flex-grow'>
        <Input
          id=''
          className={cx(' u-w-full', fieldIsValid(lastFieldName, validation))}
          classNames={inputStyling}
          placeholder='Last Name'
          defaultValue={lastName.value}
          name='lastName'
          required
        />
      </LabelAndInput>
    </div>
  )
}

export default NameInputs
