/**
 * Dropdown tracking util function
 */
import { TDropdownToggleTracking } from '@types'

import { tracking } from './tracking'

type TDropdownToggleTrackingParams = Omit<TDropdownToggleTracking, 'event'>

export const dropdownToggleTracking = (
  data: TDropdownToggleTrackingParams
): void => {
  tracking<TDropdownToggleTracking>({
    event: 'dropdown_toggle',
    ...data
  })
}
