/** Range Question
 *  An a array of range inputs utilizing a custom styled range input
 */
import { StyledRange } from '@unionco/components'
import { InfoIcon } from '@unionco/svg-icons'
import { useContext, useEffect, useMemo, useState } from 'react'
import tw from 'twin.macro'

import { TAnswerTrackingMap } from '@types'
import type {
  IQuestionSegmentData,
  IQuestionnaireData,
  IRangeQuestionData
} from '@unionco/alaris-app-types'

import { debounce } from '@unionco/utils'
import {
  getCurrentQuestionData,
  submissionSuccessful
} from 'components/questionnaire/util'
import { getCurrentSegmentData } from 'utils'
import { isAdminTracking } from 'utils/tracking'
import { questionSubmitTracking } from 'utils/tracking/questionnaire'

import { TUserContext, UserContext } from 'context'

import { SidebarContext } from 'components'

import { QuestionContext } from '../context'
import TickLine from './tick'

interface IRangeQuestionProps {
  data: IRangeQuestionData
}

export const rangeDefaultMaxValue = 100
export const rangeDefaultValue = 50

export const RangeQuestion: React.FC<IRangeQuestionProps> = ({
  data: {
    answers,
    quadBottomMidText,
    quadBottomText,
    quadTopMidText,
    quadTopText,
    quadrantTitle,
    selectedAnswer
  }
}) => {
  const { userType } = useContext(UserContext) as TUserContext
  const { submitAnswer } = useContext(QuestionContext)
  const { data, pageLocations } = useContext(SidebarContext)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [values, setValues] = useState<number[]>([rangeDefaultValue])

  /**
   * Range Questions update values
   * wrapped inside of debounced handler
   * using useMemo to wrap a debounce
   */
  const debouncedHandler = useMemo(
    () =>
      debounce(async (index: number, value: number) => {
        const newValues = [...values]
        newValues[index] = value
        setValues(newValues)

        const newState = { ...data } as IQuestionnaireData
        const currentSegment = getCurrentSegmentData(
          pageLocations,
          newState
        ) as IQuestionSegmentData
        const currentQuestion = getCurrentQuestionData(
          pageLocations,
          newState
        ) as IRangeQuestionData
        currentQuestion.selectedAnswer = newValues
        newState.segments[pageLocations['segment']] = currentSegment

        const result = await submitAnswer(true, newState, pageLocations)

        if (submissionSuccessful(result)) {
          setSubmitted(true)
        }
      }, 500),
    [data, pageLocations, submitAnswer, values]
  )

  /** Set default values Effect
   *  Set's the default values of the ranges to the number of answers
   *  if selectedAnswers aren't filled
   */
  useEffect(() => {
    if (selectedAnswer) {
      setValues(selectedAnswer)
    } else {
      setValues(Array(answers.length).fill(50))
    }
  }, [selectedAnswer, answers.length])

  const debouncedTrackingHandler = useMemo(
    () =>
      debounce((values: number[]) => {
        const isAdmin = isAdminTracking(userType)
        const answerTracking: TAnswerTrackingMap = {}
        values.forEach((value, index) => {
          answerTracking[`answer_${index}`] = `${value}`
        })
        questionSubmitTracking({
          is_admin_key: isAdmin,
          ...answerTracking
        })
        setSubmitted(false)
      }, 2000),
    [userType]
  )

  /**
   * Range Question Tracking submission
   * Uses values as a dependency and checks if Submitted is true.
   * Submitted state is used to prevent the initial load from triggering
   * a tracking submission and instead waits until the user interacts.
   * Then uses a long debounced time so that it doesn't over submit.
   *
   */
  useEffect(() => {
    if (!submitted) return
    debouncedTrackingHandler(values)
  }, [debouncedTrackingHandler, submitted, values])

  return (
    <div className='c-flow'>
      <div className='c-cluster | flex-no-wrap'>
        <div className='u-min-w-[30.9%] u-basis-[30%]'>
          <p className='u-text-left u-font-bold u-text-grayscale-800'>
            {quadrantTitle}
          </p>
        </div>
        <div className='u-flex u-flex-grow'>
          <p className='u-w-[25%]'>{quadBottomText}</p>
          <p className='u-w-[25%]'>{quadBottomMidText}</p>
          <p className='u-w-[25%]'>{quadTopMidText}</p>
          <p className='u-w-[25%]'>{quadTopText}</p>
        </div>
      </div>
      <form>
        {answers.map((answer, index) => {
          const { label } = answer
          const bgColor = index % 2 ? '' : 'u-bg-primary-300'

          return answer.isActive ? (
            <div
              className={`c-cluster | u-px-400 u-py-500 ${bgColor}`}
              key={`${answer.label}_${index}`}
            >
              <div className='c-repel | u-min-w-[30%] u-basis-[30%] u-flex-nowrap u-text-left'>
                <label>{label}</label>
                <InfoIcon
                  className='u-min-w-[.75rem] u-text-secondary-800'
                  iconStyle='outline'
                  cssClasses={tw`u-text-secondary-800`}
                />
              </div>
              <div className='c-withLayer | u-grow'>
                <div className='c-layer'>
                  <TickLine left={25} />
                  <TickLine left={50} />
                  <TickLine left={75} />
                </div>
                <div className='u-flex u-w-full u-items-center'>
                  <StyledRange
                    defaultValue={values[index]}
                    index={index}
                    onUpdateCallback={debouncedHandler}
                    cssClasses={{
                      track: tw`u-bg-secondary-300`,
                      trackActive: tw`u-bg-primary-500`,
                      thumb: { wrapper: tw`u-bg-primary-500` }
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )
        })}
      </form>
    </div>
  )
}

export default RangeQuestion
