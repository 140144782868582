import { Accordion, Card } from '@unionco/components'
import { useEffect, useState } from 'react'

import { IContractorFormData } from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

import EntryControls from './EntryControls'
import EntryTitle from './EntryTitle'
import { Dropdown, NameInputs, NumericFieldInput, YesNoSelect } from './inputs'

const ageOptions = [
  {
    key: 0,
    value: 'unanswered',
    label: 'Age Range'
  },
  {
    key: 1,
    value: '18-29',
    label: '18 - 29'
  },
  {
    key: 2,
    value: '30-39',
    label: '30 - 39'
  },
  {
    key: 3,
    value: '40-49',
    label: '40 - 49'
  },
  {
    key: 4,
    value: '50-59',
    label: '50 - 59'
  },
  {
    key: 5,
    value: '60-69',
    label: '60 - 69'
  },
  {
    key: 6,
    value: '70-79',
    label: '70 - 79'
  },
  {
    key: 7,
    value: '80-89',
    label: '80 - 89'
  },
  {
    key: 8,
    value: '90+',
    label: '90+'
  }
]

const investmentOptions = [
  {
    key: 0,
    value: 'unanswered',
    label: 'Select an option'
  },
  {
    key: 1,
    value: 'manageOwn',
    label: 'They manage their own investments'
  },
  {
    key: 2,
    value: 'centralStrategies',
    label: 'They use our central investment strategies'
  },
  {
    key: 3,
    value: 'combination',
    label: 'A combination'
  }
]

interface IContractorFormProps {
  data: IContractorFormData
  entriesTitle: string
  index: number
  deleteEntry: (i: number) => void
  saveEntry: (i: number) => Promise<null | true | string[]>
  saved: boolean
}

export const ContractorForm: React.FC<IContractorFormProps> = ({
  data: {
    age,
    brokerageAumManaged,
    feeAumManaged,
    firstName,
    grossRevenue,
    hasComplianceDisclosures,
    hasStaff,
    insuranceAumManaged,
    investmentManagementStyle,
    isJoinNewPartnership,
    lastName,
    payoutRate
  },
  deleteEntry,
  entriesTitle,
  index: i,
  saveEntry,
  saved
}) => {
  const [validation, setValidation] = useState<null | true | string[]>(null)
  const [entryTitle, setEntryTitle] = useState<string>(entriesTitle)

  /**
   * Entry title name use effect
   * Sets entry title to name of
   */
  useEffect(() => {
    if ((firstName && firstName.value) || (lastName && lastName.value)) {
      const name = cx(firstName.value, lastName.value)
      setEntryTitle(name)
    }
  }, [firstName, lastName, setEntryTitle])

  const updateValidation = async () => {
    const result = await saveEntry(i)
    setValidation(result)
  }

  const fullName =
    firstName.value && lastName.value
      ? `${firstName.value} ${lastName.value}`
      : 'this contractor'
  return (
    <Card className='b-Entry u-p-500'>
      <Accordion.Item index={i}>
        <EntryTitle entryTitle={entryTitle} index={i} saved={saved} />
        <Accordion.Drawer index={i} className='c-flow u-flow-space--500'>
          <div className='c-cluster u-mt-500 u-justify-between'>
            <div className='c-flow u-flex-grow'>
              <div className='u-flex u-justify-between'>
                <NameInputs
                  firstName={firstName}
                  lastName={lastName}
                  validation={validation}
                />
                <Dropdown
                  options={ageOptions}
                  defaultValue={age.value}
                  validation={validation}
                  label='Age'
                  name='age'
                />
              </div>
              <div className='c-switcher u-w-full'>
                <NumericFieldInput
                  defaultValue={feeAumManaged.value}
                  placeholder='$1,000,000'
                  label={`How much fee-based RIA AUM does ${fullName} manage?`}
                  name='feeAumManaged'
                  validation={validation}
                  required={true}
                />
                <NumericFieldInput
                  defaultValue={brokerageAumManaged.value}
                  placeholder='$1,000,000'
                  label={`How much brokerage AUM does ${fullName} manage?`}
                  name='brokerageAumManaged'
                  validation={validation}
                  required={true}
                />
              </div>
              <div className='c-switcher u-w-full'>
                <NumericFieldInput
                  defaultValue={insuranceAumManaged.value}
                  placeholder='$1,000,000'
                  label={`How much insurance AUM does ${fullName} manage`}
                  name='insuranceAumManaged'
                  validation={validation}
                  required={true}
                />
                <NumericFieldInput
                  defaultValue={payoutRate.value}
                  placeholder='20%'
                  label={`What percent revenue do you pay ${fullName}? (What is their payout rate?)`}
                  name='payoutRate'
                  validation={validation}
                  required={true}
                />
              </div>
              <div className='c-switcher u-w-full'>
                <YesNoSelect
                  defaultValue={hasStaff.value}
                  validation={validation}
                  label={`Does ${fullName} have their own staff?`}
                  name='hasStaff'
                />
              </div>
              <div className='c-switcher u-w-full'>
                <NumericFieldInput
                  defaultValue={grossRevenue.value}
                  placeholder='$1,000,000'
                  label={`What is ${fullName}’s gross revenue?`}
                  name='grossRevenue'
                  validation={validation}
                  required={true}
                />
              </div>
              <div className='c-switcher u-justify-between'>
                <div className='u-flex-grow'>
                  <YesNoSelect
                    defaultValue={hasComplianceDisclosures.value}
                    validation={validation}
                    label={`Does ${fullName} have any compliance disclosures?`}
                    name='hasComplianceDisclosures'
                  />
                </div>
                <div className='u-flex-grow'>
                  <YesNoSelect
                    defaultValue={isJoinNewPartnership.value}
                    validation={validation}
                    label={`Would ${fullName} wish to join you if you partner with another firm?`}
                    name='isJoinNewPartnership'
                  />
                </div>
                <div className='u-flex-grow'>
                  <Dropdown
                    options={investmentOptions}
                    defaultValue={investmentManagementStyle.value}
                    validation={validation}
                    label={`Does ${fullName} manage their own investments or leverage your central strategies?`}
                    name='investmentManagementStyle'
                  />
                </div>
              </div>
            </div>
          </div>
          <EntryControls
            index={i}
            deleteEntry={deleteEntry}
            saveEntry={updateValidation}
          />
        </Accordion.Drawer>
      </Accordion.Item>
    </Card>
  )
}

export default ContractorForm
