import { useContext } from 'react'

import { CustomSearchBox, TQueryHook } from 'components/Instantsearch'

import { SearchQuestionContext } from './context'

interface IStyledSearchBox {
  allowManualEntry?: boolean
  className: string
  placeholder: string
  queryHook?: TQueryHook
}

export const StyledSearchBox: React.FC<IStyledSearchBox> = ({
  allowManualEntry,
  className,
  placeholder,
  queryHook
}) => {
  const rounding = allowManualEntry ? 'u-rounded-l' : 'u-rounded'
  const { searchValue } = useContext(SearchQuestionContext)
  const wrapperStyles = `u-flex u-items-center u-border u-border-primary-300 u-px-4 u-py-2 u-text-5 ${rounding}`
  const formStyles = '[&_form]:u-flex [&_form]:u-w-full [&_form]:u-items-center'
  const buttonControlStyles =
    '[&_button]:u-h-4 [&_button]:u-w-4 [&_button]:aspect-sqaure'
  const searchInputStyles =
    '[&_input]:u-flex-grow [&_input[type="search"]::-webkit-search-decoration]:u-hidden [&input[type="search"]::-webkit-search-cancel-button]:u-hidden [&_input[type="search"]::placeholder]:u-font-bold'
  return (
    <CustomSearchBox
      className={`${wrapperStyles} ${formStyles} ${buttonControlStyles} ${searchInputStyles} ${className}`}
      placeholder={placeholder}
      queryHook={queryHook}
      searchValue={searchValue}
    />
  )
}

export default StyledSearchBox
