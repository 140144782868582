/** Segment Status Indicator
 * Use in wayfinder and questionnaire review components
 * Indicates status of segment
 * @see https://css-tricks.com/building-progress-ring-quickly/
 */
import { CheckMarkIcon } from '@unionco/svg-icons'

import {
  ISectionStatus,
  TBgTokenClass,
  TBorderTokenClass,
  TSectionStatusText
} from '@unionco/alaris-app-types'

import { StatusRing } from 'components'

import { styleBorderGlow } from 'styles'

interface ISegmentStatusIndicatorProps {
  bgColor: TBgTokenClass
  borderColor: TBorderTokenClass
  isActive?: boolean
  status: ISectionStatus
  transparentBorder?: boolean
  useStatusRing?: boolean // Set to true for segments that are multi question
}

export const SegmentStatusIndicator: React.FC<ISegmentStatusIndicatorProps> = ({
  bgColor,
  borderColor,
  isActive,
  status,
  useStatusRing = false
}) => {
  const statusClasses = (status: TSectionStatusText) => {
    const borderSize = 'u-border-2'
    switch (status) {
      case 'notStarted':
        return `u-bg-transparent ${borderSize} ${borderColor}`
      case 'incomplete':
        return `${bgColor} ${borderSize} ${borderColor}`
      case 'inprogress':
        return `u-bg-secondary-300 ${borderSize} ${borderColor}`
      case 'complete':
        return `u-bg-primary-500 ${borderSize} u-border-primary-500`
      default:
        return <></>
    }
  }

  const { percent, statusText } = status
  const useRing = useStatusRing && statusText !== 'complete'
  const dropshadow = isActive ? styleBorderGlow : ''

  return (
    <div
      className='b-SegmentStatusIndicator | u-aspect-square u-h-5 u-rounded-full'
      data-status={statusText}
      style={{
        filter: dropshadow
      }}
    >
      <div className={`c-withLayer | u-h-full u-w-full`}>
        <div
          className={`u-h-full u-w-full u-rounded-full ${statusClasses(
            statusText
          )}`}
        />
        {useRing && <StatusRing layer percent={percent} />}
        {statusText === 'complete' && (
          <div className='c-layer u-flex u-items-center u-justify-center u-text-white'>
            <CheckMarkIcon className={'u-h-1/2 u-w-1/2'} />
          </div>
        )}
      </div>
    </div>
  )
}

export default SegmentStatusIndicator
