import React from 'react'

import { IEmployeeData } from '@unionco/alaris-app-types'

import { displayNumberWithCommas } from '@unionco/alaris-utils'

import { Img } from 'components/generic'

import { TeamMemberHeader } from '../TeamMemberHeader'

export const EmployeeCard: React.FC<IEmployeeData> = ({
  age,
  baseSalary,
  firstName,
  jobDescription,
  lastName,
  profilePic,
  title
}) => {
  const validImg =
    profilePic && profilePic && profilePic.url && profilePic.url !== ''

  const stats = [
    { displayText: 'Role in the Firm', value: jobDescription },
    {
      displayText: 'Total Compensation',
      value: baseSalary && `$${displayNumberWithCommas(baseSalary)}`
    }
  ]
  return (
    <div className='c-cluster[--cluster-vertical-alignment:flex-start]'>
      <div className=''>
        <TeamMemberHeader
          age={age}
          firstName={firstName}
          lastName={lastName}
          title={title}
        />
        {stats.map(({ displayText, value }, index) => {
          if (!value || value === '') {
            return <React.Fragment key={`InvalidStat_${index}`} />
          }

          return (
            <p className='u-text-2' key={`StatItem_${index}`}>
              <span className='u-font-bold'>{displayText}: </span>
              <span>{value}</span>
            </p>
          )
        })}
      </div>
    </div>
  )
}

export default EmployeeCard
