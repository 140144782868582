import { Input } from '@unionco/components'

import { fieldIsValid } from '../utils'
import { cx } from '@unionco/utils'

import LabelAndInput from '../labelAndInput'
import { inputStyling } from '../mod'

interface INumericFieldProps {
  defaultValue: string
  label: string
  placeholder: string
  name: string
  validation: null | true | string[]
  required: boolean
}

export const NumericFieldInput: React.FC<INumericFieldProps> = ({
  defaultValue,
  label,
  name,
  placeholder,
  validation,
  required
}) => {
  return (
    <LabelAndInput label={label} required={required}>
      <Input
        id=''
        className={cx('u-w-full', fieldIsValid(name, validation))}
        classNames={inputStyling}
        defaultValue={defaultValue}
        placeholder={placeholder}
        step='1'
        name={name}
        type='number'
        required={required}
      />
    </LabelAndInput>
  )
}

export default NumericFieldInput
