import axios from 'axios'
import { createContext } from 'react'

import { TQuestionData } from '@unionco/alaris-app-types'

import { TSubmitAnswer } from '.'

interface IQuestionContext {
  currentQuestion: TQuestionData
  submitAnswer: TSubmitAnswer
}

const initQuestionContext: IQuestionContext = {
  currentQuestion: {
    answers: [
      {
        value: '',
        label: '',
        inputType: 'shortText'
      }
    ],
    answered: false,
    questionText: '',
    questionSubText: '',
    questionType: 'freeResponse',
    key: '',
    adminNotes: '',
    isCompanyDataQuestion: false,
    isActive: false,
    branchTriggers: '',
    triggerQuestionType: '',
    podTriggers: '',
    index: 0,
    impersonationTiedQuestionSummary: '',
    faqGroupKey: ''
  },
  submitAnswer: () => {
    return axios.post('')
  }
}

export const QuestionContext =
  createContext<IQuestionContext>(initQuestionContext)
