import { AccordionContext } from '@unionco/components'
import { useContext } from 'react'

import { CollapseButton, ModuleCard } from 'components'

export interface ICollapsibleItemsCardHeader {
  numOfItems: number
  title: string
}

export type TCollapsibleItemsCardHeaderComponent =
  React.FC<ICollapsibleItemsCardHeader>

export const CollapsibleItemsCardHeader: TCollapsibleItemsCardHeaderComponent =
  ({ numOfItems, title }) => {
    const { closeAllItems, openAllItems } = useContext(AccordionContext)
    return (
      <ModuleCard.Header title={title} classNames={{ rightSide: 'c-cluster' }}>
        <CollapseButton
          onClick={() => {
            return openAllItems(numOfItems)
          }}
        >
          Expand All
        </CollapseButton>
        <CollapseButton
          onClick={() => {
            return closeAllItems()
          }}
        >
          Collapse All
        </CollapseButton>
      </ModuleCard.Header>
    )
  }

export default CollapsibleItemsCardHeader
