import {
  IContractorFormData,
  IDepartmentHeadFormData,
  ILeaderFormData,
  IBasicTeamFormData,
  INonOwnerFormData,
  IOwnerFormData,
  ITeamMemberFormData,
  TFormAnswerData,
  TFormQuestionTypes
} from '@unionco/alaris-app-types'

import {
  contractorFields,
  departmentHeadFields,
  leaderFields,
  nonOwnerFields,
  ownerFields,
  teamMemberFields,
  basicTeamFields
} from './formFixtures'

export const fieldIsValid = (
  name: string,
  validation: null | true | string[]
) => {
  if (!validation || validation === true) return null
  if (validation.includes(name)) return 'u-border-state-danger u-border'
  return null
}

export const generateFormDefaults = (
  questionType: TFormQuestionTypes
): TFormAnswerData => {
  switch (questionType) {
    case 'contractorForm':
      return contractorFields
    case 'departmentHeadForm':
      return {
        ...departmentHeadFields,
        connectedField: 'departmentHeads'
      }
    case 'basicTeamForm':
      return { ...basicTeamFields, connectedField: '' }
    case 'leaderForm':
      return { ...leaderFields, connectedField: 'leaders' }
    case 'nonOwnerForm':
      return { ...nonOwnerFields, connectedField: 'nonOwners' }
    case 'ownerForm':
      return { ...ownerFields, connectedField: 'owners' }
    /**
     * case 'teamMember':
     * default returns teamMember form
     */
    default:
      return { ...teamMemberFields, connectedField: 'employees' }
  }
}

const getFormValue = (entry: Element, name: string) => {
  const input: HTMLFormElement = entry.querySelector(
    `[name=${name}]`
  ) as HTMLFormElement
  return input ? input.value : ''
}

const getProfilePicUrl = (imageUrl: string) => {
  if (!imageUrl) return ''
  const searchParams = new URLSearchParams(
    imageUrl.substring(imageUrl.indexOf('?') + 1)
  )
  const urlParam = searchParams.get('url') ?? ''
  const decodedUrl = decodeURIComponent(urlParam)
  return decodedUrl
}

const getConnectedField = (questionType: TFormQuestionTypes) => {
  switch (questionType) {
    case 'departmentHeadForm':
      return 'departmentHeads'
    case 'contractorForm':
      return 'contractors'
    case 'nonOwnerForm':
      return 'nonOwners'
    case 'leaderForm':
      return 'leaders'
    case 'teamMember':
      return 'employees'
    case 'ownerForm':
      return 'founders'
    default:
      return ''
  }
}

export const getFormValues = (
  entry: Element,
  questionType: TFormQuestionTypes,
  answers: TFormAnswerData[]
): TFormAnswerData => {
  const imgElement: HTMLImageElement =
    (entry.querySelector('.b-ProfilePic') as HTMLImageElement) || ''

  // This is a workaround to capture the media ID and send it to be saved with company data
  const mediaId = imgElement.alt
  const url = getProfilePicUrl(imgElement.src)

  const commonFieldsData = {
    firstName: { value: getFormValue(entry, 'firstName') },
    lastName: { value: getFormValue(entry, 'lastName') },
    connectedField:
      answers.length === 0
        ? getConnectedField(questionType)
        : answers[0].connectedField,
    profilePic: { value: { image: mediaId, value: url } }
  }

  switch (questionType) {
    case 'contractorForm':
      return {
        ...commonFieldsData,
        age: { value: getFormValue(entry, 'age') },
        feeAumManaged: { value: getFormValue(entry, 'feeAumManaged') },
        brokerageAumManaged: {
          value: getFormValue(entry, 'brokerageAumManaged')
        },
        insuranceAumManaged: {
          value: getFormValue(entry, 'insuranceAumManaged')
        },
        payoutRate: { value: getFormValue(entry, 'payoutRate') },
        hasStaff: { value: getFormValue(entry, 'hasStaff') },
        grossRevenue: { value: getFormValue(entry, 'grossRevenue') },
        hasComplianceDisclosures: {
          value: getFormValue(entry, 'hasComplianceDisclosures')
        },
        isJoinNewPartnership: {
          value: getFormValue(entry, 'isJoinNewPartnership')
        },
        investmentManagementStyle: {
          value: getFormValue(entry, 'investmentManagementStyle')
        }
      } as IContractorFormData
    case 'departmentHeadForm':
      return {
        ...commonFieldsData,
        title: { value: getFormValue(entry, 'title') },
        jobDescription: { value: getFormValue(entry, 'jobDescription') }
      } as IDepartmentHeadFormData
    case 'basicTeamForm':
      return {
        ...commonFieldsData,
        title: { value: getFormValue(entry, 'title') },
        jobDescription: { value: getFormValue(entry, 'jobDescription') }
      } as IBasicTeamFormData
    case 'leaderForm':
      return {
        ...commonFieldsData,
        title: { value: getFormValue(entry, 'title') },
        biography: { value: getFormValue(entry, 'biography') }
      } as ILeaderFormData
    case 'nonOwnerForm':
      return {
        ...commonFieldsData,
        age: { value: getFormValue(entry, 'age') },
        title: { value: getFormValue(entry, 'title') },
        baseSalary: { value: getFormValue(entry, 'baseSalary') },
        jobDescription: { value: getFormValue(entry, 'jobDescription') },
        aumManaged: { value: getFormValue(entry, 'aumManaged') },
        revenueManaged: { value: getFormValue(entry, 'revenueManaged') }
      } as INonOwnerFormData
    case 'ownerForm':
      return {
        ...commonFieldsData,
        age: { value: getFormValue(entry, 'age') },
        title: { value: getFormValue(entry, 'title') },
        baseSalary: { value: getFormValue(entry, 'baseSalary') },
        ownership: { value: getFormValue(entry, 'ownership') },
        jobDescription: { value: getFormValue(entry, 'jobDescription') },
        // transactionGoal is being set to a null value here
        // because transactionGoals cannot be parsed from HTMLElement method
        // instead the values are being set in SaveEntry()
        transactionGoal: null,
        involvedInBusiness: {
          value: getFormValue(entry, 'involvedInBusiness')
        },
        glidepath: {
          value: getFormValue(entry, 'glidepath')
        },
        aumManaged: {
          value: getFormValue(entry, 'aumManaged')
        },
        hhManaged: {
          value: getFormValue(entry, 'hhManaged')
        },
        revenueManaged: {
          value: getFormValue(entry, 'revenueManaged')
        },
        additionalNotes: { value: getFormValue(entry, 'additionalNotes') }
      } as IOwnerFormData
    case 'teamMember':
      return {
        ...commonFieldsData,
        age: { value: getFormValue(entry, 'age') },
        title: { value: getFormValue(entry, 'title') },
        jobDescription: { value: getFormValue(entry, 'jobDescription') },
        baseSalary: { value: getFormValue(entry, 'baseSalary') }
      } as ITeamMemberFormData

    default:
      throw new Error(`Invalid questionType: ${questionType}`)
  }
}
