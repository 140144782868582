/** FAQ Atoms
 *
 */
import { v4 as uuidv4 } from 'uuid'

import type { IVideoData } from '@unionco/alaris-app-types'

import { titleAndCopyTestData } from '@fixtures/generic'
import { testVideoLink } from '@fixtures/media'

export const faqQ1TestData = titleAndCopyTestData(
  'Question 1',
  'Answer copy to question 1'
)
export const faqQ2TestData = titleAndCopyTestData(
  'Question 2',
  'Answer copy to question 2'
)
export const faqQ3TestData = titleAndCopyTestData(
  'Question 3',
  'Answer copy to question 3'
)

export const faqQuestionsFixture = [faqQ1TestData, faqQ2TestData, faqQ3TestData]

export const faqVideoTestData = {
  src: testVideoLink,
  runTime: 124,
  title: 'Getting Started',
  moduleType: 'video',
  key: uuidv4()
} as IVideoData

export const cardItems = [faqVideoTestData, ...faqQuestionsFixture]
