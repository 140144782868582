/** Video Block
 *  Displays a video horizontally next to a title and copy
 *  The video block for Alaris will display a runtime and
 *  video title underneath.
 */
import type { IVideoBlockData } from '@unionco/alaris-app-types'

import TitleAndCopy from '../TitleAndCopy'
import Video from '../Video'

interface IVideoBlockProps {
  data: IVideoBlockData
}

export const VideoBlock: React.FC<IVideoBlockProps> = ({
  data: { video, ...data }
}) => {
  return (
    <div className='u-flex'>
      <Video data={video} className='u-mr-600' />
      <TitleAndCopy {...data} />
    </div>
  )
}

export default VideoBlock
