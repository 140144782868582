import markerSDK from '@marker.io/browser'
import { useEffect } from 'react'

interface IMarkerIOProps {
  children: React.ReactNode
  prodHost: string
  projectId: string | null
}

export const MarkerIO: React.FC<IMarkerIOProps> = ({
  children,
  prodHost,
  projectId
}) => {
  useEffect(() => {
    if (window.location.hostname !== prodHost && projectId) {
      markerSDK.loadWidget({
        project: projectId
      })
    }
  }, [prodHost, projectId])

  return <>{children}</>
}
