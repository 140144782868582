/** Client Profiles Test Data
 *  Test data for the <ClientProfiles /> module
 *  used in the profile template
 *  A seller only main module
 */
import { v4 as uuidv4 } from 'uuid'

import type { IClientProfileModuleData } from '@unionco/alaris-app-types'

import { paragraphTestData, shortParagraphFixture } from '@fixtures/generic'

export const clientProfileModuleTestData = {
  moduleType: 'ClientProfiles',
  title: 'Client Profile',
  items: [
    {
      title: 'Title',
      value: paragraphTestData(),
      key: uuidv4()
    }
  ],
  key: uuidv4(),
  container: 1,
  info: {
    copy: shortParagraphFixture
  }
} as IClientProfileModuleData
