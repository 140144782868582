import Link from 'next/link'
import { useContext, useEffect, useRef, useState } from 'react'

import { IPostAuthGlobalData, TImageProps } from '@unionco/alaris-app-types'

import { DashboardPageSlug, SupportPageSlug } from 'appConstants'

import { isAdminTracking } from 'utils/tracking'
import { helperTracking } from 'utils/tracking/help'

import { ConstantsContext, TUserContext, UserContext } from 'context'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { BackButton, Img, Logo } from 'components'
import { useRouter } from 'next/router'

import WayfinderAccordion from './wayfinderAccordion'

export const Wayfinder: React.FC = ({ }) => {
  const { timelineImage } = useContext(ConstantsContext) as IPostAuthGlobalData
  const wayFinderTopRef = useRef<HTMLDivElement | null>(null)
  const wayfinderBottomRef = useRef<HTMLDivElement | null>(null)
  const [wayfinderAccordionMaxHeight, setWayfinderAccordionMaxHeight] =
    useState(0)
  const { navTrackingOnClick } = useNavigationClickTracking()

  const getWayfinderAccordionMaxHeight = () => {
    if (!wayFinderTopRef || !wayfinderBottomRef) return
    if (!wayFinderTopRef.current || !wayfinderBottomRef.current) return

    const topHeight = wayFinderTopRef.current.clientHeight
    const bottomHeight = wayfinderBottomRef.current.clientHeight
    setWayfinderAccordionMaxHeight(topHeight + bottomHeight)
  }

  useEffect(() => {
    getWayfinderAccordionMaxHeight()
  }, [wayfinderAccordionMaxHeight])

  useEffect(() => {
    window.addEventListener('resize', getWayfinderAccordionMaxHeight)
  })

  const image = {
    src: timelineImage,
    width: 250,
    height: 250
  } as TImageProps
  const { userType } = useContext(UserContext) as TUserContext

  function trackingOnClick() {
    helperTracking({
      click_type: 'going to support page',
      is_admin_key: isAdminTracking(userType)
    })
  }

  //Check if the notification question is present
  const router = useRouter()
  let notificationQuestion = false
  if (router.asPath.includes('notification=true')) {
    notificationQuestion = true
  }

  if (!notificationQuestion) {
    return (
      <div
        id='Wayfinder'
        className={
          'b-wayfinder | u-relative u-min-h-screen  u-w-full u-bg-black u-text-white'
        }
      >
        <div className='u-sticky u-top-0 u-h-screen u-w-full'>
          <div className='u-width-full u-flex u-min-h-[100%] u-flex-col u-justify-between'>
            <div>
              <div ref={wayFinderTopRef} className='c-flow | u-px-4'>
                <div
                  className={'c-flow | u-items-center u-justify-center u-pt-700'}
                >
                  <Logo className='u-max-w-[7.25rem]' />
                  {/* TODO: Replace bg color div with image */}
                  {image && (
                    <div className='u-aspect-square u-w-[4.375rem]'>
                      <Img
                        {...image}
                        className='u-h-full u-w-full u-rounded-full u-bg-primary-600'
                      />
                    </div>
                  )}
                </div>
                <BackButton
                  className={'u-py-3 u-text-2 u-uppercase'}
                  content={'Dashboard'}
                />
              </div>
              <div
                className='u-border-bottom u-overflow-y-auto u-overflow-x-hidden u-border-white'
                style={{
                  maxHeight: `calc(100vh - ${wayfinderAccordionMaxHeight}px)`
                }}
              >
                <WayfinderAccordion />
              </div>
            </div>
            <div
              ref={wayfinderBottomRef}
              className='c-flow | u-flow-space--100 u-pb-4'
            >
              <Link
                className='u-px-5 u-py-2 u-text-white'
                href={DashboardPageSlug}
                onClick={() => navTrackingOnClick('Your Timeline', 'sidebar')}
              >
                Your Timeline
              </Link>
              <Link
                className='u-px-5 u-py-2 u-text-white'
                href={SupportPageSlug}
                onClick={trackingOnClick}
              >
                Get Help
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Wayfinder
