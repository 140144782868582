import { StatusText } from '../../constants'

export const setSectionStatusText = (
  numOfSections: number,
  numComplete: number,
  numStartedSegments?: number
) => {
  switch (numComplete) {
    case 0:
      if (numStartedSegments === 0) return StatusText.NOT_STARTED.value
      else return StatusText.INCOMPLETE.value
    case numOfSections:
      return StatusText.COMPLETE.value
    default:
      return StatusText.INCOMPLETE.value
  }
}
