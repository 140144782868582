/** Client Logo Marquee - Query Component
 *  An image marquee that is feed client logos
 *  Used within the authentication template
 */
import { useContext } from 'react'

import { PreAuthGlobalsContext, clientLogoSize } from 'context/PreAuthGlobals'

import { ImageMarquee } from 'components'

export const ClientLogoMarquee: React.FC = ({}) => {
  const { logos } = useContext(PreAuthGlobalsContext)

  if (!logos)
    return (
      <div>
        <div style={{ ...clientLogoSize }} />
      </div>
    )

  return <ImageMarquee id='clientLogoMarquee' images={logos} />
}

export default ClientLogoMarquee
