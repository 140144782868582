import { IRiskFactorsData } from '@unionco/alaris-app-types'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { ModuleCard, TitledList } from 'components'

interface IRiskFactorsProps {
  data: IRiskFactorsData
}

export const RiskFactors: React.FC<IRiskFactorsProps> = ({ data }) => {
  const { navTrackingOnClick } = useNavigationClickTracking()
  const trackingName = 'risk factors module'

  const { info, title } = data
  if (!data.items || data.items.length === 0) return <></>
  return (
    <ModuleCard.Body className= 'u-text-left print:u-mt-0 print:u-break-inside-avoid-page'>
      <ModuleCard.InfoHeader
        info={info}
        title={title || 'Key Considerations'}
        trackingName={trackingName}
        className='print:u-break-after-avoid print:u-mt-2'
      />
      <TitledList
        {...data}
        infoRevealCallback={() =>
          navTrackingOnClick('info tooltip', trackingName)
        }
      />
    </ModuleCard.Body>
  )
}

export default RiskFactors
