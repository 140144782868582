import type { Hit } from 'instantsearch.js'
import React from 'react'
import type { HitsClassNames } from 'react-instantsearch-hooks-web/dist/es/ui/Hits'
import { v4 as uuidv4 } from 'uuid'

import type { TDivProps } from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'
import type { SendEventForHits } from 'instantsearch.js/es/lib/utils'

export type DropdownHitsProps<THit> = TDivProps & {
  hits: THit[]
  sendEvent: SendEventForHits
  hitComponent?: React.JSXElementConstructor<{
    hit: THit
    sendEvent: SendEventForHits
  }>
  noHitsComponent: React.ReactNode
  classNames?: Partial<HitsClassNames>
}

function DefaultDropdownHitComponent({ hit }: { hit: Hit }) {
  return (
    <div style={{ wordBreak: 'break-all' }}>
      {JSON.stringify(hit).slice(0, 100)}…
    </div>
  )
}

export type DropdownHitsClassNames = {
  /**
   * Class names to apply to the root element
   */
  root: string
  /**
   * Class names to apply to the root element without results
   */
  emptyRoot: string
  /**
   * Class names to apply to the list element
   */
  list: string
  /**
   * Class names to apply to each item element
   */
  item: string
}

export function DropdownHitsUI<THit extends Hit>({
  classNames = {},
  hitComponent: HitComponent = DefaultDropdownHitComponent,
  hits,
  noHitsComponent,
  sendEvent,
  ...props
}: DropdownHitsProps<THit>) {
  const dropdownContent =
    hits.length > 0 ? (
      <ol className={cx('ais-Hits-list', classNames.list)}>
        {hits.map((hit) => (
          <li
            key={`hit_${hit.objectID || uuidv4()}`}
            className={cx('ais-Hits-item', classNames.item)}
            onClick={() => {
              sendEvent('click:internal', hit, 'Hit Clicked')
            }}
            onAuxClick={() => {
              sendEvent('click:internal', hit, 'Hit Clicked')
            }}
          >
            <HitComponent hit={hit} sendEvent={sendEvent} />
          </li>
        ))}
      </ol>
    ) : (
      noHitsComponent
    )
  return (
    <div
      {...props}
      className={cx(
        'ais-Hits',
        classNames.root,
        hits.length === 0 && cx('ais-Hits--empty', classNames.emptyRoot),
        props.className
      )}
    >
      {dropdownContent}
    </div>
  )
}

export default DropdownHitsUI
