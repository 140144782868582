import { TSectionStatusText } from '@unionco/alaris-app-types'

export const getStatusDisplayText = (statusText: TSectionStatusText) => {
  switch (statusText) {
    case 'complete':
      return 'Complete'
    case 'inprogress':
      return 'In progress'
    case 'incomplete':
      return 'Incomplete'
    default:
      return 'Not Started'
  }
}
