/**
 * Tracking Base Function
 *
 * Base tracking function that applies the hit_time_stamp
 * for each tracking function that wraps this.
 *
 * Both the event & hit_time_stamp are consistently used
 * keys for tracking, but event is specific to the wrapping
 * function and thus needs to be passed in.
 *
 */

export const tracking = <T>(obj: T) => {
  // eslint-disable-next-line no-unused-vars
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    hit_time_stamp: new Date().toISOString(),
    ...obj
  })
}
