import { v4 as uuidv4 } from 'uuid'

import type { TAlarisRepContactCardData } from '@unionco/alaris-app-types'

import { placeholderSmallImgFixture } from '@fixtures/media'

export const alarisRepContactFixture = {
  firstName: 'First',
  lastName: 'Last',
  jobTitle: 'Job Title',
  companyName: 'Alaris',
  phoneNumber: '#',
  profilePic: placeholderSmallImgFixture
}

export const alarisRepContactCardFixture = {
  ...alarisRepContactFixture,
  moduleType: 'AlarisRepContactCard',
  questionText: 'Question Text',
  container: 2,
  key: uuidv4()
} as TAlarisRepContactCardData
