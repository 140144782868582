/** FAQ Card
 *  FAQ Data fed into card for use dashboard
 *  Comes with a static rep contact card that pulls from
 *  global context
 */
import { Accordion } from '@unionco/components'
import React, { useContext } from 'react'

import type {
  IFAQCardData,
  IPostAuthGlobalData
} from '@unionco/alaris-app-types'

import { ConstantsContext } from 'context'

import { useAuthFetch } from 'hooks'

import { Loading, ModuleCard } from 'components'
import ContactLink from 'components/ContactLink'

import { FAQCardItem } from './item'

interface IFAQCardProps {
  relationKey: string
}

export const FAQCard: React.FC<IFAQCardProps> = ({ relationKey }) => {
  const { contactEmail } = useContext(ConstantsContext) as IPostAuthGlobalData
  const { data, error } = useAuthFetch<IFAQCardData>(
    `/related-faq?moduleFormat=FAQCard&relationKey=${relationKey}`
  )

  if (error) return <></>
  if (!data) return <Loading debug='FAQCard' />

  const { items, title } = data

  return (
    <ModuleCard.Body>
      <div className='c-flow | u-text-center'>
        <p className='o-overline'>SUPPORT | FAQS</p>
        <h2>{title}</h2>
      </div>
      <Accordion.Main initialOpenItems={[0]}>
        {items.map((item, index) => {
          const { key } = item
          return (
            <React.Fragment key={key}>
              {index === 0 && <div className='o-line' />}
              <FAQCardItem data={item} index={index} />
              <div className='o-line' />
            </React.Fragment>
          )
        })}
      </Accordion.Main>
      {contactEmail && (
        <div className='u-pt-200'>
          <ContactLink
            data={{
              title: '',
              contactEmail,
              moduleType: 'AlarisRepContact',
              key: 'contactLink',
              container: 1
            }}
          />
        </div>
      )}
    </ModuleCard.Body>
  )
}

export default FAQCard
