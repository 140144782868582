import { StyledSelect } from '@unionco/components'

import { fieldIsValid } from '../utils'
import { cx } from '@unionco/utils'

import LabelAndInput from '../labelAndInput'

interface IDropdownProps {
  defaultValue: string
  validation: null | true | string[]
  options: Option[]
  label: string
  name: string
}

type Option = {
  key: number
  value: string
  label: string
}

export const Dropdown: React.FC<IDropdownProps> = ({
  defaultValue,
  label,
  name,
  options,
  validation
}) => {
  return (
    <LabelAndInput label={label} required className='u-basis-[calc(33%-.5rem)]'>
      <StyledSelect
        className={cx('u-text-secondary-800', fieldIsValid(name, validation))}
        classNames={{ select: 'u-bg-primary-300' }}
        name={name}
        defaultValue={defaultValue}
        required
      >
        {options.map((option: Option) => (
          <option key={option.key} value={option.value}>
            {option.label}
          </option>
        ))}
      </StyledSelect>
    </LabelAndInput>
  )
}

export default Dropdown
