import { TPhaseStepType, TSectionStatusText } from '@unionco/alaris-app-types'

import { StatusText, StepType } from '@unionco/alaris-utils'
import { getStatusDisplayText } from 'utils'

import KeyValueText from './Timelineitems/KeyValueText'

interface ITimelineStatusText {
  dateTimeText: string
  statusText: TSectionStatusText
  stepType: TPhaseStepType
}

export const TimelineStatusText: React.FC<ITimelineStatusText> = ({
  dateTimeText,
  statusText,
  stepType
}) => {
  if (statusText === StatusText.COMPLETE.value)
    return (
      <p className='u-text-state-good'>{getStatusDisplayText(statusText)}</p>
    )

  switch (stepType) {
    case StepType.LIVE_SESSION:
      return (
        <KeyValueText
          dateTimeText={dateTimeText}
          iconName='team'
          keyText='Live Session'
        />
      )
    case StepType.QUESTIONNAIRE:
      return (
        <KeyValueText
          dateTimeText={`${dateTimeText}m`}
          iconName='clock'
          keyText='Estimated Duration'
        />
      )
    default:
      return <></>
  }
}
