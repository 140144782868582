import {
  TAssessmentGeneric,
  TAssessmentMultiSelectToRange,
  TContractorQuestionType,
  TDepartmentHeadQuestionType,
  TDocumentUploadQuestionType,
  TFreeResponseQuestionType,
  TLeaderQuestionType,
  TLiveSessionIntroType,
  TLiveSessionStepType,
  TMultiInputFreeResponse,
  TMultiSelectToRange,
  TNonOwnerQuestionType,
  TOwnerFormQuestionType,
  TBasicTeamQuestionType,
  TQuestionIntroType,
  TQuestionReviewType,
  TQuestionSegmentType,
  TQuestionnaireStepType,
  TRangeQuestionType,
  TRangeSegmentType,
  TSearchQuestionType,
  TSearchToFreeResponse,
  TSelectMultipleQuestionType,
  TSelectOneQuestionType,
  TTeamMemberQuestionType,
  TYesNoQuestionType
} from '@unionco/alaris-app-types'

/**
 *  For most use cases range should pass
 *  the same check as a question since it
 *  is a more specific subset of a question
 *  segment.
 */
export const QuestionTypes = {
  FREE_RESPONSE: 'freeResponse' as TFreeResponseQuestionType,
  SELECT_ONE: 'selectOne' as TSelectOneQuestionType,
  SELECT_MULTIPLE: 'selectMultiple' as TSelectMultipleQuestionType,
  RANGE: 'range' as TRangeQuestionType,
  SEARCH: 'search' as TSearchQuestionType,
  YES_NO: 'yesNo' as TYesNoQuestionType,
  OWNER_FORM: 'ownerForm' as TOwnerFormQuestionType,
  TEAM_MEMBER: 'teamMember' as TTeamMemberQuestionType,
  CONTRACTOR_FORM: 'contractorForm' as TContractorQuestionType,
  NON_OWNER: 'nonOwnerForm' as TNonOwnerQuestionType,
  DEPARTMENT_HEAD: 'departmentHeadForm' as TDepartmentHeadQuestionType,
  BASIC_TEAM_MEMBER: 'basicTeamForm' as TBasicTeamQuestionType,
  LEADER: 'leaderForm' as TLeaderQuestionType,
  DOCUMENT_UPLOAD: 'documentUpload' as TDocumentUploadQuestionType
}

export const TriggerQuestionTypes = {
  ASSESSMENT_GENERIC: 'assessmentGeneric' as TAssessmentGeneric,
  ASSESSMENT_MULTI_SELECT_TO_RANGE:
    'assessmentMultiSelectToRange' as TAssessmentMultiSelectToRange,
  MULTI_INPUT_FREE_RESPONSE: 'multiInputFreeResponse' as TMultiInputFreeResponse,
  MULTI_SELECT_TO_RANGE: 'multiSelectToRange' as TMultiSelectToRange,
  SEARCH_TO_FREE_RESPONSE: 'searchToFreeResponse' as TSearchToFreeResponse,
}

export const SegmentTypes = {
  REVIEW: 'questionReview' as TQuestionReviewType,
  QUESTION: 'questionSegment' as TQuestionSegmentType,
  RANGE: 'rangeSegment' as TRangeSegmentType,
  LIVE_INTRO: 'liveSessionIntro' as TLiveSessionIntroType,
  QUESTION_INTRO: 'questionIntro' as TQuestionIntroType
}

export const StepType = {
  QUESTIONNAIRE: 'questionnaire' as TQuestionnaireStepType,
  LIVE_SESSION: 'liveSession' as TLiveSessionStepType
}
