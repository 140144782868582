/** Services Offered Test Data
 *  Test data for the <ServicesOffered /> profile module
 *  used in the profile template
 *  A seller only side module
 */
import { v4 as uuidv4 } from 'uuid'

import type { IServicesOfferedData } from '@unionco/alaris-app-types'

import {
  checkmarkListItemTestData,
  shortParagraphFixture
} from '@fixtures/generic'

export const servicesOfferedModuleTestData = {
  moduleType: 'ServicesOffered',
  title: 'Services Offered',
  items: [
    checkmarkListItemTestData(),
    checkmarkListItemTestData(true),
    checkmarkListItemTestData(),
    checkmarkListItemTestData(),
    checkmarkListItemTestData(true),
    checkmarkListItemTestData(),
    checkmarkListItemTestData(),
    checkmarkListItemTestData(true, 'Lorem Ipsum')
  ],
  key: uuidv4(),
  container: 1,
  info: {
    copy: shortParagraphFixture
  }
} as IServicesOfferedData
