/** Buyer At A Glance Test API
 *
 */
import { IProfileTemplateData } from 'templates'

import {
  acquisitionModelTestData,
  averageGrowthStatisticsTestData,
  businessSummaryModuleTestData,
  clientExpereienceModuleTestData,
  dataRoomModuleTestData,
  generalInformationModuleTestData,
  historicGrowthStatisticsTestData,
  organicGrowthModuleTestData,
  pointsOfAlignmentTestData,
  servicesOfferedModuleTestData,
  teamMemberModuleTestData
} from '@fixtures/modules'

export const buyerAtAGlanceFixture = {
  companyName: 'Business Name',
  modules: [
    businessSummaryModuleTestData,
    teamMemberModuleTestData,
    generalInformationModuleTestData,
    clientExpereienceModuleTestData,
    servicesOfferedModuleTestData,
    acquisitionModelTestData,
    pointsOfAlignmentTestData,
    organicGrowthModuleTestData,
    averageGrowthStatisticsTestData,
    historicGrowthStatisticsTestData,
    dataRoomModuleTestData
  ]
} as IProfileTemplateData
