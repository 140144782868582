export const FacebookLogo: React.FC = ({}) => {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M48 24.146C48 30.1176 46.0645 35.3753 42.1936 39.9189C38.3226 44.3976 33.5161 47.0913 27.7742 48V31.1562H33.3871L34.4516 24.146H27.7742V19.57C27.7742 17.1034 29.0645 15.8702 31.6452 15.8702H34.6452V9.93104C32.8387 9.60649 31.0645 9.44422 29.3226 9.44422C27.4516 9.44422 25.8387 9.80122 24.4839 10.5152C23.1935 11.2292 22.1613 12.3002 21.3871 13.7282C20.6129 15.1562 20.2258 16.8438 20.2258 18.7911V24.146H14.129V31.1562H20.2258V48C14.4839 47.0913 9.67742 44.3976 5.80645 39.9189C1.93548 35.3753 0 30.1176 0 24.146C0 17.4604 2.32258 11.7809 6.96774 7.10751C11.6774 2.36917 17.3548 0 24 0C30.6452 0 36.2903 2.36917 40.9355 7.10751C45.6452 11.7809 48 17.4604 48 24.146Z'
        fill='#48505E'
      />
    </svg>
  )
}

export default FacebookLogo
