import { useContext } from 'react'

import { SupportPageSlug } from '@appConstants'

import { isAdminTracking } from 'utils/tracking'
import { helperTracking } from 'utils/tracking/help'

import { TUserContext, UserContext } from 'context'

import { QuestionMessageIcon, StyledButton, TButtonTheme } from 'components'

interface ISupportLink {
  theme: TButtonTheme
}

export const SupportLink: React.FC<ISupportLink> = ({ theme }) => {
  const { userType } = useContext(UserContext) as TUserContext

  function trackingOnClick() {
    helperTracking({
      click_type: 'going to support page',
      is_admin_key: isAdminTracking(userType)
    })
  }
  return (
    <StyledButton
      type='a'
      theme={theme}
      themeStyle='ghost'
      href={SupportPageSlug}
      className='c-cluster | u-cluster-gutter--200 | u-flex-nowrap u-items-center'
      onClick={trackingOnClick}
    >
      <QuestionMessageIcon />
      NEED HELP?
    </StyledButton>
  )
}

export default SupportLink
