import React from 'react'

import { IAutonomyProfileModuleData } from '@unionco/alaris-app-types'

import { ModuleCard, TitleAndCopy, Video, CheckMarkListItem } from 'components'

interface IAutonomyProfileModuleProps {
    data: IAutonomyProfileModuleData
}

export const AutonomyProfileModule: React.FC<
    IAutonomyProfileModuleProps
    > = ({data}) => {
    if (!data.items) return <></>
    const { video, title, copy, checkboxes } = data?.items
    return (
        <ModuleCard.Body>
            <ModuleCard.InfoHeader
                title={title || 'Autonomy Profile'}
                trackingName='autonomy profile module'
            />
            {video ? (
            <div className='c-cluster u-items-start lg:u-flex-nowrap'>
                <div className='u-w-1/3'>
                    <Video data={video} />
                </div>
                <div className='u-w-2/3'>
                    <TitleAndCopy title={data.title} copy={copy} />
                </div>
            </div>
        ) : (
            <TitleAndCopy title={title} copy={copy} />
        )
        }     
            <div className=''>
                <div className='u-grid u-grid-cols-2'>
                {checkboxes.map((item, index) => {
                    return (
                        <div className='' key={`OrganicGrowth_${index}`}>
                            <CheckMarkListItem
                                className={'u-py-2 u-text-left'}
                                data={item}
                                key={`AutonomyProfile_${index}`}
                            />
                        </div>
                    )
                })}
            </div>
        </div> 
        </ModuleCard.Body>
    )
}

export default AutonomyProfileModule