/** User Context
 *
 *  Possibly useful article on strapi login with next (& Next Auth)
 *  @see https://medium.com/@tom555my/strapi-next-js-email-password-authentication-a8207f72b446
 */
import { createContext } from 'react'

import { TAuthUserData, TClientData } from '@unionco/alaris-app-types'

// TODO: Flip base into client since only non admin end users need agreement, last location and progression?
export type TUserContextBase = {
  setDisplayAgreement: React.Dispatch<React.SetStateAction<boolean>>
}

export type TUserContext = TUserContextBase & TAuthUserData
export type TClientContext = TUserContextBase & TClientData

export const UserContext = createContext<TUserContext | TClientContext | null>(
  null
)

export default UserContext
