/** Filter Object Utility
 *
 */
// import { TPredicate } from '@unionco/alaris-app-types'

type TEntry<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T]

export function filterObject<T extends object>(
  obj: T,
  fn: (entry: TEntry<T>, i: number, arr: TEntry<T>[]) => boolean
) {
  return Object.fromEntries(
    (Object.entries(obj) as TEntry<T>[]).filter(fn)
  ) as Partial<T>
}
