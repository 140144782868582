import { IMatchesData } from '@unionco/alaris-app-types'

import { MatchLink, ModuleCard } from 'components'

import { processTopMatches } from 'utils'

interface IMatchesCardProps {
  data: IMatchesData
  children?: React.ReactNode
  title?: string
  useTopMatch?: boolean
  companyType?: 'buyer' | 'seller'
}

export const MatchesCard: React.FC<IMatchesCardProps> = ({
  children,
  companyType,
  data: { info, items },
  title = 'Your Top Matches',
  useTopMatch = false
}) => {
  if (!items || items.length === 0) return <></>

  items = processTopMatches(items);

  return (
    items.length > 0 && (
      <ModuleCard.Body className='u-text-left print:u-mt-2'>
        <ModuleCard.InfoHeader
          info={info}
          title={title}
          trackingName='matches module'
        />
        <div className='print:u-flex'>
          {items
            .map((item, index) => {
              return (
                <MatchLink
                  data={item}
                  isTopMatch={useTopMatch && index === 0}
                  key={`MatchLink_${index}`}
                  companyTypeForHref={companyType}
                />
              );
            })}
        </div>
        {children}
      </ModuleCard.Body>
    )
  )
}

export default MatchesCard
