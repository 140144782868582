/** Seller Top Priorities Test Data
 *  Test data for the <SellerTopPriorities /> module
 *  used in the profile template
 *  A seller only side module
 */
import { v4 as uuidv4 } from 'uuid'

import type { ITopPrioritiesData } from '@unionco/alaris-app-types'

import { shortParagraphFixture } from '@fixtures/generic'

export const topPrioritiesModuleTestData = {
  moduleType: 'TopPriorities',
  title: 'Top Priorities',
  items: [
    {
      content: 'Growth Culture',
      key: uuidv4()
    },
    {
      content: 'Autonomy & Work Life',
      key: uuidv4()
    },
    {
      content: 'Terms & Strcuture',
      key: uuidv4()
    },
    {
      content: 'Investment Management',
      key: uuidv4()
    },
    {
      content: 'Expanded Services',
      key: uuidv4()
    }
  ],
  key: uuidv4(),
  container: 2,
  info: {
    copy: shortParagraphFixture
  }
} as ITopPrioritiesData
