import { useContext } from 'react'

import { IContactLinkData } from '@unionco/alaris-app-types'

import { isAdminTracking } from 'utils/tracking'
import { helperTracking } from 'utils/tracking/help'

import { TUserContext, UserContext } from 'context'

interface IContactLinkProps {
  data: IContactLinkData
}

export const ContactLink: React.FC<IContactLinkProps> = ({
  data: { contactEmail }
}) => {
  const { userType } = useContext(UserContext) as TUserContext
  function trackingOnClick() {
    helperTracking({
      click_type: 'email',
      is_admin_key: isAdminTracking(userType)
    })
  }
  return (
    <div className='u-flex u-justify-center print:u-hidden'>
      <p className='u-flex'>
        <span>Still have questions?&nbsp;</span>
        <a
          href={`mailto:${contactEmail}`}
          target='_blank'
          rel='noreferrer'
          onClick={trackingOnClick}
        >
          Get Help!
        </a>
      </p>
    </div>
  )
}

export default ContactLink
