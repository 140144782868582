import React from 'react'

import {
  IBasicAnswer,
  IContractorFormData,
  IDepartmentHeadFormData,
  IBasicTeamFormData,
  IDocumentUploadAnswer,
  ILeaderFormData,
  INumberAnswer,
  ISearchAnswerData,
  TFormAnswerData,
  TFormQuestionTypes,
  TFreeResponseQuestionType,
  TQuestionType,
  TTeamMemberTypeFormData
} from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

import { InputAnswerReview, ProfilePicReview } from './answerBlockComponents'
import { TeamMemberReviewUI } from './answerBlockComponents/TeamMemberReview'

type IAnswerBlockProps = {
  answered: boolean
  answers:
  | IBasicAnswer[]
  | INumberAnswer[]
  | TFormAnswerData[]
  | IDocumentUploadAnswer[]
  questionType: Omit<TQuestionType, TFreeResponseQuestionType>
  questionKey: string
  selectedAnswer:
  | null
  | number
  | number[]
  | ISearchAnswerData[]
  | IBasicAnswer[]
  | TFormAnswerData[]
  | IDocumentUploadAnswer[]
  otherAnswer: string | undefined
}

export const AnswerBlock: React.FC<IAnswerBlockProps> = ({
  answered,
  answers,
  otherAnswer,
  questionKey,
  questionType,
  selectedAnswer
}) => {
  const answerTextStyles = 'o-subtitle-2 u-font-bold'
  // Not answered (displays when 1 question in a segment is answered, but another isn't)
  const notAnsweredText = (
    <p className={`${answerTextStyles} u-text-state-danger`}>Not Answered</p>
  )
  if (!answered) return notAnsweredText

  function mapInvestmentManagementStyleToLabel(styleValue: string) {
    switch (styleValue) {
      case 'manageOwn':
        return 'They manage their own investments'
      case 'centralStrategies':
        return 'They use our central investment strategies'
      case 'combination':
        return 'A combination'
      default:
        return 'Unknown'
    }
  }

  // Multi answer questions, selectMultiple, search, range
  if (Array.isArray(selectedAnswer)) {
    switch (questionType) {
      case 'selectMultiple':
        return (
          <div className='c-flow'>
            {(selectedAnswer as number[]).map((s) => {
              if (!s && s !== 0) return <></>
              const label = (answers as IBasicAnswer[])[s].label;

              return (
                <p
                  key={`${questionKey}_selectedAnswer_${s}`}
                  className={`${answerTextStyles} u-text-black`}
                >
                  {label !== 'Other' ?

                    label : `${label}: ${otherAnswer}`

                  }
                </p>
              )
            })}
          </div>
        )
      case 'range':
        return (
          <div className='c-flow'>
            {(answers as IBasicAnswer[]).map((answer, index) => {
              if (!answer || !answer.value) return <></>
              return (
                <p
                  key={`${questionKey}_selectedAnswer_${index}`}
                  className={`${answerTextStyles} u-text-black`}
                >
                  {`${answer.label}: ${selectedAnswer[index]}`}
                </p>
              )
            })}
          </div>
        )
      case 'search':
        return (
          <div className='c-flow'>
            {(selectedAnswer as ISearchAnswerData[]).map((s) => {
              if (!s) return <></>
              return (
                <p
                  key={`${questionKey}_selectedAnswer_${s.name}`}
                  className={`${answerTextStyles} u-text-black`}
                >
                  {s.name}
                </p>
              )
            })}
          </div>
        )
      case 'nonOwnerForm':
      case 'ownerForm':
      case 'teamMember':
        return (
          <TeamMemberReviewUI
            data={answers as TTeamMemberTypeFormData[]}
            questionType={questionType as TFormQuestionTypes}
          />
        )
      case 'contractorForm':
        return (
          <div className='c-flow'>
            {(answers as IContractorFormData[]).map((answer, index) => {
              const {
                age,
                brokerageAumManaged,
                feeAumManaged,
                firstName,
                grossRevenue,
                hasComplianceDisclosures,
                hasStaff,
                insuranceAumManaged,
                investmentManagementStyle,
                isJoinNewPartnership,
                lastName,
                payoutRate
              } = answer
              const name = cx(firstName.value, lastName.value)
              return (
                <React.Fragment key={`TeamMemberReviewList_${index}`}>
                  <InputAnswerReview title='Full Name: ' value={name} />
                  <InputAnswerReview title='Age: ' value={age.value} />
                  <InputAnswerReview
                    title={`How much fee-based RIA AUM does ${name} manage?: `}
                    value={feeAumManaged.value}
                  />
                  <InputAnswerReview
                    title={`How much brokerage AUM does ${name} manage? `}
                    value={brokerageAumManaged.value}
                  />
                  <InputAnswerReview
                    title={`How much insurance AUM does ${name} manage? `}
                    value={insuranceAumManaged.value}
                  />
                  <InputAnswerReview
                    title={`What percent revenue do you pay ${name}? (What is their payout
                        rate?) `}
                    value={payoutRate.value}
                  />
                  <InputAnswerReview
                    title={`Does ${name} have their own staff or do they leverage your team? `}
                    value={hasStaff.value}
                  />
                  <InputAnswerReview
                    title={`What is ${name}'s gross revenue? `}
                    value={grossRevenue.value}
                  />
                  <InputAnswerReview
                    title={`Does ${name} have any compliance disclosures? `}
                    value={hasComplianceDisclosures.value}
                  />
                  <InputAnswerReview
                    title={`Would ${name} wish to join you if you partner with another firm? `}
                    value={isJoinNewPartnership.value}
                  />
                  <InputAnswerReview
                    title={`Does ${name} manage their own investments or leverage your central strategies? `}
                    value={mapInvestmentManagementStyleToLabel(
                      investmentManagementStyle.value
                    )}
                  />
                </React.Fragment>
              )
            })}
          </div>
        )
      case 'departmentHeadForm':
        return (
          <div className='c-flow'>
            {(answers as IDepartmentHeadFormData[]).map((answer, index) => {
              if (!answer) return <></>
              const { firstName, jobDescription, lastName, profilePic, title } =
                answer
              const name = cx(firstName.value, lastName.value)
              return (
                <React.Fragment key={`DepartmentHead_${index}`}>
                  <InputAnswerReview title='Full Name: ' value={name} />
                  <InputAnswerReview title='Job Title: ' value={title?.value || ''} />
                  <InputAnswerReview
                    title='Role in the Firm: '
                    value={jobDescription.value}
                  />
                  <ProfilePicReview img={profilePic} />
                </React.Fragment>
              )
            })}
          </div>
        )
      case 'basicTeamForm':
        return (
          <div className='c-flow'>
            {(answers as IBasicTeamFormData[]).map((answer, index) => {
              if (!answer) return <></>
              const { firstName, jobDescription, lastName, profilePic, title } =
                answer
              const name = cx(firstName.value, lastName.value)
              return (
                <React.Fragment key={`BasicTeam_${index}`}>
                  <InputAnswerReview title='Full Name: ' value={name} />
                  <InputAnswerReview title='Job Title: ' value={title?.value || ''} />
                  <InputAnswerReview
                    title='Role in the Firm: '
                    value={jobDescription.value}
                  />
                  <ProfilePicReview img={profilePic} />
                </React.Fragment>
              )
            })}
          </div>
        )
      case 'leaderForm':
        return (
          <div className='c-flow'>
            {(answers as ILeaderFormData[]).map((answer, index) => {
              if (!answer) return <></>
              const { biography, firstName, lastName, profilePic, title } =
                answer
              const name = cx(firstName.value, lastName.value)
              return (
                <React.Fragment key={`Leader_${index}`}>
                  <InputAnswerReview title='Full Name: ' value={name} />
                  <InputAnswerReview title='Job Title: ' value={title?.value || ''} />
                  <InputAnswerReview
                    title='Biography: '
                    value={biography.value}
                  />
                  <ProfilePicReview img={profilePic} />
                </React.Fragment>
              )
            })}
          </div>
        )
      case 'documentUpload':
        return (
          <div>
            {(answers as IDocumentUploadAnswer[]).map(({ name }, index) => {
              if (!name) return <></>
              return <p key={`documentAnswer${index}`}>{name}</p>
            })}
          </div>
        )
    }
  }

  // For single answer questions, selectOne and yesNo
  return (
    <div>
      {(answers as IBasicAnswer[])[selectedAnswer as number] && (answers as IBasicAnswer[])[selectedAnswer as number].label === 'Other'
        ?
        <p className={`${answerTextStyles} u-text-black`}>{(answers as IBasicAnswer[])[selectedAnswer as number].label} : {otherAnswer}  </p>
        : (answers as IBasicAnswer[])[selectedAnswer as number] && (answers as IBasicAnswer[])[selectedAnswer as number].label !== 'Other' ? (
          <p className={`${answerTextStyles} u-text-black`}>{(answers as IBasicAnswer[])[selectedAnswer as number].label}</p>
        ) : (
          <p></p>
        )}
    </div>
  )
}

export default AnswerBlock
