import { IGeneralInformationData } from '@unionco/alaris-app-types'

import { capitalizeAndSplit } from '@unionco/utils'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { ModuleCard, TitledList, Video } from 'components'

interface IGeneralInformationProps {
  data: IGeneralInformationData
}

export const GeneralInformation: React.FC<IGeneralInformationProps> = ({
  data: { info, items, title, video }
}) => {
  const { navTrackingOnClick } = useNavigationClickTracking()
  const trackingName = 'general information module'

  const itemsPresent = items && items.length > 0
  if (!itemsPresent && !video) return <></>
  const getDisplayName = (key: string) => {
    switch (key) {
      case 'aumTotal':
      case 'AumTotal':
        return 'AUM Total'
      default:
        return capitalizeAndSplit(key)
    }
  }

  const liItems = items.map((item) => {
    return {
      ...item,
      title: getDisplayName(item.title)
    }
  })

  return (
    <ModuleCard.Body
      className='print:u-break-before-page'
    >
      <ModuleCard.InfoHeader
        info={info}
        title={title || 'General Information'}
        trackingName={trackingName}
      />
      {video && <Video data={video} />}
      {itemsPresent && (
        <TitledList
          items={liItems}
          infoRevealCallback={() =>
            navTrackingOnClick('info tooltip', trackingName)
          }
        />
      )}
    </ModuleCard.Body>
  )
}

export default GeneralInformation
