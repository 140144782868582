import { useContext } from 'react'

import { IDownloadItemData } from '@unionco/alaris-app-types'

import { isAdminTracking } from 'utils/tracking'
import { contentDownloadTracking } from 'utils/tracking/contentDownload'

import { TUserContext, UserContext } from 'context'

import { ProfileTemplateContext } from 'templates/profile/context'

import { DownloadIcon } from 'components/svg'

export const DownloadListItem: React.FC<IDownloadItemData> = ({
  extension,
  href,
  title
}) => {
  const { userType } = useContext(UserContext) as TUserContext
  const { companyName } = useContext(ProfileTemplateContext)

  function trackingOnClick() {
    contentDownloadTracking({
      element_name: title,
      company_name: companyName,
      element_id: href,
      is_admin_key: isAdminTracking(userType)
    })
  }
  if (!href || href === '#') return <></>
  return (
    <a
      href={href}
      className={'c-repel u-py-3'}
      download
      onClick={trackingOnClick}
      target='_blank'
      rel='noopener noreferrer'
    >
      <span className='o-subtitle-2 u-text-secondary-800'>{title}</span>
      <div className='u-flex u-items-center'>
        <span className='u-text-secondary-900'>{extension}</span>
        <DownloadIcon className='u-ml-3.5 u-w-3.5 u-text-primary-500' />
      </div>
    </a>
  )
}

export default DownloadListItem
