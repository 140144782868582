import { v4 as uuidv4 } from 'uuid'

import type { ICheckMarkItemData } from '@unionco/alaris-app-types'

type TCheckMarkItemTestData = (
  checked?: boolean,
  subText?: string
) => ICheckMarkItemData

export const checkmarkListItemTestData: TCheckMarkItemTestData = (
  checked = false,
  subText = undefined
) => {
  return {
    title: 'Item Title',
    subText,
    checked,
    key: uuidv4()
  }
}
