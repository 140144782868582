import { cx } from '@algolia/ui-components-shared'
import { Accordion, AccordionContext } from '@unionco/components'
import { ChevronIcon } from '@unionco/svg-icons'
import { useContext, useMemo } from 'react'

interface IViewMoreSectionUI {
  openButtonText: string
  closeButtonText: string
  children: React.ReactNode
}

interface IViewMoreSection extends IViewMoreSectionUI {
  className?: string
}

const ViewMoreSectionUI: React.FC<IViewMoreSectionUI> = ({
  children,
  closeButtonText,
  openButtonText
}) => {
  const { itemIsOpen } = useContext(AccordionContext)
  const buttonText = useMemo(() => {
    return itemIsOpen(0) ? closeButtonText : openButtonText
  }, [itemIsOpen, closeButtonText, openButtonText])

  return (
    <Accordion.Item index={0}>
      <Accordion.Drawer index={0}>{children}</Accordion.Drawer>
      <div className='u-my-200' />
      <div className='u-flex u-justify-center'>
        <Accordion.Button
          index={0}
          locked={false}
          className='c-cluster | u-cluster-gutter--200 | u-flex u-items-center u-py-100 u-text-5 u-text-secondary-800 hover:u-text-primary-500 print:u-hidden'
        >
          <span className=''>{buttonText}</span>
          <Accordion.Indicator index={0} animation='downToUp'>
            <ChevronIcon iconDirection='down' />
          </Accordion.Indicator>
        </Accordion.Button>
      </div>
    </Accordion.Item>
  )
}

export const ViewMoreSection: React.FC<IViewMoreSection> = ({
  children,
  className,
  closeButtonText,
  openButtonText
}) => {
  return (
    <Accordion.Main className={cx(className)}>
      <ViewMoreSectionUI
        openButtonText={openButtonText}
        closeButtonText={closeButtonText}
      >
        {children}
      </ViewMoreSectionUI>
    </Accordion.Main>
  )
}

export default ViewMoreSection
