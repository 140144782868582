import type { BaseHit, Hit } from 'instantsearch.js'
import { UseHitsProps, useHits } from 'react-instantsearch-hooks-web'

import DropdownHitsUI, {
  DropdownHitsProps as HitsUiComponentProps
} from './DropdownHitsUI'

type UiProps<THit extends BaseHit> = Pick<
  HitsUiComponentProps<Hit<THit>>,
  'hits' | 'sendEvent'
>

export type HitsProps<THit extends BaseHit = BaseHit> = Omit<
  HitsUiComponentProps<Hit<THit>>,
  keyof UiProps<THit>
> &
  UseHitsProps<THit>

export function DropdownHits<THit extends BaseHit = BaseHit>({
  escapeHTML,
  transformItems,
  ...props
}: HitsProps<THit>) {
  const { hits, sendEvent } = useHits<THit>({
    escapeHTML,
    transformItems
  })

  const uiProps: UiProps<THit> = {
    hits,
    sendEvent
  }

  return <DropdownHitsUI {...props} {...uiProps} />
}
