import { TAlignmentGrid, TTextTokenClass } from '@unionco/alaris-app-types'

import { Divider, StatusRing } from 'components'
import { IAMContext, TIAMContext } from 'context'
import { useContext, useEffect, useState } from 'react'

import { CheckMarkIcon, XIcon } from '@unionco/svg-icons'

interface IHeatMapGridProps {
  title: string
  alignment?: TAlignmentGrid
  ringColor?: TTextTokenClass,
  isTopThree?: boolean,
  compatible?: boolean,
  onElementClick: (title: string) => void
}

export const HeatMapGrid: React.FC<IHeatMapGridProps> = ({
  alignment = [],
  ringColor,
  title,
  isTopThree,
  compatible,
  onElementClick
}) => {
  const { IAMData: { impersonationMode } } = useContext(IAMContext) as TIAMContext;
  const [clickedElement, setClickedElement] = useState<string | null>('Common Ground');

  //Order of titles requested by Alaris
  const titleOrder = [
    "Common Ground",
    "Autonomy Profile",
    "Deal Needs & Objectives",
    "Partnership Preferences",
    "Buyer Stage & Equity Story",
    "Deal Breaker",
    "Other"
  ];

  useEffect(() => {
    // Set the first element in alignment as the default clicked element
    if (alignment[0] && alignment.length > 0 && !clickedElement) {
      setClickedElement(alignment[0].title);
    }
  }, [alignment, clickedElement]);

  // send the percent and title to the parent component to show category tilt configuration form
  const handleClick = (title: string) => {
    setClickedElement(title);
    if (onElementClick) {
      onElementClick(title);
    }
  };

  // Filter out "Deal Breaker" and "Other" if impersonationMode is not 'adminControl'
  const filteredAlignment = isTopThree === true ?
    alignment.filter(item => item.title !== "Deal Breaker" && item.title !== "Other") : alignment;

  const inputUIClasses = compatible
    ? 'u-bg-primary-500 u-text-white'
    : 'u-bg-white u-text-secondary-500'

  return (
    <div className='c-flow u-w-full u-flex-col u-flex-nowrap'>
      <Divider
        title={title}
        lineClasses='[--line-color:theme(colors.secondary.300)]'
      />
      <div className='c-switcher | [--switcher-threshold:6rem]'>
        {filteredAlignment
          .sort((a, b) => {
            const indexA = titleOrder.indexOf(a.title);
            const indexB = titleOrder.indexOf(b.title);
            if (indexA === -1 || indexB === -1) {
              return 0;
            }
            return indexA - indexB;
          })
          .map(({ relevancePercent, percent, title }, i) => {
            const baseStyles = 'c-flow u-flow-space-inner--200 u-items-center u-rounded-md u-p-2 cursor-pointer'
            const hoverStyles = !isTopThree ? 'u-border u-border-primary-300 hover:u-border-gray-700' : '';
            const elementStyles = `${baseStyles} ${hoverStyles} ${clickedElement === title ? 'u-border-primary-500' : ''}`;
            return (
              <div
                className={`${elementStyles}`}
                key={`MatchRing_${i}`}
                onClick={() => handleClick(title)}
              >
                <div className='u-min-w-[3rem] u-max-w-[3rem]'>
                  {(title === 'Deal Breaker' || title === 'Other') ? (
                    <div
                      className={`${inputUIClasses} | u-flex u-aspect-square u-w-full u-max-w-[3.75rem] u-items-center u-justify-center u-rounded-full`}
                    >
                      {compatible ?
                        <CheckMarkIcon className='u-h-1/2 u-w-1/2' />
                        :
                        <XIcon className='u-h-1/2 u-w-1/2' />
                      }
                    </div>
                  ) : (
                    <StatusRing
                      percent={percent}
                      layer={false}
                      twoColor
                      secondColorClass='u-text-primary-300'
                      colorClass={ringColor}
                      hasInnerRing={true}
                      innerColorClass='u-text-tertiary-300'
                      innerPercent={relevancePercent}
                      innerStrokeWidth={18}
                    />
                  )
                  }
                </div>
                <h6 className='u-text-secondary-800 u-text-center'>{title === 'Negotiation' ? 'Other' : title}</h6>
              </div>
            )
          })}
      </div>
      <div className='u-flex u-w-3/5  u-items-end'>
        <h5 className='u-flex u-flex-start u-font-open-sans u-text-secondary-800  u-text-right u-px-2'>Key:</h5>
        <div className="u-flex u-flex-start u-flex-nowrap ">
          <div className="u-border-8 u-h-3 u-w-4 u-rounded-full u-border-primary-500"></div>
          <h6 className='u-px-2 u-text-secondary-800'>Buyer Strength</h6>
        </div>
        <div className="u-flex u-flex-nowrap u-flex-start">
          <div className=" u-border-8 u-h-3 u-w-3 u-rounded-full u-border-grayscale-500"></div>
          <h6 className='u-px-2 u-text-secondary-800'>Seller Relevance</h6>
        </div>
      </div>
    </div>
  )
}

export default HeatMapGrid
