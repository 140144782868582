/** TAILWIND CONFIG
 */

/** @type {import('tailwindcss').Config } */

const alarisTheme = require('../../design-system/alaris/main.cjs')

module.exports = {
  ...alarisTheme
}
