/** Dashboard Layout Body
 *  Outer most layout component for dashboard
 *  layout style template.
 *  Both dashboard and profile have similar layouts
 */
import type { ILayoutBodyProps } from '@unionco/alaris-app-types'

export const DashboardLayoutBody: React.FC<ILayoutBodyProps> = ({
  children,
  id
}) => {
  return (
    <div id={id} className='t-Dashboard u-flex u-min-h-[100vh] u-flex-col u-font-open-sans'>
      {children}
    </div>
  )
}
