import { CheckMarkIcon, XIcon } from '@unionco/svg-icons'
import { useContext, useEffect, useMemo, useState } from 'react'

import {
  IQuestionSegmentData,
  IQuestionnaireData,
  IYesNoQuestionData
} from '@unionco/alaris-app-types'

import { debounce } from '@unionco/utils'
import { submissionSuccessful } from 'components/questionnaire/util'
// TODO: Move util to utils?
import { getCurrentSegmentData } from 'utils'
import { isAdminTracking } from 'utils/tracking'
import { questionSubmitTracking } from 'utils/tracking/questionnaire'

import { TUserContext, UserContext } from 'context'

import { SidebarContext } from 'components'

import { QuestionContext } from '../context'

interface IYesNoQuestionProps {
  data: IYesNoQuestionData
}

export const YesNoQuestion: React.FC<IYesNoQuestionProps> = ({
  data: { answers, selectedAnswer }
}) => {
  const { userType } = useContext(UserContext) as TUserContext
  const { submitAnswer } = useContext(QuestionContext)
  const { data, pageLocations } = useContext(SidebarContext)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [currentSelected, setCurrentSelected] = useState<number | null>(null)

  const updateAnswer = async (answer: number | null) => {
    setCurrentSelected(answer)

    const newState = { ...data } as IQuestionnaireData
    const currentSegment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData
    ;(
      currentSegment.questions[
        pageLocations['question'] as number
      ] as IYesNoQuestionData
    ).selectedAnswer = answer
    newState.segments[pageLocations['segment']] = currentSegment
    const result = await submitAnswer(true, newState, pageLocations)
    if (submissionSuccessful(result)) {
      setSubmitted(true)
    }
  }

  /**
   * Fill in users answer on load
   */
  useEffect(() => {
    setCurrentSelected(selectedAnswer)
  }, [selectedAnswer])

  /**
   * Tracking debouncing and sending
   */
  const debouncedTrackingHandler = useMemo(
    () =>
      debounce((currentlySelected: number | null) => {
        if (typeof currentlySelected === 'number') {
          const isAdmin = isAdminTracking(userType)
          const answerTracking =
            currentSelected === 0 ? { answer_1: 'Yes' } : { answer_2: 'No' }
          questionSubmitTracking({
            is_admin_key: isAdmin,
            ...answerTracking
          })
          setSubmitted(false)
        }
      }, 1000),
    [currentSelected, userType]
  )

  useEffect(() => {
    if (!submitted) return
    debouncedTrackingHandler(currentSelected)
  }, [currentSelected, debouncedTrackingHandler, submitted])

  return (
    <form className='b-YesNoQuestion | c-autoGrid c-autoGrid--layout-50-50 | u-rounded'>
      {answers.map((answer, index) => {
        const { value } = answer
        const checked = currentSelected === index
        const inputUIClasses = checked
          ? 'u-bg-primary-500 u-text-white'
          : 'u-bg-white u-text-secondary-500'

        return (
          <div
            className={`b-YesNoQuestion__card | c-flow | u-items-center u-bg-primary-300 u-p-500`}
            key={`questionnaire${pageLocations['section']}_segment${pageLocations['segment']}_YesNo_${index}`}
            onClick={() => updateAnswer(index)}
          >
            <div
              className={`${inputUIClasses} | u-flex u-aspect-square u-w-full u-max-w-[3.75rem] u-items-center u-justify-center u-rounded-full`}
            >
              {index === 0 ? (
                <CheckMarkIcon className='u-h-1/2 u-w-1/2' />
              ) : (
                <XIcon className='u-h-1/2 u-w-1/2' />
              )}
            </div>
            <label className='c-flow'>
              <span className='o-h3 u-text-secondary-500'>
                {index === 0 ? 'Yes' : 'No'}
              </span>
            </label>
            <input
              className='u-hidden'
              title={`answer_${index}`}
              name={`YesNo_${index}`}
              type='radio'
              value={value}
              checked={checked}
              onChange={() => {
                return undefined
              }}
            />
          </div>
        )
      })}
    </form>
  )
}

export default YesNoQuestion
