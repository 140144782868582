/**
 * TGoogleSessionID alias of basic string type
 */
type TGoogleSessionID = string

/**
 * getSessionIdFromGACookie
 *
 * Get Session Id From Google Analytics Cookie
 *
 * @returns The google session id if successful or undefined if it fails to find it.
 * This intentially returns undefined instead of null so that when applying it to a
 * tracking function if the key isn't found the field is excluded. This is per data team
 * documentation.
 *
 * [@seandepottey]: May eventually be good to add a param here to control what's
 * searched for to cover different versions of the google tracking. Would plugin to
 * the startsWith.
 */
export const getSessionIdFromGACookie = (): TGoogleSessionID | undefined => {
  const cookies = document.cookie.split(';')

  for (const c of cookies) {
    const trimmedCookie = c.trim()
    if (trimmedCookie.startsWith('_ga')) {
      // Split the cookie by '.' to extract the session ID
      const cookiePieces = trimmedCookie.split('.')
      if (cookiePieces.length === 4) {
        // The session ID is in the forth part of the cookie
        return cookiePieces[3]
      }
    }
  }

  return undefined
}
