export const processTopMatches = (items: any[]) => {
    // Add matchConfidence property if it doesn't exist and set it to 0
    // Default item.configuration.compatible to false if it doesn't exist
    items = items.map(item => ({
        ...item,
        matchConfidence: item.matchConfidence ?? 0,
        configuration: {
            ...item.configuration,
            compatible: item.configuration?.compatible ?? false,
        },
    }));

    // Exclude items with matchConfidence 0 or item.configuration.compatible false
    items = items.filter(
        item => item.matchConfidence > 0 && item.configuration.compatible
    );

    // Sort items and take top 3
    items = items
        .sort((a, b) => {
            if (a.matchConfidence === b.matchConfidence) {
                return a.companyName.localeCompare(b.companyName);
            }
            return b.matchConfidence - a.matchConfidence;
        })
        .slice(0, 3);

    return items;
}