import { v4 as uuidv4 } from 'uuid'

import type {
  IBuyerDashboardData,
  IDealData,
  IDealsInProgressData,
  IMissedDealsData,
  IPotentialMatchesData,
  IRecentlyClosedDealsData
} from '@unionco/alaris-app-types'

import { faqSectionFixture } from '..'

import { YourFirmsMetricsAtAGlanceFixture } from '@fixtures/business'
import { companyHeader, matchData } from '@fixtures/heatmap/topMatches'
import { topMatchesModuleTestData } from '@fixtures/modules'

const potentialMatchesFixture = {
  title: 'Potential Matches',
  subTitle: 'You are a top 3 match with the following sellers',
  matches: [matchData, matchData, matchData, matchData, matchData]
} as IPotentialMatchesData

function dealFixture(): IDealData {
  return {
    ...companyHeader,
    introMeeting: 'complete',
    indicationOfInterest: 'complete',
    departmentPartnerCalls: 'complete',
    letterOfIntent: 'inProgress',
    legalTransitionPreparation: 'notStarted',
    dealClosed: 'notStarted'
  }
}

const dealsInProgressFixture = {
  deals: [dealFixture(), dealFixture()],
  title: 'Deals In Progress'
} as IDealsInProgressData

const recentlyClosedDealsFixture = {
  ...topMatchesModuleTestData,
  title: 'Your Recently Closed Deals'
} as IRecentlyClosedDealsData

const missedDealsFixture = {
  info: { copy: 'Reveal Content' },
  topThreeAlignments: [
    {
      content: 'Item 1',
      key: uuidv4()
    },
    {
      content: 'Item 2',
      key: uuidv4()
    },
    {
      content: 'Item 3',
      key: uuidv4()
    }
  ],
  missedDeals: [
    companyHeader,
    companyHeader,
    companyHeader,
    companyHeader,
    companyHeader,
    companyHeader,
    companyHeader
  ]
} as IMissedDealsData

export const buyerDashboardFixture = {
  dealsInProgress: dealsInProgressFixture,
  potentialMatches: potentialMatchesFixture,
  yourFirm: YourFirmsMetricsAtAGlanceFixture,
  recentlyClosedDeals: recentlyClosedDealsFixture,
  missedDeals: missedDealsFixture,
  faq: faqSectionFixture
} as IBuyerDashboardData
