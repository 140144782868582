/** Range Question Data
 *
 */
import type { IRangeQuestionData } from '@unionco/alaris-app-types'

export const rangeQuestionFixture = {
  answered: false,
  answers: [
    {
      label: 'Range Answer 1',
      value: 20
    },
    {
      label: 'Range Answer 2 - with longer text that goes to 2 lines',
      value: 30
    },
    {
      label:
        'Range Answer 3 - with even longer text that breaks in into 3 lines to display template flexibility',
      value: 40
    },
    {
      label: 'Range Answer 4',
      value: 50
    }
  ],
  questionType: 'range',
  questionText: 'Range Question?',
  selectedAnswer: [20, 30, 40, 50] // Preselected for range inputs
} as IRangeQuestionData
