import Vimeo from '@vimeo/player'
import { useEffect, useState } from 'react'

export function useVimeo(ref: React.RefObject<HTMLDivElement>) {
  const [runTime, setRunTime] = useState<number>(0)
  const [playing, setPlaying] = useState<boolean>(false)

  useEffect(() => {
    try {
      if (!ref || !ref.current) return

      const setDuration = async (player: Vimeo) => {
        player
          .getDuration()
          .then((duration: number) => {
            setRunTime(Math.trunc(duration))
          })
          .catch((err: Error) => {
            console.error(err)
          })
      }

      const { current } = ref
      const player = new Vimeo(current)
      setDuration(player)

      player.on('play', () => setPlaying(true))
      player.on('pause', () => setPlaying(false))
    } catch (err) {
      console.error(err)
    }
  }, [ref])

  return { runTime, playing }
}
