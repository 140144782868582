import {
  IContractorFormData,
  IDepartmentHeadFormData,
  ILeaderFormData,
  INonOwnerFormData,
  IOwnerFormData,
  IBasicTeamFormData
} from '@unionco/alaris-app-types'

/**
 * Form Question Utils and default fixtures for use in application
 */
export const teamMemberFields = {
  age: {
    value: ''
  },
  baseSalary: {
    value: ''
  },
  firstName: {
    value: ''
  },
  lastName: {
    value: ''
  },
  title: {
    value: ''
  },
  jobDescription: {
    value: ''
  },
  additionalNotes: {
    value: ''
  },
  profilePic: {
    value: {
      image: '',
      value: ''
    }
  }
}

export const nonOwnerFields: INonOwnerFormData = {
  ...teamMemberFields,
  aumManaged: {
    value: ''
  },
  revenueManaged: {
    value: ''
  },
  connectedField: 'nonOwners' as const
}

export const leaderFields: ILeaderFormData = {
  ...teamMemberFields,
  biography: {
    value: ''
  },
  title: {
    value: ''
  },
  connectedField: 'leaders' as const
}

export const departmentHeadFields: IDepartmentHeadFormData = {
  ...teamMemberFields,
  title: {
    value: ''
  },
  connectedField: 'departmentHeads' as const
}

export const basicTeamFields: IBasicTeamFormData = {
  ...teamMemberFields,
  connectedField: '' as const,
  triggerQuestionType: 'corporateDevelopmentTeam' as const
}

export const ownerFields: IOwnerFormData = {
  ...teamMemberFields,
  ownership: {
    value: ''
  },
  transactionGoal: //transactionGoal is not a string like the others and should be null by default
    null,
  involvedInBusiness: {
    value: ''
  },
  glidepath: {
    value: ''
  },
  aumManaged: {
    value: ''
  },
  hhManaged: {
    value: ''
  },
  revenueManaged: {
    value: ''
  },
  connectedField: 'owners' as const
}

export const contractorFields: IContractorFormData = {
  ...teamMemberFields,
  feeAumManaged: {
    value: ''
  },
  brokerageAumManaged: {
    value: ''
  },
  insuranceAumManaged: {
    value: ''
  },
  payoutRate: {
    value: ''
  },
  hasStaff: {
    value: ''
  },
  grossRevenue: {
    value: ''
  },
  hasComplianceDisclosures: {
    value: ''
  },
  isJoinNewPartnership: {
    value: ''
  },
  investmentManagementStyle: {
    value: ''
  },
  connectedField: 'contractors' as const
}
