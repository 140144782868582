import { useContext } from 'react'

import type {
  ISupportSegmentData,
  TQuestionnaireSegmentSummariesData,
  TQuestionnaireSegmentTypeData
} from '@unionco/alaris-app-types'

import { SidebarContext } from 'components'

import WayfinderDrawerItem from '../drawerItem'

export type TDrawerListProps = {
  sectionIndex: number
  sectionKey: string
  segments: TQuestionnaireSegmentSummariesData | ISupportSegmentData[]
  type: 'questionnaire' | 'support'
}

export const WayfinderDrawerList: React.FC<TDrawerListProps> = ({
  sectionIndex,
  sectionKey,
  segments
}) => {
  const { isPhaseView } = useContext(SidebarContext)

  const questionnaireListItems = (
    segments: TQuestionnaireSegmentSummariesData
  ) => {
    if (!segments) return <></>
    return segments.slice(1).map((item, index) => {
      const { key, title, type } = item as TQuestionnaireSegmentTypeData

      return (
        <WayfinderDrawerItem
          content={title}
          sectionIndex={sectionIndex}
          sectionKey={sectionKey}
          segmentIndex={index + 1}
          segmentType={type}
          icon={'status'}
          {...item}
          key={`${key}_wayfinder_drawer`}
        />
      )
    })
  }

  const drawerListItems = (segments: ISupportSegmentData[]) => {
    if (!segments) return <></>
    return segments.map((item, index) => {
      const { key, title, type } = item
      return (
        <WayfinderDrawerItem
          content={title}
          sectionIndex={sectionIndex}
          sectionKey={sectionKey}
          segmentIndex={index}
          segmentType={type}
          icon={'chevron'}
          {...item}
          key={`${key}_wayfinder_drawer`}
        />
      )
    })
  }

  const listItems = isPhaseView
    ? questionnaireListItems(segments as TQuestionnaireSegmentSummariesData)
    : drawerListItems(segments as ISupportSegmentData[])

  return <ul>{listItems}</ul>
}

export default WayfinderDrawerList
