/**
 *  TODO: Replace with either buyer specific level lower data
 *  or remove entirly
 */
// import type { IQuestionnaireSetPageData } from '@unionco/alaris-app-types'
import { phaseStepsFixture } from '@fixtures/phase'

export const sellerQuestionnaireFixture = {
  currentStep: 1,
  contentType: 'phaseSteps',
  pageName: 'Phase Name',
  pageRoute: 'phase-name',
  sections: phaseStepsFixture
} // as IQuestionnaireSetPageData
