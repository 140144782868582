import {
  TUserAdminType,
  TUserBuyerType,
  TUserSellerType
} from '@unionco/alaris-app-types'

export const UserRoles = {
  ADMIN: 'admin' as TUserAdminType,
  SELLER: 'seller' as TUserSellerType,
  BUYER: 'buyer' as TUserBuyerType
}
