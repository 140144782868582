/** Range Segment Fixture
 *
 */
import { v4 as uuidv4 } from 'uuid'

import type { IRangeSegmentData } from '@unionco/alaris-app-types'

import { rangeQuestionFixture } from '@fixtures/phase/questionnaire/question'

export const rangeSegmentFixture = {
  title: 'Segment 5: Range',
  introCopy: 'Progression based intro copy',
  status: {
    percent: 0,
    statusText: 'notStarted'
  },
  questions: [rangeQuestionFixture],
  key: uuidv4(),
  type: 'rangeSegment',
  isAlarisOnly: false,
  isActive: true
} as IRangeSegmentData

/** Output
 {
    segmentTitle: 'Segmet 5: Range',
    introCopy: 'Progression based intro copy',
    status: {
    percent: 0,
    statusText: 'notStarted'
    },
    questions: [
    {
        answers: [
        {
            label: 'Range Answer 1',
            value: 20
        },
        {
            label: 'Range Answer 2 - with longer text that goes to 2 lines',
            value: 30
        },
        {
            label:
            'Range Answer 3 - with even longer text that breaks in into 3 lines to display template flexibility',
            value: 40
        },
        {
            label: 'Range Answer 4',
            value: 50
        }
        ],
        answered: false,
        questionType: 'range',
        questionText: 'Range Question?',
        selectedAnswer: [20, 30, 40, 50] // Preselected for range inputs
    }
    ]
}
*/
