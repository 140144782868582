/** Primary Button
 * Styled Button with caret icon specific to Alaris
 */
import { CaretIcon } from '@unionco/svg-icons'
import React from 'react'

import { StyledButton, TStyledButtonProps } from 'components'

export type IPrimaryButton = TStyledButtonProps & {
  iconLeft?: boolean
  id: string
}

export const PrimaryButton: React.FC<IPrimaryButton> = ({
  children,
  className,
  iconLeft = false,
  id,
  ...props
}) => {
  const styles = 'u-flex u-items-center u-justify-center u-text-center'
  const classes = className ? `${styles} ${className}` : styles

  const direction = iconLeft ? 'left' : 'right'
  const spacing = iconLeft ? 'u-pr-2' : 'u-pl-2'
  const icon = (
    <div
      key={`${id}_Button_Icon`}
      className={`u-translate-x-[-100%] u-transform u-opacity-0 u-transition-opacity u-transition-transform group-hover:u-translate-x-[0] group-hover:u-opacity-100 ${spacing}`}
    >
      <CaretIcon iconDirection={direction} />
    </div>
  )
  const text = (
    <span
      key={`${id}_Button_Text`}
      className='u-translate-x-[.6875rem] u-transition-transform group-hover:u-translate-x-[0]'
    >
      {children}
    </span>
  )
  const buttonContent = iconLeft ? [icon, text] : [text, icon]

  return (
    <StyledButton
      className={classes}
      theme='primary'
      themeStyle='solid'
      id={id}
      {...props}
    >
      {buttonContent}
    </StyledButton>
  )
}

export default PrimaryButton
