/**
 * Deals In Progess Dashboard Module
 *
 * Currently buyer only
 */
import { Card, useBreakAtSize } from '@unionco/components'
import { CheckMarkIcon } from '@unionco/svg-icons'
import { RefObject, createRef, useCallback, useEffect, useState } from 'react'
import tw from 'twin.macro'

import {
  IDealStatus,
  IDealsInProgressData,
  TDealKeys
} from '@unionco/alaris-app-types'

import { capitalizeAndSplit } from '@unionco/alaris-utils'
import { cx } from '@unionco/utils'

import { BusinessHeader } from 'components/BusinessHeader'
import { ModuleCard } from 'components/layout'

import { styleBorderGlow } from 'styles'

import { TimelineLine } from '../TimelineProgression/TimelineLine'

interface IDealsInProgressProps {
  data: IDealsInProgressData
}

export const DealsInProgress: React.FC<IDealsInProgressProps> = ({
  data: { deals, title }
}) => {
  const [timelineItemRefs, setTimelineItemRefs] = useState<
    RefObject<HTMLDivElement>[] | null
  >(null)
  const [itemHalfWidth, setItemHalfWidth] = useState(0)
  const [spaceBetweenItems, setSpaceBetweenItems] = useState([0])
  const { aboveBreakPoint } = useBreakAtSize('md')
  const lastStepIndex = 5

  /**
   * TODO: Move to util and replace existing in timeline progression,
   * can this be done? createRef is a hook, and hooks need to be inside of
   * a component?
   */
  const updateRefs = (
    refs: RefObject<HTMLDivElement>[] | null,
    numOfRefs: number
  ) => {
    return Array(numOfRefs)
      .fill(undefined)
      .map((_, i) => (refs ? refs[i] : createRef<HTMLDivElement>()))
  }

  const updateTimeline = useCallback(() => {
    if (timelineItemRefs) {
      const refs = [...timelineItemRefs]
      const firstItem = refs[0].current
      const halfWidth = firstItem ? firstItem.clientWidth / 2 : 0
      setItemHalfWidth(halfWidth)
      const items = refs.slice(0, -1)
      const spaces = items.map((item, index) => {
        const { current } = item as RefObject<HTMLDivElement>
        const nextItem = refs[index + 1].current as HTMLDivElement
        if (current && nextItem) {
          const rect = current.getBoundingClientRect()
          const right = rect.right
          const left = nextItem.getBoundingClientRect().left
          return left - right
        }
        return 0
      })
      setSpaceBetweenItems(spaces)
    }
  }, [timelineItemRefs])

  // Ref's Effect
  useEffect(() => {
    const numOfItems = 6
    setTimelineItemRefs((refs) => updateRefs(refs, numOfItems))
  }, [setTimelineItemRefs])

  // Update timeline effect
  useEffect(() => {
    updateTimeline()
  }, [updateTimeline])

  // Resize effect
  useEffect(() => {
    window.addEventListener('resize', updateTimeline)
  }, [updateTimeline])

  return (
    <ModuleCard.Body>
      <div className='u-mb-500 u-mt-400 u-text-center'>
        <h2>{title}</h2>
      </div>
      {deals && deals.length > 0 ? (
        deals.map((deal, index) => {
          const {
            companyLogo,
            companyName,
            dealClosed,
            departmentPartnerCalls,
            indicationOfInterest,
            introMeeting,
            legalTransitionPreparation,
            letterOfIntent
          } = deal
          let currentStatus
          for (const key in deal) {
            if (deal[key as TDealKeys] === 'inProgress') {
              currentStatus = capitalizeAndSplit(key)
            }
          }

          const statuses = [
            { title: 'Intro Meeting', status: introMeeting },
            { title: 'Indication Of Interest', status: indicationOfInterest },
            {
              title: 'Department/Partner Calls',
              status: departmentPartnerCalls
            },
            { title: 'Letter Of Intent', status: letterOfIntent },
            {
              title: 'Legal Transition Preparation',
              status: legalTransitionPreparation
            },
            { title: 'Deal Closed', status: dealClosed }
          ]

          return (
            <Card
              className='u-p-600'
              bgColorClass={tw`u-bg-primary-300`}
              key={`Deal${index}`}
            >
              <div className='c-repel'>
                <BusinessHeader logo={companyLogo} name={companyName} />
                <div className='c-flow u-flow-space--100 u-text-right'>
                  <h5 className='u-font-open-sans u-text-3 u-font-light u-uppercase u-tracking-[.1rem] u-text-secondary-500'>
                    Current Status
                  </h5>
                  <p className='u-text-5 u-font-bold u-text-primary-500'>
                    {currentStatus}
                  </p>
                </div>
              </div>
              {/* Get timeline UI and duplicate to here */}
              <div
                className={cx(
                  'c-withLayer c-switcher u-flex u-justify-between u-pt-600 [--switcher-threshold:32rem]'
                  // !aboveBreakPoint ? 'c-flow' : 'c-autoGrid'
                )}
              >
                {statuses.map(({ status, title }, index) => {
                  const percent = status === 'complete' ? 100 : 0

                  const getStatusText = (status: IDealStatus) => {
                    switch (status) {
                      case 'complete':
                        return (
                          <p className='u-font-bold u-text-state-good'>
                            Complete
                          </p>
                        )
                      case 'inProgress':
                        return (
                          <p className='u-font-bold u-text-black'>
                            In Progress
                          </p>
                        )
                      default:
                        return (
                          <p className='u-font-bold u-text-secondary-500'>
                            Upcoming
                          </p>
                        )
                    }
                  }

                  const getIndicator = (status: IDealStatus) => {
                    const indicatorClasses =
                      'u-h-full u-w-full u-rounded-full u-flex u-items-center u-justify-center'
                    switch (status) {
                      case 'complete':
                        return (
                          <div
                            className={cx('u-bg-primary-500', indicatorClasses)}
                          >
                            <CheckMarkIcon
                              curved
                              className='u-w-4 u-text-white'
                            />
                          </div>
                        )
                      case 'inProgress':
                        return (
                          <div
                            className={cx(
                              'u-h-full u-border-[.3rem] u-border-secondary-300',
                              indicatorClasses
                            )}
                            style={{ filter: styleBorderGlow }}
                          >
                            <div className='u-aspect-square u-w-7 u-rounded-full u-border-[.25rem] u-border-primary-500 u-bg-black' />
                          </div>
                        )
                      default:
                        return (
                          <div
                            className={cx(
                              'u-border-[.3rem] u-border-secondary-300',
                              indicatorClasses
                            )}
                          />
                        )
                    }
                  }

                  return (
                    <div className='c-withLayer' key={`DealStatus${index}`}>
                      {aboveBreakPoint && index !== lastStepIndex && (
                        <TimelineLine
                          itemHalfWidth={itemHalfWidth}
                          spaceBetween={spaceBetweenItems[index]}
                          useActive
                          percent={percent}
                        />
                      )}
                      {timelineItemRefs && (
                        <div className='c-flow u-relative u-items-center u-text-center'>
                          <div
                            ref={timelineItemRefs[index]}
                            className='u-aspect-square u-w-12 u-rounded-full u-bg-white'
                          >
                            {getIndicator(status)}
                          </div>
                          {getStatusText(status)}
                          <p className='u-m-0 u-text-3 u-text-black'>{title}</p>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            </Card>
          )
        })
      ) : (
        <p className='u-text-center'>You do not have any deals in progress</p>
      )}
    </ModuleCard.Body>
  )
}
