// NotificationMenu.tsx
import { API_BASE } from '@appConstants'
import axios from 'axios'
import { NotificationIcon, StyledButton } from 'components'
import { IAMContext, TIAMContext, TUserContext, UserContext } from 'context'
import { FC, useContext, useEffect, useRef, useState } from 'react'

interface INotification {
    id: string
    title: string
    description: string
    url: string
}

export const NotificationMenu: FC = () => {
    // Retrieve user context data, which includes the JWT token
    const userContextData = useContext(UserContext) as TUserContext
    const { jwt } = userContextData

    // Retrieve IAM context data, specifically the user ID
    const {
        IAMData: { id: userId }
    } = useContext(IAMContext) as TIAMContext

    // State to manage whether the dropdown menu is open or closed
    const [isOpen, setIsOpen] = useState<boolean>(false)
    // State to manage the list of notifications
    const [notifications, setNotifications] = useState<INotification[]>([])

    // Reference to the dropdown menu element
    const dropdownRef = useRef<HTMLDivElement>(null)

    // Function to toggle the dropdown menu open or closed
    const toggleDropdown = () => setIsOpen(!isOpen)

    // Function to remove a notification by ID and update the server
    const removeNotification = (id: string) => {
        // Update the local state to remove the notification
        setNotifications(notifications.filter(notification => notification.id !== id))

        // Send a request to the server to update notifications
        try {
            const result = axios.put(`${API_BASE}/api/update-notifications?userId=${userId}&notificationId=${id}`, {}, {
                headers: {
                    Authorization: `Bearer ${jwt}`, // Include the JWT for authorization
                }
            });
        } catch (error) {
            console.error('Failed to update notifications', error) // Handle any errors
        }
    }

    // useEffect hook to fetch notifications when the component mounts
    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                // Send a GET request to retrieve notifications for the user
                const result = await axios.get(`${API_BASE}/api/get-user-notifications?userId=${userId}`, {
                    headers: {
                        Authorization: `Bearer ${jwt}`, // Include the JWT for authorization
                    }
                })
                // Update the state with the retrieved notifications
                setNotifications(result.data)
            } catch (error) {
                console.error('Failed to fetch notifications:', error) // Handle any errors
            }
        }

        fetchNotifications() // Fetch notifications on component mount
    }, []) // Empty dependency array means this effect runs once, on mount

    return (
        <div ref={dropdownRef} className='u-text-white'>
            {/* Button to toggle the dropdown menu */}
            <StyledButton
                theme={notifications.length > 0 ? 'dark' : 'light'}
                type='button'
                themeStyle={notifications.length > 0 ? 'solid' : 'ghost'}
                className='c-cluster | u-cluster-gutter--200 | u-flex-nowrap u-items-center'
                onClick={toggleDropdown}
            >
                <NotificationIcon />
                {`Notifications ${notifications.length > 0 ? '(' + notifications.length + ')' : ''}`}
            </StyledButton>

            {/* Dropdown menu to display notifications if open */}
            {isOpen && (
                <div
                    id='notificationMenu_menu'
                    className='u-absolute u-right-0 u-z-10 u-rounded u-bg-white u-p-2 u-shadow-lg'
                    onClick={(e) => e.stopPropagation}
                    style={{
                        top: 'calc(80%)'
                    }}
                >
                    <ul className='u-flex u-flex-col u-items-left'>
                        {/* If there are no notifications, show a message */}
                        {notifications.length > 0 ? (
                            // Map over the notifications and display each one
                            notifications.map((notification) => (
                                <div key={notification.id}>
                                    <li className='u-flex u-justify-between u-items-center u-p-4'>
                                        <a href={notification.url} target='_blank' rel='noopener noreferrer' className='u-min-w-xs u-max-w-xs u-break-words'>
                                            <h4>{notification.title}</h4>
                                            <p>{notification.description}</p>
                                        </a>
                                        {/* Button to remove a notification */}
                                        <StyledButton
                                            type='button'
                                            onClick={() => removeNotification(notification.id)}
                                        >
                                            Done
                                        </StyledButton>
                                    </li>
                                    <div className='o-line u-grow' />
                                </div>
                            ))
                        ) : (
                            <div>
                                <li className='u-flex u-justify-between u-items-center u-p-4'>
                                    <div className='u-min-w-xs u-max-w-xs u-break-words'>
                                        <p>You don't have any notifications at this time.</p>
                                    </div>
                                </li>
                                <div className='o-line u-grow' />
                            </div>
                        )}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default NotificationMenu
