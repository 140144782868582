/** DashboardLayout
 *  Collection of components for use in the dashboard and profile templates
 *  Useful for keeptypes separate while keeping layout consistent
 *
 *  Use Structure (Dot Notaion Preferred):
 *  <DashboardLayout.Body>
 *      <DashboardLayout.Header>
 *        { header content }
 *      </DashboardLayout.Header>
 *      <DashboardLayout.Main>
 *          { body content }
 *      </DashboardLayout.Main>
 *  </DashboardLayout>
 *
 *  <DashboardLayoutBody>
 *      <DashboardLayoutHeader>
 *        { header content }
 *      </DashboardLayoutHeader>
 *      <DashboardLayoutMain>
 *          { body content }
 *      </DashboardLayoutMain>
 *  </DashboardLayout>
 */
import { TLayoutObject } from '@unionco/alaris-app-types'

import { DashboardLayoutBody } from './Body'
import { DashboardLayoutHeader } from './Header'
import { DashboardLayoutMain } from './Main'

export const DashboardLayout: TLayoutObject = {
  Body: DashboardLayoutBody,
  Header: DashboardLayoutHeader,
  Main: DashboardLayoutMain
}

export default DashboardLayout
