import type { FC } from 'react'
import { Fragment } from 'react'

import {
  INonOwnerFormData,
  IOwnerFormData,
  TFormQuestionTypes,
  TTeamMemberTypeFormData
} from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

import { InputAnswerReview } from './InputAnswerReview'
import { ProfilePicReview } from './ProfilePicReview'

/**
 * TTeamMemberTypeFormData is a union between the basic TeamMember, NonOwner, & Owner Form
 * Created because both NonOwner and Owner extend off of the TeamMember type
 */
interface TTeamMemberReviewUIProps {
  data: TTeamMemberTypeFormData[]
  questionType: TFormQuestionTypes
}

/**
 * Common Fields found in both Non Owner and Owner forms, but not in Team Members
 */
const NonBasicInputsReview: FC<{
  answer: INonOwnerFormData | IOwnerFormData
}> = ({ answer: { aumManaged, revenueManaged } }) => {
  return (
    <>
      <InputAnswerReview title='AUM Managed: ' value={aumManaged.value} />
      <InputAnswerReview
        title='Revenue Managed: '
        value={revenueManaged.value}
      />
    </>
  )
}

/**
 * Fields found in the Owner forms only
 */
const OwnerSpecificInputsReview: FC<{ answer: IOwnerFormData }> = ({
  answer: {
    additionalNotes,
    glidepath,
    hhManaged,
    involvedInBusiness,
    transactionGoal
  }
}) => {
  // transactionGoal is an array of objects, so we need to map over it to get the values
  const transactionGoalList = Array.isArray(transactionGoal) ? transactionGoal.map(item => item.value).join(', ') : '';
  return (
    <>
      <InputAnswerReview
        title='Transaction Goal: '
        value={transactionGoalList}
      />
      <InputAnswerReview title='Glide Path: ' value={glidepath.value} />
      <InputAnswerReview
        title='Involved In Bussiness: '
        value={involvedInBusiness.value}
      />
      <InputAnswerReview title='Households Managed: ' value={hhManaged.value} />
      <InputAnswerReview
        title='Additonal Notes: '
        value={additionalNotes.value}
      />
    </>
  )
}

export const TeamMemberReviewUI: FC<TTeamMemberReviewUIProps> = ({
  data,
  questionType
}) => {
  if (!data) return <></>

  return (
    <div className='c-flow'>
      {data.map((answer, index) => {
        if (!answer) return <></>
        const { age, baseSalary, firstName, lastName, profilePic, title } =
          answer
        const name = cx(firstName.value, lastName.value)
        return (
          <Fragment key={`TeamMemberReviewList_${index}`}>
            <InputAnswerReview title='Full Name: ' value={name} />
            <InputAnswerReview title='Age: ' value={age.value} />
            <InputAnswerReview title='Job Title: ' value={title.value} />
            <InputAnswerReview title='Base Salary: ' value={baseSalary.value} />
            {questionType === 'ownerForm' && (
              <InputAnswerReview
                title='Ownership %: '
                value={(answer as IOwnerFormData).ownership.value}
              />
            )}
            {questionType !== 'teamMember' && (
              <NonBasicInputsReview
                answer={answer as INonOwnerFormData | IOwnerFormData}
              />
            )}
            {questionType === 'ownerForm' && (
              <OwnerSpecificInputsReview answer={answer as IOwnerFormData} />
            )}
            <ProfilePicReview img={profilePic} />
          </Fragment>
        )
      })}
    </div>
  )
}
