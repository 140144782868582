import { InfoIcon } from '@unionco/svg-icons'
import tw from 'twin.macro'

import { IInfoData } from '@unionco/alaris-app-types'

import LockedUI from '../lockedUI'

type IUnlockableAreaCardProps = {
  children: React.ReactNode | string
  info: IInfoData
  locked: boolean
  lockedMessage: string
  title: string
}

export const UnlockableAreaCard: React.FC<IUnlockableAreaCardProps> = ({
  children,
  info,
  locked,
  lockedMessage,
  title
}) => {
  return (
    <div className='c-flow | u-rounded u-bg-white u-p-6'>
      <div className='c-repel'>
        <h3>{title}</h3>
        {info && (
          <p className='o-body-2 | u-flex u-items-center'>
            <span className='u-mr-1'>More Info</span>
            <InfoIcon iconStyle='outline' css={tw`u-text-primary-500`} />
          </p>
        )}
      </div>
      {locked ? <LockedUI stepTitle={lockedMessage} /> : children}
    </div>
  )
}

export default UnlockableAreaCard
