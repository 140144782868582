export function displayNumberWithCommas(number: number): string {
  let nf = new Intl.NumberFormat('en-US')
  return nf.format(number)
}

export function displayNumberAsPercent(number: number): string {
  return `${number}%`
}

export function displayNumberInShortForm(number: number): string {
  const ranges = [
    {
      divider: 1e3,
      suffix: 'K'
    },
    {
      divider: 1e6,
      suffix: 'M'
    },
    {
      divider: 1e9,
      suffix: 'B'
    }
  ]
  for (let index = ranges.length - 1; index >= 0; index--) {
    if (number > ranges[index].divider) {
      let quotient = number / ranges[index].divider

      if (quotient < 10) {
        quotient = Math.floor(quotient * 10) / 10
      } else {
        quotient = Math.floor(quotient)
      }

      return quotient.toString() + ranges[index].suffix
    }
  }

  return number.toString()
}
