/** ModuleCard
 *  Collection of layout components for
 */
import InfoCardHeader, { TInfoCardHeaderComponent } from './InfoCardHeader'
import ModuleCardBody, { IModuleCardBodyProps } from './body'
import ModuleCardHeader, { IModuleCardHeaderProps } from './header'
import ModuleCardViewMore, { IModuleCardViewMore } from './viewMore'

interface IModuleCard {
  Body: React.FC<IModuleCardBodyProps>
  Header: React.FC<IModuleCardHeaderProps>
  InfoHeader: TInfoCardHeaderComponent
  ViewMore: React.FC<IModuleCardViewMore>
}

export const ModuleCard: IModuleCard = {
  Body: ModuleCardBody,
  Header: ModuleCardHeader,
  InfoHeader: InfoCardHeader,
  ViewMore: ModuleCardViewMore
}
