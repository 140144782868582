/* Questionnaire Util functions
 *
 */
import type {
  IFreeResponseQuestionData,
  ILocationsIndexesData,
  IQuestionSegmentData,
  IQuestionnaireData,
  IRangeQuestionData,
  ISearchQuestionData,
  TSelectableQuestionData
} from '@unionco/alaris-app-types'

import { getCurrentSegmentData } from 'utils'

import { TAxiosSubmitReturn } from './question'

export const submissionSuccessful = (result: TAxiosSubmitReturn) => {
  return (
    !!result && result.status && result.status >= 200 && result.status < 300
  )
}

// Get Question Data
export const getCurrentQuestionData = (
  pageLocations: ILocationsIndexesData,
  questionnaireData: IQuestionnaireData
) => {
  if (typeof pageLocations['question'] !== 'number') return
  const currentSegment = getCurrentSegmentData(
    pageLocations,
    questionnaireData
  ) as IQuestionSegmentData
  return currentSegment.questions
    ? currentSegment.questions[pageLocations['question']]
    : undefined
}

export const clearSelectedAnswers = (
  pageLocations: ILocationsIndexesData,
  questionnaireData: IQuestionnaireData
) => {
  const newState = { ...questionnaireData }
  const question = getCurrentQuestionData(pageLocations, newState)
  if (question) {
    switch (question.questionType) {
      case 'freeResponse':
        // Clear Free Response Answers values
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ; (question as IFreeResponseQuestionData).answers.map(
          (a) => (a.value = '')
        )
        break
      case 'range':
        // Reset value to default of 50
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ; (question as IRangeQuestionData).selectedAnswer.map(() => 50)
        break
      case 'search':
        // Clear all three state fields
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ; (question as ISearchQuestionData).selectedAnswer = null
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ; (question as ISearchQuestionData).otherAnswer = null
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ; (question as ISearchQuestionData).searchedAnswer = null
        break
      default:
        // Clear selectable answers
        // eslint-disable-next-line @typescript-eslint/no-extra-semi
        ; (question as TSelectableQuestionData).otherAnswer = null
          // eslint-disable-next-line @typescript-eslint/no-extra-semi
          ; (question as TSelectableQuestionData).selectedAnswer = null
        window.location.reload()

    }
  }

  return newState
}
