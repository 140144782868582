/** Account Setup Fixture
 *
 *  Example of a particular use case of a live session
 */
import type { ILiveSessionData } from '@unionco/alaris-app-types'

export const accountSetup = {
  icon: 'checkmark',
  status: {
    percent: 100,
    statusText: 'complete'
  },
  type: 'liveSession',
  title: 'Account Setup'
} as ILiveSessionData
