/** Financial Snapshot Test Data
 *  Test data for the <FinancialSnapshot /> module
 *  used in the profile template
 *  A seller only main module
 */
import { v4 as uuidv4 } from 'uuid'

import type { IFinancialSnapshotData, IFinancialSnapshotItem, ITitledDescriptionData } from '@unionco/alaris-app-types'

import { shortParagraphFixture } from '@fixtures/generic'

export const financialSnapshotItem = {
  title: 'Title',
  values: ['$10000', '$10000', '$10000']
} as IFinancialSnapshotItem

export const financialSpanShotParagraph = {
  title: 'Financial Snapshot Paragraphs',
  description: 'Financial Snapshot Paragraphs Description',
  moduleType: 'TitledDescription'
} as ITitledDescriptionData

export const financialSnapshotModuleTestData = {
  moduleType: 'FinancialSnapshot',
  title: 'Financial Snapshot',
  bottomCopy: {
    copy: shortParagraphFixture,
    title: 'Finacial Snapshot bottom title'
  },
  headings: ['Heading 1', 'Heading 2', 'Heading 3'],
  items: [
    financialSnapshotItem,
    financialSnapshotItem,
    financialSnapshotItem,
    financialSnapshotItem,
    financialSnapshotItem,
    financialSnapshotItem,
    financialSnapshotItem
  ],
  key: uuidv4(),
  container: 1,
  info: {
    copy: shortParagraphFixture
  },
  paragraphs: [financialSpanShotParagraph, financialSpanShotParagraph],
} as IFinancialSnapshotData
