/**
 * [@seandepottey]
 * Should remove the eslint disalbe and replace with a generic
 */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 *  partition
 *
 *  Splits an existing array into two arrays
 *
 * @param arr: Array of any type
 * @param fn: A function to sort the original array by
 *
 * @returns void: This alters the original array
 */
export const partition = (
  arr: any[],
  fn: (entry: unknown, i: number, arr: unknown[]) => boolean
): void => {
  arr.reduce(
    (acc, val, i, arr) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val)
      return acc
    },
    [[], []]
  )
}

/**
 * partitionNew
 *
 * Splits an array into two arrays, but returns as a new array
 *
 * @param arr: Array of any type
 * @param fn: A function to sort the original array by
 *
 * @returns [[T], [T]]: This returns a new array with two arrays
 * inside that are divided based on the function passed in.
 */
export const partitionNew = (
  arr: any[],
  fn: (entry: any, i: number, arr: any[]) => boolean
) => {
  return arr.reduce(
    (acc, val, i, arr) => {
      acc[fn(val, i, arr) ? 0 : 1].push(val)
      return acc
    },
    [[], []]
  )
}
