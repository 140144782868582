/** Acquisitions Model Module Test Data
 *  Test data for the <AcquisitionModel /> module
 *  used in the profile template
 *  A seller only side module
 *
 *  For information on data see documentation in corresponding type
 */
import { v4 as uuidv4 } from 'uuid'

import type { IAcquisitionModelData } from '@unionco/alaris-app-types'

import {
  shortParagraphFixture,
  titledListItemWithInfoTestData
} from '@fixtures/generic'

const testItems = []
for (let i = 0; i < 12; i++) {
  const item = titledListItemWithInfoTestData()
  testItems.push(item)
}

export const acquisitionModelTestData = {
  moduleType: 'AcquisitionModel',
  title: 'Acquisition Model',
  copy: shortParagraphFixture,
  items: testItems,
  container: 2,
  key: uuidv4()
} as IAcquisitionModelData
