/** Base Button Tag component
 *  For styled buttons use <StyledButton />
 */

export type TButtonTypes = 'button' | 'submit' | 'reset'

export interface IButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  type: TButtonTypes
}

export const Button: React.FC<IButtonProps> = ({
  children,
  type,
  ...props
}) => {
  return (
    <button {...props} type={type}>
      {children}
    </button>
  )
}

export default Button
