import React from 'react'

import { IHistoricGrowthData } from '@unionco/alaris-app-types'

import { BarChart } from 'components/Chart'
import { customTooltip } from '../customTooltip'
import { currencyAbbr } from '@unionco/utils'


import { ModuleCard, Divider, TitleAndCopy } from 'components'
import { MetricCard } from '../components'

import { themeColors } from 'styles'

export interface IHistoricGrowthProps {
  data: IHistoricGrowthData
}

export const HistoricGrowth: React.FC<IHistoricGrowthProps> = ({
  data: {
    historicGrowthData,
    netNewAssetGrowth,
    historicGrowthParagraph,
    revenueAndEbitdaTrend,
  }
}) => {
  const scaleXStyling = {
    grid: {
      display: false
    }
  }

  const scaleYStyling = {
    border: {
      dash: [5, 8],
      display: false
    }
  }

  const { primary } = themeColors

  return (
    historicGrowthData.length !== 0 || netNewAssetGrowth || historicGrowthParagraph || revenueAndEbitdaTrend ? (
      <ModuleCard.Body
        className='print:u-break-inside-avoid-page'
      >
        <ModuleCard.InfoHeader
          title={'Historic Growth'}
          trackingName='historic growth data module'
          className='print:u-break-after-avoid-page'
        />
        {historicGrowthData && historicGrowthData.length > 0 && (
          <div className='c-flow u-flow-space--100w'>
            <div className='c-switcher u-flow-space--400 '>
              {historicGrowthData.map((metric, index) => (
                <MetricCard
                  className='print:u-border-2'
                  classNames={
                    {
                    title: 'u-text-3'
                  }}
                  data={metric}
                  key={`MetricCard_${index}`}
                />
              ))}
            </div>
          </div>
        )}
        {netNewAssetGrowth && (
          <div className='c-flow'>
            <Divider title='NET NEW ASSET GROWTH' />
            <BarChart
              data={{
                labels: netNewAssetGrowth.labels,
                datasets: netNewAssetGrowth.datasets.map((dataset) => {
                  return {
                    ...dataset,
                    backgroundColor: dataset.data.map((value) => {
                      if (value > 0) {
                        return primary['500'];
                      }
                      if (value < 0) {
                        return primary['800'];
                      }
                      return dataset.backgroundColor; // default color
                    }),
                  };
                }),
              }}
              options={{
                scales: {
                  x: {
                    ...scaleXStyling
                  },
                  y: {
                    ...scaleYStyling
                  }
                },
                plugins: {
                  tooltip: {
                    enabled: false,
                    external: (context) =>
                      customTooltip(context, 'netNewAssetGrowth', 'Growth', value => `${value}%`)
                  }
                },
                animation: {
                  duration: 0
                } 
              }}
            />
            {netNewAssetGrowth.paragraph?.description != '' && (
              <TitleAndCopy
                title={netNewAssetGrowth.paragraph?.title}
                copy={netNewAssetGrowth.paragraph?.description}
              ></TitleAndCopy>)}
          </div>
        )}
        {historicGrowthParagraph && (
          <TitleAndCopy
            title={historicGrowthParagraph.title}
            copy={historicGrowthParagraph.description}
          ></TitleAndCopy>)}
        {revenueAndEbitdaTrend && (
          <div className='c-flow'>
            <Divider title='Revenue and EBITDA Trend' />
            <BarChart
              data={{
                labels: revenueAndEbitdaTrend.labels,
                datasets: revenueAndEbitdaTrend.datasets.map((dataset) => {
                  if (dataset.label === 'EBITDA') {
                    return { ...dataset, backgroundColor: primary['800'] };
                  }
                  if (dataset.label === 'Revenue') {
                    return { ...dataset, backgroundColor: primary['500'] };
                  }
                  return dataset;
                }),
              }}
              options={{
                scales: {
                  x: {
                    stacked: false,
                    ...scaleXStyling
                  },
                  y: {
                    stacked: false,
                    ...scaleYStyling
                  }
                },
                plugins: {
                  tooltip: {
                    enabled: false,
                    external: (context) => {
                      const titleContext = context.tooltip.dataPoints[0].dataset.label;
                      let title 
                      if (titleContext=== 'Revenue') {
                        title = 'Revenue';
                      } else if (titleContext === 'EBITDA') {
                        title = 'EBITDA';
                      } else {
                        title = 'Other';
                      }
                      customTooltip(context, 'revenueAndEbitdaTrend', title, currencyAbbr);
                    }
                  }
                }
              }}
            />
            {revenueAndEbitdaTrend.paragraph?.description != '' &&
              (<TitleAndCopy
                title={revenueAndEbitdaTrend.paragraph?.title}
                copy={revenueAndEbitdaTrend.paragraph?.description}
              />)}
          </div>
        )}
      </ModuleCard.Body>
    ) : (
      <div />
    )
  )
}
export default HistoricGrowth