/** Risk Factors Test Data
 *  Test data for the <RiskFactors /> module
 *  used in the profile template
 *  A seller only side module
 */
import { v4 as uuidv4 } from 'uuid'

import type { IRiskFactorsData } from '@unionco/alaris-app-types'

import {
  clientConcentrationRiskTestData,
  clientTerminationTestData,
  complianceLegalDisclosuresTestData,
  custodyTestData,
  keyEEStaffRiskTestData,
  productMixUsageTestData,
  structuralIssuesTestData,
  successionTestData
} from '@fixtures/business'
import { shortParagraphFixture } from '@fixtures/generic'

export const riskFactorModuleTestData = {
  moduleType: 'RiskFactors',
  title: 'Risk Factors',
  items: [
    complianceLegalDisclosuresTestData,
    keyEEStaffRiskTestData,
    successionTestData,
    custodyTestData,
    productMixUsageTestData,
    structuralIssuesTestData,
    clientConcentrationRiskTestData,
    clientTerminationTestData
  ],
  key: uuidv4(),
  container: 2,
  info: {
    copy: shortParagraphFixture
  }
} as IRiskFactorsData
