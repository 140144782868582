/**
 * Design System defaults
 */

const getMaxWidthBreakpoint = (breakpointRem) => {
  const maxWidthBreakpoint = breakpointRem * 16 + 0.95
  return `${maxWidthBreakpoint}px`
}

const pxToRem = (pixelSize) => {
  return `${pixelSize / 16}rem`
}

const gutterDefault = 'clamp(1rem, calc(0.96rem + 0.18vw), 1.125rem)'

const breakpointValues = {
  sm: 40, // 460px
  md: 50, // 800px
  lg: 62.75, // 1020px
  xl: 80, // 1280px
  xxl: 90 // 1440px
}

module.exports = {
  config: {
    prefix: 'u-',
    theme: {
      screens: {
        sm: `${breakpointValues.sm}rem`,
        md: `${breakpointValues.md}rem`,
        lg: `${breakpointValues.lg}rem`,
        xl: `${breakpointValues.xl}rem`,
        xxl: `${breakpointValues.xxl}rem`,
        'max-sm': `${getMaxWidthBreakpoint(breakpointValues.sm)}`,
        'max-md': `${getMaxWidthBreakpoint(breakpointValues.md)}`,
        'max-lg': `${getMaxWidthBreakpoint(breakpointValues.lg)}`,
        'max-xl': `${getMaxWidthBreakpoint(breakpointValues.xl)}`,
        'max-xxl': `${getMaxWidthBreakpoint(breakpointValues.xxl)}`
      },
      gutter: {
        default: gutterDefault,
        narrow: '1rem'
      },
      extend: {
        colors: {
          state: {
            danger: 'var(--color-state-danger, #ff0000)'
          }
        },
        spacing: {
          px: '1px',
          base: '1rem',
          0: '0rem',
          100: 'clamp(0.25rem, calc(0.23rem + 0.09vw), 0.3125rem)',
          200: 'clamp(0.5rem, calc(0.48rem + 0.09vw), 0.5625rem)',
          300: 'clamp(0.75rem, calc(0.71rem + 0.18vw), 0.875rem)',
          400: gutterDefault,
          500: 'clamp(1.5rem, calc(1.45rem + 0.27vw), 1.6875rem)',
          600: 'clamp(2rem, calc(1.93rem + 0.36vw), 2.25rem)',
          700: 'clamp(3rem, calc(2.89rem + 54vw), 3.375rem)',
          800: 'clamp(4rem, calc(3.86rem + 0.71vw), 4.5rem)',
          900: 'clamp(6rem, calc(5.79rem + 1.07vw), 6.75rem)'
        }
      },
      measure: {
        default: '60ch'
      },
      minWidth: {
        unset: 'unset',
        0: '0',
        25: '25%',
        50: '50%',
        75: '75%',
        100: '100%'
      },
      fontSize: {
        // Max Font Size aligns with type size in figma
        base: '1rem',
        1: `${pxToRem(10)}`,
        2: `clamp(${pxToRem(10)}, 0.59rem + 0.18vw, ${pxToRem(11)})`,
        3: `clamp(${pxToRem(11)}, 0.59rem + 0.18vw, ${pxToRem(12)})`,
        4: `clamp(${pxToRem(13)}, 0.79rem + 0.09vw, ${pxToRem(14)})`,
        5: `clamp(${pxToRem(14)}, 0.829rem + 0.19vw, ${pxToRem(17)})`,
        6: `clamp(${pxToRem(18)}, 0.982rem + 0.714vw, ${pxToRem(24)})`,
        7: `clamp(${pxToRem(24)}, 1.146rem + 0.81vw, ${pxToRem(30)})`
      }
    }
  },
  values: {
    gutterDefault
  }
}
