import { IconSVG } from '@unionco/svg-icons'

import { TSVGProps } from '@unionco/alaris-app-types'

export const SearchIcon: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <IconSVG viewBoxWidth={25} viewBoxHeight={24} {...props} ref={undefined}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.7529 19.4286C5.94009 19.4286 2.03857 15.5271 2.03857 10.7143C2.03857 5.90152 5.94009 2 10.7529 2C15.5656 2 19.4671 5.90152 19.4671 10.7143C19.4671 15.5271 15.5656 19.4286 10.7529 19.4286ZM22.4528 21L21.0386 22.4142L17.0529 18.4286L18.4671 17.0144L22.4528 21ZM17.4671 10.7143C17.4671 14.4225 14.4611 17.4286 10.7529 17.4286C7.04466 17.4286 4.03857 14.4225 4.03857 10.7143C4.03857 7.00609 7.04466 4 10.7529 4C14.4611 4 17.4671 7.00609 17.4671 10.7143Z'
        fill='#647381'
      />
      <mask
        id='mask0_2596_32833'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='2'
        y='2'
        width='21'
        height='21'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M10.7529 19.4286C5.94009 19.4286 2.03857 15.5271 2.03857 10.7143C2.03857 5.90152 5.94009 2 10.7529 2C15.5656 2 19.4671 5.90152 19.4671 10.7143C19.4671 15.5271 15.5656 19.4286 10.7529 19.4286ZM22.4528 21L21.0386 22.4142L17.0529 18.4286L18.4671 17.0144L22.4528 21ZM17.4671 10.7143C17.4671 14.4225 14.4611 17.4286 10.7529 17.4286C7.04466 17.4286 4.03857 14.4225 4.03857 10.7143C4.03857 7.00609 7.04466 4 10.7529 4C14.4611 4 17.4671 7.00609 17.4671 10.7143Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_2596_32833)'></g>
    </IconSVG>
  )
}

export default SearchIcon
