import { IconSVG } from '@unionco/svg-icons'

import { TSVGProps } from '@unionco/alaris-app-types'

export const PadLockIcon: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <IconSVG viewBoxWidth={13} viewBoxHeight={17} {...props} ref={undefined}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3276 7.08153C10.3283 7.04801 10.3283 7.01449 10.3283 6.98172V4.59814C10.3283 2.40372 8.54961 0.625 6.35597 0.625C4.16155 0.625 2.3836 2.40374 2.3836 4.59814V6.98172C2.3836 7.01449 2.3836 7.04801 2.38434 7.08153C1.01304 7.43386 0 8.67853 0 10.1594V13.3378C0 15.0927 1.42269 16.5161 3.1776 16.5161H9.53431C11.2892 16.5161 12.7119 15.0927 12.7119 13.3378V10.1594C12.7119 8.6786 11.6989 7.43392 10.3276 7.08153H10.3276ZM8.73955 6.98172V4.59814C8.73955 3.28122 7.67214 2.21456 6.35597 2.21456C5.0398 2.21456 3.97239 3.28122 3.97239 4.59814V6.98172H8.73955ZM11.1231 10.1593C11.1231 9.28185 10.4118 8.57051 9.53433 8.57051H3.17761C2.30015 8.57051 1.58881 9.28187 1.58881 10.1593V13.3377C1.58881 14.2151 2.30017 14.9265 3.17761 14.9265H9.53433C10.4118 14.9265 11.1231 14.2151 11.1231 13.3377V10.1593Z'
        fill='currentColor'
      />
    </IconSVG>
  )
}

export default PadLockIcon
