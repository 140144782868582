/**
 * Navigation click tracking util
 */
import { TNavigationClickTracking } from '@types'

import { tracking } from './tracking'

type TNavigationClickTrackingParams = Omit<TNavigationClickTracking, 'event'>

export const navigationClickTracking = (
  data: TNavigationClickTrackingParams
): void => {
  tracking<TNavigationClickTracking>({
    event: 'navigation_click',
    ...data
  })
}
