import Link from 'next/link'
import { useContext } from 'react'

import {
  TAnswerQuestionData,
  TFreeResponseAnswer,
  TQuestionData
} from '@unionco/alaris-app-types'

import { editAnswerText } from '../utils'
import { getSidebarRoute } from 'components/SidebarTemplate/util'

import { SidebarContext } from 'components'

import { FreeResponseReview } from './FreeResponseReview'
import AnswerBlock from './answerBlock'

export interface IReviewQuestionCard {
  question: TQuestionData
  questionnaireIndex: number
  segmentIndex: number
  index: number
}

export const ReviewQuestionCard: React.FC<IReviewQuestionCard> = ({
  index,
  question,
  segmentIndex
}) => {
  const { pageRoute } = useContext(SidebarContext)
  const { data: sectionData } = useContext(SidebarContext)
  const { answered, questionText, questionType } = question
  const isMulti = questionType === 'selectMultiple' || questionType === 'search'
  const questionNumber = index + 1

  const answers = question.hasOwnProperty('answers')
    ? (question as TAnswerQuestionData).answers
    : [{ label: '', value: '' }]

  const pullAnswers = () => {
    switch (questionType) {
      case 'freeResponse':
        return {
          answers,
          questionType,
          selectedAnswer: question.answers
        }
      case 'contractorForm':
      case 'departmentHeadForm':
      case 'basicTeamForm':
      case 'leaderForm':
      case 'nonOwnerForm':
      case 'ownerForm':
      case 'teamMember':
      case 'documentUpload':
        return {
          answers,
          questionType,
          selectedAnswer: question.answers
        }
      case 'selectMultiple':
        return {
          answers,
          questionType,
          selectedAnswer: question.selectedAnswer,
          otherAnswer: question.otherAnswer
        }
      case 'selectOne':
        return {
          answers,
          questionType,
          selectedAnswer: question.selectedAnswer,
          otherAnswer: question.otherAnswer
        }
      default:
        return {
          answers,
          questionType,
          selectedAnswer: question.selectedAnswer
        }
    }
  }

  const data = pullAnswers()

  return (
    <div
      className='b-reviewSegment | c-flow u-flow-space--200 | u-bg-primary-300 u-px-500 u-py-400 u-text-left'
      key={`QuestionCard_${index}`}
    >
      <p>Question {questionNumber}</p>
      <p className='o-subtitle-1 | u-text-black'>{questionText}</p>
      <div className='o-line u-bg-secondary-300'></div>
      <div className='c-repel | u-flex-nowrap'>
        <div className='c-flow u-flow-space--200'>
          <p>
            Your Answer
            {Array.isArray(data.selectedAnswer) &&
              data.selectedAnswer.length > 1
              ? 's'
              : ''}
          </p>
          {data.questionType !== 'freeResponse' ? (
            <AnswerBlock
              answered={answered}
              otherAnswer={data.otherAnswer}
              questionKey={questionText}
              {...data}
            />
          ) : (
            <FreeResponseReview
              answered={answered}
              answers={question.answers as TFreeResponseAnswer[]}
            />
          )}
        </div>
        <Link
          className='u-whitespace-nowrap u-text-secondary-800 hover:u-text-primary-500'
          href={getSidebarRoute(
            pageRoute,
            sectionData.key,
            segmentIndex,
            index
          )}
        >
          {editAnswerText(isMulti, answered)}
        </Link>
      </div>
    </div>
  )
}

export default ReviewQuestionCard
