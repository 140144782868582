/** Basic Answer
 *
 */
import type { IBasicAnswer, TImageProps } from '@unionco/alaris-app-types'

export const basicAnswerFixture = (
  label: string,
  value: string,
  image?: TImageProps | null
): IBasicAnswer => {
  return {
    label: label || 'Answer 1',
    value: value || 'answer1',
    image: image ? image : undefined
  }
}

export default basicAnswerFixture
