/** Average Growth Statistics
 *  Buyer Profile Main Module
 *  Paired in a subcontainer along with Organic Growth Capabilities
 *
 *  For information on specific data shown see documentation in corresponding type
 */
import type { IAverageGrowthStatisticsData } from '@unionco/alaris-app-types'

import { ModuleCard } from 'components'

import { MetricsColumn } from '../components'

export interface IAverageGrowthStatisticsProps {
  className?: string
  data: IAverageGrowthStatisticsData
}

export const AverageGrowthStatistics: React.FC<
  IAverageGrowthStatisticsProps
> = ({ className, data: { items, title } }) => {
  if (!items || items.length === 0) return <></>
  return (
    <ModuleCard.Body className={`u-min-w-[22rem] u-flex-grow ${className}`}>
      <ModuleCard.Header title={title || 'Average Growth Statistics'} />
      <div>
        <MetricsColumn data={items} />
      </div>
    </ModuleCard.Body>
  )
}

export default AverageGrowthStatistics
