/** Use Phase Summary Data
 *  Hook for grabbing a users current phase summary data
 *  Is used for components Timeline Progression and
 */
import { useContext } from 'react'

import { IPhaseSummaryData, TState } from '@unionco/alaris-app-types'

import { API_BASE } from '@appConstants'

import { IAMContext, TIAMContext } from 'context'

import useAuthRefetch from './useAuthRefetch'
import { useRouter } from 'next/router'

type TPhaseSummaryReturn = TState<
  IPhaseSummaryData,
  'summaryData',
  'summaryError'
>

export const usePhaseSummaryData = (
  useSegments?: boolean
): {
  refetch: () => void
} & TPhaseSummaryReturn => {
  const router = useRouter()

  const {
    IAMData: {
      id,
      impersonationMode,
      isPrimaryUser,
      progression: { phase }
    }
  } = useContext(IAMContext) as TIAMContext
  /**
   * If user is not in assessment (impersonation) mode or is not the primary user
   * Omit the assessment questions
   */

  // Extract the phase from the URL to ensure we route to the correct phase
  function extractPhaseFromUrl(route: string): string | null {
    const pathSegments = route.split('?')[0].split('/'); // Split by '?' and then by '/'

    if (pathSegments.length > 2 && (pathSegments[1] === 'seller' || pathSegments[1] === 'buyer')) {
      return pathSegments[2]; // Extract the phase only if the condition is met
    }

    return null; // Return null if neither pattern is found or pathSegments length is insufficient
  }

  const phaseLocation = extractPhaseFromUrl(router.asPath) || phase

  // optional parameter (defaulted to existing functionality) that we can override
  const omitAssessmentQuestions =
    impersonationMode !== 'impersonation' || !isPrimaryUser
  const endpointBase = `/phases/${phaseLocation}?userId=${id}&omitAssessmentQuestions=${omitAssessmentQuestions}`
  const endpoint = useSegments
    ? `${endpointBase}&includeSegments=true`
    : endpointBase
  const { data, error, refetch } = useAuthRefetch<IPhaseSummaryData>(endpoint)
  return {
    summaryData: data,
    summaryError: error,
    refetch: () => refetch(`${API_BASE}/api${endpoint}`, false)
  }
}
