import { Accordion } from '@unionco/components'
import React, { useContext } from 'react'

import type {
  IPhaseSummaryData,
  ISupportPageSummariesData,
  TPhaseStepSummaryData
} from '@unionco/alaris-app-types'

import { StepType } from '@unionco/alaris-utils'
import { getPhaseStepStatus } from 'components/SidebarTemplate/util'

import { ISidebarContext, SidebarContext } from 'components'

import WayfinderAccordionButton from './accordionButton'
import WayfinderDrawerList from './drawerList'

import { IAMContext, TIAMContext } from 'context'
export interface IWayfinderAccordionProps {
  multipleOpen?: boolean
}

export const WayfinderAccordion: React.FC<IWayfinderAccordionProps> = ({
  multipleOpen = false
}) => {
  const { isPhaseView, sectionProgressIndex, sidebarData } = useContext(
    SidebarContext
  ) as ISidebarContext
  const componentName = 'Wayfinder'

  const sections = isPhaseView
    ? (sidebarData as IPhaseSummaryData)['steps']
    : (sidebarData as ISupportPageSummariesData)['sections']

  const { IAMData: { impersonationMode } } = useContext(IAMContext) as TIAMContext
  return (
    <Accordion.Main multipleOpen={multipleOpen}>
      {!!sections &&
        sections.map((section, index) => {
          const { icon, key: sectionKey, segments, title, type } = section
          const reactKey = `${sectionKey}_${componentName}Item`
          if (section.key === 'account-setup')
            return <React.Fragment key={reactKey} />
          const categoryIndex = index
          const sectionStatus = isPhaseView
            ? getPhaseStepStatus(section as TPhaseStepSummaryData)
            : undefined
          const numOfSegments = segments ? segments.length : 0
          const accordionMinHeight =
            type === StepType.QUESTIONNAIRE ? numOfSegments + 1 : numOfSegments

          const isLockedSection = sectionKey !== 'block-questions-seller' && isPhaseView && sectionProgressIndex < index;
          const lockSection = !impersonationMode && isLockedSection;

          return (
            <Accordion.Item key={reactKey} index={index}>
              <WayfinderAccordionButton
                title={title}
                icon={icon}
                index={index}
                sectionKey={sectionKey}
                subtext={sectionStatus}
                status={
                  section.hasOwnProperty('status')
                    ? (section as TPhaseStepSummaryData).status
                    : null
                }
                type={type}
                locked={false} //Temporarily changing to unlock all sections
              />
              {type !== 'liveSession' && (
                <Accordion.Drawer
                  index={index}
                  numOfItems={accordionMinHeight}
                  itemHeight={72}
                  maxItemsShown={25}
                  className='u-bg-gradient-blue-black'
                >
                  <WayfinderDrawerList
                    sectionIndex={categoryIndex}
                    sectionKey={sectionKey}
                    segments={segments}
                    type={type}
                  />
                </Accordion.Drawer>
              )}
            </Accordion.Item>
          )
        })}
    </Accordion.Main>
  )
}

export default WayfinderAccordion
