import { StyledSelect } from '@unionco/components'

import { fieldIsValid } from '../utils'
import { cx } from '@unionco/utils'

import LabelAndInput from '../labelAndInput'

interface IJobTitleProps {
  defaultValue: string
  validation: null | true | string[]
}

export const JobTitleSelect: React.FC<IJobTitleProps> = ({
  defaultValue,
  validation
}) => {
  const name = 'jobTitle'
  return (
    <LabelAndInput label='Title' required className='u-basis-[calc(66%-.5rem)]'>
      <StyledSelect
        className={cx('u-text-secondary-800', fieldIsValid(name, validation))}
        classNames={{ select: 'u-bg-primary-300 u-text-secondary-800' }}
        name={name}
        defaultValue={defaultValue}
        required
      >
        <option value={'unanswered'}>Select a title</option>
        <option value={'ceo'}>CEO</option>
        <option value={'coo'}>COO</option>
        <option value={'cto'}>CTO</option>
        <option value={'cfo'}>CFO</option>
        <option value={'partner'}>Partner</option>
      </StyledSelect>
    </LabelAndInput>
  )
}

export default JobTitleSelect
