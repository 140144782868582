import { IconSVG } from '@unionco/svg-icons'

import { TSVGProps } from '@unionco/alaris-app-types'

export const ListIcon: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <IconSVG
      viewBoxWidth={13}
      viewBoxHeight={10}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{
        transform: 'translate(12%,0%)'
      }}
      {...props}
      ref={undefined}
    >
      <path d='M0 0.96402H1.83333V2.76402H0V0.96402Z' fill='white' />
      <path d='M3.6667 0.96402H11V2.76402H3.6667V0.96402Z' fill='white' />
      <path d='M0 4.56402H1.83333V6.36402H0V4.56402Z' fill='white' />
      <path d='M3.6667 4.56402H11V6.36402H3.6667V4.56402Z' fill='white' />
      <path d='M0 8.16402H1.83333V9.96402H0V8.16402Z' fill='white' />
      <path d='M3.6667 8.16402H11V9.96402H3.6667V8.16402Z' fill='white' />
    </IconSVG>
  )
}

export default ListIcon
