/** Upload icon
 *
 */
import { IconSVG } from '@unionco/svg-icons'

interface IUploadIconProps {
  className?: string
}

export const UploadIcon: React.FC<IUploadIconProps> = ({ ...props }) => {
  return (
    <IconSVG viewBoxWidth={27} viewBoxHeight={30} {...props}>
      <path
        id='Vector'
        d='M19.2028 20.5026C18.9889 20.1966 18.5299 20.1357 18.2538 20.4106L17.5199 21.1146C17.245 21.3895 16.8159 21.3285 16.5709 21.0536L14.2462 17.9017C13.9713 17.5348 13.4501 17.5646 13.2063 17.9328L9.19737 24.3272C8.92246 24.755 9.22844 25.3061 9.71729 25.3061H21.4054C21.9254 25.3061 22.2313 24.7252 21.9254 24.2961L19.2028 20.5026Z'
        fill='#8FA8BE'
      />
      <path
        id='Vector_2'
        d='M21.4393 16.8599C21.4393 19.0221 18.1953 19.0221 18.1953 16.8599C18.1953 14.6977 21.4393 14.6977 21.4393 16.8599Z'
        fill='#8FA8BE'
      />
      <path
        id='Vector_3'
        d='M26.2411 11.262L20.0915 5.08026C19.9075 4.89619 19.6637 4.80535 19.4186 4.80535L13.7268 4.77428C12.7479 2.11249 10.1768 0.18457 7.14819 0.18457C3.29223 0.215646 0.140625 3.36738 0.140625 7.22212C0.140625 10.221 2.03746 12.7907 4.69912 13.7695V27.0484C4.69912 28.2724 5.67802 29.2513 6.90194 29.2513H24.3116C25.5355 29.2513 26.5144 28.2724 26.5144 27.0484L26.5168 11.9036C26.5168 11.6586 26.4248 11.4148 26.2407 11.2618L26.2411 11.262ZM20.3666 8.04919L23.3045 10.9871L20.3666 10.9859V8.04919ZM2.03899 7.22209C2.03899 4.40732 4.33384 2.08131 7.17977 2.08131C10.0256 2.08131 12.3205 4.37617 12.3205 7.22209C12.3205 10.068 10.0257 12.3629 7.17977 12.3629C4.33391 12.3629 2.03899 10.068 2.03899 7.22209V7.22209ZM24.3135 27.3868H6.90377C6.71971 27.3868 6.59779 27.2338 6.59779 27.0808V14.2297C6.78186 14.2607 6.96473 14.2607 7.17867 14.2607C11.0346 14.2607 14.185 11.1088 14.185 7.25439C14.185 7.07033 14.185 6.88746 14.1539 6.70338H18.4689V11.9051C18.4689 12.425 18.8968 12.8541 19.4179 12.8541H24.6196V27.051C24.6196 27.2338 24.4666 27.3868 24.3136 27.3868L24.3135 27.3868Z'
        fill='#8FA8BE'
      />
      <path
        id='Vector_4'
        d='M8.0972 9.76168V6.97677L8.31115 7.16084C8.49522 7.3449 8.73903 7.40586 8.9542 7.40586C9.19922 7.40586 9.47412 7.31383 9.6582 7.09988C10.0251 6.73295 9.99527 6.12099 9.59724 5.75405L7.79245 4.07116C7.42551 3.73409 6.8745 3.73409 6.50757 4.07116L4.73264 5.75405C4.36571 6.12099 4.33462 6.70308 4.70157 7.09988C5.0685 7.46681 5.65059 7.4979 6.04739 7.13095L6.26134 6.94689V9.7318C6.26134 10.2517 6.68922 10.6808 7.21037 10.6808C7.73029 10.6796 8.09722 10.2816 8.09722 9.7617L8.0972 9.76168Z'
        fill='#8FA8BE'
      />
    </IconSVG>
  )
}

export default UploadIcon
