/** Confidentiality Agreement
 *  User required agreement inside of modal
 *  Loaded through login component, but has pieces with potential for smaller
 *  components
 */
import { Card, CheckboxInput, Modal, ModalContext } from '@unionco/components'
import axios from 'axios'
import { signOut } from 'next-auth/react'
import type { FC } from 'react'
import { useContext, useEffect, useRef, useState } from 'react'

import type { IConfidentialityAgreementData } from '@unionco/alaris-app-types'

import { API_BASE } from '@appConstants'

import { TIAMContext, TUserContext, UserContext } from 'context'
import { IAMContext } from 'context'

import { AuthBGUI, StyledButton } from 'components'

// import tw from 'twin.macro'

interface IConfidentialityAgreementUIProps {
  data: IConfidentialityAgreementData
}

export const ConfidentialityAgreementUI: FC<
  IConfidentialityAgreementUIProps
> = ({ data: { agreementText, copy, title, version, warning } }) => {
  const { jwt, setDisplayAgreement } = useContext(UserContext) as TUserContext
  const {
    IAMData: { id }
  } = useContext(IAMContext) as TIAMContext
  const { setModalContent, setModalIsOpen } = useContext(ModalContext)
  const [agreed, setAgreed] = useState<boolean>(false)
  const scrollBoxRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<HTMLFormElement>(null)

  /** Agreed onClick
   *
   */
  const toggleAcceptButton = () => {
    if (!formRef.current) return

    const { current } = formRef
    const checkBox = current.getElementsByClassName(
      'b-Checkbox__input'
    )[0] as HTMLInputElement

    setAgreed(checkBox.checked)
  }

  /** Accept Agreement
   *
   */
  const acceptAgreement = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()

    const result = await axios.put(
      `${API_BASE}/api/user-agreements/accept`,
      {
        userId: id,
        version
      },
      {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      }
    )

    if (result.status === 200) {
      setDisplayAgreement(false)
    } else {
      console.error(result)
    }
  }

  /** Decline Agreement
   *
   */
  const declineAgreement = () => {
    signOut()
  }

  /** Decline Agreement Warning
   *
   */
  const declineAgreementWarning = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault()
    setModalContent(
      <Modal.Card>
        <div className='c-flow | u-w-screen u-max-w-[45ch]'>
          <p>
            {warning ||
              "If you don't agree to the terms and conditions you won't be able to access the application and will be signed out."}
          </p>
          <div className='c-repel'>
            <StyledButton
              type='button'
              theme='secondaryAlt'
              themeStyle='outline'
              onClick={declineAgreement}
            >
              I&apos;m sure
            </StyledButton>
            <StyledButton
              type='button'
              theme='primary'
              themeStyle='solid'
              onClick={() => setModalIsOpen(false)}
            >
              Back to agreement
            </StyledButton>
          </div>
        </div>
      </Modal.Card>
    )
    setModalIsOpen(true)
  }

  return (
    <AuthBGUI id={'confidentialityAgreementModal'}>
      <div className='c-withLayer | u-flex u-h-screen u-w-screen u-items-center u-justify-center'>
        <div className='c-layer c-layer--shadowbox' />
        <div className='c-flow | u-items-center u-justify-center'>
          <Card className='c-flow | u-z-10 u-max-h-[100vh] u-bg-white u-p-400 lg:u-p-600'>
            <div className='c-flow | u-text-center'>
              <h2>{title || 'Confidentiality Agreement'}</h2>
              <p>{copy || 'Please read and accept to continue'}</p>
            </div>
            <div
              ref={scrollBoxRef}
              className='b-ScrollBox | u-max-h-[22.1875rem] u-w-screen u-max-w-[30.25rem] u-overflow-y-auto u-text-3'
              dangerouslySetInnerHTML={{ __html: agreementText }}
            />
            <form className='c-flow | u-items-center' ref={formRef}>
              <CheckboxInput
                id={'confidentialityAgreementCheckbox'}
                label='I have read the Terms & Conditions'
                // cssClasses={{ label: tw`u-text-grayscale-800` }}
                onClick={toggleAcceptButton}
              />
              <div className='c-repel | u-w-full'>
                <StyledButton
                  id='confidentialityAgreement_DeclineBtn'
                  type='button'
                  themeStyle='outline'
                  theme='secondaryAlt'
                  onClick={(e) => declineAgreementWarning(e)}
                >
                  Decline
                </StyledButton>
                <StyledButton
                  id='confidentialityAgreement_AcceptBtn'
                  type='submit'
                  themeStyle='solid'
                  theme='primary'
                  disabled={!agreed}
                  onClick={(e) => acceptAgreement(e)}
                >
                  Accept & Continue
                </StyledButton>
              </div>
            </form>
          </Card>
        </div>
      </div>
    </AuthBGUI>
  )
}

export default ConfidentialityAgreementUI
