/** Team Members Test Data
 *  Test data for the <TeamMembers /> module
 *  used in the profile template
 *  A shared main module
 */
import { v4 as uuidv4 } from 'uuid'

import type { ITeamMembersModuleData } from '@unionco/alaris-app-types'

import { employeeTestData, founderTestData } from '@fixtures/business'
import { shortParagraphFixture } from '@fixtures/generic'

export const teamMemberModuleTestData = {
  moduleType: 'TeamMembers',
  title: 'Team Members',
  employees: [
    employeeTestData,
    employeeTestData,
    employeeTestData,
    employeeTestData
  ],
  founders: [founderTestData, founderTestData],
  key: uuidv4(),
  container: 1,
  info: {
    copy: shortParagraphFixture
  }
} as ITeamMembersModuleData
