import { cx } from '@unionco/utils'

interface ISidebarLayout {
  className?: string
  children: [React.ReactNode, React.ReactNode]
  sideBarLeft?: boolean
}

export const SidebarLayout: React.FC<ISidebarLayout> = ({
  children,
  className,
  sideBarLeft = false
}) => {
  const sideBarSideClass = sideBarLeft
    ? 'c-withSidebar--ltr'
    : 'c-withSidebar--rtl'

  return (
    <div className={cx('c-withSidebar', sideBarSideClass, className)}>
      {children}
    </div>
  )
}

export default SidebarLayout
