interface ISwiperSlideProps {
  children: React.ReactNode
}

export const SwiperSlide: React.FC<ISwiperSlideProps> = ({
  children,
  ...rest
}) => {
  // return <div className='swiper-slide'>{children}</div>
  return <swiper-slide {...rest}>{children}</swiper-slide>
}
