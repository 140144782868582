/** Client Demographics Test Data
 *  Test data for the <ClientDemographics /> module
 *  used in the profile template
 *  A seller only main module
 */
import { v4 as uuidv4 } from 'uuid'

import type { IClientDemographicsData, ITitledDescriptionData } from '@unionco/alaris-app-types'

import {
  customMetricTestData,
  hhsPerAumRange,
  hhsServedByAdvisor,
  netNewAssetGrowth,
  revenueByClientSegment,
  threeYearAumTrend,
  threeYearGrowthMix,
  trendingRevenueMix
} from '@fixtures/business'
import { shortParagraphFixture } from '@fixtures/generic'

export const clientProfileAndSegmentationParagraphs = [{
  title: 'Client Profile & Segmentation Paragraphs',
  description: 'Client Profile & Segmentation Paragraphs Description',
  moduleType: 'TitledDescription'
}] as ITitledDescriptionData[]

export const clientDemographicsModuleTestData = {
  moduleType: 'ClientDemographics',
  title: 'Client Demographics',
  highlights: [
    customMetricTestData('Average Fee per Households', '$15.3K'),
    customMetricTestData('Average Age of Households', '58.8'),
    customMetricTestData('Avg 3 Yr Client Additions', '$27.6M'),
    customMetricTestData('Avg 3 Year Client Withdrawls', '$16.3M')
  ],
  revenueData: revenueByClientSegment,
  threeYearAumTrend,
  hhsPerAumRange,
  trendingRevenueMix,
  threeYearGrowthMix,
  hhsServedByAdvisor,
  netNewAssetGrowth,
  assetsUnderManagement: {
    labels: ['2019', '2020', '2021', '2022'],
    datasets: [
      {
        // borderWidth: 1,
        data: [
          { x: 1, y: 10 },
          { x: 2, y: 13 },
          { x: 3, y: 23 },
          { x: 4, y: 36 }
        ],
        label: ''
      }
    ]
  },
  ebitda: {
    labels: ['2022', '2021', '2020', '2019'],
    datasets: [
      {
        borderWidth: 1,
        data: [11250000, 10000000, 8750000, 3800000],
        label: ''
      }
    ]
  },
  distributionOfAssets: {
    labels: ['Asset 1', 'Asset 2', 'Asset 3'],
    datasets: [
      {
        data: [100, 200, 300],
        label: 'Asset Distribution'
      }
    ]
  },
  generationalSummaryByAUM: [
    {
      id: 1,
      generationRange: 'Generation Z',
      percentageOfRIAAUM: '10',
    },
    {
      id: 2,
      generationRange: 'Millennials',
      percentageOfRIAAUM: '20',
    },
    {
      id: 3,
      generationRange: 'Generation X',
      percentageOfRIAAUM: '30',
    },
    {
      id: 4,
      generationRange: 'Baby Boomers',
      percentageOfRIAAUM: '40',
    },
  ],

  annualRevenueMix: {
    labels: ['2020', '2021'],
    datasets: [
      {
        data: [30, 70, 20, 80],
        label: 'Annual Revenue'
      }
    ]
  },
  key: uuidv4(),
  container: 1,
  info: {
    copy: shortParagraphFixture
  },
  clientProfileAndSegmentationParagraphs: clientProfileAndSegmentationParagraphs
} as IClientDemographicsData