/** Yes No Segment Fixture
 *
 *  See bottom for ouput
 */
import { v4 as uuidv4 } from 'uuid'

import type { IQuestionSegmentData } from '@unionco/alaris-app-types'

import { yesNoQuestionFixture } from '@fixtures/phase/questionnaire/question'

export const yesNoSegmentFixture = {
  title: 'Segmet 4: Yes/No',
  introCopy: 'Progression based intro copy',
  status: {
    percent: 0,
    statusText: 'notStarted'
  },
  questions: [yesNoQuestionFixture],
  key: uuidv4(),
  type: 'questionSegment',
  isAlarisOnly: false,
  isActive: true
} as IQuestionSegmentData

/**
 *
 * {
      segmentTitle: 'Segmet 4: Yes/No',
      introCopy: 'Progression based intro copy',
      status: {
        percent: 0,
        statusText: 'notStarted'
      } as ISectionStatus,
      questions: [
        {
          answers: [
            {
              label: 'Yes sub label text',
              value: 'answer1'
            },
            {
              label: 'No sub label text',
              value: 'answer2'
            }
          ],
          answered: false,
          questionType: 'yesNo',
          questionText: 'Yes/No Question?',
          selectedAnswer: null
        }
      ]
    }
*/
