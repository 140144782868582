/** Team Members form Owners
 *
 *
 *  For profile pic
 *  @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
 *  @see https://docs.strapi.io/dev-docs/plugins/upload
 */
import { Accordion, Card } from '@unionco/components'
import { FlyoutContext } from '@unionco/components'
import { capitalizeAndSplit } from '@unionco/alaris-utils'
import { AxiosResponse } from 'axios'
import { useContext, useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'

import {
  IContractorFormData,
  IDepartmentHeadFormData,
  IInputLabelValue,
  ILeaderFormData,
  INonOwnerFormData,
  IOwnerFormData,
  IOwnerQuestionData,
  IQuestionSegmentData,
  IQuestionnaireData,
  ITeamMemberFormData,
  IBasicTeamFormData,
  TFormAnswerData,
  TFormQuestionData,
  TFormQuestionTypes
} from '@unionco/alaris-app-types'

import { generateFormDefaults, getFormValues } from './utils'
import { createBooleanArrayFromNumber } from '@unionco/alaris-utils'
import { cx } from '@unionco/utils'
import { getCurrentQuestionData } from 'components/questionnaire/util'
import { getCurrentSegmentData } from 'utils'

import { CollapsibleItemsCard, SidebarContext, StyledButton } from 'components'

import { QuestionContext } from '../context'
import ContractorForm from './ContractorForm'
import DepartmentHeadForm from './DepartmentHeadForm'
import LeaderForm from './LeaderForm'
import NonOwnerForm from './NonOwnerForm'
import OwnerForm from './OwnerForm'
import TeamMemberForm from './TeamMemberForm'
import BasicTeamForm from './BasicTeamForm'

export const inputStyling = {
  input: 'u-rounded u-p-400 u-bg-primary-300'
}

type TOwnersForm = {
  className?: string
  data: TFormQuestionData
  questionType: TFormQuestionTypes
}

export const TeamMembersQuestion: React.FC<TOwnersForm> = ({
  className,
  data,
  questionType
}) => {
  // Prop destructing
  const { answers, entriesTitle, triggerQuestionType } = data
  // Context Data
  const { data: stepData, pageLocations } = useContext(SidebarContext)
  const { submitAnswer } = useContext(QuestionContext)
  const { addFlyout } = useContext(FlyoutContext)
  // Local State
  const [savedEntries, setSavedEntries] = useState<boolean[]>([false])
  const [entriesData, setEntriesData] = useState<TFormAnswerData[]>(answers)
  // Local Refs
  const questionRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const defaultValue = createBooleanArrayFromNumber(data.answers.length)
    setSavedEntries(defaultValue)
  }, [data.answers.length, setSavedEntries])

  /**
   * Save Entry function
   * Saves the entry to the Strapi API
   * @param index - the index of the entry to be saved
   * @param transactionGoals - the transactionGoals array of objects
   */
  async function saveEntry(index: number, transactionGoals: IInputLabelValue[] | null = null): Promise<null | true | string[]> {
    if (!questionRef || !questionRef.current) return null
    const { current: form } = questionRef
    const entry = form.getElementsByClassName('b-Entry')[index]
    const newState = { ...stepData } as IQuestionnaireData
    const currentSegment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData

    const currentQuestion = getCurrentQuestionData(
      pageLocations,
      newState
    ) as TFormQuestionData

    const requiredFields = [...entry.querySelectorAll('[required]')] as Array<
      HTMLInputElement | HTMLSelectElement
    >
    const missingFields = requiredFields.filter(
      (f) => f.value === 'unanswered' || f.value === ''
    )
    const valid = missingFields.length === 0
    if (valid) {
      currentQuestion.answers[index] = getFormValues(
        entry,
        questionType,
        answers
      )

      // Sets transactionGoal to the transactionGoals array of objects if it exists
      if ('transactionGoal' in currentQuestion.answers[index]) {
        (currentQuestion.answers[index] as IOwnerFormData).transactionGoal = transactionGoals
      }

      newState.segments[pageLocations['segment']] = currentSegment

      const result = (await submitAnswer(
        true,
        newState,
        pageLocations
      )) as AxiosResponse<unknown, unknown>

      const { status } = result
      if (status >= 200 && status < 300) {
        savedEntries[index] = true
        addFlyout(<p className='u-text-state-good'>Entry saved!</p>)
        return true
      } else if (status >= 400 && status < 500) {
        addFlyout(
          <p className='u-text-state-danger'>
            Sorry! There was a client side error when trying to save.
          </p>
        )
      } else if (status >= 500) {
        addFlyout(
          <p className='u-text-state-danger'>
            Sorry! There was a server side error when trying to save.
          </p>
        )
      }
    }

    return missingFields.map((f) => f.name)
  }

  const deleteEntry = async (index: number) => {
    const updatedEntries = [...entriesData]
    updatedEntries.splice(index, 1)

    setEntriesData(updatedEntries)

    const newState = { ...stepData } as IQuestionnaireData
    const currentSegment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData
    const currentQuestion = getCurrentQuestionData(
      pageLocations,
      newState
    ) as TFormQuestionData

    if (currentQuestion) {
      const { questionType } = currentQuestion
      switch (questionType) {
        case 'contractorForm':
          currentQuestion.answers = updatedEntries as IContractorFormData[]
          break
        case 'departmentHeadForm':
          currentQuestion.answers = updatedEntries as IDepartmentHeadFormData[]
          break
        case 'basicTeamForm':
          currentQuestion.answers = updatedEntries as IBasicTeamFormData[]
          break
        case 'nonOwnerForm':
          currentQuestion.answers = updatedEntries as INonOwnerFormData[]
          break
        case 'ownerForm':
          currentQuestion.answers = updatedEntries as IOwnerFormData[]
          break
        case 'leaderForm':
          currentQuestion.answers = updatedEntries as ILeaderFormData[]
          break
        // Team Member
        default:
          currentQuestion.answers = updatedEntries as ITeamMemberFormData[]
      }
    }

    newState.segments[pageLocations['segment']] = currentSegment

    const result = await submitAnswer(true, newState, pageLocations)

    if (!!result && result.status === 200) {
      return true
    }
  }

  /**
   * Add team member function
   * Creates a new entry and adds it to local state
   */
  const addEntry = () => {
    const newEntries: TFormAnswerData[] = [
      ...entriesData,
      generateFormDefaults(questionType)
    ]
    setEntriesData(newEntries)
  }

  useEffect(() => {
    setEntriesData(answers)
  }, [answers])

  const headerTitles = {
    teamMember: 'Team Members',
    ownerForm: 'Owners',
    contractorForm: '1099 Contractors',
    nonOwnerForm: 'Client Facing Non-Owners',
    leaderForm: 'Leaders',
    departmentHeadForm: 'Department Heads',
    basicTeamForm: 'Team Member' //default case if more explicit name isn't saved in triggerQuestionType
  }

  /**
   * Handles selection of correct header title; BasicTeamForm is used as a generic form type
   * and selects more specific name stored in triggerQuestionType
   */
  const selectHeaderTitle = (questionType: TFormQuestionTypes) => {
    if (questionType == 'basicTeamForm' && triggerQuestionType) {
      return capitalizeAndSplit(triggerQuestionType)
    }
    return headerTitles[questionType] || 'Default Header Title'
  }
  const headerTitle = selectHeaderTitle(questionType)
  return (
    <Card
      className={cx('c-flow u-p-500', className)}
      bgColorClass={tw`u-bg-primary-300`}
    >
      <Accordion.Main className='c-flow' multipleOpen>
        <CollapsibleItemsCard.Header
          title={headerTitle}
          numOfItems={entriesData.length}
        />
        <div className='c-flow' ref={questionRef}>
          {entriesData &&
            entriesData.map((entry, i) =>
              questionType === 'teamMember' ? (
                <TeamMemberForm
                  data={entry as ITeamMemberFormData}
                  entriesTitle={entriesTitle}
                  index={i}
                  key={`TeamMember_${i}`}
                  deleteEntry={deleteEntry}
                  saveEntry={saveEntry}
                  saved={savedEntries[i]}
                />
              ) : questionType === 'ownerForm' ? (
                <OwnerForm
                  data={entry as IOwnerFormData}
                  entriesTitle={entriesTitle}
                  index={i}
                  key={`OwnerForm_${i}`}
                  info={
                    (data as IOwnerQuestionData).additionalNotesTooltip ||
                    undefined
                  }
                  deleteEntry={deleteEntry}
                  saveEntry={saveEntry}
                  saved={savedEntries[i]}
                />
              ) : questionType === 'nonOwnerForm' ? (
                <NonOwnerForm
                  data={entry as INonOwnerFormData}
                  entriesTitle={entriesTitle}
                  index={i}
                  key={`NonOwnerForm_${i}`}
                  deleteEntry={deleteEntry}
                  saveEntry={saveEntry}
                  saved={savedEntries[i]}
                />
              ) : questionType === 'leaderForm' ? (
                <LeaderForm
                  data={entry as ILeaderFormData}
                  entriesTitle={entriesTitle}
                  index={i}
                  key={`LeaderForm_${i}`}
                  deleteEntry={deleteEntry}
                  saveEntry={saveEntry}
                  saved={savedEntries[i]}
                />
              ) : questionType === 'departmentHeadForm' ? (
                <DepartmentHeadForm
                  data={entry as IDepartmentHeadFormData}
                  entriesTitle={entriesTitle}
                  index={i}
                  key={`DepartmentHeadForm_${i}`}
                  deleteEntry={deleteEntry}
                  saveEntry={saveEntry}
                  saved={savedEntries[i]}
                />
              ) : questionType === 'basicTeamForm' ? (
                <BasicTeamForm
                  data={entry as IBasicTeamFormData}
                  entriesTitle={entriesTitle}
                  index={i}
                  key={`BasicTeamForm_${i}`}
                  deleteEntry={deleteEntry}
                  saveEntry={saveEntry}
                  saved={savedEntries[i]}
                />
              )
                : (
                  <ContractorForm
                    data={entry as IContractorFormData}
                    entriesTitle={entriesTitle}
                    index={i}
                    key={`TeamMember_${i}`}
                    deleteEntry={deleteEntry}
                    saveEntry={saveEntry}
                    saved={savedEntries[i]}
                  />
                )
            )}
        </div>
      </Accordion.Main>
      <div className='u-flex u-justify-center'>
        <StyledButton
          type='button'
          theme='darkAlt'
          themeStyle='outline'
          onClick={addEntry}
        >
          Add Team Member
        </StyledButton>
      </div>
    </Card>
  )
}

export default TeamMembersQuestion
