import { TSVGProps } from '@unionco/alaris-app-types'

export const Logo: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <svg
      width='180'
      height='31'
      viewBox='0 0 180 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M41.3144 26.8882V0.988996H38.0464V30.1726H55.7428V26.8882H41.3144Z'
        fill='currentColor'
      />
      <path
        d='M170.647 13.8814C164.34 12.5252 163.18 11.0219 163.18 8.57085C163.18 5.9891 165.565 4.17533 168.964 4.17533C171.889 4.17533 174.422 5.12306 176.938 7.18193L177.085 7.29631L177.199 7.14925L179.046 4.71456L179.144 4.58384L179.013 4.4858C175.974 2.05111 173 1.00534 169.062 1.00534C163.621 1.00534 159.683 4.30606 159.683 8.86497C159.683 13.4239 162.363 15.6625 169.225 17.1658C175.239 18.4403 176.382 19.9763 176.382 22.362C176.382 25.1235 173.948 26.9862 170.304 26.9862C166.66 26.9862 163.882 25.8588 160.81 23.0646L160.68 22.9502L160.565 23.0809L158.604 25.4012L158.49 25.532L158.621 25.6463C162.085 28.7346 165.761 30.1726 170.19 30.1726C176.023 30.1726 179.928 26.9045 179.928 22.0352C179.928 17.754 177.15 15.3193 170.663 13.8977L170.647 13.8814Z'
        fill='currentColor'
      />
      <path
        d='M90.1389 30.1726H93.7174L80.8904 0.988996L68.0633 30.1726H71.6418L75.7922 20.7443H82.4264L81.0048 17.4599H77.2302L80.8904 9.12641L90.1389 30.1726Z'
        fill='currentColor'
      />
      <path
        d='M120.924 19.9109C124.47 18.424 126.953 14.9108 126.953 10.8258C126.953 5.38451 122.542 0.972656 117.1 0.972656H106.022V30.1726H109.29V4.25703H117.084C120.711 4.25703 123.653 7.19827 123.653 10.8258C123.653 14.4533 120.711 17.3946 117.084 17.3946H113.048L111.626 20.6626H117.427L123.979 30.1399H127.966L120.875 19.8946L120.924 19.9109Z'
        fill='currentColor'
      />
      <path
        d='M144.895 0.988996H141.627V30.1726H144.895V0.988996Z'
        fill='currentColor'
      />
      <path
        d='M22.131 30.1726H25.7095L12.8988 0.988996L0.0717773 30.1726H3.65028L7.80069 20.7443H14.4185L12.9969 17.4599H9.23863L12.8988 9.12641L22.131 30.1726Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default Logo
