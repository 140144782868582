/**
 *  import { useSearchBox } from "react-instantsearch-hooks-web"
 *  import { SearchBoxProps } from 'react-instantsearch-hooks-web'
 */
import { XIcon } from '@unionco/svg-icons'
import {
  SearchBoxClassNames,
  SearchBoxTranslations
} from 'react-instantsearch-hooks-web/dist/es/ui/SearchBox'

import { TDivProps, TSVGProps } from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

import { LoadingSpinner, SearchIcon } from 'components'

type TFormFunction = (e: React.FormEvent<HTMLFormElement>) => void

export type IconProps = {
  classNames: Partial<SearchBoxClassNames>
}

export type TCustomSearchBoxUIProps = Omit<
  TDivProps,
  'onSubmit' | 'onReset' | 'onChange'
> &
  Pick<React.ComponentProps<'form'>, 'onSubmit'> &
  Required<Pick<React.ComponentProps<'form'>, 'onReset'>> &
  Pick<
    React.ComponentProps<'input'>,
    'placeholder' | 'onChange' | 'autoFocus'
  > & {
    classNames?: Partial<SearchBoxClassNames>
    formRef?: React.RefObject<HTMLFormElement>
    inputRef: React.RefObject<HTMLInputElement>
    onReset: TFormFunction
    onSubmit: TFormFunction
    value: string
    resetIconComponent?: React.JSXElementConstructor<TSVGProps>
    submitIconComponent?: React.JSXElementConstructor<TSVGProps>
    loadingIconComponent?: React.JSXElementConstructor<TSVGProps>
    translations: SearchBoxTranslations
    searchAsYouType: boolean
  }

export const CustomSearchBoxUI: React.FC<TCustomSearchBoxUIProps> = ({
  autoFocus,
  classNames = {},
  formRef,
  inputRef,
  loadingIconComponent: LoadingIcon = LoadingSpinner,
  onChange,
  onReset,
  onSubmit,
  placeholder,
  resetIconComponent: ResetIcon = XIcon,
  searchAsYouType,
  submitIconComponent: SubmitIcon = SearchIcon,
  translations,
  value,
  ...props
}) => {
  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()

    if (onSubmit) {
      onSubmit(e)
    }

    if (inputRef.current) {
      inputRef.current.blur()
    }
  }

  function handleReset(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()
    e.stopPropagation()

    onReset(e)

    if (inputRef.current) {
      inputRef.current.focus
    }
  }

  return (
    <div
      {...props}
      className={cx('ais-SearchBox', classNames.root, props.className)}
    >
      <form
        ref={formRef}
        action=''
        className={cx('ais-SearchBox-form', classNames.form)}
        noValidate
        onSubmit={handleSubmit}
        onReset={handleReset}
      >
        <input
          ref={inputRef}
          className={cx('ais-SearchBox-input', classNames.input)}
          autoComplete='off'
          autoCorrect='off'
          autoCapitalize='off'
          placeholder={placeholder}
          spellCheck={false}
          maxLength={512}
          type='search'
          value={value}
          onChange={onChange}
          autoFocus={autoFocus}
        />
        <button
          className={cx('ais-searchBox-submit', classNames.submit)}
          type='submit'
          title={translations.submitButtonTitle}
          hidden={searchAsYouType && value.length !== 0}
        >
          <SubmitIcon />
        </button>
        <button
          className={cx('ais-searchBox-reset', classNames.reset)}
          type='reset'
          title={translations.resetButtonTitle}
          hidden={value.length === 0}
        >
          <ResetIcon />
        </button>
        <span
          className={cx(
            'ais-searchBox-loadingIndicator',
            classNames.loadingIndicator
          )}
          hidden={true}
        >
          <LoadingIcon />
        </span>
      </form>
    </div>
  )
}

export default CustomSearchBoxUI
