/** Question Utils
 *  Contains common styling logic functiosn between question types
 */
// import { TQuestionType } from '@unionco/alaris-app-types'

// #region styling util functions
export const questionLayoutClasses: (numOfAnswers: number) => string = (
  numOfAnswers
) => {
  return numOfAnswers < 4 ? 'c-flow' : 'c-autoGrid c-autoGrid--layout-50-50'
}

export const inputSelectedClasses: (checked: boolean) => string = (checked) => {
  return checked
    ? 'u-text-black u-font-bold u-border-primary-500'
    : 'u-text-secondary-800 u-border-transparent'
}

export const inputBgClasses: (checked: boolean) => string = (checked) => {
  return checked ? 'u-bg-primary-500' : 'u-bg-white'
}
// #endregion styling util functions

/**
 * Started work on a more dynamic solution for debounce time of questionnaire reload
 * Currently it's checked in the question component, and leverages the branchTriggers
 * key of the question. But we may need a more dynamic solution based on some other factors
 * such as question type, and the number of answers.
 *
 * As an example a select multiple question, with +10 answers will likly need a higher debounce
 * time than a yes / no.
 *
 * But branch questions should have a lower debounce time so that it doesn't take too long for
 * new questions to appear
 */
// export const getQuestionDebounceValue = (questionType: TQuestionType) => {
//   switch(questionType) {
//     case 'selectMultiple':
//       // Get number of answers
//       return 500
//     case '':
//     default:
//       return 500
//   }
// }
