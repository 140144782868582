/** Authentication Component
 *
 *  Checks is user is authenticated,
 *  While waiting shows basic loading component
 *  If user is unauthenticated checks if route is protected or not
 *      if protected sends user to login
 *      if unprotected loads page without Global Context (Which currently provides user context)
 *  If user is authenticated adds global context for normal use.
 *
 *  User Context may be able to be removed from Global if we end up getting everything we need
 *  from the session hook
 */
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import type { FC, ReactNode } from 'react'

import { LoginPageSlug } from 'appConstants'

import { PostAuthGlobalContext } from 'context'

import { PageLoading } from 'components'

interface IAuthenticationProps {
  children: ReactNode
}

export const Authentication: FC<IAuthenticationProps> = ({ children }) => {
  const router = useRouter()
  const { pathname } = router
  const { data: session, status } = useSession()

  if (status === 'unauthenticated') {
    const unprotectedRoutes = [LoginPageSlug]
    const pathIsProtected = unprotectedRoutes.indexOf(pathname) === -1

    if (!session && pathIsProtected && pathname !== LoginPageSlug) {
      router.push(LoginPageSlug)
    } else {
      return <>{children}</>
    }
  }

  if (status === 'authenticated') {
    // Can't add reroute check for confidentiality agreement here because
    // it relies on context lower in the component tree
    return <PostAuthGlobalContext>{children}</PostAuthGlobalContext>
  }

  return <PageLoading />
}

export default Authentication
