import type {
  TImageDefinedWidthProps,
  TImageProps
} from '@unionco/alaris-app-types'

export const placeHolderImageUrl = {
  default:
    'https://as1.ftcdn.net/v2/jpg/02/48/42/64/1000_F_248426448_NVKLywWqArG2ADUxDq6QprtIzsF82dMF.jpg'
}

export const placeholderSmallImgFixture: TImageProps = {
  src: placeHolderImageUrl['default'],
  alt: ``,
  width: 50,
  height: 50
}

export const placeholderFillImgFixture: TImageProps = {
  src: placeHolderImageUrl['default'],
  alt: ``
}

export const clientLogoFixture: TImageDefinedWidthProps = {
  src: placeHolderImageUrl['default'],
  alt: ``,
  width: 150,
  height: 50
}
