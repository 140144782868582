/** FAQ Section Fixture / Test Data
 *
 *  Data is fed into two different components
 *  Sean: Christine, I'm still working through the relation between
 *  The <FAQSection /> which is for the questionnaire
 *  As well as
 *  The <FAQCard /> the dashboard variation
 */
import type { IFAQCardData, IFAQSectionData } from '@unionco/alaris-app-types'

import {
  cardItems,
  faqQuestionsFixture,
  faqVideoTestData
} from '@fixtures/faq/questions'

export const faqSectionFixture = {
  faqs: faqQuestionsFixture
} as IFAQSectionData

export const faqSectionWithVideoFixture = {
  ...faqSectionFixture,
  eyebrow: 'Video overview',
  video: faqVideoTestData,
  copy: 'Your answers to these questions, in conjunction with the next section, will help us quickly identify all your potential matches.'
} as IFAQSectionData

export const faqCardWithVideoFixture = {
  title: "Need Help? We've got you covered.",
  items: cardItems
} as IFAQCardData
