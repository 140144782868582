import { useBreakAtSize } from '@unionco/components'
import { useContext } from 'react'

import type { TSidebarSectionData } from '@unionco/alaris-app-types'

import { TPageName } from '@appConstants'

import { getSidebarRoute } from '../util'
import { getCurrentSegmentData } from 'utils'

import { AccountDropdown, NotificationMenu, IconHeader } from 'components'

import { SidebarContext } from '..'

export interface IQuestionnaireHeaderProps {
  currentSectionData: TSidebarSectionData
  pageName: TPageName
}

export const SidebarHeader: React.FC<IQuestionnaireHeaderProps> = ({
  currentSectionData,
  pageName
}) => {
  const { aboveBreakPoint: tabletLayout } = useBreakAtSize('sm')
  const { data, pageLocations, pageRoute } = useContext(SidebarContext)
  const { icon, title } = currentSectionData
  const currentSegment = getCurrentSegmentData(
    pageLocations,
    data as TSidebarSectionData
  )
  const currentSegmentTitle = currentSegment ? currentSegment.title : ''

  const headerIcon = currentSectionData.headerIconOverride
    ? currentSectionData.headerIconOverride
    : icon

  return (
    <div className='c-wrapper u-flex u-justify-between u-w-full'>
      <IconHeader
        icon={headerIcon}
        header={currentSegmentTitle}
        eyebrow={title}
        isLink={true}
        href={getSidebarRoute(pageRoute, data.key, 0, 0)}
      />
      <div className='c-cluster'>
        <NotificationMenu />
        <AccountDropdown mobileLayout={!tabletLayout} pageName={pageName} />
      </div>
    </div>
  )
}

export default SidebarHeader
