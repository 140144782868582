import React from 'react'
import { ITopPrioritiesData } from '@unionco/alaris-app-types'
import { ModuleCard } from 'components'
import { PriorityList } from './PriorityList'
import tw from 'twin.macro'


interface ITopPrioritiesProps {
  data: ITopPrioritiesData
  title?: string
  className?: string
  titleClassName?: string
  bgClassName?: string
  style?: React.CSSProperties;
}

export const TopPriorities: React.FC<ITopPrioritiesProps> = ({
  bgClassName,
  className,
  data: { info, items },
  title,
  titleClassName,
}) => {
  if (!items || items.length === 0) return <></>

  return (
    <ModuleCard.Body
      className='print:u-break-inside-avoid-page print:u-py-0' 
      bgClassName={bgClassName}
    >
      <ModuleCard.InfoHeader
        className={`u-w-full u-pb-200 print:u-pb-0 ${titleClassName}`}
        title={title || 'Your Top Priorities'}
        info={info}
        trackingName='top priorities module'
      />
      <div className={`${className}`}>
        <PriorityList items={items} />
      </div>
    </ModuleCard.Body>
  )
}

export default TopPriorities
