import { CaretIcon } from '@unionco/svg-icons'

import { StyledButton } from 'components'

interface ICollapseButton {
  children: string
  onClick: () => void
}

export const CollapseButton: React.FC<ICollapseButton> = ({
  children,
  onClick
}) => {
  return (
    <StyledButton
      type='button'
      theme='secondary'
      themeStyle='ghost'
      onClick={onClick}
      className='c-cluster | [--cluster-gutter:.5rem]'
    >
      {children}
      <CaretIcon iconDirection='down' />
    </StyledButton>
  )
}
