/**
 * Questionnaire based tracking
 */
import {
  TQuestionnaireCompleteTracking,
  TQuestionnaireNavigationTracking,
  TQuestionnaireStartTracking,
  TQuestionnaireStepTracking
} from '@types'

import { tracking } from './tracking'

/**
 * TQuestionnaireStartTrackingParams
 *
 * TQuestionnaireStartTracking without the event
 * because it's autofilled in the function
 */
type TQuestionnaireStartTrackingParams = Omit<
  TQuestionnaireStartTracking,
  'event'
>

/**
 * questionnaireStartTracking
 *
 * Runs once from the dashboard instance of the
 * section intro card based on a users progression.
 *
 * @param data: All TQuestionnaireStartTracking fields except event
 *
 * @returns void, the data is sent off to google analytics
 */
export const questionnaireStartTracking = (
  data: TQuestionnaireStartTrackingParams
): void => {
  tracking<TQuestionnaireStartTracking>({
    event: 'questionnaire_start',
    ...data
  })
}

/**
 * TQuestionnaireNavigationTrackingParams
 *
 * TQuestionnaireNavigationTracking without the event field
 * because it's auto filled in the function
 */
type TQuestionnaireNavigationTrackingParams = Omit<
  TQuestionnaireNavigationTracking,
  'event'
>

/**
 * questionnaireNavigationTracking
 *
 * @param data: All TQuestionnaireNavigationTracking fields except event
 *
 * @returns void, the data is sent off to google analytics
 */
export const questionnaireNavigationTracking = (
  data: TQuestionnaireNavigationTrackingParams
): void => {
  tracking<TQuestionnaireNavigationTracking>({
    event: 'questionnaire_nav',
    ...data
  })
}

/**
 * TQuestionnaireStepTrackingParams
 *
 * TQuestionnaireStepTracking without the event field
 * because it's auto filled in the function
 */
type TQuestionnaireStepTrackingParams = Omit<
  TQuestionnaireStepTracking,
  'event'
>

/**
 * questionSubmitTracking
 *
 * @param data: All TQuestionnaireStepTracking fields except event
 *
 * @returns void, the data is sent off to google analytics
 */
export const questionSubmitTracking = (
  data: TQuestionnaireStepTrackingParams
) => {
  tracking<TQuestionnaireStepTracking>({
    event: 'question_submission',
    ...data
  })
}

/**
 * TQuestionnaireCompleteTrackingParams
 *
 * TQuestionnaireCompleteTracking without the event field
 * because it's auto filled in the function
 */
type TQuestionnaireCompleteTrackingParams = Omit<
  TQuestionnaireCompleteTracking,
  'event'
>

/**
 * questionnaireCompleteTracking
 *
 * @param data: All TQuestionnaireCompleteTracking fields except event
 *
 * @returns void, the data is sent off to google analytics
 */
export const questionnaireCompleteTracking = (
  data: TQuestionnaireCompleteTrackingParams
) => {
  tracking<TQuestionnaireCompleteTracking>({
    event: 'questionnaire_complete',
    ...data
  })
}
