import { inputSelectedClasses } from '../util'

interface ISelectAnswer {
  checked: boolean
  children: React.ReactNode
  className?: string
  componentName: string
  onClick: () => void
  composition?: 'cluster' | 'repel'
}

export const SelectAnswer: React.FC<ISelectAnswer> = ({
  checked,
  children,
  className,
  componentName,
  composition = 'cluster',
  onClick,
  ...props
}) => {
  const selectedStyles = inputSelectedClasses(checked)
  const classes = className ? className : ''

  return (
    <div
      className={`b-${componentName} c-${composition} | u-flex u-items-center u-bg-primary-300 u-px-600 u-py-700 ${selectedStyles} u-rounded u-border-2 ${classes}`}
      onClick={() => onClick()}
      {...props}
    >
      {children}
    </div>
  )
}

export default SelectAnswer
