/**
 * Content Download tracking util function
 */
import { TContentDownloadTracking } from '@types'

import { tracking } from './tracking'

type TContentDownloadTrackingParams = Omit<TContentDownloadTracking, 'event'>

export const contentDownloadTracking = (
  data: TContentDownloadTrackingParams
): void => {
  tracking<TContentDownloadTracking>({
    event: 'content_download',
    ...data
  })
}
