import { Accordion, Card, Input } from '@unionco/components'
import { useEffect, useState } from 'react'

import { ILeaderFormData } from '@unionco/alaris-app-types'

import { fieldIsValid } from './utils'
import { cx } from '@unionco/utils'

import EntryControls from './EntryControls'
import EntryTitle from './EntryTitle'
import { NameInputs, ProfilePic } from './inputs'
import LabelAndInput from './labelAndInput'
import { inputStyling } from './mod'

interface ILeaderFormProps {
  data: ILeaderFormData
  entriesTitle: string
  index: number
  deleteEntry: (i: number) => void
  saveEntry: (i: number) => Promise<null | true | string[]>
  saved: boolean
}

export const LeaderForm: React.FC<ILeaderFormProps> = ({
  data: { biography, firstName, lastName, profilePic, title },
  deleteEntry,
  entriesTitle,
  index: i,
  saveEntry,
  saved
}) => {
  const [validation, setValidation] = useState<null | true | string[]>(null)
  const [entryTitle, setEntryTitle] = useState<string>(entriesTitle)

  /**
   * Entry title name use effect
   * Sets entry title to name of
   */
  useEffect(() => {
    if ((firstName && firstName.value) || (lastName && lastName.value)) {
      const name = cx(firstName.value, lastName.value)
      setEntryTitle(name)
    }
  }, [firstName, lastName, setEntryTitle])

  const updateValidation = async () => {
    const result = await saveEntry(i)
    setValidation(result)
  }

  return (
    <Card className='b-Entry u-p-500'>
      <Accordion.Item index={i}>
        <EntryTitle entryTitle={entryTitle} index={i} saved={saved} />
        <Accordion.Drawer index={i} className='c-flow u-flow-space--500'>
          <div className='c-cluster u-mt-500 u-justify-between'>
            <div className='c-flow u-flex-grow'>
              <div className='u-flex u-justify-between'>
                <NameInputs
                  firstName={firstName}
                  lastName={lastName}
                  validation={validation}
                />
                <ProfilePic
                  profilePic={profilePic}
                  className='u-basis-[calc(25%-1rem)]'
                />
              </div>

              <div className='c-flow u-basis-[calc(75%-1rem)]'>
                <div className='u-flex u-justify-between'>
                  <LabelAndInput
                    label='Title'
                    required
                    className='u-basis-[calc(50%-.5rem)]'
                  >
                    <Input
                      id=''
                      className={cx(
                        ' u-w-full',
                        fieldIsValid('title', validation)
                      )}
                      classNames={inputStyling}
                      placeholder='CEO'
                      defaultValue={title ? title.value : ''}
                      name='title'
                      required
                    />
                  </LabelAndInput>
                  <div className='u-basis-[calc(50%-.5rem)]'>
                    <LabelAndInput label='Biography' required>
                      <textarea
                        className='u-min-h-[5rem] u-w-full u-rounded u-border-2 u-border-secondary-400 u-p-400 u-text-secondary-800'
                        defaultValue={biography?.value}
                        name='biography'
                      />
                    </LabelAndInput>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EntryControls
            index={i}
            deleteEntry={deleteEntry}
            saveEntry={updateValidation}
          />
        </Accordion.Drawer>
      </Accordion.Item>
    </Card>
  )
}

export default LeaderForm
