/** Select Multiple Question Fixtures
 *  First item uses 3 answers to display answers in single column,
 *  Second use 4 to display answers in 2 columns on larger screens
 */
import type { ISelectOneQuestionData } from '@unionco/alaris-app-types'

import { basicAnswersFixture } from '@fixtures/phase/questionnaire'

export const selectOneSingleColumnQuestionFixture = {
  answered: false,
  answers: basicAnswersFixture({ numOfAnswers: 3 }),
  questionText: 'Select One Single Column Question Layout?',
  questionType: 'selectOne'
} as ISelectOneQuestionData

export const selectOneDoubleColumnQuestionFixture = {
  answered: false,
  answers: basicAnswersFixture({ numOfAnswers: 3 }),
  questionText: 'Select One 2 Column Layout Question?',
  questionType: 'selectOne'
} as ISelectOneQuestionData
