/**
 * Notes tracking util function
 *
 * Tracks when an admin saves notes within the questionnaire
 */
import type { TNotesTracking } from '@types'

import { tracking } from './tracking'

type TNotesTrackingParams = Omit<TNotesTracking, 'event'>

export const notesTracking = (data: TNotesTrackingParams): void => {
  tracking<TNotesTracking>({
    event: 'notes_saved',
    ...data
  })
}
