/** Module Card Body
 *  Card instance styled specifically for Modules
 */
import { Card } from '@unionco/components'
import tw, { TwStyle } from 'twin.macro'

import {
  TFlowInnerSpacingClass,
  TPaddingTokenClass
} from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

export interface IModuleCardBodyProps {
  bgColorClass?: TwStyle
  bgClassName?: string
  children: React.ReactNode
  className?: string
  flowspace?: TFlowInnerSpacingClass
  padding?: TPaddingTokenClass | null
}

export const ModuleCardBody: React.FC<IModuleCardBodyProps> = ({
  bgColorClass = tw`u-bg-white`,
  children,
  className,
  bgClassName,
  flowspace = 'u-flow-space-inner--400',
  padding = 'u-p-500 print:u-p-0'
}) => {
  return (
    <Card
      className={cx('c-flow', flowspace, padding, className)}
      bgColorClass={bgClassName ? tw`u-bg-primary-300` : bgColorClass}
    >
      {children}
    </Card>
  )
}

export default ModuleCardBody
