import { IconSVG } from '@unionco/svg-icons'

import { TSVGProps } from '@unionco/alaris-app-types'

export const FlagIcon: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <IconSVG
      viewBoxWidth={11}
      viewBoxHeight={12}
      style={{
        transform: 'translate(25%,0%)'
      }}
      {...props}
      ref={undefined}
    >
      <path
        d='M7.7039 4.25731L1.00087 1.00144C0.997023 0.999596 0.99263 0.999134 0.988785 0.997285C0.970661 0.989431 0.951989 0.982963 0.932218 0.977881C0.925628 0.976032 0.919037 0.974184 0.912448 0.972798C0.887184 0.967254 0.861372 0.96402 0.833912 0.96402C0.807001 0.96402 0.780639 0.967254 0.755377 0.972798C0.748787 0.974184 0.742196 0.976032 0.735606 0.977881C0.715834 0.982963 0.697162 0.989431 0.679039 0.997285C0.675195 0.999134 0.670801 0.999596 0.666957 1.00144C0.664211 1.00283 0.662563 1.00468 0.659817 1.00606C0.637299 1.01761 0.615881 1.03101 0.597208 1.04626C0.596659 1.04672 0.596659 1.04718 0.59611 1.04718C0.577986 1.06243 0.563157 1.07953 0.549978 1.09801C0.546133 1.10355 0.542838 1.10863 0.539543 1.11418C0.530207 1.1285 0.523067 1.14421 0.517025 1.15992C0.514828 1.16593 0.512632 1.17147 0.510435 1.17748C0.504394 1.19873 0.5 1.22091 0.5 1.24401L0.501098 7.75585V11.3292C0.501098 11.484 0.649933 11.6092 0.833916 11.6092C1.0179 11.6092 1.16673 11.484 1.16673 11.3292V7.91707L7.7033 4.74199C7.80655 4.69209 7.86971 4.59969 7.86971 4.49942C7.87026 4.39962 7.80655 4.30722 7.70385 4.25732L7.7039 4.25731Z'
        fill='white'
      />
    </IconSVG>
  )
}

export default FlagIcon
