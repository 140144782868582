/** Use Auth Refetch
 *  A wrapping hook for useRefetch that by default
 *  provides a header with a jwt token provided
 *  from the UserContext
 */
import { useContext } from 'react'

import type { TState } from '@unionco/alaris-app-types'

import { API_BASE } from 'appConstants'

import { TClientContext, UserContext } from 'context'

import { TRefetchReturn, useRefetch } from './useRefetch'

export const useAuthRefetch = <T>(
  slug: string,
  options?: RequestInit
): TState<T, 'data', 'error'> & TRefetchReturn => {
  const { jwt } = useContext(UserContext) as TClientContext

  const defaultHeader = {
    headers: {
      Authorization: `Bearer ${jwt}`
    }
  }

  const o = options
    ? {
        defaultHeader,
        ...options
      }
    : defaultHeader

  const { data, error, refetch } = useRefetch<T>(`${API_BASE}/api${slug}`, o)

  return {
    data,
    error,
    refetch
  }
}

export default useAuthRefetch
