/** SVG Ring
 * @see https://css-tricks.com/building-progress-ring-quickly/
 */
import { v4 as uuidv4 } from 'uuid'

import { TSVGProps } from '@unionco/alaris-app-types'

interface ISVGRing extends TSVGProps {
  percent: number
  radius: number
  strokeWidth: number
}

export const SVGRing: React.FC<ISVGRing> = ({
  percent,
  radius,
  strokeWidth,
  ...props
}) => {
  /*  Different way of handling stroke that isn't based on inner stroke but
        centering stroke. Could be useful in the future
    */
  // const normalizedRadius = radius - strokeWidth * 2
  // const circumference = normalizedRadius * 2 * Math.PI
  const circumference = radius * 2 * Math.PI
  const offset = circumference - (percent / 100) * circumference

  const id = uuidv4()
  const svgId = `SVGRing_${id}`
  const clipId = `SVGRingClip_${id}`

  return (
    <svg
      data-name='ring'
      width='100%'
      height='100%'
      viewBox={`0 0 ${radius * 2} ${radius * 2}`}
      {...props}
    >
      <defs>
        <circle
          id={svgId}
          stroke='currentColor'
          fill='transparent'
          strokeWidth={strokeWidth}
          // r={normalizedRadius}
          r={radius}
          cx={radius}
          cy={radius}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
        />
        <clipPath id={clipId}>
          <use xlinkHref={`#${svgId}`} />
        </clipPath>
      </defs>
      <g>
        <use
          xlinkHref={`#${svgId}`}
          clipPath={`url(#${clipId})`}
          strokeWidth={strokeWidth}
        />
      </g>
    </svg>
  )
}

export default SVGRing
