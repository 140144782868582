import axios from 'axios'
import { useContext, useEffect, useState } from 'react'

import { IInputValueProfilePic } from '@unionco/alaris-app-types'

import { API_BASE } from '@appConstants'

import { cx } from '@unionco/utils'

import { IAMContext, TClientContext, TIAMContext, UserContext } from 'context'

import { Img, UploadIcon, LoadingSpinner } from 'components'

interface IProfilePicProps {
  profilePic: IInputValueProfilePic
  className?: string
}

export const ProfilePic: React.FC<IProfilePicProps> = ({
  className,
  profilePic
}) => {
  const { jwt } = useContext(UserContext) as TClientContext
  const {
    IAMData: { companyId }
  } = useContext(IAMContext) as TIAMContext
  const [files, setFiles] = useState<FileList | null>(null)
  const [profilePicUrl, setProfilePicUrl] = useState<string>('')
  const [profilePicId, setProfilePicId] = useState<number | undefined>(
    undefined
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const validImg =
    profilePic && profilePic.value.value && profilePic.value.value !== ''

  useEffect(() => {
    if (!files) return
    const uploadImage = () => {
      setIsLoading(true)
      const data = new FormData()
      data.append('files', files[0])
      data.append('path', `${companyId}`)
      data.append('fileInfo', '{ "caption": "", "alternativeText": "" }')
      axios
        .post(`${API_BASE}/api/upload`, data, {
          headers: {
            Authorization: `Bearer ${jwt}`
          }
        })
        .then((res) => {
          setIsLoading(false)
          const { data } = res
          const { id, url } = data[0]
          setProfilePicUrl(url)
          setProfilePicId(id)
           console.log(url, id)
        })
        .catch((err) => {
          console.error(err)
          setIsLoading(false)
        })
    }

    uploadImage()
  }, [companyId, jwt, files])

  useEffect(() => {
    if (validImg) {
        setIsLoading(true)
          setProfilePicUrl(profilePic.value.value)
          setProfilePicId(
        profilePic.value.image ? Number(profilePic.value.image) : undefined
      )
      setIsLoading(false)
    }
  }, [profilePic, validImg, isLoading])

  return (
    <div
      className={cx(
        'c-flow | u-flow-space-inner--300 u-items-start u-self-stretch u-text-left',
        className
      )}
    >
      <h5 className='u-font-open-sans u-text-3 u-uppercase u-text-secondary-800'>
        Profile Image
      </h5>
      <div className='c-withLayer u-w-full u-flex-grow'>
        {profilePicUrl !== '' ? (
          <>
            <div className='c-frame u-h-full u-w-full'>
              <Img
                className='b-ProfilePic'
                src={profilePicUrl}
                alt={profilePicId ? profilePicId.toString() : ''}
                width={252}
                height={252}
              />
            </div>
            <div className='c-layer u-flex u-items-end u-justify-center u-py-400'>
              <span className='u-text-center u-font-open-sans u-text-5 u-font-normal u-text-white'>
                Edit Image
              </span>
            </div>
          </>
        ): isLoading == true ? (
          <div className= 'c-flow u-flex u-h-full u-w-full u-items-center u-justify-center u-rounded u-border-[.2rem] u-border-secondary-400 u-bg-white'>
            <div>
            <LoadingSpinner className='u-w-[2.5rem]'/>
            </div>
            <p>Loading...</p>
          </div>
          ) : (
          <div className='c-flow u-flex u-h-full u-w-full u-items-center u-justify-center u-rounded u-border-[.2rem] u-border-secondary-400 u-bg-white'>
            <div>
              <UploadIcon className='u-w-[2.5rem]' />
            </div>
            <p>Upload Image</p>
          </div>
          )}
        <form className='c-layer'>
          <input
            type='file'
            className='u-h-full u-w-full u-cursor-pointer u-opacity-0'
            name='profilePic'
            onChange={(e) => setFiles(e.target.files)}
          />
        </form>
      </div>
    </div>
  )
}

export default ProfilePic
