/** Agreement Wrapper
 *  If needs to agree route user to login
 *  Displays agreement when user needs to agree to new confidentiality agreement
 *  Otherwise allows them to continue to other content
 */
import { useRouter } from 'next/router'
import { useContext } from 'react'

import { TUserData } from '@unionco/alaris-app-types'

import { LoginPageSlug } from '@appConstants'

import { IAMContext, TIAMContext, UserContext } from 'context'

import { ConfidentialityAgreement } from 'components'

interface IAgreementWrapper {
  children: React.ReactNode
}

export const AgreementWrapper: React.FC<IAgreementWrapper> = ({ children }) => {
  const router = useRouter()
  const { pathname } = router
  const { showAgreement } = useContext(UserContext) as TUserData
  const {
    IAMData: { userType }
  } = useContext(IAMContext) as TIAMContext

  if (showAgreement && pathname !== LoginPageSlug) {
    router.push(LoginPageSlug)
  }
  if (showAgreement && userType !== 'admin') return <ConfidentialityAgreement />

  return <>{children}</>
}

export default AgreementWrapper
