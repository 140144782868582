import { cx } from '@unionco/utils'

interface IQuestionNumberIndicator {
  activeIndex: number
  numOfQuestions: number
}

export const QuestionNumberIndicator: React.FC<IQuestionNumberIndicator> = ({
  activeIndex,
  numOfQuestions
}) => {
  const indicatorStatuses: boolean[] = []
  for (let i = 0; i < numOfQuestions; i++) {
    if (i === activeIndex) {
      indicatorStatuses.push(true)
    } else {
      indicatorStatuses.push(false)
    }
  }

  return (
    <div className='u-flex u-w-full u-justify-center'>
      <div className='c-cluster | u-w-full u-max-w-[30rem] u-justify-center'>
        {indicatorStatuses.map((status, index) => {
          const opacityClass = status ? '' : ' u-opacity-[.15]'
          return (
            <div
              key={`questionIndicator_${index}`}
              className={cx(
                `u-h-[3px] u-max-w-[4.25rem] u-flex-grow u-bg-primary-500 `,
                opacityClass
              )}
            />
          )
        })}
      </div>
    </div>
  )
}

export default QuestionNumberIndicator
