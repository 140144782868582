/**
 * Format Currency
 *
 */

type TFormatCurrency = (value: string, currencySign?: string) => string

export const formatCurrency: TFormatCurrency = (value, currencySign = '$') => {
  const num: number = typeof value === 'number' ? value : Number(value)
  if (typeof num !== 'number') return `${currencySign}${value}`
  return `${currencySign}${num.toLocaleString()}`
}
