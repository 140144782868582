import type { Dispatch, SetStateAction } from 'react'
import { createContext } from 'react'

interface ISearchQuestionContext {
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
}

const initSearchQuestionContext = {
  searchValue: '',
  setSearchValue: () => {
    return null
  }
} as ISearchQuestionContext

export const SearchQuestionContext = createContext<ISearchQuestionContext>(
  initSearchQuestionContext
)
