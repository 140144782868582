/** Module Card Header
 *  A basic header component used for module cards
 *  that is built for profile (cim / buyer-at-a-glance)
 *  modules
 */
import { cx } from '@unionco/utils'

export interface IModuleCardHeaderProps {
  children?: React.ReactNode
  className?: string
  classNames?: {
    rightSide: string
  }
  title: string
}

export const ModuleCardHeader: React.FC<IModuleCardHeaderProps> = ({
  children,
  className,
  classNames,
  title
}) => {
  return (
    <div className={cx('c-repel', className)}>
      <h3 className='u-font-open-sans'>{title}</h3>
      <div className={cx(classNames?.rightSide)}>{children}</div>
    </div>
  )
}

export default ModuleCardHeader
