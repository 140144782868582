import Link from 'next/link'

interface IStepLink {
  alignment: string
  children: React.ReactNode
  href: string
  locked: boolean
  onClick?: () => void
}

export const StepLink: React.FC<IStepLink> = ({
  alignment,
  children,
  href,
  locked,
  onClick
}) => {
  const classes = `c-cluster | u-flex-grow u-flex-nowrap u-p-400 u-text-white ${alignment}`

  if (locked) {
    return (
      <div className={`${classes}`} title='Complete previous steps to unlock'>
        {children}
      </div>
    )
  }
  return (
    <Link href={href} className={classes} onClick={onClick}>
      {children}
    </Link>
  )
}

export default StepLink
