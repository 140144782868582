import { IconSVG } from '@unionco/svg-icons'

import { TSVGProps } from '@unionco/alaris-app-types'

export const QuestionMessageIcon: React.FC<TSVGProps> = ({ ...props }) => {
  return (
    <IconSVG viewBoxWidth={15} {...props} ref={undefined}>
      <path
        d='M6.79492 8.47754H8.20482V9.88744H6.79492V8.47754Z'
        fill='currentColor'
      />
      <path
        d='M7.50156 2.83789C6.30315 2.83789 5.38672 3.82482 5.38672 4.95274H6.79662C6.79662 4.52977 7.0786 4.24779 7.50156 4.24779C7.92453 4.24779 8.20651 4.52977 8.20651 4.95274C8.20651 5.16422 8.13602 5.37571 7.92453 5.51669C7.14909 6.08065 6.79662 6.8561 6.79662 7.77253H8.20651C8.20651 7.27907 8.3475 6.92659 8.77047 6.64461C9.26394 6.29214 9.61641 5.65768 9.61641 4.95274C9.61641 3.82482 8.69998 2.83789 7.50156 2.83789Z'
        fill='currentColor'
      />
      <path
        d='M7.50066 0.0175781C3.62344 0.0175781 0.451172 2.83737 0.451172 6.36211C0.451172 8.05399 1.22662 9.67537 2.56602 10.8738V14.1165L6.72521 12.6362C7.00719 12.7067 7.28917 12.7067 7.50066 12.7067C11.3779 12.7067 14.5501 9.88686 14.5501 6.36211C14.5501 2.83737 11.3779 0.0175781 7.50066 0.0175781ZM7.50066 11.2968C7.28917 11.2968 7.00719 11.2968 6.72521 11.2263H6.58422L3.97591 12.1427V10.2393L3.69393 10.0278C2.56602 9.04092 1.86107 7.77201 1.86107 6.36211C1.86107 3.61282 4.39888 1.42747 7.50066 1.42747C10.6024 1.42747 13.1402 3.61282 13.1402 6.36211C13.1402 9.11141 10.6024 11.2968 7.50066 11.2968Z'
        fill='currentColor'
      />
    </IconSVG>
  )
}

export default QuestionMessageIcon
