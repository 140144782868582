import type { FC } from 'react'

interface IInputAnswerReviewProps {
  title: string
  value: string
}

export const InputAnswerReview: FC<IInputAnswerReviewProps> = ({
  title,
  value
}) => {
  if (!title || !value) return <></>

  return (
    <p>
      <span>{title}</span>
      <span>{value}</span>
    </p>
  )
}
