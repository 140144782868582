import axios from 'axios'
import { useRouter } from 'next/router'
import { useContext, useEffect, useState } from 'react'

import {
  IPanelPhaseData,
  TClientData,
  TPhaseLevels
} from '@unionco/alaris-app-types'

import {
  API_BASE,
  AdminPanelPageSlug,
  BuyerQuestionnairePageSlug,
  DashboardPageSlug,
  SellerQuestionnairePageSlug
} from '@appConstants'

import { TUserContext, UserContext } from 'context/user'

import { Loading } from 'components'

import {
  IAMContext,
  IAMDataDefault,
  IIAMData,
  TImpersonationMode
} from './context'

interface IIAMContextWrapperProps {
  children: React.ReactNode
}

export const IAMContextWrapper: React.FC<IIAMContextWrapperProps> = ({
  children
}) => {
  // Router
  const router = useRouter()
  const { pathname } = router
  // User Context
  const userContextData = useContext(UserContext) as TUserContext
  const { jwt: jwtToken } = userContextData
  // Local State
  const [showResumeInitially, setShowResumeInitially] = useState<boolean>(false)
  const [IAM, setIAM] = useState<IIAMData>(IAMDataDefault)
  const [reload, setReload] = useState<boolean>(false)

  /**
   * Set show resume based on IAM context
   */
  const showResume = (data: IIAMData) => {
    if (!data) return
    // Returning user initial loc / prog check
    const { lastLocation, progression } = data

    for (const key in progression) {
      const isPhaseKey =
        key === 'phase' ||
        key === 'step' ||
        key === 'segment' ||
        key === 'question'
      if (
        progression[key as TPhaseLevels] !==
          lastLocation[key as TPhaseLevels] &&
        isPhaseKey
      ) {
        setShowResumeInitially(true)
      }
    }
  }

  /** Initalize User Context State Effect
   *
   *  Set state of show agreement
   *  If not an admin update progression and last location
   */
  useEffect(() => {
    if (!IAM) return
    if (IAM.userType !== 'admin') {
      showResume(IAM)
    }
  }, [IAM])

  /**
   * This is a basic setup for setting defaults,
   * but some of these will need to come from the API call
   * that will be added.
   */
  useEffect(() => {
    if (IAM.companyId !== 0) return
    /**
     * Check if companyId is 0 which indicates that state is set to the default found
     * in the context file.
     *
     * Then check if session data exists, and if it does pull default data from there,
     * otherwise load in the users default data.
     */
    const sessionData = sessionStorage.getItem('impersonationData')
    const dataSource = !!sessionData ? JSON.parse(sessionData) : userContextData
    const { companyId, companyTypeKey, companyName, id, timelineComplete, userType } =
      dataSource
    const newId = id ? id : 0
    const newUserType = userType ? userType : 'admin'
    const newCompanyId = companyId ? companyId : 0
    const newCompanyType = companyTypeKey ? companyTypeKey : null
    const newCompanyName = companyName ? companyName : 'Alaris'
    const newProgression =
      userType !== 'admin' ? dataSource.progression : IAMDataDefault.progression
    const newLastLocation =
      userType !== 'admin'
        ? dataSource.lastLocation
        : IAMDataDefault.lastLocation

    setIAM({
      id: newId,
      userType: newUserType,
      companyId: newCompanyId,
      companyType: newCompanyType,
      companyName: newCompanyName,
      progression: newProgression,
      lastLocation: newLastLocation,
      lastLocationIndexes: undefined,
      timelineComplete,
      impersonationMode: dataSource.impersonationMode || null,
      firstName: dataSource.firstName,
      lastName: dataSource.lastName,
      isPrimaryUser: dataSource.isPrimaryUser || false
    })
  }, [IAM.companyId, userContextData])

  /**
   * Rerun api call useEffect
   *
   * setReload exists in the user context so that progression and
   * last location are updated properly
   */
  useEffect(() => {
    if (!reload || !IAM) return
    const updateUserData = async () => {
      const slug =
        userContextData.userType !== 'admin'
          ? 'self'
          : `impersonate?userId=${IAM.id}`
      const result = await axios.get(`${API_BASE}/api/user/${slug}`, {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      })
      const { data } = result
      const updatedImpersonation = {
        ...IAM,
        progression: data.progression,
        lastLocation: data.lastLocation,
        lastLocationIndexes: undefined
      }
      sessionStorage.setItem(
        'impersonationData',
        JSON.stringify(updatedImpersonation)
      )
      setIAM(updatedImpersonation)
      setReload(false)
    }
    updateUserData()
  }, [IAM, reload, jwtToken, userContextData.userType])

  /**
   *  Impersonate Function
   *
   *  Accepts a user id
   */
  const impersonateUser = async (
    id: number,
    impersonationMode: TImpersonationMode,
    isPrimaryUser?: boolean,
    jumpToPhase?: IPanelPhaseData
  ) => {
    const result = await axios.get(
      `${API_BASE}/api/user/impersonate?userId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`
        }
      }
    )

    if (result.status === 200) {
      const data = result.data as TClientData
      const companyTypeKey = data.seller ? 'seller' : 'buyer'
      // Optional jumpToPhase allows an Admin user to jump into a previous portion of the timeline without actually modifying that user's progression or location
      if (jumpToPhase) {
        const keys = {
          phase: jumpToPhase.key,
          step: jumpToPhase.firstStepKey,
          segment: jumpToPhase.firstSegmentKey,
          question: null
        }
        data.progression = keys
        data.lastLocation = keys
      }
      const impersonationData = {
        id: data.id,
        userType: data.userType,
        companyId: data[companyTypeKey]?.id || 0,
        companyTypeKey: companyTypeKey,
        companyName: data[companyTypeKey]?.companyName || 'Alaris',
        progression: data.progression || IAMDataDefault.progression,
        lastLocation: data.lastLocation || IAMDataDefault.lastLocation,
        lastLocationIndexes: undefined,
        timelineComplete: data.timelineComplete,
        impersonationMode: impersonationMode,
        firstName: data.firstName,
        lastName: data.lastName,
        isPrimaryUser: isPrimaryUser || false
      }
      sessionStorage.setItem(
        'impersonationData',
        JSON.stringify(impersonationData)
      )
      setIAM(impersonationData)
    }
  }

  if (IAM.id === 0 || !IAM || reload)
    return <Loading debug='IAMContextWrapper' />

  /**
   * If user is an admin send them to admin-panel
   */
  if (
    (pathname === DashboardPageSlug ||
      pathname === SellerQuestionnairePageSlug ||
      pathname == BuyerQuestionnairePageSlug) &&
    userContextData.userType === 'admin' &&
    !IAM.impersonationMode
  ) {
    router.push(AdminPanelPageSlug)
  }

  /**
   * If non-admin user tries to access the admin dashboard reroute them to dashboard
   */
  if (pathname === AdminPanelPageSlug && IAM.userType !== 'admin') {
    router.push(DashboardPageSlug)
  }

  return (
    <IAMContext.Provider
      value={{
        IAMData: IAM,
        impersonateUser,
        showResumeInitially,
        setShowResumeInitially,
        setIAM,
        setReload
      }}
    >
      {children}
    </IAMContext.Provider>
  )
}
