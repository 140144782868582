import {
  BubbleDataPoint,
  Chart,
  ChartTypeRegistry,
  TooltipModel
} from 'chart.js'
import { Point } from 'chart.js/dist/core/core.controller'

export function customTooltip(
  context: {
    chart: Chart<
      keyof ChartTypeRegistry,
      (number | Point | [number, number] | BubbleDataPoint | null)[],
      unknown
    >
    tooltip:
      | TooltipModel<'bar'>
      | TooltipModel<'line'>
      | TooltipModel<'doughnut'>
  },
  name: string,
  tooltipTitle: string,
  bodyTransform?: (value: string) => string
): void {
  // Create ID
  const id = `${name}-chartjs-tooltip`
  // Tooltip Element
  let tooltipEl = document.getElementById(id)

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = id

    const tooltipTableStyling =
      'u-block u-bg-primary-300 u-text-2 u-text-center u-p-1 u-rounded u-drop-shadow u-caret u-pointer-events-none'

    tooltipEl.innerHTML = `<table class='${tooltipTableStyling}'></table>`
    document.body.appendChild(tooltipEl)
  }

  /**
   * Caret Pos needs to be determined on hover
   */
  const table = tooltipEl.getElementsByTagName('table')[0]
  table.classList.remove('u-caret-down')
  table.classList.remove('u-caret-up')
  table.classList.remove('u-translate-y-[-100%]')
  table.classList.remove('u-translate-y-[0]')
  const caretClasses =
    (context.tooltip.dataPoints[0].raw as number) < 0
      ? { caret: 'u-caret-up', transform: 'translate(-50%,0)' }
      : { caret: 'u-caret-down', transform: 'translate(-50%,-100%)' }
  table.classList.add(caretClasses.caret)
  table.style.transform = caretClasses.transform

  // Hide if no tooltip
  const tooltipModel = context.tooltip
  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = '0'
    return
  }

  // if positive value
  tooltipEl.classList.add('below')
  // else if negative value

  function getBody(bodyItem: {
    before: string[]
    lines: string[]
    after: string[]
  }) {
    return bodyItem.lines
  }

  // Set Text
  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || []
    const bodyLines = tooltipModel.body.map(getBody)

    let innerHtml = `<thead>`

    titleLines.forEach(function () {
      innerHtml += '<tr><th>' + tooltipTitle + '</th></tr>'
    })
    innerHtml += '</thead></tbody>'

    bodyLines.forEach(function (body) {
      const bodyText = !!bodyTransform ? bodyTransform(body[0].replace(/.*:\s*/, '')) : body
      const tooltipTextStyling = 'u-text-primary-500 u-font-bold u-text-3'
      const span = `<span class='${tooltipTextStyling}'>` + bodyText + '</span>'
      innerHtml += '<tr><td>' + span + '</td></tr>'
    })
    innerHtml += '</tbody>'

    const tableRoot = tooltipEl.querySelector('table') as HTMLTableElement
    tableRoot.innerHTML = innerHtml
  }

  const position = context.chart.canvas.getBoundingClientRect()

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1'
  tooltipEl.style.position = 'absolute'
  tooltipEl.style.left =
    position.left + window.pageXOffset + tooltipModel.caretX + 'px'
  tooltipEl.style.top =
    position.top + window.pageYOffset + tooltipModel.caretY + 'px'
}
