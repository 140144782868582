/** Points of Alignment Module Test Data
 *  Test data for the <AcquisitionModel /> module
 *  used in the profile template
 *  A seller only main module
 */
import { v4 as uuidv4 } from 'uuid'

import { shortParagraphFixture, titleAndCopyTestData } from '@fixtures/generic'
import { paragraphTestData } from '@fixtures/generic'

export const pointsOfAlignmentTestData = {
  moduleType: 'PointsOfAlignment',
  title: 'Points of Alignment',
  copy: paragraphTestData(),
  items: [
    titleAndCopyTestData(),
    titleAndCopyTestData(),
    titleAndCopyTestData(),
    titleAndCopyTestData(),
    titleAndCopyTestData(),
    titleAndCopyTestData(),
    titleAndCopyTestData(),
    titleAndCopyTestData(),
    titleAndCopyTestData()
  ],
  container: 1,
  key: uuidv4(),
  info: {
    copy: shortParagraphFixture
  }
}
