// Should probably make as a generic drawer similar to accordion
export interface IModuleCardViewMore {
  children: string
  title: string
}

export const ModuleCardViewMore: React.FC<IModuleCardViewMore> = ({
  children,
  title
}) => {
  return (
    <div>
      <button>{title}</button>
      <div>{children}</div>
    </div>
  )
}

export default ModuleCardViewMore
