import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useFetch } from 'usehooks-ts'
import axios from 'axios'


import {
  IPreAuthGlobalsData,
  TImageDefinedWidthProps
} from '@unionco/alaris-app-types'

import { API_BASE } from 'appConstants'

import { ErrorUI, Loading } from 'components'

import { PreAuthGlobalsContext } from './context'

interface IPreAuthGlobalsContextWrapper {
  children: React.ReactNode
}

export const PreAuthGlobalsContextWrapper: React.FC<
  IPreAuthGlobalsContextWrapper
> = ({ children }) => {
  const router = useRouter()
  const { data, error } = useFetch<IPreAuthGlobalsData>(
    `${API_BASE}/api/preauth-global`
  )
  const [pageLoaded, setPageLoaded] = useState<boolean>(false)

  const setPageLoadedTrue = () => {
    setPageLoaded(true)
  }

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      // This function will be called whenever the URL changes
      console.log('URL changed to:', url)

      // Initialize lastEndpoint as null
      let lastEndpoint = null;

      // Check if router.asPath is other than '/' or not null/undefined
      if (router.asPath && router.asPath !== '/') {
        lastEndpoint = router.asPath;
      }

      // If the URL is /login, set the callback URL
      // which will be used after the user logs in
      // to redirect them to the desired page.
      if (url === '/login') {
        axios.post(
          '/api/callbackURL', // apps/alaris-app-ui/src/pages/api/callbackURL.ts
          {
            endpoint: lastEndpoint
          }
        ).catch((error) => {
          console.error('Error setting callback url', error);
        });
      }

      setPageLoaded(false)
    }

    // Subscribe to the router's route change event
    router.events.on('routeChangeComplete', handleRouteChange)

    // Cleanup the event listener when the component unmounts
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  if (error) return <ErrorUI error={error} page />
  if (!data) return <Loading debug='PreAuthGlobalsContextWrapper' />

  const { companyLogos } = data

  /** Filter out logos without a valid companyLogo & companyName
   */
  const filteredLogos = companyLogos
    ? companyLogos.filter((logo) => logo.companyLogo && logo.companyName)
    : []

  /** Transform API company logos data into useable img type
   */
  const logos: TImageDefinedWidthProps[] = filteredLogos.map((logo) => {
    const { companyLogo, companyName, height, width } = logo
    return {
      src: companyLogo,
      alt: companyName,
      height,
      width
    }
  })

  const transformedData = {
    ...data,
    logos: logos.length > 0 ? logos : null,
    pageLoaded,
    setPageLoadedTrue
  }

  return (
    <PreAuthGlobalsContext.Provider value={transformedData}>
      {children}
    </PreAuthGlobalsContext.Provider>
  )
}

export default PreAuthGlobalsContextWrapper
