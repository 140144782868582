import { ITitledVideoData } from '@unionco/alaris-app-types'

import { ModuleCard, Video } from 'components'

interface IGeneralInformationProps {
  data: ITitledVideoData
}

export const TitledVideo: React.FC<IGeneralInformationProps> = ({
  data: { video }
}) => {
  const trackingName = 'titled video module'

  if (!video) return <></>
 
  return (
    <ModuleCard.Body className='print:u-hidden'>
      <ModuleCard.InfoHeader
        title={video.title || 'Overview'}
        trackingName={trackingName}
      />
      {video && <Video data={video} videoOnly={true} />}

    </ModuleCard.Body>
  )
}

export default TitledVideo
