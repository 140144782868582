import { CheckMarkIcon } from '@unionco/svg-icons'

import {
  BullseyeIcon,
  FlagIcon,
  ListIcon,
  PieChartIcon,
  TeamIcon
} from '../svg'

export type TStepIcon =
  | 'piechart'
  | 'checkmark'
  | 'list'
  | 'flag'
  | 'team'
  | 'bullseye'

interface IStepIconProps {
  // TODO: replace with step names? & rename step
  icon?: TStepIcon
}

export const StepIcon: React.FC<IStepIconProps> = ({ icon = 'piechart' }) => {
  switch (icon) {
    case 'piechart':
      return <PieChartIcon />
    case 'checkmark':
      return <CheckMarkIcon curved />
    case 'list':
      return <ListIcon />
    case 'flag':
      return <FlagIcon />
    case 'team':
      return <TeamIcon />
    case 'bullseye':
      return <BullseyeIcon />
    default:
      return <></>
  }
}

export default StepIcon
