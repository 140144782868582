import { Doughnut } from 'react-chartjs-2'

import { TBgTokenClass, TDoughnutChartData } from '@unionco/alaris-app-types'

import { themeColors } from 'styles'

import { Divider } from '../components'
import { customTooltip } from '../customTooltip'
import DotLabel from './dotLabel'
import {TitleAndCopy} from 'components'
interface IThreeYearGrowthMixProps {
  data: TDoughnutChartData
}

export const ThreeYearGrowthMix: React.FC<IThreeYearGrowthMixProps> = ({
  data
}) => {
  /**
   * Raw Color Values
   */
  const { primary, secondary } = themeColors
  const doughnutColors = [secondary['300'], primary['500'], primary['800']]
  const tailwindColors: TBgTokenClass[] = [
    'u-bg-secondary-300',
    'u-bg-primary-500',
    'u-bg-primary-800'
  ]

  /**
   *
   */
  const styledData = {
    ...data,
    datasets: data.datasets.map((set) => {
      return {
        ...set,
        backgroundColor: doughnutColors,
        borderColor: 'transparent'
      }
    })
  }
  return (
    <div className='c-flow print:u-w-1/2 print:u-mx-auto'>
      <Divider title='3 YR. GROWTH MIX' />
      <div>
        <div className='c-flow u-flex'>
          <Doughnut
            className='u-max-h-[13.5rem]'
            data={styledData}
            width='500px'
            height='500px'
            options={{
              responsive: true,
              maintainAspectRatio: false,
              aspectRatio: 1,
              // height: '500',
              plugins: {
                tooltip: {
                  enabled: false,
                  external: (context) =>
                    customTooltip(context, 'threeYearGrowthMix', 'GROWTH MIX')
                }
              },
              animation: {
                duration: 0
              }
            }}
          />
          <div>
            {data.labels?.map((label, i) => {
              return (
                <DotLabel
                  dotColor={tailwindColors[i]}
                  label={label as string}
                  key={`DotLabel_${i}`}
                />
              )
            })}
          </div>
        </div>
        {data.paragraph?.description != '' &&
          (
          <div className='u-mt-4'>
            <TitleAndCopy
              title={data.paragraph?.title}
              copy={data.paragraph?.description}
            />
          </div>)}
      </div>
    </div>
  )
}

export default ThreeYearGrowthMix
