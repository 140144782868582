import { Accordion, Tooltip } from '@unionco/components'
import { CaretIcon } from '@unionco/svg-icons'
import Link from 'next/link'
import tw from 'twin.macro'

import {
  TRangeReviewQuadrantTrackingName,
  useNavigationClickTracking
} from 'hooks/useNavigationClickTracking'

import { IFormattedRangeAnswerData } from '.'

interface IQuadrantBlockProps {
  answers: IFormattedRangeAnswerData[]
  index: number
  infoCopy: string
  title: string
  trackingName: TRangeReviewQuadrantTrackingName
}

/**
 * QuadrantBlock component
 *
 * @param answers IFormattedRangeAnswerData user answers from questionnaire
 * @see IFormattedRangeAnswerData
 * @param index number 0-3 index represent which quadrant it is, 0 high 3 low
 * @param infoCopy string tooltip reveal content
 * @param title string UI display name of quadrant
 * @param trackingName string
 *
 * @returns UI list of items organzied by importantance, pulled
 * from range segment range questions. Displayed in the questionnaire
 * review.
 */
export const QuadrantBlock: React.FC<IQuadrantBlockProps> = ({
  answers,
  index,
  infoCopy,
  title,
  trackingName
}) => {
  const { navTrackingOnClick } = useNavigationClickTracking()

  return (
    <Accordion.Item index={index}>
      <div className='c-repel | u-pb-600'>
        <div className='c-cluster | u-items-center'>
          <h3 className='u-font-bold'>{title}</h3>
          <Tooltip
            copy={infoCopy}
            iconColorClass={tw`u-text-secondary-800`}
            revealCallback={() =>
              navTrackingOnClick('info tooltip', `range review ${trackingName}`)
            }
          />
          {answers.length === 0 && (
            <p className=''>No answers in this section</p>
          )}
        </div>
        {answers.length > 0 && (
          <Accordion.Button index={index} locked={false}>
            <Accordion.Indicator index={index} animation='downToUp'>
              <CaretIcon className='u-text-secondary-500' direction='down' />
            </Accordion.Indicator>
          </Accordion.Button>
        )}
      </div>
      {answers.length > 0 && (
        <Accordion.Drawer index={index} className='c-flow | u-flow-space--200'>
          {answers.map((a, i) => {
            const { label, link } = a
            return (
              <div
                key={`a_${label}_${i}`}
                className='c-repel | u-flex-nowrap u-bg-primary-300 u-px-500 u-py-400 u-text-left'
              >
                <div className='c-cluster'>
                  <p className='o-subtitle-1 | u-text-black'>{label}</p>
                  {index === 0 && i === 0 && (
                    <p className='u-text-primary-500'>Most Important</p>
                  )}
                </div>
                <Link
                  className='u-whitespace-nowrap u-text-secondary-800 hover:u-text-primary-500'
                  href={link}
                >
                  Edit Answer
                </Link>
              </div>
            )
          })}
        </Accordion.Drawer>
      )}
    </Accordion.Item>
  )
}

export default QuadrantBlock
