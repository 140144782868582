/** Average Growth Statistics Test Data
 *  Test data for the <AverageGrowthStatistics /> module
 *  used in the profile template
 *  A buyer only main module (in sub container for side by side)
 *
 *  For information on specific data shown see documentation in corresponding type
 */
import { v4 as uuidv4 } from 'uuid'

import type { IAverageGrowthStatisticsData } from '@unionco/alaris-app-types'

import { basicMetricTestData } from '@fixtures/business'

export const averageGrowthStatisticsTestData = {
  moduleType: 'AverageGrowthStatistics',
  title: 'Average Growth Statistics',
  items: [
    basicMetricTestData(),
    basicMetricTestData(),
    basicMetricTestData(),
    basicMetricTestData()
  ],
  key: uuidv4(),
  container: 1,
  subContainer: 1
} as IAverageGrowthStatisticsData
