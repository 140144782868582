/* Sidebar Util functions
 *
 */
import {
  ILocationsIndexesData,
  IQuestionSegmentData,
  IQuestionnaireData,
  IQuestionnaireSummaryData,
  TPhaseStepData,
  TPhaseStepSummaryData,
  TQuestionnaireSegmentSummariesData
} from '@unionco/alaris-app-types'

import { StatusText } from '@unionco/alaris-utils'

// #region Routing Utils
export const updateRouteIndex = (
  index: number,
  modifier: number,
  parentLength: number,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newParentLoc: { [key: string]: any },
  key: string
) => {
  const newIndex = index + modifier

  // If index was first of array
  if (newIndex < 0) {
    const newLocation = newParentLoc ? newParentLoc : null
    const newParentLength =
      newLocation && newLocation.hasOwnProperty(key)
        ? newLocation[key].length
        : 1
    return {
      index: newParentLength - 1,
      mod: -1
    }
  }

  // If index was last of array
  if (newIndex >= parentLength)
    return {
      index: 0,
      mod: 1
    }

  return {
    index: newIndex,
    mod: 0
  }
}

/** Updated Questionnaire Location
 * Function for getting next or previous as a return object without updating the state
 * Used for questionnaire controls
 */
interface IgetAdjacentLocationsIndexesParams {
  locations: ILocationsIndexesData
  modifier: number
  sectionData: TPhaseStepData
  summaryData: TPhaseStepSummaryData[]
}

type TgetAdjacentLocationsIndexes = (
  p: IgetAdjacentLocationsIndexesParams
) => ILocationsIndexesData

export const getAdjacentLocationsIndexes: TgetAdjacentLocationsIndexes = ({
  locations,
  modifier,
  sectionData,
  summaryData
}) => {
  const { question, section, segment } = locations

  // Current Locations Data
  const currentSection = summaryData[section] as IQuestionnaireData
  const currentSegment = sectionData.segments[segment]

  const currentQuestions =
    segment === 0 || segment === currentSection.segments.length - 1
      ? [0]
      : (currentSegment as IQuestionSegmentData).questions

  // Current Locations Lengths
  const numOfQuestions = currentQuestions.length || 1
  const numOfSegments = currentSection.segments.length
  const numOfSections = summaryData.length

  // Get new index numbers
  const newQuestionLoc = updateRouteIndex(
    question || 0,
    modifier,
    numOfQuestions,
    currentSection.segments[segment + modifier],
    'questions'
  )
  const newSegmentLoc = updateRouteIndex(
    segment,
    newQuestionLoc.mod,
    numOfSegments,
    summaryData[section + modifier], // TODO: Replace with summary data
    'segments'
  )
  const newSectionLoc = updateRouteIndex(
    section,
    newSegmentLoc.mod,
    numOfSections,
    { sections: summaryData },
    'sections'
  )

  const questionIndex = currentSection.segments[
    newSegmentLoc.index
  ].hasOwnProperty('questions')
    ? newQuestionLoc.index
    : null

  return {
    section: newSectionLoc.index,
    segment: newSegmentLoc.index,
    question: questionIndex
  }
}

export const getSidebarRoute: (
  pageName: string,
  sectionKey: string,
  segment: number,
  question: number | null
) => string = (pageName, sectionKey, segment, question) => {
  const route = `${pageName}?section=${sectionKey}&segment=${segment}`
  if ((question === 0 || question) && question !== -1)
    return `${route}&question=${question}`

  return route
}
// #endregion Routing Utils

// #region: Mutate State Copy
export const getQuestionnaireStatus: (
  questionnaire: IQuestionnaireSummaryData | IQuestionnaireData
) => string = (questionnaire) => {
  const segments = questionnaire.segments.slice(
    1,
    -1
  ) as TQuestionnaireSegmentSummariesData

  const segmentStatuses = (segments as IQuestionSegmentData[]).map((s) =>
    s.status ? s.status.statusText : StatusText.INCOMPLETE.label
  )

  const inProgress =
    segmentStatuses.includes(StatusText.IN_PROGRESS.value) ||
    (segmentStatuses.includes(StatusText.COMPLETE.value) &&
      (segmentStatuses.includes(StatusText.INCOMPLETE.value) ||
        segmentStatuses.includes(StatusText.NOT_STARTED.value)))
  if (inProgress) return StatusText.IN_PROGRESS.label

  const notStarted = segments.every(
    (s) =>
      (s as IQuestionSegmentData).status.statusText ===
        StatusText.INCOMPLETE.value ||
      (s as IQuestionSegmentData).status.statusText ===
        StatusText.NOT_STARTED.value
  )
  if (notStarted) return StatusText.INCOMPLETE.label

  return StatusText.COMPLETE.label
}

export const getPhaseStepStatus = (
  section: TPhaseStepData | TPhaseStepSummaryData
) => {
  if (section.type === 'liveSession') {
    return section.status.percent === 100
      ? StatusText.COMPLETE.label
      : StatusText.NOT_STARTED.label
  }
  return getQuestionnaireStatus(section)
}
// #region: Mutate State Copy
