export const revenueByClientSegment = {
  // labels: ['0', '$500K', '$1M', '$1.5M', '$2M', '$2.5M', '$3M']
  labels: ['>35', '35-55', '55-65', '65-75', '75+'], // Static?
  datasets: [
    {
      borderWidth: 1,
      data: [20, 300, 4000, 50000, 1000000],
      label: ''
    }
  ]
}

export const threeYearAumTrend = {
  // labels: ['0', '$100M', '$200M', '$300M', '$400M']
  labels: ['2020', '2021', '2022'], // Needs to dynamically fill last three years
  datasets: [
    {
      borderWidth: 1,
      data: [1, 2, 3],
      label: ''
    }
  ]
}

export const hhsPerAumRange = {
  labels: [],
  datasets: [
    {
      borderWidth: 1,
      data: [1, 2, 3],
      label: ''
    }
  ]
}
export const trendingRevenueMix = {
  labels: [],
  datasets: [
    {
      borderWidth: 1,
      data: [1, 2, 3],
      label: ''
    }
  ]
}
export const threeYearGrowthMix = {
  labels: [],
  datasets: [
    {
      borderWidth: 1,
      data: [1, 2, 3],
      label: ''
    }
  ]
}
export const hhsServedByAdvisor = {
  labels: [],
  datasets: [
    {
      borderWidth: 1,
      data: [1, 2, 3],
      label: ''
    }
  ]
}

export const netNewAssetGrowth = {
  labels: [],
  datasets: [
    {
      borderWidth: 1,
      data: [1, 2, 3],
      label: ''
    }
  ]
}
