import React from 'react'

import {
  IBasicMetricData,
  IKeyRatiosAndMetricsData
} from '@unionco/alaris-app-types'

import { capitalizeAndSplit } from '@unionco/alaris-utils'

import { Divider, ModuleCard } from 'components'

import { MetricCard, MetricsColumn, ViewMoreSection } from '../components'

export interface IKeyRatiosAndMetricsProps {
  data: IKeyRatiosAndMetricsData
}

export const KeyRatiosAndMetrics: React.FC<IKeyRatiosAndMetricsProps> = ({
  data: {
    assetsUnderManagement,
    info,
    otherRevenueMetrics,
    title,
    topRevenueMetrics
  }
}) => {
  if (
    (!assetsUnderManagement || assetsUnderManagement.length === 0) &&
    (!otherRevenueMetrics || otherRevenueMetrics.length === 0) &&
    (!topRevenueMetrics || topRevenueMetrics.length === 0)
  )
    return <></>

  const formattedTopRevenueMetrics = topRevenueMetrics.map(
    ({ title, value }) => {
      let formattedTitle
      switch (title) {
        case 'revPerEE':
          formattedTitle = 'Rev Per EE'
          break
        case 'ebitdaMargin':
          formattedTitle = 'EBITDA Margin'
          break
        case 'threeYearAVGAUMGrowth':
          formattedTitle = '3-Year Average AUM Growth'
          break
        default:
          formattedTitle = capitalizeAndSplit(title)
      }

      // find and replace 'Avg' with 'Average'
      formattedTitle = formattedTitle.replace('Avg', 'Average')

      return {
        title: formattedTitle,
        value
      } as IBasicMetricData
    }
  )
  const topOtherRevenueMetrics = otherRevenueMetrics.slice(0, 3)
  const revealOtherRevenueMetrics = otherRevenueMetrics.slice(3)
  const topAssetsUnderManagement = assetsUnderManagement.slice(0, 3)
  const revealAssetsUnderManagement = assetsUnderManagement.slice(3)

  const viewMoreData =
    revealOtherRevenueMetrics.length !== 0 ||
    revealAssetsUnderManagement.length !== 0

  return (
    <ModuleCard.Body
      className='u-flow-space--500 print:u-w-full print:u-break-inside-avoid-page'
    >
      <ModuleCard.InfoHeader
        info={info}
        title={title || 'Assets & Revenue Metrics'}
        trackingName='key ratios and metrics module'
        className='print:u-break-before-page'
      />
      <div className='c-flow'>
        <Divider title='Revenue' />
        <div className='c-switcher u-flow-space--400 print:u-break-inside-avoid-page'>
          {formattedTopRevenueMetrics.map((metric, index) => {
            return (
              <MetricCard
                className='print:u-border-2'
                classNames={{
                  title: 'u-text-3'
                }}
                data={metric}
                key={`MetricCard_${index}`}
              />
            )
          })}
        </div>
      </div>
      {(topAssetsUnderManagement.length || topOtherRevenueMetrics.length > 0) && (
        <div className='c-autoGrid [--auto-grid-min-item-size:18rem]'>
          {
            (topAssetsUnderManagement.length > 0) && (
              <div className='c-flow u-flow-space--100w'>
                <Divider title='Assets under management' />
                <MetricsColumn data={topAssetsUnderManagement} />
              </div>
            )
          }
          {
            (topOtherRevenueMetrics.length > 0) && (
              <div className='c-flow u-flow-space--100w'>
                <Divider title='Revenue' />
                <MetricsColumn data={topOtherRevenueMetrics} />
              </div>
            )
          }
        </div>
      )
      }
      {viewMoreData && (
        <ViewMoreSection
          openButtonText='Section Expand'
          closeButtonText='Section Close'
          className='u-mt-0 print:u-break-inside-avoid-page'
        >
          <div className='c-autoGrid [--auto-grid-min-item-size:18rem]'>
            <div className='c-flow'>
              <div className='u-flow-space--100'>
                {revealAssetsUnderManagement && (
                  <MetricsColumn
                    data={revealAssetsUnderManagement}
                    useTopDivider
                  />
                )}
              </div>
            </div>
            <div>
              {revealOtherRevenueMetrics && (
                <MetricsColumn data={revealOtherRevenueMetrics} useTopDivider />
              )}
            </div>
          </div>
        </ViewMoreSection>
      )}
    </ModuleCard.Body>
  )
}

export default KeyRatiosAndMetrics
