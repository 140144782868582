/** Collapsible Item Card
 *
 */
import { Accordion } from '@unionco/components'
import type { TAccordionItemElement } from '@unionco/components'

export interface ICollapsibleItemsCardBody {
  children: TAccordionItemElement[]
}

export type TCollapsibleItemsCardBodyComponent =
  React.FC<ICollapsibleItemsCardBody>

export const CollapsibleItemsCardBody: TCollapsibleItemsCardBodyComponent = ({
  children
}) => {
  return (
    <div>
      <Accordion.Main>{children}</Accordion.Main>
    </div>
  )
}

export default CollapsibleItemsCardBody
