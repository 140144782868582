/** Loading Spinner Icon
 *
 */
import { IconSVG } from '@unionco/svg-icons'
import { forwardRef, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { TSVGProps } from '@unionco/alaris-app-types'

const LoadingDot = forwardRef<
  SVGCircleElement,
  React.SVGProps<SVGCircleElement>
>(({ ...props }, ref) => {
  return <circle ref={ref} fill='currentColor' {...props} />
})

LoadingDot.displayName = 'LoadingDot'

interface ILoadingSpinner extends TSVGProps {
  dotCount?: number
  size?: number
}

export const LoadingSpinner: React.FC<ILoadingSpinner> = ({ dotCount = 8 }) => {
  const spinnerRef = useRef<SVGElement>(null)
  const dotsRef = useRef<Array<SVGCircleElement | null>>([])
  const boxSize = 50
  const animationTime = 2000

  /** Dots Animation Effect
   *
   */
  useEffect(() => {
    if (!dotsRef) return
    const animate = () => {
      dotsRef.current.forEach((dot, index) => {
        if (!dot) return
        dot.animate(
          [
            { transform: 'scale(1)', opacity: 1, color: '' },
            { transform: 'scale(0.96)', opacity: 0.5, color: '' },
            { transform: 'scale(1)', opacity: 1 }
          ],
          {
            duration: animationTime,
            delay: (index * animationTime) / dotCount,
            iterations: Infinity
          }
        )
      })
    }

    animate()
  }, [dotCount, dotsRef])

  /** Dots Animation Effect
   *
   */
  useEffect(() => {
    if (!spinnerRef.current) return
    spinnerRef.current.animate(
      [
        {
          transform: 'rotate(0deg)'
        },
        {
          transform: 'rotate(360deg)'
        }
      ],
      {
        duration: animationTime * 10,
        iterations: Infinity
      }
    )
  }, [spinnerRef])

  const radius = boxSize / 2 - boxSize / 10
  const center = boxSize / 2
  const angleStep = (Math.PI * 2) / dotCount

  const dots = Array.from({ length: dotCount }, (_, index) => {
    const angle = angleStep * index
    const x = center + Math.sin(angle) * radius
    const y = center + Math.cos(angle) * radius

    return (
      <LoadingDot
        key={`LoadingSpinner_${index}_${uuidv4()}`}
        ref={(el) => {
          return (dotsRef.current[index] = el)
        }}
        cx={x}
        cy={y}
        r={boxSize / 14}
      />
    )
  })

  return (
    <IconSVG
      className='u-aspect-square u-w-14 u-text-primary-500'
      viewBoxWidth={boxSize}
      ref={spinnerRef}
    >
      {dots}
    </IconSVG>
  )
}

export default LoadingSpinner
