/** Timeline Item Component
 *  This component displays a single point within the timeline
 *  parent component, used in the dashboard template.
 *
 *  Visullay it shows a centered status ring with icon on top
 *  as bullets on the timeline. Below it shows a phase step name
 *  followed by either 'complete', estimaed time, or live session date
 */
import { forwardRef } from 'react'

import { type TPhaseStepSummaryData } from '@unionco/alaris-app-types'

import { timelineStepStatus } from '../utils'
import { isCompletedPhase } from '@unionco/alaris-utils'
import { getPhaseStepPercent } from 'utils'

import { SectionStatusIndicator } from 'components'

import { TimelineStatusText } from '../TimelineStatusText'

export type TTimelineItemProps = TPhaseStepSummaryData & {
  currentSectionIndex: number // Moved to context
  dateTimeText: string
  index: number
  stepKey?: string
}

export type TTimelineStepStatus = 'complete' | 'current' | 'future'

export const TimelineItem = forwardRef<HTMLDivElement, TTimelineItemProps>(
  (
    {
      currentSectionIndex,
      dateTimeText,
      icon,
      index,
      status: { statusText },
      stepKey,
      title,
      type
    },
    ref
  ) => {
    const percent = getPhaseStepPercent(type, statusText)

    const allPhasesComplete = stepKey ? isCompletedPhase(stepKey) : false

    return (
      <div
        key={`${title}_timelineStep`}
        className='c-flow | u-relative u-items-center u-text-center'
      >
        <div ref={ref || null}>
          <SectionStatusIndicator
            icon={icon}
            isTimeline
            percent={percent}
            phaseStepStatus={timelineStepStatus(index, currentSectionIndex)}
            type={type}
          />
        </div>
        <div>
          <h3>{title}</h3>
          {!allPhasesComplete && (
            <TimelineStatusText
              statusText={statusText}
              stepType={type}
              dateTimeText={dateTimeText}
            />
          )}
        </div>
      </div>
    )
  }
)

TimelineItem.displayName = 'TimelineItem'

export default TimelineItem
