import { useContext } from 'react'

import { ILockedCardData } from '@unionco/alaris-app-types'

import { ValuationCalculatorPageSlug } from '@appConstants'

import { IAMContext, TIAMContext } from 'context'

import { useNavigationClickTracking } from 'hooks/useNavigationClickTracking'

import { StyledButton, UnlockableAreaCard } from 'components'

interface IValuationEstimateProps {
  data: ILockedCardData
}

export const ValuationEstimate: React.FC<IValuationEstimateProps> = ({
  data: { info }
}) => {
  const {
    IAMData: { timelineComplete }
  } = useContext(IAMContext) as TIAMContext
  const { navTrackingOnClick } = useNavigationClickTracking()

  return (
    <UnlockableAreaCard
      title='Valuation Estimate'
      locked={!timelineComplete}
      lockedMessage={'IOS 3'}
      info={info}
    >
      <StyledButton
        type='a'
        theme='primary'
        themeStyle='outline'
        href={ValuationCalculatorPageSlug}
        className='u-justify-center u-text-center'
        onClick={() =>
          navTrackingOnClick(
            'valuation calculator',
            'unlockable valuation estimate'
          )
        }
      >
        Valuation Calculator
      </StyledButton>
    </UnlockableAreaCard>
  )
}

export default ValuationEstimate
