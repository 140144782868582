/**
 * This utility checks a phase key and returns true if the user is in the 'Completed' phase
 * The completed phase is the last phase in the questionnaire and is used as a placeholder phase
 * to show the user that they have completed the questionnaire. It does not have any questions
 */

const QuestionnaireCompletedKeys = [
  'end-of-questionnaire-buyer',
  'end-of-questionnaire-seller'
]

export const isCompletedPhase = (key: string) => {
  return QuestionnaireCompletedKeys.includes(key)
}
