import { createContext } from 'react'

export interface IProfileTemplateContext {
  companyName: string
}

const ProfileTemplateContextDefault = { companyName: '' }
export const ProfileTemplateContext = createContext<IProfileTemplateContext>(
  ProfileTemplateContextDefault
)
