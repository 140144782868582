import { ChartOptions } from 'chart.js'

import { ITitledDescriptionData, TLineChartData } from '@unionco/alaris-app-types'

import { LineChart } from 'components/Chart'

import { themeColors } from 'styles'

import { Divider } from '../components'
import DotLabel, { TDotLabels } from './dotLabel'
import { TitleAndCopy } from 'components/generic'

interface ITrendingRevenueProps {
  data: TLineChartData
  options?: Partial<ChartOptions<'line'>>
}

export const TrendingRevenue: React.FC<ITrendingRevenueProps> = ({
  data,
  options
}) => {
  const { primary, secondary } = themeColors
  /**
   * Raw Color Values
   */
  const lineColors = [
    primary['500'],
    primary['800'],
    secondary['500'],
    secondary['300']
  ]

  /**
   * Label Data
   */
  const lineLabels: TDotLabels = [
    { dotColor: 'u-bg-primary-500', label: data.datasets[0].label || '' },
    { dotColor: 'u-bg-primary-800', label: data.datasets[1].label || '' },
    { dotColor: 'u-bg-secondary-500', label: data.datasets[2].label || '' },
    { dotColor: 'u-bg-secondary-300', label: data.datasets[3].label || '' }
  ]

  return (
    <div className='c-flow'>
      <Divider title='TRENDING REVENUE MIX' />
      <div className='c-flow'>
        <div className='u-flex u-justify-between'>
          {lineLabels.map((l, index) => {
            const { dotColor, label } = l
            return (
              <DotLabel
                dotColor={dotColor}
                label={label}
                key={`DotLabel_${index}`}
              />
            )
          })}
        </div>
        <LineChart
          data={data}
          options={{
            ...options,
            animation: {
              duration: 0
            }
          }}
          lineColors={lineColors}
        />
      </div>
      {data.paragraph?.description != '' &&
        (<TitleAndCopy
          title={data.paragraph?.title}
          copy={data.paragraph?.description}
        />)}
    </div>
  )
}

export default TrendingRevenue
