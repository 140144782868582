/** Confidentiality Agreement Test Data
 *
 */
import type { IConfidentialityAgreementData } from '@unionco/alaris-app-types'

import { paragraphTestData } from '..'

export const confidentialityAgreementFixture = {
  agreementText: `${paragraphTestData()} ${paragraphTestData()} ${paragraphTestData()} ${paragraphTestData()} ${paragraphTestData()}`,
  title: 'Confindentaility Agreement',
  copy: 'Please read and aceept to continue'
} as IConfidentialityAgreementData
