/** Global Context
 *
 *  Global constants context
 */
import { createContext } from 'react'

import { IPostAuthGlobalData } from '@unionco/alaris-app-types'

export const ConstantsContext = createContext<IPostAuthGlobalData | null>(null)

export default ConstantsContext
