/**
 * Loading component
 *
 * Generic loading component
 */
import { useEffect } from 'react'

import { TBgTokenClass } from '@unionco/alaris-app-types'

import { cx } from '@unionco/utils'

import { AuthBGUI, LoadingSpinner } from 'components'

interface ILoadingProps {
  bgColor?: TBgTokenClass
  children?: React.ReactNode
  className?: string
  size?: 'fill' | 'screen'
  useBg?: boolean
  prodHost?: string
  debug: string
}

export const Loading: React.FC<ILoadingProps> = ({
  bgColor,
  children,
  className,
  debug,
  prodHost = process.env.NEXT_PUBLIC_PROD_HOST,
  size,
  useBg
}) => {
  const sizeClasses =
    size === 'screen' ? 'u-h-screen u-w-screen' : 'u-h-full u-w-full'
  const defaultClasses = `u-flex u-items-center u-justify-center`
  const classes = cx(defaultClasses, sizeClasses, bgColor, className)
  const content = children ? children : <LoadingSpinner />

  useEffect(() => {
    if (window.location.hostname !== prodHost) {
      console.debug('Loading: ', debug)
    }
  })

  if (useBg) {
    return (
      <AuthBGUI>
        <div className={classes}>{content}</div>
      </AuthBGUI>
    )
  }

  return <div className={classes}>{content}</div>
}

export default Loading
