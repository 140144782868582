/** Select Multiple
 *  Question type for an array of checkboxes
 */
import { CheckMarkIcon } from '@unionco/svg-icons'
import { useContext, useEffect, useMemo, useState, useCallback, useRef } from 'react'

import { TAnswerTrackingMap } from '@types'
import type {
  IQuestionSegmentData,
  IQuestionnaireData,
  ISelectMultipleQuestionData
} from '@unionco/alaris-app-types'

import { inputBgClasses, questionLayoutClasses } from '../util'
import { debounce } from '@unionco/utils'

import {
  getCurrentQuestionData,
  submissionSuccessful
} from 'components/questionnaire/util'
import { getCurrentSegmentData } from 'utils'
import { isAdminTracking } from 'utils/tracking'
import { questionSubmitTracking } from 'utils/tracking/questionnaire'

import { TUserContext, UserContext } from 'context'

import { Img, SidebarContext } from 'components'

import { QuestionContext } from '../context'
import SelectAnswer from '../selectAnswer'

interface ISelectMultipleQuestionProps {
  data: ISelectMultipleQuestionData
}

export const SelectMultipleQuestion: React.FC<ISelectMultipleQuestionProps> = ({
  data: { answers, key, otherAnswer, selectedAnswer }
}) => {
  const { userType } = useContext(UserContext) as TUserContext
  const { data: stepData, pageLocations } = useContext(SidebarContext)
  const { submitAnswer } = useContext(QuestionContext)
  const [submitted, setSubmitted] = useState<boolean>(false)
  const [currentlySelected, setCurrentlySelected] = useState<number[] | null>(selectedAnswer)
  const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null)


  const updateAnswers = async (index: number, checked: boolean) => {
    let currentSelections = !!currentlySelected ? [...currentlySelected] : null
    if (!currentSelections) {
      // Make an array if it isn't
      currentSelections = []
    }

    if (checked) {
      // Make null if unselecting the only selectedAnswer item
      if (currentSelections.length === 1) {
        currentSelections = null
      } else {
        // or remove item from array
        const selectedIndex = currentSelections.indexOf(index)
        currentSelections.splice(selectedIndex, 1)
      }
    } else {
      // Add item if not unselecting
      currentSelections.push(index)
      currentSelections.sort((a, b) => a - b)
    }

    // Update local component state
    setCurrentlySelected(currentSelections)

    // Update application state of questionnaire
    const newState = { ...stepData } as IQuestionnaireData
    const currentSegment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData
    const currentQuestion = getCurrentQuestionData(
      pageLocations,
      newState
    ) as ISelectMultipleQuestionData
    if (!currentQuestion.selectedAnswer) currentQuestion.selectedAnswer = []

    currentQuestion.selectedAnswer = currentSelections

    newState.segments[pageLocations['segment']] = currentSegment

    const answered = !!currentSelections
    const result = await submitAnswer(answered, newState, pageLocations)
    if (submissionSuccessful(result)) {
      setSubmitted(true)
    }
  }

  const changeAnswer = async (value: string) => {
    const newState = { ...stepData } as IQuestionnaireData
    const currentSegment = getCurrentSegmentData(
      pageLocations,
      newState
    ) as IQuestionSegmentData
    const currentQuestion = getCurrentQuestionData(
      pageLocations,
      newState
    ) as ISelectMultipleQuestionData

    currentQuestion.otherAnswer = value
    newState.segments[pageLocations['segment']] = currentSegment

    const result = await submitAnswer(true, newState, pageLocations)
    if (submissionSuccessful(result)) {
      setSubmitted(true)
    }
  }


  const onChange = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      if (!e || !e.target) return
      const { target } = e
      const newValue = (target as HTMLInputElement).value

      changeAnswer(newValue)

    }),
    [changeAnswer]
  )

  /**
   * Tracking debouncing
   */

  const debouncedTrackingHandler = useMemo(
    () =>
      debounce((currentlySelected: number[] | null) => {
        if (Array.isArray(currentlySelected)) {
          const answerTracking: TAnswerTrackingMap = {}
          currentlySelected?.forEach((answerIndex: number) => {
            answerTracking[`answer_${answerIndex}`] = answers[answerIndex].label
          })
          const isAdmin = isAdminTracking(userType)
          questionSubmitTracking({
            is_admin_key: isAdmin,
            ...answerTracking
          })
          setSubmitted(false)
        }
      }, 2000),
    [answers, userType]
  )

  useEffect(() => {
    if (!submitted) return
    debouncedTrackingHandler(currentlySelected)
  }, [debouncedTrackingHandler, currentlySelected, submitted])

  const layoutClasses = questionLayoutClasses(answers.length)

  return (
    <form className={layoutClasses}>
      {answers.map((answer, index) => {
        const { image, label, value } = answer
        const checked = currentlySelected
          ? currentlySelected.includes(index)
          : false
        const inputBgColor = inputBgClasses(checked)

        return (
          <SelectAnswer
            componentName='selectMultiple'
            key={`questionnaire${pageLocations['section']}_segment${pageLocations['segment']}_SelectMultiple_${index}`}
            onClick={() => updateAnswers(index, checked)}
            checked={checked}
            composition={image ? 'repel' : 'cluster'}
          >
            <input
              className={'u-hidden'}
              title={`answer${index}`}
              name={`SelectMultiple_${key}`}
              type='checkbox'
              value={value}
              checked={checked}
              onChange={() => {
                return null
              }}
            />
            <div className='u-flex u-w-full'>
              <div className={`${inputBgColor} u-flex u-h-[1.875rem] u-w-[1.875rem] u-min-w-[1.875rem] u-items-center u-justify-center u-rounded u-border-[0.25rem] u-border-white  u-mr-4`}>
                {checked && (
                  <div className='u-h-3/5 u-w-3/5 u-text-white'>
                    <CheckMarkIcon />
                  </div>
                )}
              </div>

              {
                currentlySelected !== null &&
                  currentlySelected.includes(answers.length - 1) &&
                  label === 'Other' ? (
                  <div className='u-flex u-w-full u-items-center u-bg-primary-300'>
                    <div className='u-space-x-9 u-w-full'>
                      <input
                        ref={inputRef as React.RefObject<HTMLInputElement>}
                        type="text"
                        onChange={(e) => onChange(e)}
                        onClick={(e) => e.stopPropagation()}
                        className='u-w-full u-font-normal u-pl-2'
                        required={true}
                        defaultValue={otherAnswer}
                        placeholder="Please Explain."
                      />
                    </div>
                  </div>
                ) : (
                  <label className='u-text-left' htmlFor={`answer${index}`}>
                    {label}
                  </label>
                )
              }

            </div>

            {image && (
              <div>
                <Img {...image} />
              </div>
            )}
          </SelectAnswer>
        )
      })}

    </form>
  )
}

export default SelectMultipleQuestion
