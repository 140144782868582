import { useState } from 'react'
import { InstantSearch } from 'react-instantsearch-hooks-web'

import type {
  ISearchQuestionData,
  TMeiliSearchAnswerData
} from '@unionco/alaris-app-types'

import { searchClient } from 'context'

import { useAuthFetch } from 'hooks'

import ErrorUI from 'components/Error'
import { Loading } from 'components/Loading'

import SearchQuestionUI from './SearchQuestionUI'
import { SearchQuestionContext } from './context'

interface ISearchQuestionProps {
  data: ISearchQuestionData
}

export const SearchQuestion: React.FC<ISearchQuestionProps> = ({ data }) => {
  const [searchValue, setSearchValue] = useState<string>('')
  const { numberOfDefaultHits, searchIndex } = data
  const { data: answers, error } = useAuthFetch<TMeiliSearchAnswerData[]>(
    `/search/${searchIndex}/top/${numberOfDefaultHits || 8}`
  )

  if (error)
    return (
      <ErrorUI text='There was an issue on our end getting the most popular options' />
    )
  if (!answers) return <Loading debug='SearchQuestion' />

  return (
    <SearchQuestionContext.Provider value={{ searchValue, setSearchValue }}>
      <InstantSearch searchClient={searchClient} indexName={data.searchIndex}>
        <SearchQuestionUI popularAnswers={answers} />
      </InstantSearch>
    </SearchQuestionContext.Provider>
  )
}

export default SearchQuestion
