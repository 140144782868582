import { INegotiationPairs } from '@unionco/alaris-app-types';
import { IAMContext, TIAMContext } from 'context'
import { useContext } from 'react'

import { ModuleCard } from 'components'
import tw from 'twin.macro'


// interface INegotiationPairsProps {
//     title: string;
//     alignments: any;
// }

export const NegotiationPairs: React.FC<any> = ({ title, alignments }) => {
    const { IAMData: { impersonationMode } } = useContext(IAMContext) as TIAMContext;
    const selectedAlignment = alignments[0]?.find((alignment: any) => alignment.title === title);
    return (
        <div>
            <ModuleCard.Body
                bgColorClass={tw`u-bg-primary-300`}
            >
                <table className="table-auto text-center w-full">
                    <thead className="u-p-2">
                        <tr>
                            <th><p className="u-text-center u-text-secondary-800 u-font-bold u-text-4">{impersonationMode === 'adminControl' ? '' : 'Alignment Question'}</p></th>
                            <th><p className="u-text-center u-text-secondary-800 u-font-bold u-text-4">{impersonationMode === 'adminControl' ? 'Seller Answer' : 'Your Answer'}</p></th>
                            <th><p className="u-text-secondary-800 u-text-center u-font-bold u-text-4">Buyer Answer</p></th>
                        </tr>
                    </thead>
                    <tbody >
                        {selectedAlignment?.questionDetails?.map((detail: any, index: number) => {
                            if (detail.sellerQuestion === null && detail.buyerQuestion === null && detail.sellerAnswer === null && detail.buyerAnswer === null) {
                                return null;
                            }

                            detail.sellerAnswer = Array.isArray(detail.sellerAnswer) ? detail.sellerAnswer.join(', ') : detail.sellerAnswer;
                            detail.buyerAnswer = Array.isArray(detail.buyerAnswer) ? detail.buyerAnswer.join(', ') : detail.buyerAnswer;

                            return (
                                <tr key={index}>
                                    <td className="u-border u-text-secondary-800 u-p-2 u-m-2 u-w-1/3">
                                        <p className="u-font-bold u-text-3">{detail.sellerQuestion || detail.buyerQuestion}</p>
                                    </td>
                                    <td className="u-border u-text-grayscale-500 u-p-4 u-w-1/3">
                                        <p className="u-text-3">{detail.sellerAnswer}</p>
                                    </td>
                                    <td className="u-border u-text-grayscale-500 u-p-4 u-w-1/3">
                                        <p className="u-text-3">{detail.buyerAnswer}</p>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </ModuleCard.Body>
        </div>
    );
};

export default NegotiationPairs;
