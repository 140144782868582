import { Input } from '@unionco/components'

import LabelAndInput from '../labelAndInput'
import { inputStyling } from '../mod'

interface IBonusCompensationProps {
  defaultValue: string
}

export const BonusCompensationInput: React.FC<IBonusCompensationProps> = ({
  defaultValue
}) => {
  return (
    <LabelAndInput label='Bonus Compensation'>
      <Input
        id=''
        className='u-w-full'
        classNames={inputStyling}
        defaultValue={defaultValue}
        placeholder='Bonus Compensation'
        step='1'
        name='bonusCompensation'
        type='number'
      />
    </LabelAndInput>
  )
}

export default BonusCompensationInput
