/** Styled Button Utils
 *  Functions for getting correct tailwind classes to style different buttons
 *  This currently leverages the tailwind "White list", which is an array of
 *  tailwind classNames that are safe to assume will be used.
 *  White list can be found in `styles/whitelist.ts`
 */
import { TTokenColor } from '@unionco/alaris-app-types'

import { twBgColor, twBorderColor, twTextColor } from 'styles/whiteList'

import { TButtonTheme, TOutlineButtonTheme, TSolidButtonTheme } from '.'

interface IStyledButtonArguments {
  // TODO replace with theme colors
  main: TTokenColor
  secondary?: TTokenColor
  hoverMain?: TTokenColor
  hoverSecondary?: TTokenColor
  disabled: boolean
  disabledMain?: TTokenColor
  disabledSecondary?: TTokenColor
}

// #region: Solid Style Button
export const solidStyle: (obj: IStyledButtonArguments) => string = ({
  disabled,
  disabledMain,
  disabledSecondary,
  main,
  secondary
}) => {
  const borderSize = 'u-border'
  const secondaryColor = secondary ? secondary : main
  const disabledMainColor = disabledMain ? disabledMain : main
  const disabledSecondaryColor = disabledSecondary
    ? disabledSecondary
    : disabledMainColor

  if (disabled) {
    return `u-opacity-[.6] ${borderSize} ${twTextColor[disabledSecondaryColor]} ${twBgColor[disabledMainColor]} ${twBorderColor[disabledMainColor]}`
  }

  const defaultState = `${twBgColor[main]} ${twTextColor[secondaryColor]} ${twBorderColor[main]}`

  return `${borderSize} ${defaultState}`
}

export const themedSolidStyle = (
  theme: TSolidButtonTheme,
  disabled: boolean
) => {
  switch (theme) {
    case 'primary':
      return solidStyle({
        main: 'primary-500',
        secondary: 'white',
        disabled,
        disabledMain: 'secondary-300',
        disabledSecondary: 'secondary-900'
      })
    case 'primary300':
      return solidStyle({
        main: 'primary-300',
        secondary: 'black',
        disabled,
        disabledMain: 'secondary-300',
        disabledSecondary: 'secondary-900'
      })
    case 'danger':
      return solidStyle({
        main: 'danger-500',
        secondary: 'white',
        disabled,
        disabledMain: 'secondary-300',
        disabledSecondary: 'secondary-900'
      })
    case 'secondary':
      return solidStyle({
        main: 'secondary-800',
        secondary: 'white',
        disabled
      })
    case 'dark':
      return solidStyle({
        main: 'primary-700',
        disabled
      })
    case 'light':
      return solidStyle({
        main: 'white',
        disabled
      })
    default:
      return ''
  }
}
// #endregion: Solid Style Button

/* #region: Outline Style */
export const outlineStyle: (obj: IStyledButtonArguments) => string = ({
  disabled,
  hoverMain,
  hoverSecondary,
  main,
  secondary
}) => {
  const borderSize = 'u-border'
  const secondaryColor = secondary ? secondary : main
  const hoverColorMain = hoverMain ? hoverMain : main
  const hoverColorSecondary = hoverSecondary ? hoverSecondary : hoverColorMain

  if (disabled) {
    return ''
  }

  const defaultState = `${twBorderColor[secondaryColor]} ${twTextColor[main]}`
  const hoverState = `hover:${twBgColor[hoverColorMain]} hover:${twBorderColor[hoverColorMain]} hover:${twTextColor[hoverColorSecondary]}`

  return `${borderSize} ${defaultState} ${hoverState}`
}

export const themedOutlineStyle = (
  theme: TOutlineButtonTheme,
  disabled: boolean
) => {
  // const borderSize = 'u-border'
  switch (theme) {
    case 'primary':
      return outlineStyle({
        main: 'primary-500',
        hoverMain: 'primary-500',
        hoverSecondary: 'white',
        disabled
        // disabledMain: ''
      })
    case 'secondary': {
      return outlineStyle({
        main: 'secondary-800',
        hoverMain: 'primary-500',
        hoverSecondary: 'white',
        disabled
        // disabledMain: ''
      })
    }
    case 'secondaryAlt': {
      return outlineStyle({
        main: 'secondary-800',
        secondary: 'primary-300',
        hoverMain: 'primary-500',
        hoverSecondary: 'white',
        disabled
        // disabledMain: ''
      })
    }
    case 'dark':
      return outlineStyle({
        main: 'black',
        disabled
        // disabledMain: ''
      })
    case 'darkAlt':
      return outlineStyle({
        main: 'secondary-800',
        secondary: 'secondary-400',
        disabled
      })
    case 'light':
      return outlineStyle({
        main: 'white',
        disabled
      })
    default:
      return ''
  }
}
/* #endregion: Outline Style */

/* #region: Ghost Style */
export const ghostStyle: (ojb: IStyledButtonArguments) => string = ({
  hoverMain,
  main
}) => {
  const hoverColorMain = hoverMain ? hoverMain : main

  const defaultState = `${twTextColor[main]}`
  const hoverState = `hover:${twTextColor[hoverColorMain]}`

  return `${defaultState} ${hoverState}`
}

export const themedGhostStyle = (theme: TButtonTheme, disabled: boolean) => {
  switch (theme) {
    case 'primary':
      return ghostStyle({
        main: 'primary-500',
        disabled
      })
    case 'secondary':
      return ghostStyle({
        main: 'secondary-800',
        hoverMain: 'primary-500',
        disabled
      })
    case 'dark':
      return ghostStyle({
        main: 'secondary-800',
        hoverMain: 'primary-500',
        disabled
      })
    case 'light':
      return ghostStyle({
        main: 'white',
        disabled
      })
    default:
      return ''
  }
}
/* #endregion: Ghost Style */
