import { v4 as uuidv4 } from 'uuid'

import type {
  IBasicMetricData,
  TBasicMetricColumnData
} from '@unionco/alaris-app-types'

export const basicMetricTestData = (): IBasicMetricData => {
  return {
    title: 'Metric Title',
    value: '$value%',
    key: uuidv4()
  }
}

export const customMetricTestData = (
  title: string,
  value: string
): IBasicMetricData => {
  return {
    title,
    value,
    key: uuidv4()
  }
}

export const basicMetricBarTestData = (): IBasicMetricData => {
  return {
    subText: 'Subtext string content',
    title: 'Metric Title',
    value: '$value%',
    key: uuidv4()
  }
}

export const basicMetricColumnData = [
  basicMetricBarTestData(),
  basicMetricBarTestData(),
  basicMetricBarTestData()
] as TBasicMetricColumnData
