/** Custom Searchbar
 *
 *  A near duplicate of instantsearch
 *  searchbox component with an added searchValue
 *  prop so that the state can be held in a higher component
 *  Fitting the niche use case of having a search bar inside of
 *  a another component like a question that might cause the
 *  search bar to reload reseting it's state at unintended times.
 *
 */
import { useEffect, useRef, useState } from 'react'
import { useSearchBox } from 'react-instantsearch-hooks-web'

import CustomSearchBoxUI, { TCustomSearchBoxUIProps } from './UI'

export type TQueryHook = (
  query: string,
  search: (value: string) => void
) => void

type UiProps = Pick<
  TCustomSearchBoxUIProps,
  | 'inputRef'
  | 'onChange'
  | 'onReset'
  | 'onSubmit'
  | 'value'
  | 'autoFocus'
  | 'translations'
>

type TCustomSearchBoxProps = Partial<
  Pick<
    TCustomSearchBoxUIProps,
    'className' | 'classNames' | 'onSubmit' | 'placeholder'
  >
> & {
  queryHook?: TQueryHook
  /**
   * Whether to trigger the search only on submit.
   * @default true
   */
  searchAsYouType?: boolean
  searchValue?: string
  translations?: Partial<UiProps['translations']>
}

export const CustomSearchBox: React.FC<TCustomSearchBoxProps> = ({
  queryHook,
  searchAsYouType = true,
  searchValue,
  translations,
  ...props
}) => {
  const { query, refine } = useSearchBox(
    { queryHook },
    { $$widgetType: 'ais.searchBox' }
  )
  const [inputValue, setInputValue] = useState(query)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!searchValue) return
    if (searchValue !== query) {
      refine(searchValue)
    }
  }, [query, searchValue, setInputValue, refine])

  function setQuery(newQuery: string) {
    setInputValue(newQuery)

    if (searchAsYouType) {
      refine(newQuery)
    }
  }

  function onReset() {
    setQuery('')

    if (!searchAsYouType) {
      refine('')
    }
  }

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setQuery(e.currentTarget.value)
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    if (!searchAsYouType) {
      refine(inputValue)
    }

    if (props.onSubmit) {
      props.onSubmit(e)
    }
  }

  if (query !== inputValue && document.activeElement !== inputRef.current) {
    setInputValue(query)
  }

  const uiProps: UiProps = {
    inputRef,
    onChange,
    onReset,
    onSubmit,
    value: inputValue || searchValue || '',
    translations: {
      submitButtonTitle: 'Submit the search query',
      resetButtonTitle: 'Clear the search query',
      ...translations
    }
  }
  return (
    <CustomSearchBoxUI
      searchAsYouType={searchAsYouType}
      {...props}
      {...uiProps}
    />
  )
}

export default CustomSearchBox
