import { IconSVG } from '@unionco/svg-icons'

export const ClockIcon: React.FC = ({ ...props }) => {
  return (
    <IconSVG viewBoxWidth={15} {...props} ref={undefined}>
      <path
        d='M7.5 0.758789C3.6392 0.758789 0.5 3.89798 0.5 7.75879C0.5 11.6196 3.6392 14.7588 7.5 14.7588C11.3608 14.7588 14.5 11.6196 14.5 7.75879C14.5 3.89798 11.3608 0.758789 7.5 0.758789ZM7.5 1.67183C10.8668 1.67183 13.587 4.39194 13.587 7.75879C13.587 11.1256 10.8668 13.8457 7.5 13.8457C4.13315 13.8457 1.41304 11.1256 1.41304 7.75879C1.41304 4.39194 4.13315 1.67183 7.5 1.67183ZM7.5 2.73705C7.24797 2.73705 7.04348 2.94154 7.04348 3.19357V7.75879C7.04348 8.01082 7.24797 8.21531 7.5 8.21531H11.7609C12.0129 8.21531 12.2174 8.01082 12.2174 7.75879C12.2174 7.50676 12.0129 7.30227 11.7609 7.30227H7.95652V3.19357C7.95652 2.94154 7.75203 2.73705 7.5 2.73705Z'
        fill='#2C76F0'
      />
    </IconSVG>
  )
}

export default ClockIcon
