/** Metrics Column
 *
 */
import React from 'react'

import { IBasicMetricData } from '@unionco/alaris-app-types'

import { MetricBar } from 'components'

export interface IMetricsColumnProps {
  data: IBasicMetricData[]
  useTopDivider?: boolean
}

export const MetricsColumn: React.FC<IMetricsColumnProps> = ({
  data,
  useTopDivider
}) => {
  return (
    <div>
      {data.map((metric, index) => {
        const dividerCheck = useTopDivider ? true : index !== 0
        const { key } = metric
        const uuid = key ? key : index
        return (
          <React.Fragment key={`Metric_${uuid}`}>
            {dividerCheck && <div className='o-line' />}
            <MetricBar {...metric} />
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default MetricsColumn
