/** Live Session Intro Fixture
 *
 */
import { v4 as uuidv4 } from 'uuid'

export const liveSessionIntroFixture = {
  title: 'Intro',
  introCopy: 'Intro paragrpah text',
  dateScheduled: '2/20/2023',
  key: uuidv4()
}
