import type {
  IQuestionSegmentData,
  IQuestionnaireData,
  ISectionStatus
} from '@unionco/alaris-app-types'

import { calcPercent, setSectionStatusText } from '..'
import { StatusText } from '../../constants'

export const setQuestionnaireStatus = (questionnaire: IQuestionnaireData) => {
  const questionSegments = [...questionnaire.segments]
    .slice(1, -1)
    .filter((segment) => segment.isActive) as IQuestionSegmentData[]
  const numOfQuestionSegments = questionSegments.length
  const numCompletedSegments = questionSegments.filter(
    (s) => s.status.statusText === StatusText.COMPLETE.value
  ).length
  const numStartedSegments = questionSegments.filter(
    (s) => s.status.statusText === StatusText.INCOMPLETE.value
  ).length
  const percent = calcPercent(numCompletedSegments, numOfQuestionSegments)
  return {
    percent,
    statusText: setSectionStatusText(
      numOfQuestionSegments,
      numCompletedSegments,
      numStartedSegments
    )
  } as ISectionStatus
}
