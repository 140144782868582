import { IBasicMetricData } from '@unionco/alaris-app-types'

export const MetricBar: React.FC<IBasicMetricData> = ({
  subText,
  title,
  value
}) => {
  return (
    <div className='u-flex u-justify-between u-py-400 print:u-py-0'>
      <div className='c-flow'>
        <p className='o-subtitle-1'>{title}</p>
        <p className={`u-mt-0 u-text-1${subText ? '' : ' placeholder'}`}>{subText || '\u00A0'}</p>
      </div>
      <p className='o-h3 u-font-bold u-text-primary-500'>{value}</p>
    </div>
  )
}

export default MetricBar
