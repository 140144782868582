import FacebookLogo from './facebook'
import InstagramLogo from './instagram'
import TwitterLogo from './twitter'

export * from './instagram'
export * from './twitter'
export * from './facebook'

export const SocialIcons: () => JSX.Element[] = () => {
  return [
    <FacebookLogo key='faceBookLogo' />,
    <InstagramLogo key='instagramLogo' />,
    <TwitterLogo key='twitterLogo' />
  ]
}

export default SocialIcons
