import React from 'react'

import { IClientProfileModuleData } from '@unionco/alaris-app-types'

import { ModuleCard, TitleAndCopy, Video } from 'components'

interface IClientProfilesModuleProps {
    data: IClientProfileModuleData
}

export const ClientProfilesModule: React.FC<
    IClientProfilesModuleProps
> = ({ data: { info, items, title } }) => {
    if (!items || items.length === 0) return <></>

    return (
        <ModuleCard.Body>
            <ModuleCard.InfoHeader
                info={info}
                title={title || 'Client Profile'}
                trackingName='client profiles module'
            />
            {items.map((item, index) => {
                const { video } = item
                const content = <TitleAndCopy title={item.title} copy={item.value} />

                const elements = video ? (
                    <div className='c-cluster u-items-start lg:u-flex-nowrap print:u-break-inside-avoid'>
                        <div className='u-w-1/3 print:u-hidden'>
                            <Video data={video} />
                        </div>
                        <div className='u-w-2/3 print:u-w-full'>
                            {content}
                        </div>
                    </div>
                ) : (
                    content
                )
                return (
                    <React.Fragment key={`TitleAndCopy_${index}`}>
                        {index !== 0 && <div className='o-line' />}
                        {elements}
                    </React.Fragment>
                )
            })}
        </ModuleCard.Body>
    )
}

export default ClientProfilesModule
