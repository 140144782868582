export const TwitterLogo: React.FC = ({}) => {
  return (
    <svg
      width='48'
      height='40'
      viewBox='0 0 48 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M43.0312 9.77778C43.0938 10.0285 43.125 10.4359 43.125 11C43.125 14.3219 42.5 17.6439 41.25 20.9658C40 24.2251 38.2188 27.2336 35.9062 29.9915C33.6562 32.6866 30.7188 34.8803 27.0938 36.5727C23.4688 38.265 19.4688 39.1111 15.0938 39.1111C9.59375 39.1111 4.5625 37.6382 0 34.6923C0.6875 34.755 1.46875 34.7863 2.34375 34.7863C6.90625 34.7863 11 33.3761 14.625 30.5556C12.4375 30.5556 10.5 29.9288 8.8125 28.6752C7.1875 27.359 6.0625 25.7293 5.4375 23.7863C6.0625 23.849 6.65625 23.8803 7.21875 23.8803C8.09375 23.8803 8.96875 23.7863 9.84375 23.5983C8.34375 23.2849 7 22.6581 5.8125 21.7179C4.625 20.7778 3.6875 19.6496 3 18.3333C2.3125 16.9544 1.96875 15.4815 1.96875 13.9145V13.7265C3.34375 14.5413 4.8125 14.9801 6.375 15.0427C3.4375 13.037 1.96875 10.2792 1.96875 6.76923C1.96875 5.01425 2.4375 3.35328 3.375 1.78632C5.8125 4.85755 8.78125 7.30199 12.2812 9.11966C15.8438 10.9373 19.625 11.9402 23.625 12.1282C23.5 11.3761 23.4375 10.6239 23.4375 9.87179C23.4375 7.17664 24.375 4.85755 26.25 2.91453C28.1875 0.97151 30.5 0 33.1875 0C36.0625 0 38.4688 1.03419 40.4062 3.10256C42.6562 2.66382 44.75 1.88034 46.6875 0.752137C45.9375 3.07123 44.5 4.88889 42.375 6.20513C44.25 5.95442 46.125 5.42165 48 4.60684C46.625 6.61254 44.9688 8.33618 43.0312 9.77778Z'
        fill='#48505E'
      />
    </svg>
  )
}

export default TwitterLogo
