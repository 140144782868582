/** Live Session Summary Fixture
 *
 */
import type { ILiveSessionData } from '@unionco/alaris-app-types'

export const liveSessionSummaryFixture = {
  icon: 'piechart',
  status: {
    percent: 0,
    statusText: 'notStarted'
  },
  type: 'liveSession',
  title: 'Live Session Step Name'
} as ILiveSessionData
