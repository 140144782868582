/** Pre Auth Global Context
 *
 *  Currently used for Authentication images and cms based content
 */
import { createContext } from 'react'

import type {
  IConfidentialityAgreementData,
  ICopyBlockData,
  TImageDefinedWidthProps
} from '@unionco/alaris-app-types'

import { confidentialityAgreementFixture } from '@fixtures/authentication'
import { copyBlockFixture } from '@fixtures/generic/copyBlock'

interface IPreAuthGlobalsContext {
  contactEmail: string // `${string}@${string}.${string}`
  confidentialityAgreement: IConfidentialityAgreementData
  loginBackground: string
  logos: TImageDefinedWidthProps[] | null
  loginPageLoginForm: ICopyBlockData
  loginPageEmailError: ICopyBlockData
  loginPageEmailSuccess: ICopyBlockData
  loginPageLoggedIn: ICopyBlockData
  pageLoaded: boolean
  setPageLoadedTrue: () => void
}

export const clientLogoSize = {
  width: 150,
  height: 50
}

const initPreAuthGlobalsData = {
  contactEmail: '',
  confidentialityAgreement: confidentialityAgreementFixture,
  loginBackground: '',
  logos: [
    {
      alt: '',
      src: '',
      ...clientLogoSize
    }
  ],
  loginPageLoginForm: copyBlockFixture(),
  loginPageLoggedIn: copyBlockFixture(),
  loginPageEmailError: copyBlockFixture(),
  loginPageEmailSuccess: copyBlockFixture(),
  pageLoaded: false,
  setPageLoadedTrue: () => {
    return undefined
  }
}

export const PreAuthGlobalsContext = createContext<IPreAuthGlobalsContext>(
  initPreAuthGlobalsData
)

export default PreAuthGlobalsContext
