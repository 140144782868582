/** Business Summary Test Data
 *  Test data for the <BusinessSummary /> module
 *  used in the profile template
 *  A shared main module, always appears first
 */
import { v4 as uuidv4 } from 'uuid'

import type { IBusinessSummaryModuleData } from '@unionco/alaris-app-types'

import { paragraphTestData } from '@fixtures/generic'
import { placeholderSmallImgFixture } from '@fixtures/media'

export const businessSummaryModuleTestData = {
  moduleType: 'BusinessSummary',
  companyName: 'Business Name',
  logo: placeholderSmallImgFixture,
  website: {
    href: 'https://www.google.com',
    displayText: 'google.com'
  },
  description: paragraphTestData(),
  key: uuidv4(),
  container: 1
} as IBusinessSummaryModuleData
